import { DELETE_SERVICE_CATION_ACTION, ADD_SERVICE_CATION_ACTION, USING_SUB_SERVICE_CATION_ACTION, RETURN_SUB_SERVICE_CATION_ACTION, DELETED_CATEGORY_CATION_ACTION, EDIT_SERVICE_CATION_ACTION, SET_SERVICES_CATION_ACTION, ADD_SERVICE_IMG_CATION_ACTION } from './constants.js';

export const addServiceCationAction = (payload) => ({
    type: ADD_SERVICE_CATION_ACTION,
    payload,
});

export const deleteServiceCationAction = (payload) => ({
    type: DELETE_SERVICE_CATION_ACTION, 
    payload, 
});

export const usingSubServiceCationAction = (payload) => ({
    type: USING_SUB_SERVICE_CATION_ACTION, 
    payload, 
});

export const returnSubServiceCationAction = (payload) => ({
    type: RETURN_SUB_SERVICE_CATION_ACTION, 
    payload, 
});

export const deletedCategoryCationAction = (payload) => ({
    type: DELETED_CATEGORY_CATION_ACTION, 
    payload, 
});

export const editServiceCationAction = (payload) => ({
    type: EDIT_SERVICE_CATION_ACTION, 
    payload, 
});

export const setServicesCationAction = (payload) => ({
    type: SET_SERVICES_CATION_ACTION, 
    payload, 
});

export const addServicesImgCationAction = (payload) => ({
    type: ADD_SERVICE_IMG_CATION_ACTION, 
    payload, 
});







