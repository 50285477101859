import { categoryInfoSelector } from "../../Store/Categories/selectors";
import { useDispatch, useSelector } from "react-redux";
import { selectCategoryCationAction } from "../../Store/Categories/actions";
import { currentLanguageSelector } from "../../Store/Language/selectors";

export const Filters = () => {
    const categories = useSelector(categoryInfoSelector);
    const currentLanguage = useSelector(currentLanguageSelector);

    const dispatch = useDispatch();

    const handleChangeFilter = (e) => {
        dispatch(selectCategoryCationAction({ id: e.target.id }));
    }

    return (
        <div className="filters">
            {categories.map(el => {
                return (<button key={el.id}
                    id={el.id}
                    onClick={handleChangeFilter}
                    className={"filters__el " + (el.isChosen ? "chosen" : '')}>
                    {el[currentLanguage]}
                </button>)
            })}
        </div>
    );
}