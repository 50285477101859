import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { ROUTS } from '../../Constants/Routes';
import IMAGES from "./../../images/profile/images";
import { useSelector } from 'react-redux';
import { subscriptionsSelector } from '../../Store/Subscriptions/selectors';
import { currentLanguageSelector } from '../../Store/Language/selectors';

export const Sugettions = () => {
    const { t, i18n } = useTranslation();

    const subscriptions = useSelector(subscriptionsSelector);
    const currentLanguage = useSelector(currentLanguageSelector);

    const Navigate = useNavigate();

    const handleButtonClick = (e) => {
        Navigate(ROUTS.HOME + ROUTS.PROFILE + ROUTS.BUY + `/${e.target.id}`)
    }

    return (
        <div className="subscribes__suggestions">
            {subscriptions.map((item) => {
                return (
                    <div key={item.id} className="subscribes__suggestions__card subscribe">
                        <div className="subscribes__suggestions__card__header">
                            <div className="subscribes__suggestions__card__wrapper">
                                <div className="subscribes__suggestions__card__header__container">
                                    <div className="subscribes__suggestions__card__header__title">{item.name[currentLanguage]}</div>
                                    <p className="subscribes__suggestions__card__header__title__text">{item.duration != 1 ? (`${t("subscribebox.boxperiod1")}${item.duration}${t("subscribebox.boxperiod2")}`) : ('')}</p>
                                </div>

                                <div className="subscribes__suggestions__card__header__text">
                                    <p className="subscribes__suggestions__card__header__text__price">₾{item.price}</p>
                                    <p className="subscribes__suggestions__card__header__text__month">{t("subscribebox.boxmonthly")}</p>
                                </div>
                                <button id={item.id} onClick={handleButtonClick} className='subscribes__suggestions__card__header__button'>
                                    <svg id={item.id} className="subscribes__suggestions__card__header__button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20" cy="20" r="20" fill="#0B0A0A" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8431 13.4286L30.2071 19.7926C30.5976 20.1831 30.5976 20.8163 30.2071 21.2068L23.8431 27.5708C23.4526 27.9613 22.8195 27.9613 22.4289 27.5708C22.0384 27.1802 22.0384 26.5471 22.4289 26.1566L27.0858 21.4997H9.5V19.4997H27.0858L22.4289 14.8428C22.0384 14.4523 22.0384 13.8192 22.4289 13.4286C22.8195 13.0381 23.4526 13.0381 23.8431 13.4286Z" fill="#FDFAFA" />
                                    </svg>
                                    <p id={item.id} className="subscribes__suggestions__card__header__button__text">{t("subscribebox.boxchoose")}</p>
                                </button>
                            </div>
                        </div>
                        <div className="subscribes__suggestions__card__body">
                            <div className="subscribes__suggestions__card__body__wrapper">
                                {item.description[currentLanguage].map((item) => {
                                    return (<div className="subscribes__suggestions__card__body__el">
                                        <p className="subscribes__suggestions__card__body__el__text">{item}</p>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}