import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import IMAGES from "./../images/mainPage/images";
import { ROUTS } from '../Constants/Routes';
import { Questions } from '../Component/Questions';
import { useTranslation } from "react-i18next";
import { subscriptionsSelector } from "../Store/Subscriptions/selectors";
import { currentLanguageSelector } from "../Store/Language/selectors";
import { useSelector } from "react-redux";

export const MainPage = () => {

    gsap.registerPlugin(ScrollTrigger);
    const { t, i18n } = useTranslation();

    const subscriptions = useSelector(subscriptionsSelector);
    const currentLanguage = useSelector(currentLanguageSelector);
    const Navigate = useNavigate();

    console.log(currentLanguage);

    const handleButtonClick = () => {
        Navigate(ROUTS.HOME + ROUTS.MAINPAGE + ROUTS.BUY + "/1");
    }

    const handleAboutUsButton = () => {
        Navigate(ROUTS.HOME + ROUTS.ABOUT);
    }

    const mainScreen = useRef(null);
    const firstScreen = useRef(null);
    const mainScreenPic = useRef(null);
    const mainScreeBg = useRef(null);
    const mainSecondTitle = useRef(null);

    const leadersContainer = useRef(null);
    const leadersTextOne = useRef(null);
    const leadersTextTwo = useRef(null);
    const leadersTextThree = useRef(null);
    const leadersImgOne = useRef(null);
    const leadersImgTwo = useRef(null);
    const leadersImgThree = useRef(null);
    const leadersImgOneMobile = useRef(null);
    const leadersImgTwoMobile = useRef(null);
    const leadersImgThreeMobile = useRef(null);

    const subContainer = useRef(null);
    const subText = useRef(null);
    const secondSub = useRef(null);
    const thirdSub = useRef(null);

    const tl = useRef();
    const tl2 = useRef();
    const tl3 = useRef();

    function useWindowDimensions() {

        const hasWindow = typeof window !== 'undefined';

        function getWindowDimensions() {
            const width = hasWindow ? window.innerWidth : null;
            return {
                width,
            };
        }

        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        useEffect(() => {
            if (hasWindow) {
                function handleResize() {
                    setWindowDimensions(getWindowDimensions());
                }

                window.addEventListener('resize', handleResize);
                return () => window.removeEventListener('resize', handleResize);
            }
        }, [hasWindow]);

        return windowDimensions;
    }

    const width = useWindowDimensions();

    useEffect(() => {
        tl.current && tl.current.progress(0).kill();
        tl2.current && tl2.current.progress(0).kill();
        tl3.current && tl3.current.progress(0).kill();

        const header = document.querySelector(".header");
        const size = -header.offsetHeight + 1;

        const sizeThird = size + width.width * 0.04 - 40;

        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: mainScreen.current,
                start: `${size} top`,
                pin: true,
                toggleActions: "restart none reverse reverse",
                immediateRender: false,
            }
        }).fromTo(firstScreen.current, { scale: 0, duration: 0.5}, { scale: 1, duration: 0.5 })
            .fromTo(mainScreenPic.current, { y: 1000 }, { y: 0, duration: 0.5 }, '<')
            .fromTo(mainScreeBg.current, { scale: 0, duration: 0.5 }, { scale: 7, duration: 1 }, '<')
            .fromTo(mainSecondTitle.current, { opacity: 0 }, { opacity: 1, duration: 1 }, '<')
            .to({}, { x: 100 })

        if (width.width > 600) {
            tl2.current = gsap.timeline({
                scrollTrigger: {
                    trigger: leadersContainer.current,
                    start: `${size} top`,
                    end: "+1800 top",
                    pin: true,
                    scrub: 2,
                    immediateRender: false,
                }
            })
                .to(leadersImgOne.current, { opacity: 0 })
                .to(leadersImgTwo.current, { opacity: 1 }, '<')
                .to(leadersTextOne.current, { y: -350 }, '<')
                .to(leadersTextTwo.current, { y: -350 }, '<')
                .to(leadersImgTwo.current, { opacity: 0 })
                .to(leadersImgThree.current, { opacity: 1 }, '<')
                .to(leadersTextTwo.current, { y: -700 }, '<')
                .to(leadersTextThree.current, { y: -700 }, '<')
        } else {
            tl2.current = gsap.timeline({
                scrollTrigger: {
                    trigger: leadersContainer.current,
                    start: `${size} top`,
                    end: "+1800 top",
                    pin: true,
                    scrub: 2,
                    immediateRender: false,
                }
            })
                .to(leadersImgOneMobile.current, { opacity: 0 })
                .to(leadersImgTwoMobile.current, { opacity: 1 }, '<')
                .to(leadersTextOne.current, { y: -350 }, '<')
                .to(leadersTextTwo.current, { y: -350 }, '<')
                .to(leadersImgTwoMobile.current, { opacity: 0 })
                .to(leadersImgThreeMobile.current, { opacity: 1 }, '<')
                .to(leadersTextTwo.current, { y: -700 }, '<')
                .to(leadersTextThree.current, { y: -700 }, '<')
        }

        let firstY;
        let secondY = -146;

        if (width.width < 350) {
            firstY = -57;
        } else if (width.width < 400) {
            firstY = -56;
        } else {
            firstY = -63;
        }

        if (width.width > 1000) {
            tl3.current = gsap.timeline({
                scrollTrigger: {
                    trigger: subContainer.current,
                    start: `${sizeThird} top`,
                    end: "1800 center",
                    toggleActions: "restart none reverse none",
                    immediateRender: false,
                    pin: true,
                    scrub: 1,
                }
            }).to({}, { x: 100 })
                .to(subText.current, { opacity: 0 })
                .fromTo(secondSub.current, { xPercent: 30 }, { xPercent: 102 }, '<')
                .fromTo(thirdSub.current, { xPercent: 60 }, { xPercent: 204 }, '<')
                .to({}, { x: 100 })
        } else {
            tl3.current = gsap.timeline({
                scrollTrigger: {
                    trigger: subContainer.current,
                    start: "100 top",
                    toggleActions: "play none none none",
                    immediateRender: false,
                }
            }).from(secondSub.current, { yPercent: firstY, duration: 2, ease: "Power2.easeInOut" })
                .from(thirdSub.current, { yPercent: secondY, duration: 2, ease: "Power2.easeInOut" }, '<')
        }


        return () => {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <div className="mainPage">
            <div ref={mainScreen} className="mainPage__start">
                <div className="mainPage__first">
                    <div className="mainPage__first__wrapper">
                        <img src={i18n.language.includes('ru') ? IMAGES.titleFirst : IMAGES.titleFirstEn} alt="title" className="mainPage__first__title" />
                        <img ref={mainSecondTitle} src={i18n.language.includes('ru') ? IMAGES.titleSecond : IMAGES.titleSecondEn} alt="title" className="mainPage__first__titleSecond" />
                        <svg className="mainPage__first__arrow" width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="100" cy="100" r="99" stroke="#E75549" stroke-width="2" />
                            <path d="M99.2929 160.707C99.6834 161.098 100.317 161.098 100.707 160.707L107.071 154.343C107.462 153.953 107.462 153.319 107.071 152.929C106.681 152.538 106.047 152.538 105.657 152.929L100 158.586L94.3431 152.929C93.9526 152.538 93.3195 152.538 92.9289 152.929C92.5384 153.319 92.5384 153.953 92.9289 154.343L99.2929 160.707ZM99 40L99 160H101L101 40H99Z" fill="#E75549" />
                        </svg>
                        <div ref={mainScreeBg} className="mainPage__first__bg" />
                        <img ref={mainScreenPic} src={width.width > 600 ? IMAGES.icons : IMAGES.iconsMobile} alt="icons" className="mainPage__first__icons" />
                    </div>
                </div>
                <div ref={firstScreen} className="mainPage__solution">
                    <div className="mainPage__solution__wrapper">
                    </div>
                </div>
            </div>
            <div ref={leadersContainer} className="mainPage__leaders">
                <div className="mainPage__leaders__wrapper">
                    <div className="mainPage__leaders__left">
                        <img ref={leadersImgThree} src={IMAGES.topImg} alt="topImg" className="mainPage__leaders__right__topImg" />
                        <img ref={leadersImgTwo} src={IMAGES.firstImg} alt="secondImg" className="mainPage__leaders__left__img" />
                    </div>
                    <div className="mainPage__leaders__right">
                        <h2 className="mainPage__leaders__left__title">{t("mainpage.leaderstitle")}</h2>
                        <div className="mainPage__leaders__right__textBox">
                            <p className="mainPage__leaders__right__textBox__el one">
                                <span ref={leadersTextOne}>{t("mainpage.leadertext11")}
                                    <font style={{ color: "#0B0A0A" }}>{t("mainpage.leadertext12")}</font>{t("mainpage.leadertext13")}
                                </span>
                                <span ref={leadersTextTwo}><font style={{ color: "#0B0A0A" }}>{t("mainpage.leadertext21")}</font>
                                    {t("mainpage.leadertext22")}<font style={{ color: "#0B0A0A" }}>{t("mainpage.leadertext23")}</font></span>
                                <span ref={leadersTextThree}><font style={{ color: "#0B0A0A" }}>{t("mainpage.leadertext31")}</font>{t("mainpage.leadertext32")}<font style={{ color: "#0B0A0A" }}>{t("mainpage.leadertext33")}</font>
                                    <font style={{ color: "#0B0A0A" }}>{t("mainpage.leadertext34")}</font></span></p>
                        </div>
                        <button onClick={handleAboutUsButton} className="mainPage__leaders__button-about">
                            <svg className="mainPage__leaders__button-about__img" width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="14.5" cy="14.5" r="14.5" fill="#56514D" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3822 9.57123L22.1552 14.3442C22.4481 14.6371 22.4481 15.112 22.1552 15.4049L17.3822 20.1778C17.0893 20.4707 16.6145 20.4707 16.3216 20.1778C16.0287 19.8849 16.0287 19.4101 16.3216 19.1172L19.8142 15.6245H6.62488V14.1245H19.8142L16.3216 10.6319C16.0287 10.339 16.0287 9.86413 16.3216 9.57123C16.6145 9.27834 17.0893 9.27834 17.3822 9.57123Z" fill="#FDFAFA" />
                            </svg>
                            <p className="mainPage__leaders__button-about__text">{t("mainpage.leaderbutton")}</p>
                        </button>
                        <img ref={leadersImgOne} src={IMAGES.secondImg} alt="secondImg" className="mainPage__leaders__right__secondImg" />
                        <img ref={leadersImgOneMobile} src={IMAGES.mobileFirst} alt="secondImg" className="mainPage__leaders__right__mobileFirstImg" />
                        <img ref={leadersImgTwoMobile} src={IMAGES.mobileSecond} alt="secondImg" className="mainPage__leaders__right__mobileSecondImg" />
                        <img ref={leadersImgThreeMobile} src={IMAGES.mobileThird} alt="secondImg" className="mainPage__leaders__right__mobileThirdImg" />
                    </div>
                </div>
            </div>
            <div ref={subContainer} className="mainPage__subscribes">
                <div className="mainPage__subscribes__wrapper">
                    <div className="subscribes__suggestions mainPage__subscribes__suggetions">
                        {subscriptions.map((item) => {
                            return (
                                <div ref={item.id == 2 ? secondSub : (item.id == 3 ? thirdSub : null)} className="subscribes__suggestions__card mainPage__subscribes__suggetions__card">
                                    <div className="subscribes__suggestions__card__header">
                                        <div className="subscribes__suggestions__card__wrapper">
                                            <div className="subscribes__suggestions__card__header__container">
                                                <div className="subscribes__suggestions__card__header__title">{item.name[currentLanguage]}</div>
                                                <p className="subscribes__suggestions__card__header__title__text">{item.duration != 1 ? (`${t("subscribebox.boxperiod1")}${item.duration}${t("subscribebox.boxperiod2")}`) : ('')}</p>
                                            </div>

                                            <div className="subscribes__suggestions__card__header__text">
                                                <p className="subscribes__suggestions__card__header__text__price">₾{item.price}</p>
                                                <p className="subscribes__suggestions__card__header__text__month">{t("subscribebox.boxmonthly")}</p>
                                            </div>
                                            <button id={item.id} onClick={handleButtonClick} className='subscribes__suggestions__card__header__button'>
                                                <svg id={item.id} className="subscribes__suggestions__card__header__button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="20" cy="20" r="20" fill="#0B0A0A" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8431 13.4286L30.2071 19.7926C30.5976 20.1831 30.5976 20.8163 30.2071 21.2068L23.8431 27.5708C23.4526 27.9613 22.8195 27.9613 22.4289 27.5708C22.0384 27.1802 22.0384 26.5471 22.4289 26.1566L27.0858 21.4997H9.5V19.4997H27.0858L22.4289 14.8428C22.0384 14.4523 22.0384 13.8192 22.4289 13.4286C22.8195 13.0381 23.4526 13.0381 23.8431 13.4286Z" fill="#FDFAFA" />
                                                </svg>
                                                <p id={item.id} className="subscribes__suggestions__card__header__button__text">{t("subscribebox.boxchoose")}</p>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="subscribes__suggestions__card__body">
                                        <div className="subscribes__suggestions__card__body__wrapper">
                                            {item.description[!["en", "ka", "uk"].includes(currentLanguage)  ? "en" : currentLanguage].map((item) => {
                                                return (<div className="subscribes__suggestions__card__body__el">
                                                    <p className="subscribes__suggestions__card__body__el__text">{item}</p>
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                    <div ref={subText} className="mainPage__subscribes__box">
                        <h2 className="mainPage__subscribes__box__title">{t("mainpage.subscribetitle")}</h2>
                        <p className="mainPage__subscribes__box__text">{t("mainpage.subscribetext")}</p>
                    </div>
                </div>
            </div>
            <Questions />
        </div>
    )
}