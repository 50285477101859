import { ADD_MY_SERVICE_CATION_ACTION, DELETE_MY_SERVICE_CATION_ACTION, SET_MY_SERVICE_CATION_ACTION } from './constants.js';

export const addMyServiceCationAction = (payload) => ({
    type: ADD_MY_SERVICE_CATION_ACTION,
    payload,
});

export const deleteMyServiceCationAction = (payload) => ({
    type: DELETE_MY_SERVICE_CATION_ACTION, 
    payload, 
});

export const setMyServiceCationAction = (payload) => ({
    type: SET_MY_SERVICE_CATION_ACTION, 
    payload, 
});


