import {
    ADD_QUESTION_CATION_ACTION, SET_QUESTIONS_CATION_ACTION
} from './constants.js';

const initialState = [
    // {
    //     id: 1, // id обращения
    //     message: "Здарова. У меня тут вопрос. А как ... ?", // текст обращения
    //     status: 2, // статус обращения. 0 - только поступила, 1 - в обработке, 2 - выполнена
    //     date: "1670555177748" // таймстамп отправки обращения
    // },
    // {
    //     id: 2, // id обращения
    //     message: "Здарова. У меня тут вопрос. А как ... ?", // текст обращения
    //     status: 1, // статус обращения. 0 - только поступила, 1 - в обработке, 2 - выполнена
    //     date: "1670555177748" // таймстамп отправки обращения
    // },
];

export const userQuestionReducer = (state = initialState, action) => {
    switch (action.type) { 
        case SET_QUESTIONS_CATION_ACTION: {
            if (action.payload == null) {
                return [];
            } else if (Array.isArray(action.payload)) {
                return [
                    ...action.payload
                ];
            } else {
                return [{
                    ...action.payload
                }];
            }
        }
        case ADD_QUESTION_CATION_ACTION: {
            return ([
                ...state,
                {
                    ...action.payload
                }
            ]);
        }
        default:
            return state;
    }
}