import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentLanguageSelector } from "../../Store/Language/selectors";

export const Booking = ({ bookData }) => {
    const { t, i18n } = useTranslation();
    const data = bookData[0];
    let time;
    let notime;

    if (data.completedate != null) {
        time = new Date(data.completedate).toLocaleString('ru', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        });
    } else {
        notime = t("notstated");
    }

    const currentLanguage = useSelector(currentLanguageSelector);

    const [language, setLanguage] = useState('ru-RU');

    useEffect(() => {
        i18n.language.includes('ru') ? setLanguage('ru-RU') : setLanguage('en-EN');
    }, [i18n.language]);

    function getMonthName(monthNumber) {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return monthNames[monthNumber];
    }

    return (
        <>
            <div className="bookingDate__container__left__el">
                <p className="bookingDate__container__left__el__title">{t("booking.service")}</p>
                <p className="bookingDate__container__left__el__text">{data.servicename[currentLanguage]}</p>
            </div>
            <div className="bookingDate__container__left__el">
                <p className="bookingDate__container__left__el__title">{t("booking.location")}</p>
                <p className="bookingDate__container__left__el__text">{data.servicedata.location[currentLanguage]}</p>
            </div>
            {time && (<div className="bookingDate__container__left__el">
                <p className="bookingDate__container__left__el__title">{t("booking.date")}</p>
                <p className="bookingDate__container__left__el__text">{time.getDate()}&nbsp;{t(`months.${getMonthName(time.getMonth())}`)}&nbsp;{time.getFullYear()}</p>
            </div>)}
            {time && (<div className="bookingDate__container__left__el">
                <p className="bookingDate__container__left__el__title">{t("booking.time")}</p>
                <p className="bookingDate__container__left__el__text">{(`${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`)}</p>
            </div>)}
            {data.servicedata.price ?
                (<div className="bookingDate__container__left__el">
                    <p className="bookingDate__container__left__el__title">{t("booking.price")}</p>
                    <p className="bookingDate__container__left__el__text">₾{data.servicedata.price}</p>
                </div>) :
                (<div className="bookingDate__container__left__el">
                    <p className="bookingDate__container__left__el__title">{t("booking.textStatus")}</p>
                    <p className="bookingDate__container__left__el__text">{t("booking.status")}</p>
                </div>)}
        </>
    )
}