import { useEffect } from "react"
import { Filters } from "../Component/Dashboard/Filters"
import { useSelector } from "react-redux";
import { userInfoSelector } from "../Store/User/selectors";
import { useNavigate } from "react-router-dom";
import { ROUTS } from "../Constants/Routes";
import { useTranslation } from "react-i18next";

export const Dashboard = ({ page = null }) => {
    const { t, i18n } = useTranslation();
    const user = useSelector(userInfoSelector);
    const Navigate = useNavigate();

    if (user.user.status == 'admin') {
        return (
            <div className="dashboard">
                <div className="dashboard__wrapper">

                    <h1 className="dashboard__main-title">{t("dashboard.dashboard")}</h1>
                    <nav className="dashboard__nav">
                        <Filters />
                    </nav>
                    <div className="dashboard__content">
                        {page}
                    </div>
                </div>
            </div>
        );
    }
}