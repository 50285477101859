const HOME = "/";

export const ROUTS = {
    HOME: HOME,
    MAINPAGE: "main",
    PROFILE: "profile",
    NOTFOUND: "404",
    SUBSCRIBE: "/subscribe",
    SERVICES: "/services",
    QUERIES: "/queries",
    BUY: "/buy",
    BOOK: "/book",
    BOOKPAYMENT: "/book/payment",
    BOOKEDIT: "/book/edit",
    FAQ: "FAQ",
    CONTACTS: "contacts",
    CONTACTSINFO: "contacts/info",
    ABOUT: "about",
    TERMS: "termsAndConditions",
    DASHBOARD: "dashboard",
    DASHBOARDQUERIES: "/siteQueries",
    DASHBOARDBOOKING: "/serviceBookings",
    DASHBOARDUSERS: "/users",
    DASHBOARDPLANS: "/plans",
    DASHBOARDSERVICES: "/services",
    DASHBOARDGROUPS: "/serviceGroups",
}