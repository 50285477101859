import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { subscriptionsSelector } from "../../../../Store/Subscriptions/selectors";
import { changeSubscriptionCationAction } from "../../../../Store/Subscriptions/actions";
import axios from "axios";

export const PlansInput = ({ trigger, language, action, id }) => {
    const { t, i18n } = useTranslation();

    const plan = useSelector(subscriptionsSelector).filter((item) => item.id == id)[0];

    const [titleKa, setTitleKa] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [titleUk, setTitleUk] = useState('');

    const [time, setTime] = useState('');

    const [price, setPrice] = useState('');

    const [descrKa, setDescrKa] = useState([]);
    const [descrEn, setDescrEn] = useState([]);
    const [descrUk, setDescrUk] = useState([]);


    useEffect(() => {
        if (plan) {
            setTitleKa(plan.name.ka);
            setTitleEn(plan.name.en);
            setTitleUk(plan.name.uk);

            setTime(plan.duration);
            setPrice(plan.price);

            setDescrKa([...plan.description.ka]);
            setDescrEn([...plan.description.en]);
            setDescrUk([...plan.description.uk]);
        }
    }, [plan]);

    const dispatch = useDispatch();

    const changeDescrEn = (e, id) => {
        let temp = [...descrEn];
        temp[id] = e.target.value;
        setDescrEn(temp);
    }

    const changeDescrKa = (e, id) => {
        let temp = [...descrKa];
        temp[id] = e.target.value;
        setDescrKa(temp);
    }

    const changeDescrUk = (e, id) => {
        let temp = [...descrUk];
        temp[id] = e.target.value;
        setDescrUk(temp);
    }

    const handleDeleteEl = (id) => {
        let tempKa = [...descrKa];
        let tempEn = [...descrEn];
        let tempUk = [...descrUk];

        tempKa.splice(id, 1);
        tempEn.splice(id, 1);
        tempUk.splice(id, 1);

        setDescrKa(tempKa);
        setDescrEn(tempEn);
        setDescrUk(tempUk);
    }

    const handleAddEl = () => {
        let tempKa = [...descrKa, ''];
        let tempEn = [...descrEn, ''];
        let tempUk = [...descrUk, ''];

        setDescrKa(tempKa);
        setDescrEn(tempEn);
        setDescrUk(tempUk);
    }

    useEffect(() => {
        if (trigger) {
            const data = {
                name: {
                    ka: titleKa,
                    en: titleEn,
                    uk: titleUk,
                },
                duration: time,
                price: price,
                description: {
                    ka: descrKa,
                    en: descrEn,
                    uk: descrUk,
                }
            };

            if (action == 'edit') {
                axios({
                    method: 'post',
                    url: 'https://api.lawchill.ge/editSubscription',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                    },
                    data: {
                        id: id,
                        data: data
                    },
                    withCredentials: true,
                }).then((res) => {
                    console.log(res)
                    dispatch(changeSubscriptionCationAction({ ...data, id: id }));
                    console.log(data);
                }).catch((err) => {
                    console.log(err)
                });
            }
        }
    }, [trigger]);


    return (
        <div className="dashboard__popup__inputs">
            {language == 'ka' ?
                (<>
                    <div key={1} className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                        </svg>

                        <input value={titleKa} onChange={(e) => setTitleKa(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.plans.title')} />
                    </div>
                    <div key={2} className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 1.33301H7.5V2.99967H12.5V1.33301ZM9.16667 12.1663H10.8333V7.16634H9.16667V12.1663ZM15.8583 6.65801L17.0417 5.47467C16.6833 5.04967 16.2917 4.64967 15.8667 4.29967L14.6833 5.48301C13.3917 4.44967 11.7667 3.83301 10 3.83301C5.85833 3.83301 2.5 7.19134 2.5 11.333C2.5 15.4747 5.85 18.833 10 18.833C14.15 18.833 17.5 15.4747 17.5 11.333C17.5 9.56634 16.8833 7.94134 15.8583 6.65801ZM10 17.1663C6.775 17.1663 4.16667 14.558 4.16667 11.333C4.16667 8.10801 6.775 5.49967 10 5.49967C13.225 5.49967 15.8333 8.10801 15.8333 11.333C15.8333 14.558 13.225 17.1663 10 17.1663Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container">
                            <input style={{ width: `${time.toString().length > 0 ? time.toString().length * 1 : 1}rem`, marginRight: '0.35rem' }} value={time} onChange={(e) => setTime(e.target.value)} type="text" className="dashboard__popup__inputs__el__input desktop" placeholder="0" />
                            <input style={{ width: `${time.toString().length > 0 ? time.toString().length * 9.5 : 9.5}px`, marginRight: '5px' }} value={time} onChange={(e) => setTime(e.target.value)} type="text" className="dashboard__popup__inputs__el__input mobile" placeholder="0" />
                            <span className="dashboard__popup__inputs__el__input">{t('dashboard.popup.plans.months')}</span>
                        </div>
                    </div>
                    <div key={3} className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 2.16699C5.39935 2.16699 1.66602 5.90033 1.66602 10.5003C1.66602 15.1003 5.39935 18.8337 9.99935 18.8337C14.5993 18.8337 18.3327 15.1003 18.3327 10.5003C18.3327 5.90033 14.5993 2.16699 9.99935 2.16699ZM9.99935 17.167C6.32435 17.167 3.33268 14.1753 3.33268 10.5003C3.33268 6.82533 6.32435 3.83366 9.99935 3.83366C13.6743 3.83366 16.666 6.82533 16.666 10.5003C16.666 14.1753 13.6743 17.167 9.99935 17.167ZM10.2577 9.78366C8.78268 9.40866 8.30768 9.00033 8.30768 8.39199C8.30768 7.69199 8.96602 7.20033 10.0577 7.20033C11.2077 7.20033 11.641 7.75033 11.6743 8.56699H13.0993C13.0577 7.45033 12.3743 6.42533 11.0243 6.09199V4.66699H9.08268V6.07533C7.82435 6.34199 6.81602 7.15866 6.81602 8.41699C6.81602 9.90866 8.05768 10.6587 9.86602 11.092C11.491 11.4753 11.816 12.0503 11.816 12.6503C11.816 13.092 11.491 13.8087 10.066 13.8087C8.73268 13.8087 8.20768 13.2087 8.13268 12.442H6.69935C6.78268 13.8587 7.83268 14.6587 9.08268 14.917V16.3337H11.0327V14.942C12.2993 14.7003 13.2993 13.9753 13.3077 12.6337C13.2993 10.8003 11.7243 10.167 10.2577 9.78366Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container price">
                            ₾
                            <input value={price} onChange={(e) => setPrice(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder="0" />
                        </div>
                    </div>
                    <div className="dashboard__popup__box">
                        <span className="dashboard__popup__box__title">{t('dashboard.popup.plans.features')}</span>
                        {descrKa.length != 0 && (descrKa.map((item, index) => {
                            return (
                                <div key={index} className="dashboard__popup__box__el">
                                    <input value={item} onChange={(e) => changeDescrKa(e, index)} type="text" className="dashboard__popup__box__el__input" />
                                    <svg className="dashboard__popup__box__el__img" onClick={() => handleDeleteEl(index)} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10.5" r="10" fill="#BCB7B3" />
                                        <path d="M5.75827 6.25736L14.2435 14.7426M14.2435 6.25736L5.75827 14.7426" stroke="#FDFAFA" stroke-width="2" />
                                    </svg>
                                </div>
                            )
                        }))}
                        <div onClick={handleAddEl} className="dashboard__popup__box__button">
                            <svg className="dashboard__popup__box__button__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10.5" r="10" fill="#BCB7B3" />
                                <path d="M10 4.5V16.5M16 10.5H4" stroke="#FDFAFA" stroke-width="2" />
                            </svg>

                            <span className="dashboard__popup__box__button__text">{t('dashboard.popup.plans.add')}</span>
                        </div>
                    </div>
                </>) : language === "en" ?
                (<>
                    <div key={1} className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                        </svg>
                        <input value={titleEn} onChange={(e) => setTitleEn(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.plans.title')} />
                    </div>
                    <div key={2} className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 1.33301H7.5V2.99967H12.5V1.33301ZM9.16667 12.1663H10.8333V7.16634H9.16667V12.1663ZM15.8583 6.65801L17.0417 5.47467C16.6833 5.04967 16.2917 4.64967 15.8667 4.29967L14.6833 5.48301C13.3917 4.44967 11.7667 3.83301 10 3.83301C5.85833 3.83301 2.5 7.19134 2.5 11.333C2.5 15.4747 5.85 18.833 10 18.833C14.15 18.833 17.5 15.4747 17.5 11.333C17.5 9.56634 16.8833 7.94134 15.8583 6.65801ZM10 17.1663C6.775 17.1663 4.16667 14.558 4.16667 11.333C4.16667 8.10801 6.775 5.49967 10 5.49967C13.225 5.49967 15.8333 8.10801 15.8333 11.333C15.8333 14.558 13.225 17.1663 10 17.1663Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container">
                            <input style={{ width: `${time.toString().length > 0 ? time.toString().length * 1 : 1}rem`, marginRight: '0.35rem' }} value={time} onChange={(e) => setTime(e.target.value)} type="text" className="dashboard__popup__inputs__el__input months desktop" placeholder="0" />
                            <input style={{ width: `${time.toString().length > 0 ? time.toString().length * 9.5 : 9.5}px`, marginRight: '5px' }} value={time} onChange={(e) => setTime(e.target.value)} type="text" className="dashboard__popup__inputs__el__input months mobile" placeholder="0" />
                            <span className="dashboard__popup__inputs__el__input">{t('dashboard.popup.plans.months')}</span>
                        </div>
                    </div>
                    <div key={3} className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 2.16699C5.39935 2.16699 1.66602 5.90033 1.66602 10.5003C1.66602 15.1003 5.39935 18.8337 9.99935 18.8337C14.5993 18.8337 18.3327 15.1003 18.3327 10.5003C18.3327 5.90033 14.5993 2.16699 9.99935 2.16699ZM9.99935 17.167C6.32435 17.167 3.33268 14.1753 3.33268 10.5003C3.33268 6.82533 6.32435 3.83366 9.99935 3.83366C13.6743 3.83366 16.666 6.82533 16.666 10.5003C16.666 14.1753 13.6743 17.167 9.99935 17.167ZM10.2577 9.78366C8.78268 9.40866 8.30768 9.00033 8.30768 8.39199C8.30768 7.69199 8.96602 7.20033 10.0577 7.20033C11.2077 7.20033 11.641 7.75033 11.6743 8.56699H13.0993C13.0577 7.45033 12.3743 6.42533 11.0243 6.09199V4.66699H9.08268V6.07533C7.82435 6.34199 6.81602 7.15866 6.81602 8.41699C6.81602 9.90866 8.05768 10.6587 9.86602 11.092C11.491 11.4753 11.816 12.0503 11.816 12.6503C11.816 13.092 11.491 13.8087 10.066 13.8087C8.73268 13.8087 8.20768 13.2087 8.13268 12.442H6.69935C6.78268 13.8587 7.83268 14.6587 9.08268 14.917V16.3337H11.0327V14.942C12.2993 14.7003 13.2993 13.9753 13.3077 12.6337C13.2993 10.8003 11.7243 10.167 10.2577 9.78366Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container price">
                            ₾
                            <input value={price} onChange={(e) => setPrice(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder="0" />
                        </div>
                    </div>
                    <div className="dashboard__popup__box">
                        <span className="dashboard__popup__box__title">{t('dashboard.popup.plans.features')}</span>
                        {descrEn.length != 0 && (descrEn.map((item, index) => {
                            return (
                                <div className="dashboard__popup__box__el">
                                    <input value={item} onChange={(e) => changeDescrEn(e, index)} type="text" className="dashboard__popup__box__el__input" />
                                    <svg className="dashboard__popup__box__el__img" onClick={() => handleDeleteEl(index)} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10.5" r="10" fill="#BCB7B3" />
                                        <path d="M5.75827 6.25736L14.2435 14.7426M14.2435 6.25736L5.75827 14.7426" stroke="#FDFAFA" stroke-width="2" />
                                    </svg>
                                </div>
                            )
                        }))}
                        <div onClick={handleAddEl} className="dashboard__popup__box__button">
                            <svg className="dashboard__popup__box__button__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10.5" r="10" fill="#BCB7B3" />
                                <path d="M10 4.5V16.5M16 10.5H4" stroke="#FDFAFA" stroke-width="2" />
                            </svg>
                            <span className="dashboard__popup__box__button__text">{t('dashboard.popup.plans.add')}</span>
                        </div>
                    </div>
                </>) :
                 (<>
                    <div key={1} className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                        </svg>
                        <input value={titleUk} onChange={(e) => setTitleUk(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.plans.title')} />
                    </div>
                    <div key={2} className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 1.33301H7.5V2.99967H12.5V1.33301ZM9.16667 12.1663H10.8333V7.16634H9.16667V12.1663ZM15.8583 6.65801L17.0417 5.47467C16.6833 5.04967 16.2917 4.64967 15.8667 4.29967L14.6833 5.48301C13.3917 4.44967 11.7667 3.83301 10 3.83301C5.85833 3.83301 2.5 7.19134 2.5 11.333C2.5 15.4747 5.85 18.833 10 18.833C14.15 18.833 17.5 15.4747 17.5 11.333C17.5 9.56634 16.8833 7.94134 15.8583 6.65801ZM10 17.1663C6.775 17.1663 4.16667 14.558 4.16667 11.333C4.16667 8.10801 6.775 5.49967 10 5.49967C13.225 5.49967 15.8333 8.10801 15.8333 11.333C15.8333 14.558 13.225 17.1663 10 17.1663Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container">
                            <input style={{ width: `${time.toString().length > 0 ? time.toString().length * 1 : 1}rem`, marginRight: '0.35rem' }} value={time} onChange={(e) => setTime(e.target.value)} type="text" className="dashboard__popup__inputs__el__input months desktop" placeholder="0" />
                            <input style={{ width: `${time.toString().length > 0 ? time.toString().length * 9.5 : 9.5}px`, marginRight: '5px' }} value={time} onChange={(e) => setTime(e.target.value)} type="text" className="dashboard__popup__inputs__el__input months mobile" placeholder="0" />
                            <span className="dashboard__popup__inputs__el__input">{t('dashboard.popup.plans.months')}</span>
                        </div>
                    </div>
                    <div key={3} className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 2.16699C5.39935 2.16699 1.66602 5.90033 1.66602 10.5003C1.66602 15.1003 5.39935 18.8337 9.99935 18.8337C14.5993 18.8337 18.3327 15.1003 18.3327 10.5003C18.3327 5.90033 14.5993 2.16699 9.99935 2.16699ZM9.99935 17.167C6.32435 17.167 3.33268 14.1753 3.33268 10.5003C3.33268 6.82533 6.32435 3.83366 9.99935 3.83366C13.6743 3.83366 16.666 6.82533 16.666 10.5003C16.666 14.1753 13.6743 17.167 9.99935 17.167ZM10.2577 9.78366C8.78268 9.40866 8.30768 9.00033 8.30768 8.39199C8.30768 7.69199 8.96602 7.20033 10.0577 7.20033C11.2077 7.20033 11.641 7.75033 11.6743 8.56699H13.0993C13.0577 7.45033 12.3743 6.42533 11.0243 6.09199V4.66699H9.08268V6.07533C7.82435 6.34199 6.81602 7.15866 6.81602 8.41699C6.81602 9.90866 8.05768 10.6587 9.86602 11.092C11.491 11.4753 11.816 12.0503 11.816 12.6503C11.816 13.092 11.491 13.8087 10.066 13.8087C8.73268 13.8087 8.20768 13.2087 8.13268 12.442H6.69935C6.78268 13.8587 7.83268 14.6587 9.08268 14.917V16.3337H11.0327V14.942C12.2993 14.7003 13.2993 13.9753 13.3077 12.6337C13.2993 10.8003 11.7243 10.167 10.2577 9.78366Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container price">
                            ₾
                            <input value={price} onChange={(e) => setPrice(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder="0" />
                        </div>
                    </div>
                    <div className="dashboard__popup__box">
                        <span className="dashboard__popup__box__title">{t('dashboard.popup.plans.features')}</span>
                        {descrUk.length != 0 && (descrUk.map((item, index) => {
                            return (
                                <div className="dashboard__popup__box__el">
                                    <input value={item} onChange={(e) => changeDescrUk(e, index)} type="text" className="dashboard__popup__box__el__input" />
                                    <svg className="dashboard__popup__box__el__img" onClick={() => handleDeleteEl(index)} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10.5" r="10" fill="#BCB7B3" />
                                        <path d="M5.75827 6.25736L14.2435 14.7426M14.2435 6.25736L5.75827 14.7426" stroke="#FDFAFA" stroke-width="2" />
                                    </svg>
                                </div>
                            )
                        }))}
                        <div onClick={handleAddEl} className="dashboard__popup__box__button">
                            <svg className="dashboard__popup__box__button__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10.5" r="10" fill="#BCB7B3" />
                                <path d="M10 4.5V16.5M16 10.5H4" stroke="#FDFAFA" stroke-width="2" />
                            </svg>
                            <span className="dashboard__popup__box__button__text">{t('dashboard.popup.plans.add')}</span>
                        </div>
                    </div>
                </>)
            }
        </div >
    )
}