import {
    CHANGE_LANGUAGE_CATION_ACTION
} from './constants.js';

const initialState = {
    current: "en"
};


export const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE_CATION_ACTION: {
            let newCurrent = action.payload;
            return {
                current: newCurrent
            }
        }
        default:
            return state;
    }
}