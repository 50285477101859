import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

export const Dropdown = ({ defaultValue, content, handler, id = 0 }) => {
    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState();

    useEffect(() => {
        setValue([[defaultValue]]);
    }, [defaultValue]);

    const handleChangeValue = (e) => {
        setValue(e.target.dataset.value);
        handler(id, e.target.dataset.value);
    }

    const handleOpen = () => {
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                document.addEventListener("click", handleClose);
            }, 0);
        } else {
            document.removeEventListener("click", handleClose);
        }
        return () => {
            document.removeEventListener("click", handleClose);
        };
    }, [isOpen]);

    return (
        <div className="querie__table__el delete">
            <button className={"querie__table__el__status dashboard__text delete " + (isOpen ? 'open' : '')} onClick={handleOpen}>
                {t(`dashboard.dropdown.${value}`)}
            </button>
            <div className="querie__table__el__status__content delete">
                {content.map((item) => {
                    return (
                        <div data-value={item} onClick={handleChangeValue} className="querie__table__el__status__content__el delete">
                            {t(`dashboard.dropdown.${item}`)}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}