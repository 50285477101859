import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

export const DropdownBooking = ({ defaultValue, content, statusId, ids, handler, id = 0 }) => {
    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState();
    const [itemId, setItemId] = useState();

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        setItemId(statusId);
    }, [statusId]);


    const handleChangeValue = (e) => {
        setValue(e.target.dataset.value);
        setItemId(e.target.id);
        handler(id, e.target.id);
    }

    const handleOpen = () => {
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                document.addEventListener("click", handleClose);
            }, 0);
        } else {
            document.removeEventListener("click", handleClose);
        }
        return () => {
            document.removeEventListener("click", handleClose);
        };
    }, [isOpen]);

    return (
        <div className="querie__table__el delete">
            <button className={"querie__table__el__status dashboard__text delete " + (isOpen ? 'open' : '') + ((itemId == 5 || itemId == 6) ? ' paid' : (itemId == 2 ? ' cancelled' : ''))} onClick={handleOpen}>
                {t(`dashboard.booking.${value}`)}
            </button>
            <div className="querie__table__el__status__content delete">
                {content.map((item, index) => {
                    return (
                        <div data-value={item} id={ids[index]} onClick={handleChangeValue} className="querie__table__el__status__content__el delete">
                            {t(`dashboard.booking.${item}`)}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}