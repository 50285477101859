const IMAGES = {
    photo: require('./photo.png'),
    rotate: require('./rotate.svg').default,
    why_img1: require('./why-1.svg').default,
    why_img2: require('./why-2.svg').default,
    why_img3: require('./why-3.svg').default,
    why_img4: require('./why-4.svg').default,
    bgFirstPage: require('./bg__first-screen.png'),
    Ekaterina: require('./Ekaterina.jpg'),
    Elene1: require('./Tedo.png'),
    Iulia1: require('./Iulia1.jpg'),
    NikaTs1: require('./NikaTs1.jpg'),
    Valeri1: require('./Valeri1.jpg'),
    Nadejda1: require('./Nadejda1.jpg'),
}

export default IMAGES;