import {
    DELETE_MY_SERVICE_CATION_ACTION,
    ADD_MY_SERVICE_CATION_ACTION,
    SET_MY_SERVICE_CATION_ACTION
} from './constants.js';

const initialState = [
    // {
    //     "id": 54, // id заявки
    //     "status": 4, // статус заявки. 0 - поступила, 1 - отменена админом, 2 - отменена юзером, 3 - в обработке, 4 - выполнена
    //     // при отправке статуса "4", будет выставлено время выполнения услуги
    //     "user": 12, // id пользователя
    //     "expert": 12, // id эксперта
    //     "expertname": {
    //         'ru': "Русское имя",
    //         'en': "Mercedidze Alashvili"
    //     }, // имя эксперта
    //     "service": 8, // id услуги
    //     "servicename": {
    //         'ru': "Перевод паспорта",
    //         'en': "Passport translation"
    //     }, // название услуги
    //     "servicedata": { // информация об услуге на момент заказа
    //         "price": 100, // цена в грузинских лари
    //         "duration": 30, // время выполнения в минутах
    //         "location": {
    //             "ru": "Онлайн",
    //             "en": "Online"
    //         },
    //         "subscriptions": { // подписки, в которых есть услуга и максимальное кол-во оформлений
    //             "1": 2,
    //             "2": 3,
    //             "3": 0
    //         },
    //         "description": {
    //             "en": "Do vjuh-vjuh and all krasivo",
    //             "ru": "Делаем вжух-вжух и всё красиво"
    //         }
    //     },
    //     "createdate": "1671003660830", // дата отправления заявки
    //     "completedate": "1671176588327" // таймстамп выполнения услуги (статус "4")
    // },
    // {
    //     "id": 56,
    //     "status": 0,
    //     "user": 12,
    //     "expert": 2,
    //     "expertname": {
    //         "ru": "Александр Решалидзе",
    //         "en": "Alexander Reshalidze"
    //     },
    //     "service": 9,
    //     "servicename": {
    //         "ru": "Аудит",
    //         "en": "Audit"
    //     },
    //     "servicedata": {
    //         "price": 150,
    //         "duration": 60,
    //         "location": {
    //             "ru": "Абобридж, 24",
    //             "en": "Abobridze, 24"
    //         },
    //         "subscriptions": {
    //             "2": 1,
    //             "3": 3
    //         },
    //         "description": {
    //             "en": "Do vjuh-vjuh and all krasivo",
    //             "ru": "Делаем вжух-вжух и всё красиво"
    //         }
    //     },
    //     "createdate": "1671003661620",
    //     "completedate": null
    // },
    // {
    //     "id": 50,
    //     "status": 0,
    //     "user": 12,
    //     "expert": 2,
    //     "expertname": {
    //         "ru": "Александр Решалидзе",
    //         "en": "Alexander Reshalidze"
    //     },
    //     "service": 9,
    //     "servicename": {
    //         "ru": "Аудит",
    //         "en": "Audit"
    //     },
    //     "servicedata": {
    //         "price": 150,
    //         "duration": 60,
    //         "location": {
    //             "ru": "Абобридж, 24",
    //             "en": "Abobridze, 24"
    //         },
    //         "subscriptions": {
    //             "2": 1,
    //             "3": 3
    //         },
    //         "description": {
    //             "en": "Do vjuh-vjuh and all krasivo",
    //             "ru": "Делаем вжух-вжух и всё красиво"
    //         }
    //     },
    //     "createdate": "1671003661620",
    //     "completedate": null
    // },
    // {
    //     "id": 52,
    //     "status": 0,
    //     "user": 12,
    //     "expert": 2,
    //     "expertname": {
    //         "ru": "Александр Решалидзе",
    //         "en": "Alexander Reshalidze"
    //     },
    //     "service": 9,
    //     "servicename": {
    //         "ru": "Аудит",
    //         "en": "Audit"
    //     },
    //     "servicedata": {
    //         "price": 150,
    //         "duration": 60,
    //         "location": {
    //             "ru": "Абобридж, 24",
    //             "en": "Abobridze, 24"
    //         },
    //         "subscriptions": {
    //             "2": 1,
    //             "3": 3
    //         },
    //         "description": {
    //             "en": "Do vjuh-vjuh and all krasivo",
    //             "ru": "Делаем вжух-вжух и всё красиво"
    //         }
    //     },
    //     "createdate": "1671003661620",
    //     "completedate": null
    // }
];

export const myServicesInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MY_SERVICE_CATION_ACTION: {
            if (action.payload == null) {
                return [];
            } else if (Array.isArray(action.payload)) {
                return [
                    ...action.payload
                ];
            } else {
                return [{
                    ...action.payload
                }];
            }
        }
        case ADD_MY_SERVICE_CATION_ACTION: {
            return [{
                    ...action.payload.service
                },
                ...state
            ];
        }
        case DELETE_MY_SERVICE_CATION_ACTION: {
            return state.map(item => {
                if (item.id == action.payload.id) {
                    return ({
                        ...item,
                        status: 2
                    })
                } else {
                    return ({
                        ...item
                    })
                }
            });
        }
        default:
            return state;
    }
}