import {
    CHANGE_DATA_CATION_ACTION,
    CHANGE_SUB_CATION_ACTION,
    CHANGE_USER_PIC_CATION_ACTION,
    LOGIN_CATION_ACTION,
    LOGOUT_CATION_ACTION
} from './constants.js';

// initial state with default info
const initialState = {
    status: "not logged",
    user: {
        status: '',

        // если имеется. в случае авторизации по телефону - будет обязательно.
        phone: '', // номер телефона

        // если имеется. в случае авторизации с использованием телеграма - будет обязательно.
        telegram: {
            id: 0, // id аккаунта телеграм
            username: '' // логин аккаунта телеграм
        },

        name: '', // если имеется
        email: '', // если имеется
        subscription: { // если имеется
            id: 0, // id подписки (порядковый номер)
            end: 0 // таймстамп, когда подписка истекает (ms)
        },
        requests: [ // id заявок. если имеются
            
        ],
        image: null,
    }
};

export const userInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_DATA_CATION_ACTION: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                }
            }
        }
        case LOGIN_CATION_ACTION: {
            return {
                ...state,
                status: action.payload.status,
                user: {
                    ...action.payload.user,
                }
            };
        }
        case CHANGE_USER_PIC_CATION_ACTION: {
            return {
                ...state,
                user: {
                    ...state.user,
                    image: action.payload
                }
            };
        }
        case CHANGE_SUB_CATION_ACTION: {
            return {
                ...state,
                user: {
                    ...state.user,
                    subscription: { 
                        id: action.payload.id, 
                        end: action.payload.end 
                    },
                }
            };
        }
        case LOGOUT_CATION_ACTION: {
            return {
                ...state,
                status: "not logged",
                user: {
                    status: '',
                    phone: '',
                    telegram: {
                        id: 0,
                        username: ''
                    },
            
                    name: '',
                    email: '',
                    subscription: { 
                        id: 0, 
                        end: 0 
                    },
                    requests: [ 
                        
                    ],
                    image: null,
                }
            }
        }
        default:
            return state;
    }
}