import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addCategoryCationAction, changeCategoryCationAction } from "../../../../Store/Categories/actions";
import { useEffect } from "react";
import { categoryInfoSelector } from "../../../../Store/Categories/selectors";
import axios from "axios";

export const GroupInput = ({ setSelectedGroupId, trigger, language, action, id }) => {
    const { t, i18n } = useTranslation();

    const category = useSelector(categoryInfoSelector).filter((item) => item.id == id)[0];

    const [titleKa, setTitleKa] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [titleUk, setTitleUk] = useState('');

    useEffect(() => {
        if (category) {
            setTitleKa(category.ka);
            setTitleEn(category.en);
            setTitleUk(category.uk);
        } else {
            setTitleKa("");
            setTitleEn("");
            setTitleUk("");
        }
    }, [category]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (trigger) {
            if (action == 'add') {
                axios({
                    method: 'post',
                    url: 'https://api.lawchill.ge/createCategory',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                    },
                    data: {
                        "name": {
                            "ka": titleKa,
                            "en": titleEn,
                            "uk": titleUk,
                        }
                    },
                    withCredentials: true,
                }).then((res) => {
                    setSelectedGroupId(res.insertId)
                    dispatch(addCategoryCationAction(
                        {
                            id: res.insertId,
                            uk: titleUk,
                            en: titleEn,
                            ka: titleKa,
                            isChosen: false
                        }
                    ));
                }).catch((err) => {
                    console.log(err)
                });
            }

            if (action == 'edit') {
                dispatch(changeCategoryCationAction(
                    {
                        id: id,
                        ka: titleKa,
                        en: titleEn,
                        uk: titleUk,
                    }
                ));
            }
        }
    }, [trigger]);

    return (
        <div className="dashboard__popup__inputs">
            {language == 'ka' ?
                (<div key={1} className="dashboard__popup__inputs__el">
                    <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                    </svg>
                    <input value={titleKa} onChange={(e) => setTitleKa(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.groups.title')} />
                </div>) : language == "en" ? 
                (<div key={2} className="dashboard__popup__inputs__el">
                    <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                    </svg>
                    <input value={titleEn} onChange={(e) => setTitleEn(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.groups.title')} />
                </div>) : 
                (<div key={3} className="dashboard__popup__inputs__el">
                <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                </svg>
                <input value={titleUk} onChange={(e) => setTitleUk(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.groups.title')} />
            </div>)
            }
        </div >
    )
}