import { useTranslation } from "react-i18next";
import { LanguageChoose } from "./LanguageChoose";
import { useState } from "react";
import { GroupStatic } from "./Group/GroupStatic";
import { PlansStatic } from "./Plans/PlansStatic";
import { UserStatic } from "./Users/UsersStatic";
import { ServicesStatic } from "./Services/ServicesStatic";
import { useDispatch, useSelector } from "react-redux";
import { languageSelector, currentLanguageSelector } from "../../../Store/Language/selectors";
import IMAGESLOGIN from "../../../images/login/images";

export const PopupDone = ({ page, userTrigger, isOpen, handler, id, action, openEditModal }) => {
    const { t, i18n } = useTranslation();

    const [language, setLanguage] = useState('ru');
    const [trigger, setTrigger] = useState(false);

    const currentLanguage = useSelector(currentLanguageSelector);


    const handleClosePopup = () => {
        handler();
        setLanguage('en');
    }

    const handleOpenEditPopup = () => {
        handler();
        openEditModal();
        setLanguage('en');
    }

    const handleCreate = () => {
        setTrigger(true);
        handler();
        setLanguage('en');
        setTimeout(() => {
            setTrigger(false);
        }, 0);
    }

    const changeLanguage = (id, value) => {
       console.log(value.language);
        setLanguage(value.language.toLowerCase());
    }

    return (
        <div className={"dashboard__popup modal" + (isOpen ? ' show' : '') + (page == 'plans' ? ' scroll' : '')}>
            <div onClick={handleClosePopup} className="overlay"></div>
            <div className={"dashboard__popup__content modal-content" + (page == 'plans' ? ' scroll' : '')}>
                <div className="dashboard__popup__content__wrapper">
                    <div className="dashboard__popup__content__header">
                        <svg className="dashboard__popup__content__header__img" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="60" cy="60" r="59" stroke="#14AE5C" stroke-width="2" />
                            <path d="M54.0004 71.1196L42.8804 59.9996L39.0938 63.7596L54.0004 78.6663L86.0004 46.6662L82.2404 42.9062L54.0004 71.1196Z" fill="#14AE5C" />
                        </svg>
                        <p className="dashboard__popup__content__header__text green">{action == 'add' ? t(`dashboard.popup.${page}.done.title`) : t(`dashboard.popup.${page}.edited.title`)}</p>
                    </div>
                    <div className="dashboard__popup__content__info">
                        {page != 'users' && <LanguageChoose language={language} setLanguage={setLanguage} id={1} handler={changeLanguage} defaultValue={{language: currentLanguage.toUpperCase(), image: IMAGESLOGIN[currentLanguage.toUpperCase()]}} content={[{language: "EN", image: IMAGESLOGIN.EN}, {language: "KA", image: IMAGESLOGIN.KA}, {language: "UK", image: IMAGESLOGIN.UK}]}/>}
                        {page == 'groups' && (<GroupStatic language={language} id={id} />)}
                        {page == 'plans' && (<PlansStatic language={language} id={id} />)}
                        {page == 'users' && (<UserStatic userTrigger={userTrigger} language={language} id={id} />)}
                        {page == 'service' && (<ServicesStatic language={language} id={id} />)}
                    </div>
                    <div className="dashboard__popup__content__buttons">
                        <button onClick={handleCreate} className="dashboard__popup__content__buttons__button keep">
                            <svg className="dashboard__popup__content__buttons__button__img" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20.5" cy="20" r="20" fill="#FDFAFA" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3431 13.4291L30.7071 19.7931C31.0976 20.1836 31.0976 20.8168 30.7071 21.2073L24.3431 27.5713C23.9526 27.9618 23.3195 27.9618 22.9289 27.5713C22.5384 27.1807 22.5384 26.5476 22.9289 26.157L27.5858 21.5002H10V19.5002H27.5858L22.9289 14.8433C22.5384 14.4528 22.5384 13.8196 22.9289 13.4291C23.3195 13.0386 23.9526 13.0386 24.3431 13.4291Z" fill="#E75549" />
                            </svg>

                            <p className="dashboard__popup__content__buttons__button__text">{t('dashboard.popup.close')}</p>
                        </button>
                        <button onClick={handleOpenEditPopup} className="dashboard__popup__content__buttons__button-close">
                            <svg className="dashboard__popup__content__buttons__button-close__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.5" cy="10.5" r="10" fill="#BCB7B3" />
                                <g clip-path="url(#clip0_2243_4190)">
                                    <path d="M4.5 16.5002H6.99961L14.3718 9.12803L11.8722 6.62842L4.5 14.0006V16.5002ZM5.83313 14.5539L11.8722 8.51479L12.4854 9.12803L6.44637 15.1671H5.83313V14.5539Z" fill="#FDFAFA" />
                                    <path d="M14.7456 4.69497C14.4856 4.43501 14.0657 4.43501 13.8057 4.69497L12.5859 5.91478L15.0856 8.4144L16.3054 7.19459C16.5653 6.93463 16.5653 6.51469 16.3054 6.25473L14.7456 4.69497Z" fill="#FDFAFA" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2243_4190">
                                        <rect width="12" height="12" fill="white" transform="translate(4.5 4.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <p className="dashboard__popup__content__buttons__button-close__text">{t(`dashboard.popup.${page}.edit`)}</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}