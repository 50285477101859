import {
    ADD_SERVICE_CATION_ACTION,
    DELETE_SERVICE_CATION_ACTION,
    USING_SUB_SERVICE_CATION_ACTION,
    RETURN_SUB_SERVICE_CATION_ACTION,
    DELETED_CATEGORY_CATION_ACTION,
    EDIT_SERVICE_CATION_ACTION,
    SET_SERVICES_CATION_ACTION,
    ADD_SERVICE_IMG_CATION_ACTION
} from './constants.js';

const initialState = [
    // {
    //     "id": 1,
    //     "category": null,
    //     "name": {
    //         "ru": "Личная консультация",
    //         "en": "Private consultation"
    //     },
    //     "description": {
    //         "ru": "Персональная консультация в приватном чате с экспертом.",
    //         "en": "Personal consultation with an expert in a private chat."
    //     },
    //     "price": 100,
    //     "duration": 60,
    //     "location": {
    //         "ru": "Онлайн",
    //         "en": "Online"
    //     },
    //     "image": "SP_1671183840138.jpg",
    //     "date": "1671084872333"
    // },
    // {
    //     "id": 123,
    //     "category": null,
    //     "name": {
    //         "ru": "Хороший такой аудит",
    //         "en": "Good takoi audit"
    //     },
    //     "description": {
    //         "ru": "Делаем вжух-вжух и всё красиво",
    //         "en": "Do vjuh-vjuh and all krasivo"
    //     },
    //     "available": 5,
    //     "price": 150,
    //     "duration": 60,
    //     "location": {
    //         "ru": "Абобридж, 24",
    //         "en": "Abobridze, 24"
    //     },
    //     "subscriptions": {
    //         "2": 1,
    //         "3": 3
    //     },
    //     "image": "SP_1671183840138.jpg",
    //     "date": "1671084872333"
    // },
    // {
    //     "id": 2,
    //     "category": null,
    //     "name": {
    //         "ru": "Хороший такой аудит",
    //         "en": "Good takoi audit"
    //     },
    //     "description": {
    //         "ru": "Делаем вжух-вжух и всё красиво",
    //         "en": "Do vjuh-vjuh and all krasivo"
    //     },
    //     "price": 150,
    //     "duration": 30,
    //     "location": {
    //         "ru": "Абобридж, 24",
    //         "en": "Abobridze, 24"
    //     },
    //     "subscriptions": {
    //         "2": 1,
    //         "3": 3
    //     },
    //     "image": "SP_1671183840138.jpg",
    //     "date": "1671084872333"
    // },
    // {
    //     "id": 3,
    //     "category": null,
    //     "name": {
    //         "ru": "Хороший такой аудит",
    //         "en": "Good takoi audit"
    //     },
    //     "description": {
    //         "ru": "Делаем вжух-вжух и всё красиво",
    //         "en": "Do vjuh-vjuh and all krasivo"
    //     },
    //     "price": 150,
    //     "duration": 45,
    //     "location": {
    //         "ru": "Абобридж, 24",
    //         "en": "Abobridze, 24"
    //     },
    //     "available": 2,
    //     "subscriptions": {
    //         "2": 1,
    //         "3": 3
    //     },
    //     "image": "SP_1671183840138.jpg",
    //     "date": "1671084872333"
    // },
    // {
    //     "id": 4,
    //     "category": null,
    //     "name": {
    //         "ru": "Хороший такой аудит",
    //         "en": "Good takoi audit"
    //     },
    //     "description": {
    //         "ru": "Делаем вжух-вжух и всё красиво",
    //         "en": "Do vjuh-vjuh and all krasivo"
    //     },
    //     "price": 150,
    //     "duration": 120,
    //     "location": {
    //         "ru": "Абобридж, 24",
    //         "en": "Abobridze, 24"
    //     },
    //     "subscriptions": {
    //         "2": 1,
    //         "3": 3
    //     },
    //     "available": 2,
    //     "image": "SP_1671183840138.jpg",
    //     "date": "1671084872333"
    // },
    // {
    //     "id": 5,
    //     "category": null,
    //     "name": {
    //         "ru": "Хороший такой аудит",
    //         "en": "Good takoi audit"
    //     },
    //     "available": 1,
    //     "description": {
    //         "ru": "Делаем вжух-вжух и всё красиво",
    //         "en": "Do vjuh-vjuh and all krasivo"
    //     },
    //     "price": 150,
    //     "duration": 60,
    //     "location": {
    //         "ru": "Абобридж, 24",
    //         "en": "Abobridze, 24"
    //     },
    //     "subscriptions": {
    //         "2": 1,
    //         "3": 3
    //     },
    //     "image": "SP_1671183840138.jpg",
    //     "date": "1671084872333"
    // },
    // {
    //     "id": 9,
    //     "category": null,
    //     "name": {
    //         "ru": "Хороший такой аудит",
    //         "en": "Good takoi audit"
    //     },
    //     "description": {
    //         "ru": "Делаем вжух-вжух и всё красиво",
    //         "en": "Do vjuh-vjuh and all krasivo"
    //     },
    //     "price": 150,
    //     "duration": 60,
    //     "location": {
    //         "ru": "Абобридж, 24",
    //         "en": "Abobridze, 24"
    //     },
    //     "subscriptions": {
    //         "2": 1,
    //         "3": 3
    //     },
    //     "image": "SP_1671183840138.jpg",
    //     "date": "1671084872333"
    // },
    // {
    //     "id": 10,
    //     "category": 2,
    //     "name": {
    //         'ru': "Какие-то мутки",
    //         'en': "Mutki"
    //     },
    //     "description": {
    //         "ru": "Делаем вжух-вжух и всё красиво",
    //         "en": "Do vjuh-vjuh and all krasivo"
    //     },
    //     "price": 200,
    //     "duration": 90,
    //     "location": {
    //         "ru": "Абобридж, 24",
    //         "en": "Abobridze, 24"
    //     },
    //     "subscriptions": {
    //         "2": 1,
    //         "3": 3
    //     },
    //     "image": "SP_1671183840138.jpg",
    //     "date": "1671084872333"
    // },
    // {
    //     "id": 8,
    //     "category": 3,
    //     "name": {
    //         'ru': "Какие-то мутки",
    //         'en': "Mutki"
    //     },
    //     "description": {
    //         "ru": "Делаем вжух-вжух и всё красиво",
    //         "en": "Do vjuh-vjuh and all krasivo"
    //     },
    //     "price": 100,
    //     "duration": 30,
    //     "location": {
    //         'ru': 'Онлайн',
    //         'en': 'Online'
    //     },
    //     "subscriptions": {
    //         "1": 2,
    //         "2": 3,
    //     },
    //     "image": "SP_1671003782791.jpg",
    //     "date": "1670998277207"
    // }
];

export const servicesInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SERVICES_CATION_ACTION: {
            return([
                ...action.payload
            ])
        }
        case ADD_SERVICE_CATION_ACTION: {
            return ([
                ...state,
                {
                    ...action.payload.service
                }
            ])
        }
        case EDIT_SERVICE_CATION_ACTION: {
            return state.map((item) => {
                if (item.id != action.payload.service.id) {
                    return item;
                }
                return {
                    ...action.payload.service
                };
            });
        }
        case ADD_SERVICE_IMG_CATION_ACTION: {
            return state.map((item) => {
                if (item.id != action.payload.service.id) {
                    return item;
                }
                return {
                    ...item,
                    preview: action.payload.service.img
                };
            });
        }
        case DELETE_SERVICE_CATION_ACTION: {
            return state.filter(item => item.id != action.payload.id);
        }
        case USING_SUB_SERVICE_CATION_ACTION: {
            return state.map((item) => {
                if (item.id != action.payload.id) {
                    return item;
                }
                return {
                    ...item,
                    available: item.available - 1
                };
            });
        }
        case RETURN_SUB_SERVICE_CATION_ACTION: {
            return state.map((item) => {
                if (item.id != action.payload.id) {
                    return item;
                }
                return {
                    ...item,
                    available: item.available + 1
                };
            });
        }
        case DELETED_CATEGORY_CATION_ACTION: {
            return state.map((item) => {
                if (item.category == action.payload.id) {
                    return {
                        ...item,
                        category: null
                    }
                }
                return item
            });
        }
        default:
            return state;
    }
}