import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { subscriptionsSelector } from "../../Store/Subscriptions/selectors";
import { currentLanguageSelector } from "../../Store/Language/selectors";

export const DropdownForServices = ({ data, handler }) => {
    const { t, i18n } = useTranslation();

    const subscriptions = useSelector(subscriptionsSelector);
    const currentLanguage = useSelector(currentLanguageSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState([data[1], data[2], data[3]]);

    useEffect(() => {
        setValue([data[1], data[2], data[3]]);
        handler([data[1], data[2], data[3]]);
    }, [data]);

    const handleOpen = () => {
        setIsOpen(prev => !prev);
    }

    const handleClose = (e) => {
        if(!e.target.closest('.servicesDropdown')){
            setIsOpen(false);
        }
    }

    const handleChangeValue = (e, index) => {
        let temp = [...value];
        temp[index] = e.target.value;
        handler(temp);
        setValue(temp);
    }

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                document.addEventListener("click", handleClose);
            }, 0);
        } else {
            document.removeEventListener("click", handleClose);
        }
        return () => {
            document.removeEventListener("click", handleClose);
        };
    }, [isOpen]);

    return (
        <div className="querie__table__el delete">
            <button className={"querie__table__el__status dashboard__text delete " + (isOpen ? 'open' : '')} onClick={handleOpen}>
                {t(`dashboard.dropdown.plans`)}
            </button>
            <div className="querie__table__el__status__content delete servicesDropdown">
                {subscriptions.map((item, index) => {
                    return (
                        <div className="querie__table__el__status__content__el delete servicesDropdown">
                            <span className="querie__table__el__status__content__el__text">{item.name[currentLanguage]}</span>
                            <input key={Math.random() * 1000} value={value[index]} onChange={(e) => handleChangeValue(e, index)} type="text" className="querie__table__el__status__content__el__input" placeholder="0" />
                            <span className="querie__table__el__status__content__el__free">{t(`dashboard.dropdown.free`)}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}