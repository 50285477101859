import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { PopupDropdown } from "./Popup/PopupDropdown";
import IMAGESCALENDAR from './../../images/calendar/images';
import axios from "axios";
import { Calendar } from "react-calendar";
import { useSelector } from "react-redux";
import { currentLanguageSelector } from "../../Store/Language/selectors";

export const DropdownForDate = ({ handleTrigger, id, expertName, date = 0, expertsId = 0 }) => {
    const { t, i18n } = useTranslation();

    const [expertId, setExpertId] = useState();
    const [experts, setExperts] = useState();

    const [time, setTime] = useState([true, false, false, false, false, false]);
    const [newDate, setNewDate] = useState();
    const [value, onChange] = useState(new Date());
    const [newTime, setNewTime] = useState('13:00');

    const language = useSelector(currentLanguageSelector);


    useEffect(() => {
        axios({
            method: 'get',
            url: 'https://api.lawchill.ge/getAccounts',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            withCredentials: true,
        }).then((res) => {
            setExperts(res.data.accounts.filter(item => item.status == 'expert'));
        }).catch((err) => {
            console.log(err)
        });
        setExpertId(expertsId);
        if (date != 0) onChange(new Date(date * 1));
    }, []);

    useEffect(() => {
        if (experts) experts.map(item => item.name);
    }, [experts]);

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(prev => !prev);
    }

    const handleChangeTime = (e, id) => {
        setNewTime(e.target.value);
        console.log(e.target.value);
    }


    const arrowRight = <img className='react-calendar__navigation__arrow-right' src={IMAGESCALENDAR.arrowRight} alt=">" />
    const arrowLeft = <img className='react-calendar__navigation__arrow-left' src={IMAGESCALENDAR.arrowLeft} alt="<" />

    useEffect(() => {
        const date = value.toLocaleDateString('fr-CA');
        setNewDate(date);
    }, [value]);

    const [showDate, setShowDate] = useState('-');

    useEffect(() => {
        if (date == 0) {
            setShowDate('-');
        } else {
            setShowDate(new Date(date * 1).toLocaleString('ru', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            }));
        }
    }, [date]);

    const handleUpd = () => {
        setIsOpen(false);
        handleTrigger(prev => !prev);
        axios({
            method: 'post',
            url: 'https://api.lawchill.ge/createRegistration',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            data: {
                "rid": id,
                "eid": expertId,
                "date": `${newDate} ${newTime}`
            },
            withCredentials: true,
        }).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        });
    }

    return (
        <div key={id} className="querie__table__el delete">
            <button className={"querie__table__el__status " + (isOpen ? 'open' : '')} onClick={handleOpen}>{expertName}, {showDate}</button>
            <div className="querie__table__el__status__content big">
                <div className="querie__table__el__status__content__wrapper">
                    <div className="querie__table__el__status__content__elem expert">
                        <span className="querie__table__el__status__content__elem__title">{t('dashboard.dropdown.expert')}</span>
                        {experts && <PopupDropdown add={' full'} id={expertId} setId={setExpertId}
                            content={[t('dashboard.dropdown.notAssigned'), ...experts.map((item) => { return item.name })]}
                            ids={[0, ...experts.map((item) => { return item.id })]}
                        />}
                    </div>
                    <div className="querie__table__el__status__content__elem date">
                        <span className="querie__table__el__status__content__elem__title">{t('dashboard.dropdown.date')}</span>
                        <Calendar minDetail="month" prevLabel={arrowLeft} nextLabel={arrowRight} locale="en" onChange={onChange} value={value} className="calendar" />
                    </div>
                    <div className="querie__table__el__status__content__elem time">
                        <span className="querie__table__el__status__content__elem__title">{t('dashboard.dropdown.time')}</span>
                        <div className="querie__table__el__status__content__elem__time-box" style={{display: "flex", justifyContent: "center"}}>
                            {/* <button onClick={(e) => handleChangeTime(e, 0)} className={"querie__table__el__status__content__elem__time-box__el" + (time[0] ? ' active' : '')}>13:00</button>
                            <button onClick={(e) => handleChangeTime(e, 1)} className={"querie_ _table__el__status__content__elem__time-box__el" + (time[1] ? ' active' : '')}>14:00</button>
                            <button onClick={(e) => handleChangeTime(e, 2)} className={"querie__table__el__status__content__elem__time-box__el" + (time[2] ? ' active' : '')}>15:00</button>
                            <button onClick={(e) => handleChangeTime(e, 3)} className={"querie__table__el__status__content__elem__time-box__el" + (time[3] ? ' active' : '')}>16:00</button>
                            <button onClick={(e) => handleChangeTime(e, 4)} className={"querie__table__el__status__content__elem__time-box__el" + (time[4] ? ' active' : '')}>17:00</button>
                            <button onClick={(e) => handleChangeTime(e, 5)} className={"querie__table__el__status__content__elem__time-box__el" + (time[5] ? ' active' : '')}>18:00</button> */}
                            <input onChange={handleChangeTime} className={"querie__table__el__status__content__elem__time-box__el"} type="time" id="appointmentTime" name="appointmentTime" required></input>
                        </div>
                    </div>
                    <button onClick={handleUpd} className="querie__table__el__status__content__button">
                        <svg className="querie__table__el__status__content__button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="20" fill="#FDFAFA" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8431 13.4286L30.2071 19.7926C30.5976 20.1831 30.5976 20.8163 30.2071 21.2068L23.8431 27.5708C23.4526 27.9613 22.8195 27.9613 22.4289 27.5708C22.0384 27.1802 22.0384 26.5471 22.4289 26.1566L27.0858 21.4997H9.5V19.4997H27.0858L22.4289 14.8428C22.0384 14.4523 22.0384 13.8192 22.4289 13.4286C22.8195 13.0381 23.4526 13.0381 23.8431 13.4286Z" fill="#E75549" />
                        </svg>
                        <span className="querie__table__el__status__content__button__text">{t('dashboard.dropdown.signUp')}</span>
                    </button>
                </div>
            </div>
        </div>
    )
}