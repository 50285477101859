import { useDispatch, useSelector } from "react-redux";
import IMAGES from "./../../images/dashboard/images";
import { subscriptionsSelector } from "../../Store/Subscriptions/selectors";
import { currentLanguageSelector } from "../../Store/Language/selectors";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { PopupCreate } from "./Popup/PopupCreate";
import { PopupDone } from "./Popup/PopupDone";

export const Plans = () => {
    const { t, i18n } = useTranslation();

    const currentLanguage = useSelector(currentLanguageSelector);
    const subscriptions = useSelector(subscriptionsSelector);

    const [selectedGroupId, setSelectedGroupId] = useState();

    const [createModal, setCreateModal] = useState(false);
    const [doneModal, setDoneModal] = useState(false);

    const [actionCreate, setActionCreate] = useState('');


    const toggleModal = (modal, setModal) => {
        if (modal) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal');
        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal')
        }
        setModal(prev => !prev);
    };

    const openCreateModal = () => {
        toggleModal(createModal, setCreateModal);
    }

    const openDoneModal = () => {
        toggleModal(doneModal, setDoneModal);
    }

    const handleOpenCreateModal = (e, id, type) => {
        if (!e.target.classList.contains('delete')) {
            setActionCreate(type);
            setSelectedGroupId(id);
            openCreateModal();
        }
    }


    return (
        <div className="plans">
            <div className="plans__table desktop-dashboard">
                <div className="plans__table__title">
                    <div className="plans__table__title__el dashboard__title">{t('dashboard.plan.title')}</div>
                    <div className="plans__table__title__el dashboard__title">{t('dashboard.plan.cost')}</div>
                    <div className="plans__table__title__el dashboard__title">{t('dashboard.plan.duration')}</div>
                    <div className="plans__table__title__el dashboard__title">{t('dashboard.plan.features')}</div>
                    <div className="plans__table__title__el dashboard__title"></div>
                </div>
                {subscriptions.map((item) => {
                    const features = item.description[currentLanguage].length;
                    return (
                        <div onClick={(e) => handleOpenCreateModal(e, item.id, 'edit')} key={item.id} className="plans__table__row">
                            <div className="plans__table__el dashboard__text">{item.name[currentLanguage]}</div>
                            <div className="plans__table__el dashboard__text">₾{item.price}</div>
                            <div className="plans__table__el dashboard__text">{item.duration != 1 ? (`${item.duration} ${t("text-months")}`) : (`${item.duration} ${t("text-month")}`)}</div>
                            <div className="plans__table__el dashboard__text">{`${features} ${features > 4 ? (t('dashboard.features2')) : (t('dashboard.features1'))}`}</div>
                            <svg className="plans__table__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.5" cy="10.5" r="10" fill="#BCB7B3" />
                                <g clip-path="url(#clip0_2243_4190)">
                                    <path d="M4.5 16.5002H6.99961L14.3718 9.12803L11.8722 6.62842L4.5 14.0006V16.5002ZM5.83313 14.5539L11.8722 8.51479L12.4854 9.12803L6.44637 15.1671H5.83313V14.5539Z" fill="#FDFAFA" />
                                    <path d="M14.7456 4.69497C14.4856 4.43501 14.0657 4.43501 13.8057 4.69497L12.5859 5.91478L15.0856 8.4144L16.3054 7.19459C16.5653 6.93463 16.5653 6.51469 16.3054 6.25473L14.7456 4.69497Z" fill="#FDFAFA" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2243_4190">
                                        <rect width="12" height="12" fill="white" transform="translate(4.5 4.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    );
                })}
            </div>

            <div className="dashboard__table mobile">
                {subscriptions.map((item) => {
                    const features = item.description[currentLanguage].length;
                    return (
                        <div className="dashboard__table__mobile-row">
                            <div className="dashboard__table__mobile-row__title">
                                <div className="dashboard__table__mobile-row__title__left">
                                    <svg className="dashboard__table__mobile-row__title__left__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.16699 6.33366H10.8337V8.00033H9.16699V6.33366ZM9.16699 9.66699H10.8337V14.667H9.16699V9.66699ZM10.0003 2.16699C5.40033 2.16699 1.66699 5.90033 1.66699 10.5003C1.66699 15.1003 5.40033 18.8337 10.0003 18.8337C14.6003 18.8337 18.3337 15.1003 18.3337 10.5003C18.3337 5.90033 14.6003 2.16699 10.0003 2.16699ZM10.0003 17.167C6.32533 17.167 3.33366 14.1753 3.33366 10.5003C3.33366 6.82533 6.32533 3.83366 10.0003 3.83366C13.6753 3.83366 16.667 6.82533 16.667 10.5003C16.667 14.1753 13.6753 17.167 10.0003 17.167Z" fill="#BCB7B3" />
                                    </svg>
                                    <div className="dashboard__table__mobile-row__title__left__text">
                                        <span className="dashboard__table__mobile-row__title__left__text__title">{item.name[currentLanguage]}</span>
                                        <span className="dashboard__table__mobile-row__title__left__text__value">{item.duration != 1 ? (`${item.duration} ${t("text-months")}`) : (`${item.duration} ${t("text-month")}`)}</span>
                                    </div>
                                </div>
                                <div onClick={(e) => handleOpenCreateModal(e, item.id, 'edit')} className="dashboard__table__mobile-row__title__right">
                                    <svg className="plans__table__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10.5" cy="10.5" r="10" fill="#BCB7B3" />
                                        <g clip-path="url(#clip0_2243_4190)">
                                            <path d="M4.5 16.5002H6.99961L14.3718 9.12803L11.8722 6.62842L4.5 14.0006V16.5002ZM5.83313 14.5539L11.8722 8.51479L12.4854 9.12803L6.44637 15.1671H5.83313V14.5539Z" fill="#FDFAFA" />
                                            <path d="M14.7456 4.69497C14.4856 4.43501 14.0657 4.43501 13.8057 4.69497L12.5859 5.91478L15.0856 8.4144L16.3054 7.19459C16.5653 6.93463 16.5653 6.51469 16.3054 6.25473L14.7456 4.69497Z" fill="#FDFAFA" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2243_4190">
                                                <rect width="12" height="12" fill="white" transform="translate(4.5 4.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <PopupCreate page={'plans'} isOpen={createModal} openDoneModal={openDoneModal} handler={openCreateModal} id={selectedGroupId} action={actionCreate} />
            <PopupDone page={'plans'} isOpen={doneModal} openEditModal={openCreateModal} handler={openDoneModal} id={selectedGroupId} action={actionCreate} />
        </div>
    )
}