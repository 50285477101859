import { useTranslation } from "react-i18next";
import IMAGES from "../../../images/dashboard/images"
import IMAGESLOGIN from "../../../images/login/images";
import { LanguageChoose } from "./LanguageChoose";
import { useState } from "react";
import { GroupInput } from "./Group/GroupInput";
import { PlansInput } from "./Plans/PlansInput";
import { UsersInput } from "./Users/UsersInput";
import { ServicesInput } from "./Services/ServicesInput";
import { changeLanguage } from "i18next";
import { languageSelector, currentLanguageSelector } from "../../../Store/Language/selectors";
import { useDispatch, useSelector } from "react-redux";


export const PopupCreate = ({ userTrigger, data, page, setSelectedGroupId, isOpen, openDoneModal, handler, action, id, handlerOpenDelete }) => {
    const { t, i18n } = useTranslation();

    const [language, setLanguage] = useState('en');
    const [trigger, setTrigger] = useState(false);

    const currentLanguage = useSelector(currentLanguageSelector);


    const handleClosePopup = () => {
        handler();
        setLanguage('en');
    }

    const handleEditPopup = () => {
        handler();
        handlerOpenDelete();
        setLanguage('en');
    }

    const handleCreate = () => {
        setTrigger(true);
        handler();
        setLanguage('en');
        setTimeout(() => {
            setTrigger(false);
        }, 0);
        openDoneModal();
    }

    const changeLanguage = (id, value) => {
        setLanguage(value.language.toLowerCase());
        console.log(value.language.toLowerCase());
    }

    return (
        <div className={"dashboard__popup modal" + (isOpen ? ' show' : '') + (page == 'plans' ? ' scroll' : '')}>
            <div onClick={handleClosePopup} className="overlay"></div>
            <div className={"dashboard__popup__content modal-content" + (page == 'plans' ? ' scroll' : '')}>
                <div className="dashboard__popup__content__wrapper">
                    <div className="dashboard__popup__content__header">
                        <svg className="dashboard__popup__content__header__img" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="60" cy="60" r="59" stroke="#0B0A0A" stroke-width="2" />
                            <g clip-path="url(#clip0_1758_26708)">
                                <path d="M35 84.9997H45.4151L76.1325 54.2822L65.7175 43.8672L35 74.5847V84.9997ZM40.5547 76.8899L65.7175 51.7271L68.2726 54.2822L43.1099 79.445H40.5547V76.8899Z" fill="#0B0A0A" />
                                <path d="M77.6881 35.8124C76.6049 34.7292 74.8552 34.7292 73.772 35.8124L68.6895 40.8949L79.1045 51.31L84.1871 46.2274C85.2702 45.1443 85.2702 43.3945 84.1871 42.3114L77.6881 35.8124Z" fill="#0B0A0A" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1758_26708">
                                    <rect width="50" height="50" fill="white" transform="translate(35 35)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <p className="dashboard__popup__content__header__text">{action == 'add' ? t(`dashboard.popup.${page}.create.title`) : t(`dashboard.popup.${page}.change.title`)}</p>
                    </div>
                    <div className="dashboard__popup__content__info">
                        {page != 'users' && <LanguageChoose language={language} setLanguage={setLanguage} id={1} handler={changeLanguage} defaultValue={{language: currentLanguage.toUpperCase(), image: IMAGESLOGIN[currentLanguage.toUpperCase()]}} content={[{language: "EN", image: IMAGESLOGIN.EN}, {language: "KA", image: IMAGESLOGIN.KA}, {language: "UK", image: IMAGESLOGIN.UK}]}/>}
                        {page == 'groups' && (<GroupInput setSelectedGroupId={setSelectedGroupId} trigger={trigger} action={action} language={language} id={id} />)}
                        {page == 'plans' && (<PlansInput trigger={trigger} action={action} language={language} id={id} />)}
                        {page == 'users' && (<UsersInput userTrigger={userTrigger} data={data} trigger={trigger} action={action} language={language} id={id} />)}
                        {page == 'service' && (<ServicesInput setSelectedGroupId={setSelectedGroupId} trigger={trigger} action={action} language={language} id={id} />)}
                    </div>
                    <div className="dashboard__popup__content__buttons">
                        <button onClick={handleCreate} className="dashboard__popup__content__buttons__button keep">
                            <svg className="dashboard__popup__content__buttons__button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="#FDFAFA" />
                                <path d="M20 10V30M30 20H10" stroke="#E75549" stroke-width="2" />
                            </svg>

                            <p className="dashboard__popup__content__buttons__button__text">{action == 'add' ? t('dashboard.popup.create') : t('dashboard.popup.save')}</p>
                        </button>
                        <button onClick={(action == 'add' || page == 'plans' || page == 'users') ? handleClosePopup : handleEditPopup} className="dashboard__popup__content__buttons__button-close">
                            <svg className="dashboard__popup__content__buttons__button-close__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.5" cy="10.5" r="10" fill="#BCB7B3" />
                                <g clip-path="url(#clip0_2243_447)">
                                    <path d="M6.25827 6.25736L14.7435 14.7426M14.7435 6.25736L6.25827 14.7426" stroke="#FDFAFA" stroke-width="2" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2243_447">
                                        <rect width="12" height="12" fill="white" transform="translate(4.5 4.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <p className="dashboard__popup__content__buttons__button-close__text">{(action == 'add' || page == 'plans' || page == 'users') ? t('dashboard.popup.close') : t(`dashboard.popup.${page}.deleteButton`)}</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}