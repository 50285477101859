import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const { t, i18n } = useTranslation();

    const [type, setType] = useState('default');
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.toLowerCase().includes('/main/buy')) {
            setType('none');
        } else if (location.pathname.toLowerCase().includes('/faq') || location.pathname.toLowerCase().includes('/about') || location.pathname.toLowerCase().includes('/main')) {
            setType('alternative');
        } else {
            setType('default');
        }
    }, [location.pathname]);

    if (type === 'default') {
        return (
            <footer className="footer">
                <div className="footer__left">
                    <p className="footer__left__text">© Lawchill, 2022, {t("footer.rights")}</p>
                    <a href="https://heavens.pro/" target='_blank' className="footer__left__text__small">
                        <svg className="footer__left__text__small" viewBox="0 0 276 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.7899 7.17688L17.6266 9.0136L9.01445 17.6258L7.17773 15.7891L15.7899 7.17688Z" fill="#FE415E" />
                            <path d="M22.5654 13.9523L24.4021 15.7891L15.7899 24.4012L13.9532 22.5645L22.5654 13.9523Z" fill="#FE415E" />
                            <path d="M22.1368 13.5238L20.3001 15.3605L16.2185 11.2789L18.0552 9.44217L22.1368 13.5238Z" fill="#FE415E" />
                            <path d="M15.3613 20.2992L13.5246 22.1359L9.44302 18.0543L11.2797 16.2176L15.3613 20.2992Z" fill="#FE415E" />
                            <path d="M31.5781 19.2836H34.5106C36.7583 19.2836 39.1224 18.6506 39.1224 14.7235C39.1224 10.8092 36.7583 10.1762 34.5106 10.1762H31.5781V19.2836ZM32.7537 18.276V11.1839H34.5106C36.1124 11.1839 37.9468 11.4422 37.9468 14.7235C37.9468 18.0176 36.1254 18.276 34.5106 18.276H32.7537Z" fill="#0B0A0A" />
                            <path d="M39.8699 15.9895C39.8699 17.8884 40.9292 19.4128 43.022 19.4128C44.9985 19.4128 45.8511 18.2243 46.1094 17.0358H44.9726C44.753 17.9272 44.1071 18.4956 43.0091 18.4956C41.6785 18.4956 41.0197 17.5396 40.9809 16.2866H46.0836V15.8732C46.0836 13.8838 44.8435 12.7341 42.9445 12.7341C41.1101 12.7341 39.8699 13.9096 39.8699 15.9895ZM40.9938 15.4727C41.0842 14.3101 41.8852 13.6383 42.9445 13.6383C44.0942 13.6383 44.8822 14.3101 44.9597 15.4727H40.9938Z" fill="#0B0A0A" />
                            <path d="M49.6624 19.4128C51.3159 19.4128 52.1943 18.754 52.1943 17.5913C52.1943 16.5966 51.5355 16.067 50.4504 15.7569L48.9519 15.3306C48.3189 15.1498 48.0347 14.8914 48.0347 14.478C48.0347 13.9096 48.5385 13.6254 49.3653 13.6254C50.347 13.6254 50.8896 14.0388 50.8896 14.8914H52.0393C52.0393 13.4962 51.0059 12.7341 49.3523 12.7341C47.828 12.7341 46.8979 13.3671 46.8979 14.4909C46.8979 15.5115 47.66 15.9765 48.3835 16.1832L50.1016 16.687C50.7733 16.8679 51.0446 17.1263 51.0446 17.5655C51.0446 18.2243 50.5796 18.5085 49.6753 18.5085C48.4868 18.5085 47.7892 17.9789 47.7892 16.9196H46.6395C46.6395 18.5085 47.7505 19.4128 49.6624 19.4128Z" fill="#0B0A0A" />
                            <path d="M52.645 19.2836H57.2826V18.3018H55.487V13.6125C55.487 13.0829 55.2286 12.8632 54.7636 12.8632H53.0971V13.845H54.4277V18.3018H52.645V19.2836ZM53.911 10.8997C53.911 11.4035 54.3114 11.7652 54.8152 11.7652C55.332 11.7652 55.7454 11.4035 55.7454 10.8997C55.7454 10.3959 55.332 10.0341 54.8152 10.0341C54.3114 10.0341 53.911 10.3959 53.911 10.8997Z" fill="#0B0A0A" />
                            <path d="M60.3709 19.4128C61.8436 19.4128 62.6833 18.6119 62.9675 17.6172H63.2904L63.0967 18.3923V19.2836H63.0837V20.808H58.4332V21.7898H63.3421C63.9493 21.7898 64.2335 21.5185 64.2335 21.0017V12.8632H63.0967V13.7546L63.2904 14.5168H62.9675C62.6833 13.5221 61.8436 12.7341 60.3709 12.7341C58.4978 12.7341 57.3222 14.168 57.3222 16.067C57.3222 17.966 58.4978 19.4128 60.3709 19.4128ZM58.4719 16.067C58.4719 14.4134 59.4279 13.7158 60.7326 13.7158C62.0374 13.7158 63.0967 14.4134 63.0967 16.067C63.0967 17.7205 62.0374 18.431 60.7326 18.431C59.4279 18.431 58.4719 17.7205 58.4719 16.067Z" fill="#0B0A0A" />
                            <path d="M65.2356 19.2836H66.3854V16.4674C66.3854 14.7364 67.3155 13.7158 68.659 13.7158C69.7441 13.7158 70.2608 14.2197 70.2608 15.5502V19.2836H71.3976V15.3952C71.3976 13.5867 70.4417 12.7341 68.9819 12.7341C67.5868 12.7341 66.7729 13.5479 66.5145 14.7106H66.1916L66.3854 13.9355V12.8632H65.2356V19.2836Z" fill="#0B0A0A" />
                            <path d="M72.1782 15.9895C72.1782 17.8884 73.2375 19.4128 75.3302 19.4128C77.3067 19.4128 78.1593 18.2243 78.4177 17.0358H77.2809C77.0613 17.9272 76.4154 18.4956 75.3173 18.4956C73.9867 18.4956 73.3279 17.5396 73.2892 16.2866H78.3919V15.8732C78.3919 13.8838 77.1517 12.7341 75.2527 12.7341C73.4183 12.7341 72.1782 13.9096 72.1782 15.9895ZM73.3021 15.4727C73.3925 14.3101 74.1934 13.6383 75.2527 13.6383C76.4025 13.6383 77.1905 14.3101 77.268 15.4727H73.3021Z" fill="#0B0A0A" />
                            <path d="M82.1124 19.4128C83.5851 19.4128 84.4248 18.6119 84.709 17.6172H85.0319L84.8382 18.3923V19.2836H85.975V9.7887H84.8382V13.7546L85.0319 14.5168H84.709C84.4248 13.5221 83.5851 12.7341 82.1124 12.7341C80.2393 12.7341 79.0637 14.168 79.0637 16.067C79.0637 17.966 80.2393 19.4128 82.1124 19.4128ZM80.2134 16.067C80.2134 14.4134 81.1694 13.7158 82.4741 13.7158C83.7789 13.7158 84.8382 14.4134 84.8382 16.067C84.8382 17.7205 83.7789 18.431 82.4741 18.431C81.1694 18.431 80.2134 17.7205 80.2134 16.067Z" fill="#0B0A0A" />
                            <path d="M90.4757 17.5009C90.4757 18.1726 90.7599 19.4128 92.646 19.4128C94.2091 19.4128 94.8937 18.4956 95.1392 17.5138H95.4621L95.2813 18.1985V18.5085C95.2813 18.9994 95.5655 19.2836 96.0564 19.2836H97.2319V18.3018H96.2889V15.5761C96.2889 13.8838 95.2813 12.7341 93.4081 12.7341C91.7417 12.7341 90.7599 13.69 90.6178 15.1498H91.7546C91.8579 14.0776 92.5297 13.6642 93.421 13.6642C94.5579 13.6642 95.0875 14.2197 95.1392 15.3823L92.6201 15.6277C91.0958 15.7698 90.4757 16.4804 90.4757 17.5009ZM91.6125 17.3588C91.6125 16.7387 92.0259 16.5191 92.8139 16.4416L95.1392 16.1962C95.1392 17.488 94.222 18.4698 92.8268 18.4698C92.0905 18.4698 91.6125 18.0951 91.6125 17.3588Z" fill="#0B0A0A" />
                            <path d="M97.7836 19.2836H98.9333V16.4674C98.9333 14.7364 99.8634 13.7158 101.207 13.7158C102.292 13.7158 102.809 14.2197 102.809 15.5502V19.2836H103.946V15.3952C103.946 13.5867 102.99 12.7341 101.53 12.7341C100.135 12.7341 99.3209 13.5479 99.0625 14.7106H98.7395L98.9333 13.9355V12.8632H97.7836V19.2836Z" fill="#0B0A0A" />
                            <path d="M107.775 19.4128C109.248 19.4128 110.087 18.6119 110.371 17.6172H110.694L110.501 18.3923V19.2836H111.637V9.7887H110.501V13.7546L110.694 14.5168H110.371C110.087 13.5221 109.248 12.7341 107.775 12.7341C105.902 12.7341 104.726 14.168 104.726 16.067C104.726 17.966 105.902 19.4128 107.775 19.4128ZM105.876 16.067C105.876 14.4134 106.832 13.7158 108.137 13.7158C109.441 13.7158 110.501 14.4134 110.501 16.067C110.501 17.7205 109.441 18.431 108.137 18.431C106.832 18.431 105.876 17.7205 105.876 16.067Z" fill="#0B0A0A" />
                            <path d="M119.187 19.4128C120.659 19.4128 121.499 18.6119 121.783 17.6172H122.106L121.913 18.3923V19.2836H123.049V9.7887H121.913V13.7546L122.106 14.5168H121.783C121.499 13.5221 120.659 12.7341 119.187 12.7341C117.314 12.7341 116.138 14.168 116.138 16.067C116.138 17.966 117.314 19.4128 119.187 19.4128ZM117.288 16.067C117.288 14.4134 118.244 13.7158 119.549 13.7158C120.853 13.7158 121.913 14.4134 121.913 16.067C121.913 17.7205 120.853 18.431 119.549 18.431C118.244 18.431 117.288 17.7205 117.288 16.067Z" fill="#0B0A0A" />
                            <path d="M123.97 15.9895C123.97 17.8884 125.029 19.4128 127.122 19.4128C129.098 19.4128 129.951 18.2243 130.209 17.0358H129.073C128.853 17.9272 128.207 18.4956 127.109 18.4956C125.778 18.4956 125.12 17.5396 125.081 16.2866H130.183V15.8732C130.183 13.8838 128.943 12.7341 127.044 12.7341C125.21 12.7341 123.97 13.9096 123.97 15.9895ZM125.094 15.4727C125.184 14.3101 125.985 13.6383 127.044 13.6383C128.194 13.6383 128.982 14.3101 129.06 15.4727H125.094Z" fill="#0B0A0A" />
                            <path d="M135.678 12.8632L133.74 18.9607H133.417L131.453 12.8632H130.304L132.384 19.2836H134.773L136.815 12.8632H135.678Z" fill="#0B0A0A" />
                            <path d="M136.921 15.9895C136.921 17.8884 137.98 19.4128 140.073 19.4128C142.049 19.4128 142.902 18.2243 143.16 17.0358H142.024C141.804 17.9272 141.158 18.4956 140.06 18.4956C138.729 18.4956 138.071 17.5396 138.032 16.2866H143.135V15.8732C143.135 13.8838 141.894 12.7341 139.995 12.7341C138.161 12.7341 136.921 13.9096 136.921 15.9895ZM138.045 15.4727C138.135 14.3101 138.936 13.6383 139.995 13.6383C141.145 13.6383 141.933 14.3101 142.011 15.4727H138.045Z" fill="#0B0A0A" />
                            <path d="M143.362 19.2836H148.103V18.3018H146.308V10.4346C146.308 10.0083 146.062 9.7887 145.636 9.7887H143.815V10.7705H145.158V18.3018H143.362V19.2836Z" fill="#0B0A0A" />
                            <path d="M148.081 16.067C148.081 17.9789 149.437 19.4128 151.491 19.4128C153.532 19.4128 154.888 17.9789 154.888 16.067C154.888 14.168 153.532 12.7341 151.491 12.7341C149.437 12.7341 148.081 14.168 148.081 16.067ZM149.217 16.067C149.217 14.7751 150.018 13.7158 151.491 13.7158C152.951 13.7158 153.752 14.7751 153.752 16.067C153.752 17.3717 152.951 18.431 151.491 18.431C150.018 18.431 149.217 17.3717 149.217 16.067Z" fill="#0B0A0A" />
                            <path d="M155.792 21.7898H156.942V18.3923L156.748 17.6172H157.071C157.342 18.6119 158.182 19.4128 159.655 19.4128C161.528 19.4128 162.703 17.966 162.703 16.0799C162.703 14.168 161.528 12.7341 159.655 12.7341C158.182 12.7341 157.342 13.5221 157.071 14.5168H156.748L156.942 13.7546V12.8632H155.792V21.7898ZM156.942 16.0799C156.942 14.4134 157.988 13.7158 159.293 13.7158C160.611 13.7158 161.567 14.4393 161.567 16.0799C161.567 17.7076 160.611 18.431 159.293 18.431C157.988 18.431 156.942 17.7334 156.942 16.0799Z" fill="#0B0A0A" />
                            <path d="M163.403 15.9895C163.403 17.8884 164.463 19.4128 166.555 19.4128C168.532 19.4128 169.384 18.2243 169.643 17.0358H168.506C168.286 17.9272 167.64 18.4956 166.542 18.4956C165.212 18.4956 164.553 17.5396 164.514 16.2866H169.617V15.8732C169.617 13.8838 168.377 12.7341 166.478 12.7341C164.643 12.7341 163.403 13.9096 163.403 15.9895ZM164.527 15.4727C164.618 14.3101 165.419 13.6383 166.478 13.6383C167.628 13.6383 168.416 14.3101 168.493 15.4727H164.527Z" fill="#0B0A0A" />
                            <path d="M173.338 19.4128C174.81 19.4128 175.65 18.6119 175.934 17.6172H176.257L176.063 18.3923V19.2836H177.2V9.7887H176.063V13.7546L176.257 14.5168H175.934C175.65 13.5221 174.81 12.7341 173.338 12.7341C171.464 12.7341 170.289 14.168 170.289 16.067C170.289 17.966 171.464 19.4128 173.338 19.4128ZM171.439 16.067C171.439 14.4134 172.394 13.7158 173.699 13.7158C175.004 13.7158 176.063 14.4134 176.063 16.067C176.063 17.7205 175.004 18.431 173.699 18.431C172.394 18.431 171.439 17.7205 171.439 16.067Z" fill="#0B0A0A" />
                            <path d="M181.959 19.2836H183.109V18.3923L182.915 17.6172H183.238C183.509 18.6119 184.362 19.4128 185.835 19.4128C187.708 19.4128 188.87 17.966 188.87 16.067C188.87 14.168 187.708 12.7341 185.835 12.7341C184.362 12.7341 183.509 13.5221 183.238 14.5168H182.915L183.109 13.7546V9.7887H181.959V19.2836ZM183.109 16.067C183.109 14.4134 184.155 13.7158 185.46 13.7158C186.778 13.7158 187.734 14.4134 187.734 16.067C187.734 17.7205 186.778 18.431 185.46 18.431C184.155 18.431 183.109 17.7205 183.109 16.067Z" fill="#0B0A0A" />
                            <path d="M195.85 12.8632H194.7V15.6794C194.7 17.4105 193.77 18.431 192.427 18.431C191.342 18.431 190.825 17.9272 190.825 16.5966V12.8632H189.688V16.7516C189.688 18.5602 190.644 19.4128 192.104 19.4128C193.499 19.4128 194.313 18.5989 194.571 17.4363H194.894L194.7 18.2114V20.808H190.05V21.7898H194.959C195.566 21.7898 195.85 21.5185 195.85 21.0017V12.8632Z" fill="#0B0A0A" />
                            <path d="M200.458 19.2836H201.608V16.5579C201.608 14.8268 202.474 13.7158 203.869 13.7158C204.967 13.7158 205.471 14.2326 205.471 15.5502V19.2836H206.608V15.4211C206.608 13.69 205.729 12.7341 204.269 12.7341C202.874 12.7341 202.022 13.5479 201.737 14.7106H201.402L201.608 13.9355V9.7887H200.458V19.2836Z" fill="#FE415E" />
                            <path d="M207.401 15.9895C207.401 17.8884 208.46 19.4128 210.553 19.4128C212.53 19.4128 213.382 18.2243 213.641 17.0358H212.504C212.284 17.9272 211.638 18.4956 210.54 18.4956C209.21 18.4956 208.551 17.5396 208.512 16.2866H213.615V15.8732C213.615 13.8838 212.375 12.7341 210.476 12.7341C208.641 12.7341 207.401 13.9096 207.401 15.9895ZM208.525 15.4727C208.615 14.3101 209.416 13.6383 210.476 13.6383C211.625 13.6383 212.413 14.3101 212.491 15.4727H208.525Z" fill="#FE415E" />
                            <path d="M214.274 17.5009C214.274 18.1726 214.558 19.4128 216.444 19.4128C218.007 19.4128 218.692 18.4956 218.937 17.5138H219.26L219.08 18.1985V18.5085C219.08 18.9994 219.364 19.2836 219.855 19.2836H221.03V18.3018H220.087V15.5761C220.087 13.8838 219.08 12.7341 217.206 12.7341C215.54 12.7341 214.558 13.69 214.416 15.1498H215.553C215.656 14.0776 216.328 13.6642 217.219 13.6642C218.356 13.6642 218.886 14.2197 218.937 15.3823L216.418 15.6277C214.894 15.7698 214.274 16.4804 214.274 17.5009ZM215.411 17.3588C215.411 16.7387 215.824 16.5191 216.612 16.4416L218.937 16.1962C218.937 17.488 218.02 18.4698 216.625 18.4698C215.889 18.4698 215.411 18.0951 215.411 17.3588Z" fill="#FE415E" />
                            <path d="M225.78 12.8632L223.842 18.9607H223.519L221.556 12.8632H220.406L222.486 19.2836H224.876L226.917 12.8632H225.78Z" fill="#FE415E" />
                            <path d="M227.023 15.9895C227.023 17.8884 228.082 19.4128 230.175 19.4128C232.152 19.4128 233.004 18.2243 233.263 17.0358H232.126C231.906 17.9272 231.26 18.4956 230.162 18.4956C228.832 18.4956 228.173 17.5396 228.134 16.2866H233.237V15.8732C233.237 13.8838 231.997 12.7341 230.098 12.7341C228.263 12.7341 227.023 13.9096 227.023 15.9895ZM228.147 15.4727C228.237 14.3101 229.038 13.6383 230.098 13.6383C231.247 13.6383 232.035 14.3101 232.113 15.4727H228.147Z" fill="#FE415E" />
                            <path d="M234.154 19.2836H235.304V16.4674C235.304 14.7364 236.234 13.7158 237.578 13.7158C238.663 13.7158 239.18 14.2197 239.18 15.5502V19.2836H240.316V15.3952C240.316 13.5867 239.36 12.7341 237.901 12.7341C236.506 12.7341 235.692 13.5479 235.433 14.7106H235.11L235.304 13.9355V12.8632H234.154V19.2836Z" fill="#FE415E" />
                            <path d="M244.017 19.4128C245.67 19.4128 246.549 18.754 246.549 17.5913C246.549 16.5966 245.89 16.067 244.805 15.7569L243.306 15.3306C242.673 15.1498 242.389 14.8914 242.389 14.478C242.389 13.9096 242.893 13.6254 243.719 13.6254C244.701 13.6254 245.244 14.0388 245.244 14.8914H246.393C246.393 13.4962 245.36 12.7341 243.706 12.7341C242.182 12.7341 241.252 13.3671 241.252 14.4909C241.252 15.5115 242.014 15.9765 242.738 16.1832L244.456 16.687C245.128 16.8679 245.399 17.1263 245.399 17.5655C245.399 18.2243 244.934 18.5085 244.029 18.5085C242.841 18.5085 242.143 17.9789 242.143 16.9196H240.994C240.994 18.5085 242.105 19.4128 244.017 19.4128Z" fill="#FE415E" />
                            <path d="M248.068 19.4128C248.688 19.4128 249.179 18.9219 249.179 18.3147C249.179 17.7076 248.688 17.2296 248.068 17.2296C247.435 17.2296 246.97 17.7076 246.97 18.3147C246.97 18.9219 247.435 19.4128 248.068 19.4128Z" fill="#FE415E" />
                            <path d="M249.891 21.7898H251.041V18.3923L250.847 17.6172H251.17C251.441 18.6119 252.281 19.4128 253.754 19.4128C255.627 19.4128 256.802 17.966 256.802 16.0799C256.802 14.168 255.627 12.7341 253.754 12.7341C252.281 12.7341 251.441 13.5221 251.17 14.5168H250.847L251.041 13.7546V12.8632H249.891V21.7898ZM251.041 16.0799C251.041 14.4134 252.087 13.7158 253.392 13.7158C254.71 13.7158 255.665 14.4393 255.665 16.0799C255.665 17.7076 254.71 18.431 253.392 18.431C252.087 18.431 251.041 17.7334 251.041 16.0799Z" fill="#FE415E" />
                            <path d="M257.698 13.7546V19.2836H258.847V13.845H262.232V12.8632H258.602C257.995 12.8632 257.698 13.1474 257.698 13.7546Z" fill="#FE415E" />
                            <path d="M262.016 16.067C262.016 17.9789 263.372 19.4128 265.426 19.4128C267.467 19.4128 268.824 17.9789 268.824 16.067C268.824 14.168 267.467 12.7341 265.426 12.7341C263.372 12.7341 262.016 14.168 262.016 16.067ZM263.153 16.067C263.153 14.7751 263.954 13.7158 265.426 13.7158C266.886 13.7158 267.687 14.7751 267.687 16.067C267.687 17.3717 266.886 18.431 265.426 18.431C263.954 18.431 263.153 17.3717 263.153 16.067Z" fill="#FE415E" />
                        </svg>
                    </a>
                </div>
                <div className="footer__right">
                    <Link to="/TermsAndConditions" className="footer__right__text first">{t("footer.terms")}</Link>
                    {/* <Link className="footer__right__text second">{t("footer.policy")}</Link> */}
                </div>
            </footer>)
    } else if (type === 'alternative') {
        return (
            <footer className="footer alternitive">
                <div className="footer__left">
                    <p className="footer__left__text alternitive">© Lawchill, 2022, {t("footer.rights")}</p>
                    <a href="https://heavens.pro/" target='_blank' className="footer__left__text__small">
                        <svg className="footer__left__text__small alternitive" viewBox="0 0 276 32" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.7899 7.17688L17.6266 9.0136L9.01445 17.6258L7.17773 15.7891L15.7899 7.17688Z" fill="#FFFFFF" />
                            <path d="M22.5654 13.9523L24.4021 15.7891L15.7899 24.4012L13.9532 22.5645L22.5654 13.9523Z" fill="#FFFFFF" />
                            <path d="M22.1368 13.5238L20.3001 15.3605L16.2185 11.2789L18.0552 9.44217L22.1368 13.5238Z" fill="#FFFFFF" />
                            <path d="M15.3613 20.2992L13.5246 22.1359L9.44302 18.0543L11.2797 16.2176L15.3613 20.2992Z" fill="#FFFFFF" />
                            <path d="M31.5781 19.2836H34.5106C36.7583 19.2836 39.1224 18.6506 39.1224 14.7235C39.1224 10.8092 36.7583 10.1762 34.5106 10.1762H31.5781V19.2836ZM32.7537 18.276V11.1839H34.5106C36.1124 11.1839 37.9468 11.4422 37.9468 14.7235C37.9468 18.0176 36.1254 18.276 34.5106 18.276H32.7537Z" fill="white" />
                            <path d="M39.8699 15.9895C39.8699 17.8884 40.9292 19.4128 43.022 19.4128C44.9985 19.4128 45.8511 18.2243 46.1094 17.0358H44.9726C44.753 17.9272 44.1071 18.4956 43.0091 18.4956C41.6785 18.4956 41.0197 17.5396 40.9809 16.2866H46.0836V15.8732C46.0836 13.8838 44.8435 12.7341 42.9445 12.7341C41.1101 12.7341 39.8699 13.9096 39.8699 15.9895ZM40.9938 15.4727C41.0842 14.3101 41.8852 13.6383 42.9445 13.6383C44.0942 13.6383 44.8822 14.3101 44.9597 15.4727H40.9938Z" fill="white" />
                            <path d="M49.6624 19.4128C51.3159 19.4128 52.1943 18.754 52.1943 17.5913C52.1943 16.5966 51.5355 16.067 50.4504 15.7569L48.9519 15.3306C48.3189 15.1498 48.0347 14.8914 48.0347 14.478C48.0347 13.9096 48.5385 13.6254 49.3653 13.6254C50.347 13.6254 50.8896 14.0388 50.8896 14.8914H52.0393C52.0393 13.4962 51.0059 12.7341 49.3523 12.7341C47.828 12.7341 46.8979 13.3671 46.8979 14.4909C46.8979 15.5115 47.66 15.9765 48.3835 16.1832L50.1016 16.687C50.7733 16.8679 51.0446 17.1263 51.0446 17.5655C51.0446 18.2243 50.5796 18.5085 49.6753 18.5085C48.4868 18.5085 47.7892 17.9789 47.7892 16.9196H46.6395C46.6395 18.5085 47.7505 19.4128 49.6624 19.4128Z" fill="white" />
                            <path d="M52.645 19.2836H57.2826V18.3018H55.487V13.6125C55.487 13.0829 55.2286 12.8632 54.7636 12.8632H53.0971V13.845H54.4277V18.3018H52.645V19.2836ZM53.911 10.8997C53.911 11.4035 54.3114 11.7652 54.8152 11.7652C55.332 11.7652 55.7454 11.4035 55.7454 10.8997C55.7454 10.3959 55.332 10.0341 54.8152 10.0341C54.3114 10.0341 53.911 10.3959 53.911 10.8997Z" fill="white" />
                            <path d="M60.3709 19.4128C61.8436 19.4128 62.6833 18.6119 62.9675 17.6172H63.2904L63.0967 18.3923V19.2836H63.0837V20.808H58.4332V21.7898H63.3421C63.9493 21.7898 64.2335 21.5185 64.2335 21.0017V12.8632H63.0967V13.7546L63.2904 14.5168H62.9675C62.6833 13.5221 61.8436 12.7341 60.3709 12.7341C58.4978 12.7341 57.3222 14.168 57.3222 16.067C57.3222 17.966 58.4978 19.4128 60.3709 19.4128ZM58.4719 16.067C58.4719 14.4134 59.4279 13.7158 60.7326 13.7158C62.0374 13.7158 63.0967 14.4134 63.0967 16.067C63.0967 17.7205 62.0374 18.431 60.7326 18.431C59.4279 18.431 58.4719 17.7205 58.4719 16.067Z" fill="white" />
                            <path d="M65.2356 19.2836H66.3854V16.4674C66.3854 14.7364 67.3155 13.7158 68.659 13.7158C69.7441 13.7158 70.2608 14.2197 70.2608 15.5502V19.2836H71.3976V15.3952C71.3976 13.5867 70.4417 12.7341 68.9819 12.7341C67.5868 12.7341 66.7729 13.5479 66.5145 14.7106H66.1916L66.3854 13.9355V12.8632H65.2356V19.2836Z" fill="white" />
                            <path d="M72.1782 15.9895C72.1782 17.8884 73.2375 19.4128 75.3302 19.4128C77.3067 19.4128 78.1593 18.2243 78.4177 17.0358H77.2809C77.0613 17.9272 76.4154 18.4956 75.3173 18.4956C73.9867 18.4956 73.3279 17.5396 73.2892 16.2866H78.3919V15.8732C78.3919 13.8838 77.1517 12.7341 75.2527 12.7341C73.4183 12.7341 72.1782 13.9096 72.1782 15.9895ZM73.3021 15.4727C73.3925 14.3101 74.1934 13.6383 75.2527 13.6383C76.4025 13.6383 77.1905 14.3101 77.268 15.4727H73.3021Z" fill="white" />
                            <path d="M82.1124 19.4128C83.5851 19.4128 84.4248 18.6119 84.709 17.6172H85.0319L84.8382 18.3923V19.2836H85.975V9.7887H84.8382V13.7546L85.0319 14.5168H84.709C84.4248 13.5221 83.5851 12.7341 82.1124 12.7341C80.2393 12.7341 79.0637 14.168 79.0637 16.067C79.0637 17.966 80.2393 19.4128 82.1124 19.4128ZM80.2134 16.067C80.2134 14.4134 81.1694 13.7158 82.4741 13.7158C83.7789 13.7158 84.8382 14.4134 84.8382 16.067C84.8382 17.7205 83.7789 18.431 82.4741 18.431C81.1694 18.431 80.2134 17.7205 80.2134 16.067Z" fill="white" />
                            <path d="M90.4757 17.5009C90.4757 18.1726 90.7599 19.4128 92.646 19.4128C94.2091 19.4128 94.8937 18.4956 95.1392 17.5138H95.4621L95.2813 18.1985V18.5085C95.2813 18.9994 95.5655 19.2836 96.0564 19.2836H97.2319V18.3018H96.2889V15.5761C96.2889 13.8838 95.2813 12.7341 93.4081 12.7341C91.7417 12.7341 90.7599 13.69 90.6178 15.1498H91.7546C91.8579 14.0776 92.5297 13.6642 93.421 13.6642C94.5579 13.6642 95.0875 14.2197 95.1392 15.3823L92.6201 15.6277C91.0958 15.7698 90.4757 16.4804 90.4757 17.5009ZM91.6125 17.3588C91.6125 16.7387 92.0259 16.5191 92.8139 16.4416L95.1392 16.1962C95.1392 17.488 94.222 18.4698 92.8268 18.4698C92.0905 18.4698 91.6125 18.0951 91.6125 17.3588Z" fill="white" />
                            <path d="M97.7836 19.2836H98.9333V16.4674C98.9333 14.7364 99.8634 13.7158 101.207 13.7158C102.292 13.7158 102.809 14.2197 102.809 15.5502V19.2836H103.946V15.3952C103.946 13.5867 102.99 12.7341 101.53 12.7341C100.135 12.7341 99.3209 13.5479 99.0625 14.7106H98.7395L98.9333 13.9355V12.8632H97.7836V19.2836Z" fill="white" />
                            <path d="M107.775 19.4128C109.248 19.4128 110.087 18.6119 110.371 17.6172H110.694L110.501 18.3923V19.2836H111.637V9.7887H110.501V13.7546L110.694 14.5168H110.371C110.087 13.5221 109.248 12.7341 107.775 12.7341C105.902 12.7341 104.726 14.168 104.726 16.067C104.726 17.966 105.902 19.4128 107.775 19.4128ZM105.876 16.067C105.876 14.4134 106.832 13.7158 108.137 13.7158C109.441 13.7158 110.501 14.4134 110.501 16.067C110.501 17.7205 109.441 18.431 108.137 18.431C106.832 18.431 105.876 17.7205 105.876 16.067Z" fill="white" />
                            <path d="M119.187 19.4128C120.659 19.4128 121.499 18.6119 121.783 17.6172H122.106L121.913 18.3923V19.2836H123.049V9.7887H121.913V13.7546L122.106 14.5168H121.783C121.499 13.5221 120.659 12.7341 119.187 12.7341C117.314 12.7341 116.138 14.168 116.138 16.067C116.138 17.966 117.314 19.4128 119.187 19.4128ZM117.288 16.067C117.288 14.4134 118.244 13.7158 119.549 13.7158C120.853 13.7158 121.913 14.4134 121.913 16.067C121.913 17.7205 120.853 18.431 119.549 18.431C118.244 18.431 117.288 17.7205 117.288 16.067Z" fill="white" />
                            <path d="M123.97 15.9895C123.97 17.8884 125.029 19.4128 127.122 19.4128C129.098 19.4128 129.951 18.2243 130.209 17.0358H129.073C128.853 17.9272 128.207 18.4956 127.109 18.4956C125.778 18.4956 125.12 17.5396 125.081 16.2866H130.183V15.8732C130.183 13.8838 128.943 12.7341 127.044 12.7341C125.21 12.7341 123.97 13.9096 123.97 15.9895ZM125.094 15.4727C125.184 14.3101 125.985 13.6383 127.044 13.6383C128.194 13.6383 128.982 14.3101 129.06 15.4727H125.094Z" fill="white" />
                            <path d="M135.678 12.8632L133.74 18.9607H133.417L131.453 12.8632H130.304L132.384 19.2836H134.773L136.815 12.8632H135.678Z" fill="white" />
                            <path d="M136.921 15.9895C136.921 17.8884 137.98 19.4128 140.073 19.4128C142.049 19.4128 142.902 18.2243 143.16 17.0358H142.024C141.804 17.9272 141.158 18.4956 140.06 18.4956C138.729 18.4956 138.071 17.5396 138.032 16.2866H143.135V15.8732C143.135 13.8838 141.894 12.7341 139.995 12.7341C138.161 12.7341 136.921 13.9096 136.921 15.9895ZM138.045 15.4727C138.135 14.3101 138.936 13.6383 139.995 13.6383C141.145 13.6383 141.933 14.3101 142.011 15.4727H138.045Z" fill="white" />
                            <path d="M143.362 19.2836H148.103V18.3018H146.308V10.4346C146.308 10.0083 146.062 9.7887 145.636 9.7887H143.815V10.7705H145.158V18.3018H143.362V19.2836Z" fill="white" />
                            <path d="M148.081 16.067C148.081 17.9789 149.437 19.4128 151.491 19.4128C153.532 19.4128 154.888 17.9789 154.888 16.067C154.888 14.168 153.532 12.7341 151.491 12.7341C149.437 12.7341 148.081 14.168 148.081 16.067ZM149.217 16.067C149.217 14.7751 150.018 13.7158 151.491 13.7158C152.951 13.7158 153.752 14.7751 153.752 16.067C153.752 17.3717 152.951 18.431 151.491 18.431C150.018 18.431 149.217 17.3717 149.217 16.067Z" fill="white" />
                            <path d="M155.792 21.7898H156.942V18.3923L156.748 17.6172H157.071C157.342 18.6119 158.182 19.4128 159.655 19.4128C161.528 19.4128 162.703 17.966 162.703 16.0799C162.703 14.168 161.528 12.7341 159.655 12.7341C158.182 12.7341 157.342 13.5221 157.071 14.5168H156.748L156.942 13.7546V12.8632H155.792V21.7898ZM156.942 16.0799C156.942 14.4134 157.988 13.7158 159.293 13.7158C160.611 13.7158 161.567 14.4393 161.567 16.0799C161.567 17.7076 160.611 18.431 159.293 18.431C157.988 18.431 156.942 17.7334 156.942 16.0799Z" fill="white" />
                            <path d="M163.403 15.9895C163.403 17.8884 164.463 19.4128 166.555 19.4128C168.532 19.4128 169.384 18.2243 169.643 17.0358H168.506C168.286 17.9272 167.64 18.4956 166.542 18.4956C165.212 18.4956 164.553 17.5396 164.514 16.2866H169.617V15.8732C169.617 13.8838 168.377 12.7341 166.478 12.7341C164.643 12.7341 163.403 13.9096 163.403 15.9895ZM164.527 15.4727C164.618 14.3101 165.419 13.6383 166.478 13.6383C167.628 13.6383 168.416 14.3101 168.493 15.4727H164.527Z" fill="white" />
                            <path d="M173.338 19.4128C174.81 19.4128 175.65 18.6119 175.934 17.6172H176.257L176.063 18.3923V19.2836H177.2V9.7887H176.063V13.7546L176.257 14.5168H175.934C175.65 13.5221 174.81 12.7341 173.338 12.7341C171.464 12.7341 170.289 14.168 170.289 16.067C170.289 17.966 171.464 19.4128 173.338 19.4128ZM171.439 16.067C171.439 14.4134 172.394 13.7158 173.699 13.7158C175.004 13.7158 176.063 14.4134 176.063 16.067C176.063 17.7205 175.004 18.431 173.699 18.431C172.394 18.431 171.439 17.7205 171.439 16.067Z" fill="white" />
                            <path d="M181.959 19.2836H183.109V18.3923L182.915 17.6172H183.238C183.509 18.6119 184.362 19.4128 185.835 19.4128C187.708 19.4128 188.87 17.966 188.87 16.067C188.87 14.168 187.708 12.7341 185.835 12.7341C184.362 12.7341 183.509 13.5221 183.238 14.5168H182.915L183.109 13.7546V9.7887H181.959V19.2836ZM183.109 16.067C183.109 14.4134 184.155 13.7158 185.46 13.7158C186.778 13.7158 187.734 14.4134 187.734 16.067C187.734 17.7205 186.778 18.431 185.46 18.431C184.155 18.431 183.109 17.7205 183.109 16.067Z" fill="white" />
                            <path d="M195.85 12.8632H194.7V15.6794C194.7 17.4105 193.77 18.431 192.427 18.431C191.342 18.431 190.825 17.9272 190.825 16.5966V12.8632H189.688V16.7516C189.688 18.5602 190.644 19.4128 192.104 19.4128C193.499 19.4128 194.313 18.5989 194.571 17.4363H194.894L194.7 18.2114V20.808H190.05V21.7898H194.959C195.566 21.7898 195.85 21.5185 195.85 21.0017V12.8632Z" fill="white" />
                            <path d="M200.458 19.2836H201.608V16.5579C201.608 14.8268 202.474 13.7158 203.869 13.7158C204.967 13.7158 205.471 14.2326 205.471 15.5502V19.2836H206.608V15.4211C206.608 13.69 205.729 12.7341 204.269 12.7341C202.874 12.7341 202.022 13.5479 201.737 14.7106H201.402L201.608 13.9355V9.7887H200.458V19.2836Z" fill="#FFFFFFF" />
                            <path d="M207.401 15.9895C207.401 17.8884 208.46 19.4128 210.553 19.4128C212.53 19.4128 213.382 18.2243 213.641 17.0358H212.504C212.284 17.9272 211.638 18.4956 210.54 18.4956C209.21 18.4956 208.551 17.5396 208.512 16.2866H213.615V15.8732C213.615 13.8838 212.375 12.7341 210.476 12.7341C208.641 12.7341 207.401 13.9096 207.401 15.9895ZM208.525 15.4727C208.615 14.3101 209.416 13.6383 210.476 13.6383C211.625 13.6383 212.413 14.3101 212.491 15.4727H208.525Z" fill="#FFFFFFF" />
                            <path d="M214.274 17.5009C214.274 18.1726 214.558 19.4128 216.444 19.4128C218.007 19.4128 218.692 18.4956 218.937 17.5138H219.26L219.08 18.1985V18.5085C219.08 18.9994 219.364 19.2836 219.855 19.2836H221.03V18.3018H220.087V15.5761C220.087 13.8838 219.08 12.7341 217.206 12.7341C215.54 12.7341 214.558 13.69 214.416 15.1498H215.553C215.656 14.0776 216.328 13.6642 217.219 13.6642C218.356 13.6642 218.886 14.2197 218.937 15.3823L216.418 15.6277C214.894 15.7698 214.274 16.4804 214.274 17.5009ZM215.411 17.3588C215.411 16.7387 215.824 16.5191 216.612 16.4416L218.937 16.1962C218.937 17.488 218.02 18.4698 216.625 18.4698C215.889 18.4698 215.411 18.0951 215.411 17.3588Z" fill="#FFFFFFF" />
                            <path d="M225.78 12.8632L223.842 18.9607H223.519L221.556 12.8632H220.406L222.486 19.2836H224.876L226.917 12.8632H225.78Z" fill="#FFFFFFF" />
                            <path d="M227.023 15.9895C227.023 17.8884 228.082 19.4128 230.175 19.4128C232.152 19.4128 233.004 18.2243 233.263 17.0358H232.126C231.906 17.9272 231.26 18.4956 230.162 18.4956C228.832 18.4956 228.173 17.5396 228.134 16.2866H233.237V15.8732C233.237 13.8838 231.997 12.7341 230.098 12.7341C228.263 12.7341 227.023 13.9096 227.023 15.9895ZM228.147 15.4727C228.237 14.3101 229.038 13.6383 230.098 13.6383C231.247 13.6383 232.035 14.3101 232.113 15.4727H228.147Z" fill="#FFFFFFF" />
                            <path d="M234.154 19.2836H235.304V16.4674C235.304 14.7364 236.234 13.7158 237.578 13.7158C238.663 13.7158 239.18 14.2197 239.18 15.5502V19.2836H240.316V15.3952C240.316 13.5867 239.36 12.7341 237.901 12.7341C236.506 12.7341 235.692 13.5479 235.433 14.7106H235.11L235.304 13.9355V12.8632H234.154V19.2836Z" fill="#FFFFFFF" />
                            <path d="M244.017 19.4128C245.67 19.4128 246.549 18.754 246.549 17.5913C246.549 16.5966 245.89 16.067 244.805 15.7569L243.306 15.3306C242.673 15.1498 242.389 14.8914 242.389 14.478C242.389 13.9096 242.893 13.6254 243.719 13.6254C244.701 13.6254 245.244 14.0388 245.244 14.8914H246.393C246.393 13.4962 245.36 12.7341 243.706 12.7341C242.182 12.7341 241.252 13.3671 241.252 14.4909C241.252 15.5115 242.014 15.9765 242.738 16.1832L244.456 16.687C245.128 16.8679 245.399 17.1263 245.399 17.5655C245.399 18.2243 244.934 18.5085 244.029 18.5085C242.841 18.5085 242.143 17.9789 242.143 16.9196H240.994C240.994 18.5085 242.105 19.4128 244.017 19.4128Z" fill="#FFFFFFF" />
                            <path d="M248.068 19.4128C248.688 19.4128 249.179 18.9219 249.179 18.3147C249.179 17.7076 248.688 17.2296 248.068 17.2296C247.435 17.2296 246.97 17.7076 246.97 18.3147C246.97 18.9219 247.435 19.4128 248.068 19.4128Z" fill="#FFFFFFF" />
                            <path d="M249.891 21.7898H251.041V18.3923L250.847 17.6172H251.17C251.441 18.6119 252.281 19.4128 253.754 19.4128C255.627 19.4128 256.802 17.966 256.802 16.0799C256.802 14.168 255.627 12.7341 253.754 12.7341C252.281 12.7341 251.441 13.5221 251.17 14.5168H250.847L251.041 13.7546V12.8632H249.891V21.7898ZM251.041 16.0799C251.041 14.4134 252.087 13.7158 253.392 13.7158C254.71 13.7158 255.665 14.4393 255.665 16.0799C255.665 17.7076 254.71 18.431 253.392 18.431C252.087 18.431 251.041 17.7334 251.041 16.0799Z" fill="#FFFFFFF" />
                            <path d="M257.698 13.7546V19.2836H258.847V13.845H262.232V12.8632H258.602C257.995 12.8632 257.698 13.1474 257.698 13.7546Z" fill="#FFFFFFF" />
                            <path d="M262.016 16.067C262.016 17.9789 263.372 19.4128 265.426 19.4128C267.467 19.4128 268.824 17.9789 268.824 16.067C268.824 14.168 267.467 12.7341 265.426 12.7341C263.372 12.7341 262.016 14.168 262.016 16.067ZM263.153 16.067C263.153 14.7751 263.954 13.7158 265.426 13.7158C266.886 13.7158 267.687 14.7751 267.687 16.067C267.687 17.3717 266.886 18.431 265.426 18.431C263.954 18.431 263.153 17.3717 263.153 16.067Z" fill="#FFFFFFF" />
                        </svg>
                    </a>
                </div>
                <div className="footer__right">
                    <Link to="/TermsAndConditions" className="footer__right__text first alternitive">{t("footer.terms")}</Link>
                    {/* <Link to="/TermsAndConditions" className="footer__right__text second alternitive">{t("footer.policy")}</Link> */}
                </div>
            </footer>
        )
    }
}