import Router from "./Router";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./Store";

function App() {
  useEffect(() => {
    window.addEventListener('popstate', bodyLockChecker)
    return () => {
      window.removeEventListener('popstate', bodyLockChecker)
    }
  }, []);

  const bodyLockChecker = () => {
    if(document.body.classList[0] === "active-modal"){
      document.body.style.paddingRight = '0px';
      document.body.classList.remove('active-modal');
    }  
  }

  // function reloadPageOnRenderError() {
  //   window.location.reload();
  // }
  
  // window.addEventListener('error', reloadPageOnRenderError);

  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

export default App;
