export const ADD_SERVICE_CATION_ACTION = "SERVICES::ADD_SERVICE_CATION_ACTION";
export const DELETE_SERVICE_CATION_ACTION = "SERVICES::DELETE_SERVICE_CATION_ACTION";
export const USING_SUB_SERVICE_CATION_ACTION = "SERVICES::USING_SUB_SERVICE_CATION_ACTION";
export const RETURN_SUB_SERVICE_CATION_ACTION = "SERVICES::RETURN_SUB_SERVICE_CATION_ACTION";
export const DELETED_CATEGORY_CATION_ACTION = "SERVICES::DELETED_CATEGORY_CATION_ACTION";
export const EDIT_SERVICE_CATION_ACTION = "SERVICES::EDIT_SERVICE_CATION_ACTION";
export const SET_SERVICES_CATION_ACTION = "SERVICES::SET_SERVICES_CATION_ACTION";
export const ADD_SERVICE_IMG_CATION_ACTION = "SERVICES::ADD_SERVICE_IMG_CATION_ACTION";



