import React, { useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { ROUTS } from "./Constants/Routes";
import { Layout } from "./Component/Layout";
import { Profile } from "./Screens/Profile";
import { NotFound } from "./Screens/404";
import { Subscribes } from "./Component/Profile/Subscribes";
import { Services } from "./Component/Profile/Services";
import { Service } from "./Component/Dashboard/Service";
import { Buy } from "./Component/Profile/Buy";
import { BookingPayment } from "./Screens/BookingPayment";
import { BookingEdit } from "./Screens/BookingEdit";
import { FAQ } from "./Screens/FAQ";
import { About } from "./Screens/About";
import { Contact } from "./Screens/Contact";
import { MainPage } from "./Screens/MainPage";
import { Queries } from "./Component/Dashboard/Queries";
import { QueriesProfile } from "./Component/Profile/Queries";
import { Terms } from "./Screens/Terms";
import { Dashboard } from "./Screens/DashboardPage";
import { Bookings } from "./Component/Dashboard/Bookings";
import { Users } from "./Component/Dashboard/Users";
import { Plans } from "./Component/Dashboard/Plans";
import { Groups } from "./Component/Dashboard/Groups";

const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
}

const Router = () => {
    return (
        // basename="https://lawchill.heavens.pro"
        <BrowserRouter>  
            <Wrapper>
                <Routes>
                    <Route path={ROUTS.HOME} element={<Layout />} >
                        <Route path={ROUTS.HOME + ROUTS.MAINPAGE} element={<MainPage />} />
                        <Route path={ROUTS.HOME + ROUTS.MAINPAGE + ROUTS.BUY + "/:id"} element={<Buy />} />
                        <Route path={ROUTS.HOME} element={<Navigate replace to={ROUTS.MAINPAGE} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE} element={<Navigate replace to={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SUBSCRIBE} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.QUERIES} element={<Profile page={<QueriesProfile />} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SUBSCRIBE} element={<Profile page={<Subscribes />} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.BUY} element={<Navigate replace to={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SUBSCRIBE} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.BUY + "/:id"} element={<Profile page={<Buy />} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES} element={<Profile page={<Services />} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES + ROUTS.BOOK} element={<Navigate replace to={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES + ROUTS.BOOK + "/:id"} element={<BookingPayment />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES + ROUTS.BOOKPAYMENT} element={<Navigate replace to={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES + ROUTS.BOOKPAYMENT + "/:id"} element={<BookingPayment />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SUBSCRIBE + ROUTS.BOOK} element={<Navigate replace to={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SUBSCRIBE} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SUBSCRIBE + ROUTS.BOOK + "/:id"} element={<BookingPayment />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SUBSCRIBE + ROUTS.BOOKPAYMENT} element={<Navigate replace to={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SUBSCRIBE} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SUBSCRIBE + ROUTS.BOOKPAYMENT + "/:id"} element={<BookingPayment />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES + ROUTS.BOOKEDIT} element={<Navigate replace to={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES} />} />
                        <Route path={ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES + ROUTS.BOOKEDIT + "/:id"} element={<BookingEdit />} />
                        <Route path={ROUTS.HOME + ROUTS.FAQ} element={<FAQ />} />
                        <Route path={ROUTS.HOME + ROUTS.ABOUT} element={<About />} />
                        <Route path={ROUTS.HOME + ROUTS.CONTACTS + "/questions"} element={<Contact />} />
                        <Route path={ROUTS.HOME + ROUTS.CONTACTS + "/info"} element={<Contact />} />
                        <Route path={ROUTS.HOME + ROUTS.CONTACTS} element={<Navigate replace to={ROUTS.HOME + ROUTS.CONTACTS + "/questions"} />} />
                        <Route path={ROUTS.HOME + ROUTS.CONTACTS + "/questions" + ROUTS.BUY + "/:id"} element={<Buy />} />
                        <Route path={ROUTS.HOME + ROUTS.TERMS} element={<Terms />} />
                        <Route path={ROUTS.HOME + ROUTS.DASHBOARD} element={<Navigate replace to={ROUTS.HOME + ROUTS.DASHBOARD + ROUTS.DASHBOARDQUERIES} />} />
                        <Route path={ROUTS.HOME + ROUTS.DASHBOARD + ROUTS.DASHBOARDQUERIES} element={<Dashboard page={<Queries />} />} />
                        <Route path={ROUTS.HOME + ROUTS.DASHBOARD + ROUTS.DASHBOARDBOOKING} element={<Dashboard page={<Bookings />} />} />
                        <Route path={ROUTS.HOME + ROUTS.DASHBOARD + ROUTS.DASHBOARDUSERS} element={<Dashboard page={<Users />} />} />
                        <Route path={ROUTS.HOME + ROUTS.DASHBOARD + ROUTS.DASHBOARDPLANS} element={<Dashboard page={<Plans />} />} />
                        <Route path={ROUTS.HOME + ROUTS.DASHBOARD + ROUTS.DASHBOARDSERVICES} element={<Dashboard page={<Service />} />} />
                        <Route path={ROUTS.HOME + ROUTS.DASHBOARD + ROUTS.DASHBOARDGROUPS} element={<Dashboard page={<Groups />} />} />
                        <Route path={ROUTS.HOME + ROUTS.NOTFOUND} element={<NotFound />} />
                        <Route path="*" element={<Navigate replace to={ROUTS.NOTFOUND} />} />
                    </Route>
                </Routes>
            </Wrapper>
        </BrowserRouter>
    );
}

export default Router;