import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import axios from "axios";

export const PopupDelete = ({ page, isOpen, handler, id, action }) => {
    const { t, i18n } = useTranslation();

    const handleDeleteCategory = () => {
        if (page == 'groups') {
            axios({
                method: 'post',
                url: 'https://api.lawchill.ge/deleteCategory',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                },
                data: {
                    "id": id
                },
                withCredentials: true,
            }).then((res) => {
                console.log(res)
            }).catch((err) => {
                console.log(err)
            });
        }
        if (page == 'service') {
            axios({
                method: 'post',
                url: 'https://api.lawchill.ge/deleteService',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                },
                data: {
                    "id": id
                },
                withCredentials: true,
            }).then((res) => {
                console.log(res)
            }).catch((err) => {
                console.log(err)
            });
        }
        action(id);
        handler();
    }

    return (
        <div className={"dashboard__popup modal" + (isOpen ? ' show' : '')}>
            <div onClick={handler} className="overlay"></div>
            <div className="dashboard__popup__content modal-content">
                <div className="dashboard__popup__content__wrapper">
                    <div className="dashboard__popup__content__header">
                        <svg className="dashboard__popup__content__header__img" width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="60.5" cy="60" r="59" stroke="#0B0A0A" stroke-width="2" />
                            <path d="M79.1673 45.0935L75.4073 41.3335L60.5007 56.2402L45.594 41.3335L41.834 45.0935L56.7407 60.0002L41.834 74.9068L45.594 78.6668L60.5007 63.7602L75.4073 78.6668L79.1673 74.9068L64.2607 60.0002L79.1673 45.0935Z" fill="#0B0A0A" />
                        </svg>
                        <p className="dashboard__popup__content__header__text">{t(`dashboard.popup.${page}.delete.title`)}</p>
                    </div>
                    <div className="dashboard__popup__content__info">
                        <p className="dashboard__popup__content__info__text">
                            {t(`dashboard.popup.${page}.delete.text`)}
                        </p>
                    </div>
                    <div className="dashboard__popup__content__buttons">
                        <button onClick={handler} className="dashboard__popup__content__buttons__button keep">
                            <svg className="dashboard__popup__content__buttons__button__img" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20.5" cy="20" r="20" fill="#FDFAFA" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3431 13.4291L30.7071 19.7931C31.0976 20.1836 31.0976 20.8168 30.7071 21.2073L24.3431 27.5713C23.9526 27.9618 23.3195 27.9618 22.9289 27.5713C22.5384 27.1807 22.5384 26.5476 22.9289 26.157L27.5858 21.5002H10V19.5002H27.5858L22.9289 14.8433C22.5384 14.4528 22.5384 13.8196 22.9289 13.4291C23.3195 13.0386 23.9526 13.0386 24.3431 13.4291Z" fill="#E75549" />
                            </svg>
                            <p className="dashboard__popup__content__buttons__button__text">{t('dashboard.popup.keep')}</p>
                        </button>
                        <button onClick={handleDeleteCategory} className="dashboard__popup__content__buttons__button delete">
                            <svg className="dashboard__popup__content__buttons__button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="#FDFAFA" />
                                <path d="M12.9285 12.9289L27.0706 27.0711M27.0706 12.9289L12.9285 27.0711" stroke="#0B0A0A" stroke-width="2" />
                            </svg>
                            <p className="dashboard__popup__content__buttons__button__text">{t('dashboard.popup.delete')}</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}