import { useState } from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { userQuestionsSelector } from "../../Store/Questions/selectors";
import { addQuestionCationAction } from "../../Store/Questions/actions";
import axios from "axios";

export const QueriesProfile = () => {
    const { t, i18n } = useTranslation();

    const [questionText, setQuestionText] = useState('');

    const questions = useSelector(userQuestionsSelector);
    const dispatch = useDispatch();

    const handleSendQuestion = () => {
        if (questionText.length > 0) {

            const date = new Date();

            axios({
                method: 'post',
                url: 'https://api.lawchill.ge/createQuestion',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                },
                data: {
                    "message": questionText
                },
                withCredentials: true,
            }).then((res) => {
                console.log(res.data)
                const data = {
                    id: Math.random() * 10000,
                    message: questionText,
                    status: 0,
                    date: date.getTime()
                }
                dispatch(addQuestionCationAction(data));
            }).catch((err) => {
                console.log(err)
            });
            setQuestionText('');
        }
    }

    return (
        <section className="queries">
            <div className="queries__ask">
                <textarea value={questionText} onChange={e => setQuestionText(e.target.value)} placeholder={t("queries.queriesplaceholder")} className="queries__ask__textarea"></textarea>
                <button onClick={handleSendQuestion} className="queries__ask__submit">{t("queries.button")}</button>
            </div>
            <div className="queries__list">
                <h2 className="queries__list__title">{t("queries.myQueries")}</h2>
                {questions.length != 0 ? (
                    <div className="queries__list__box">
                        {questions.map((el) => {
                            let date = new Date(el.date * 1).toLocaleDateString('ru-RU', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric'
                            });
                            return (
                                <div className="queries__list__box__el" key={el.id}>
                                    <div className="queries__list__box__el__wrapper">
                                        <p className="queries__list__box__el__text">{el.message}</p>
                                        <div className="queries__list__box__el__info">
                                            <div className="queries__list__box__el__info__date">{date}</div>
                                            {el.status === 2 ? (<div className="queries__list__box__el__info__status done">{t("queries.statusDone")}</div>) : (<div className="queries__list__box__el__info__status inprogress">{t("queries.statusInProgress")}</div>)}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                ) : (<p className="queries__list__text">{t("queries.noQueries")}</p>)
                }
            </div>
        </section>
    )
}