import { useTranslation } from "react-i18next";
import { currentLanguageSelector } from "../../Store/Language/selectors";
import { useDispatch, useSelector } from "react-redux";
import { categoryInfoSelector } from "../../Store/Categories/selectors";
import { deleteCategoryCationAction } from "../../Store/Categories/actions";
import { PopupDelete } from "./Popup/PopupDelete";
import { useState } from "react";
import { PopupCreate } from "./Popup/PopupCreate";
import { PopupDone } from "./Popup/PopupDone";
import { deletedCategoryCationAction } from "../../Store/Services/actions";

export const Groups = () => {
    const { t, i18n } = useTranslation();

    const currentLanguage = useSelector(currentLanguageSelector);
    const categories = useSelector(categoryInfoSelector);

    const [deleteModal, setDeleteModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [doneModal, setDoneModal] = useState(false);
    const [actionCreate, setActionCreate] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState();

    const dispatch = useDispatch();

    const handleDeleteCategoryEverywhere = (id) => {
        dispatch(deleteCategoryCationAction({ id: id }));
        dispatch(deletedCategoryCationAction({ id: id }));
    }

    const [, ...groups] = categories;

    const toggleModal = (modal, setModal) => {
        if (modal) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal');
        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal')
        }
        setModal(prev => !prev);
    };

    const handleOpenDeleteModal = () => {
        toggleModal(deleteModal, setDeleteModal);
    }

    const openCreateModal = () => {
        toggleModal(createModal, setCreateModal);
    }

    const openDoneModal = () => {
        toggleModal(doneModal, setDoneModal);
    }

    const handleOpenCreateModal = (e, id, type) => {
        if (!e.target.closest('.delete')) {
            setActionCreate(type);
            setSelectedGroupId(id);
            openCreateModal();
        }

    }

    const handleDeleteCategory = (id) => {
        setSelectedGroupId(id);
        handleOpenDeleteModal();
    }

    const [searchFilter, setSearchFilter] = useState('');

    return (
        <div className="groups">
            <div className="dashboard__filter">
                <div className="dashboard__filter__search-box">
                    <input value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} type="text" className="dashboard__filter__search-box__search" placeholder={t("dashboard.searchGroups")} />
                    <div className="dashboard__filter__search-box__img" />
                </div>
            </div>
            <div className="groups__table desktop-dashboard">
                <div className="groups__table__title">
                    <div className="groups__table__title__el dashboard__title">{t("dashboard.group")}</div>
                    <div className="groups__table__title__el dashboard__title"></div>
                </div>
                {groups.filter(item => item[currentLanguage].toLowerCase().includes(searchFilter.toLowerCase())).map((item) => {
                    return (
                        <div onClick={(e) => handleOpenCreateModal(e, item.id, 'edit')} key={item.id} className="groups__table__row">
                            <div className="groups__table__el dashboard__text">{item[currentLanguage]}</div>
                            <svg onClick={() => handleDeleteCategory(item.id)} className="groups__table__el__img delete" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10.5" r="10" fill="#BCB7B3" />
                                <path d="M5.75827 6.25736L14.2435 14.7426M14.2435 6.25736L5.75827 14.7426" stroke="#FDFAFA" stroke-width="2" />
                            </svg>
                        </div>
                    )
                })}
            </div>

            <div className="dashboard__table mobile">
                {groups.filter(item => item[currentLanguage].toLowerCase().includes(searchFilter.toLowerCase())).map((item) => {
                    return (
                        <div onClick={(e) => handleOpenCreateModal(e, item.id, 'edit')} key={item.id} className="dashboard__table__mobile-row">
                            <div className="dashboard__table__mobile-row__title">
                                <div className="dashboard__table__mobile-row__title__left">
                                    <div className="dashboard__table__mobile-row__title__left__text">
                                        <span className="dashboard__table__mobile-row__title__left__text__title">{item[currentLanguage]}</span>
                                    </div>
                                </div>
                                <div className="dashboard__table__mobile-row__title__right">
                                    <svg onClick={() => handleDeleteCategory(item.id)} className="groups__table__el__img delete" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10.5" r="10" fill="#BCB7B3" />
                                        <path d="M5.75827 6.25736L14.2435 14.7426M14.2435 6.25736L5.75827 14.7426" stroke="#FDFAFA" stroke-width="2" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <button onClick={(e) => handleOpenCreateModal(e, Math.random() * 1000, 'add')} className="dashboard__add-button">{t("dashboard.addGroup")}</button>
            <PopupDelete page={'groups'} isOpen={deleteModal} handler={handleOpenDeleteModal} id={selectedGroupId} action={handleDeleteCategoryEverywhere} />
            <PopupCreate page={'groups'} setSelectedGroupId={setSelectedGroupId} isOpen={createModal} openDoneModal={openDoneModal} handler={openCreateModal} id={selectedGroupId} action={actionCreate} handlerOpenDelete={handleOpenDeleteModal} />
            <PopupDone page={'groups'} isOpen={doneModal} openEditModal={openCreateModal} handler={openDoneModal} id={selectedGroupId} action={actionCreate} />
        </div>
    )
}