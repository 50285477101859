import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTS } from "../../Constants/Routes";
import { useDispatch, useSelector } from "react-redux";
import IMAGES from "./../../images/profile/images";
import { servicesInfoSelector } from "../../Store/Services/selectors";
import { currentLanguageSelector } from "../../Store/Language/selectors";
import { myServicesSelector } from "../../Store/MyServices/selectors";
import { addMyServiceCationAction } from "../../Store/MyServices/actions";
import { userInfoSelector } from "../../Store/User/selectors";
import { usingSubServiceCationAction } from "../../Store/Services/actions";
import axios from "axios";
import { useEffect } from "react";

export const ServicesModal = ({ props, modalBuy, serviceId }) => {
    const { t, i18n } = useTranslation();

    const services = useSelector(servicesInfoSelector);

    const service = services.filter(item => item.id == serviceId)[0];
    const myService = useSelector(myServicesSelector).filter(item => item.id == serviceId)[0];
    const currentLanguage = useSelector(currentLanguageSelector);
    const user = useSelector(userInfoSelector);

    const image = myService ? (services.filter(item => item.id == myService.service)[0] ? `https://lawchill.ge/imagesFromLawchill/${services.filter(item => item.id == myService.service)[0].image}` : null) : null;

    const dispatch = useDispatch();

    const Navigate = useNavigate();

    const handleCloseModal = () => {
        document.body.style.paddingRight = '0px';
        document.body.classList.remove('active-modal');
        props.handleClickFunc(false);
    }

    const handleChangeBooking = () => {
        document.body.style.paddingRight = '0px';
        document.body.classList.remove('active-modal');
        props.handleClickFunc(false);
        Navigate(ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES + ROUTS.BOOKEDIT + `/${serviceId}`);
    }

    const handleGoBuy = () => {
        document.body.style.paddingRight = '0px';
        document.body.classList.remove('active-modal');
        props.handleClickFunc(false);
        Navigate(ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES + ROUTS.BOOK + `/${serviceId}`);
    }

    const handleBook = () => {
        const timestamp = new Date().getTime();

        const services = {
            "id": Math.random() * 1000,
            "status": 0,
            "user": user.user.id,
            "expert": null,
            "expertname": null,
            "service": service.id,
            "servicename": {
                ...service.name
            },
            "servicedata": {
                "price": 0,
                "duration": service.duration,
                "location": {
                    ...service.location
                },
                "subscriptions": {
                    ...service.subscriptions
                },
                "description": {
                    ...service.description
                }
            },
            "createdate": timestamp,
            "completedate": null
        };

        axios({
            method: 'post',
            url: 'https://api.lawchill.ge/createRequest',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            data: {
                "sid": service.id
            },
            withCredentials: true,
        }).then((res) => {
            dispatch(addMyServiceCationAction({ service: services }));
            dispatch(usingSubServiceCationAction({ id: serviceId }));
        }).catch((err) => {
            console.log(err)
        });

        document.body.style.paddingRight = '0px';
        document.body.classList.remove('active-modal');
        props.handleClickFunc(false);
    }

    if (props.type === 'unpaid') {
        return (
            <div className={"bookingPayment__modal modal" + (modalBuy ? ' show' : '')}>
                <div onClick={handleCloseModal} className="overlay"></div>
                <div className="subscribeServicesList__modal modal-content">
                    <img src={service.image ? `https://lawchill.ge/imagesFromLawchill/${service.image}` : IMAGES.modalImg} alt="check" className="subscribeServicesList__modal__img" />
                    <div className="subscribeServicesList__modal__info start">
                        <p className="subscribeServicesList__modal__info__title">
                            {service.name[currentLanguage]}
                        </p>
                        <div className="subscribeServicesList__modal__info__list start">
                            <div className="subscribeServicesList__modal__info__list__el">
                                <svg className="subscribeServicesList__modal__info__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.667 3H6.33366C5.41699 3 4.66699 3.75 4.66699 4.66667V18L10.5003 15.5L16.3337 18V4.66667C16.3337 3.75 15.5837 3 14.667 3ZM14.667 15.5L10.5003 13.6833L6.33366 15.5V4.66667H14.667V15.5Z" fill="#BCB7B3" />
                                </svg>

                                <p className="subscribeServicesList__modal__info__list__el__text">{service.description[currentLanguage]}</p>
                            </div>
                            <div className="subscribeServicesList__modal__info__list__el">
                                <svg className="subscribeServicesList__modal__info__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.5003 2.1665C7.27533 2.1665 4.66699 4.77484 4.66699 7.99984C4.66699 12.3748 10.5003 18.8332 10.5003 18.8332C10.5003 18.8332 16.3337 12.3748 16.3337 7.99984C16.3337 4.77484 13.7253 2.1665 10.5003 2.1665ZM6.33366 7.99984C6.33366 5.69984 8.20033 3.83317 10.5003 3.83317C12.8003 3.83317 14.667 5.69984 14.667 7.99984C14.667 10.3998 12.267 13.9915 10.5003 16.2332C8.76699 14.0082 6.33366 10.3748 6.33366 7.99984Z" fill="#BCB7B3" />
                                    <path d="M10.5003 10.0832C11.6509 10.0832 12.5837 9.15043 12.5837 7.99984C12.5837 6.84924 11.6509 5.9165 10.5003 5.9165C9.34973 5.9165 8.41699 6.84924 8.41699 7.99984C8.41699 9.15043 9.34973 10.0832 10.5003 10.0832Z" fill="#BCB7B3" />
                                </svg>

                                <p className="subscribeServicesList__modal__info__list__el__text">{service.duration % 60 == 0 && service.duration / 60 == 1 ? `1 ${t("hour")}` : (service.duration % 60 == 0 ? `${service.duration / 60} ${t("hours")}` : `${service.duration} ${t("minutes")}`)}</p>
                            </div>
                            {service.available > 0 ?
                                (<div className="subscribeServicesList__modal__info__list__el">
                                    <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.49967 3.61035C3.81634 3.61035 0.833008 6.59368 0.833008 10.277C0.833008 13.9604 3.81634 16.9437 7.49967 16.9437C11.183 16.9437 14.1663 13.9604 14.1663 10.277C14.1663 6.59368 11.183 3.61035 7.49967 3.61035ZM7.49967 15.277C4.74134 15.277 2.49967 13.0354 2.49967 10.277C2.49967 7.51869 4.74134 5.27702 7.49967 5.27702C10.258 5.27702 12.4997 7.51869 12.4997 10.277C12.4997 13.0354 10.258 15.277 7.49967 15.277ZM14.1663 3.82702V5.56868C16.108 6.25202 17.4997 8.10202 17.4997 10.277C17.4997 12.452 16.108 14.302 14.1663 14.9854V16.727C17.0413 15.9854 19.1663 13.3854 19.1663 10.277C19.1663 7.16868 17.0413 4.56869 14.1663 3.82702Z" fill="#BCB7B3" />
                                    </svg>

                                    <p className="subscribeServicesList__modal__info__list__el__text">{service.available}{t("servicesList.inSub")}</p>
                                </div>)
                                : (<div className="subscribeServicesList__modal__info__list__el">
                                    <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0003 1.66699C5.40033 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.40033 18.3337 10.0003 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 10.0003 1.66699ZM10.0003 16.667C6.32533 16.667 3.33366 13.6753 3.33366 10.0003C3.33366 6.32533 6.32533 3.33366 10.0003 3.33366C13.6753 3.33366 16.667 6.32533 16.667 10.0003C16.667 13.6753 13.6753 16.667 10.0003 16.667ZM10.2587 9.28366C8.78366 8.90866 8.30866 8.50033 8.30866 7.89199C8.30866 7.19199 8.96699 6.70033 10.0587 6.70033C11.2087 6.70033 11.642 7.25033 11.6753 8.06699H13.1003C13.0587 6.95033 12.3753 5.92533 11.0253 5.59199V4.16699H9.08366V5.57533C7.82533 5.84199 6.81699 6.65866 6.81699 7.91699C6.81699 9.40866 8.05866 10.1587 9.86699 10.592C11.492 10.9753 11.817 11.5503 11.817 12.1503C11.817 12.592 11.492 13.3087 10.067 13.3087C8.73366 13.3087 8.20866 12.7087 8.13366 11.942H6.70033C6.78366 13.3587 7.83366 14.1587 9.08366 14.417V15.8337H11.0337V14.442C12.3003 14.2003 13.3003 13.4753 13.3087 12.1337C13.3003 10.3003 11.7253 9.66699 10.2587 9.28366Z" fill="#BCB7B3" />
                                    </svg>
                                    <p className="subscribeServicesList__modal__info__list__el__text">₾{service.price}</p>
                                </div>)}
                            <div className="subscribeServicesList__modal__info__list__el">
                                <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0003 1.66699C6.77533 1.66699 4.16699 4.27533 4.16699 7.50033C4.16699 11.8753 10.0003 18.3337 10.0003 18.3337C10.0003 18.3337 15.8337 11.8753 15.8337 7.50033C15.8337 4.27533 13.2253 1.66699 10.0003 1.66699ZM5.83366 7.50033C5.83366 5.20033 7.70033 3.33366 10.0003 3.33366C12.3003 3.33366 14.167 5.20033 14.167 7.50033C14.167 9.90033 11.767 13.492 10.0003 15.7337C8.26699 13.5087 5.83366 9.87533 5.83366 7.50033Z" fill="#BCB7B3" />
                                    <path d="M10.0003 9.58366C11.1509 9.58366 12.0837 8.65092 12.0837 7.50033C12.0837 6.34973 11.1509 5.41699 10.0003 5.41699C8.84973 5.41699 7.91699 6.34973 7.91699 7.50033C7.91699 8.65092 8.84973 9.58366 10.0003 9.58366Z" fill="#BCB7B3" />
                                </svg>
                                <p className="subscribeServicesList__modal__info__list__el__text">{service.location[currentLanguage]}</p>
                            </div>
                        </div>
                    </div>
                    <button onClick={service.available > 0 ? handleBook : handleGoBuy} className="subscribeServicesList__modal__button">{t("services.modal.button")}</button>
                </div>
            </div>
        )
    } else if (props.type === 'paid') {
        return (
            <div className={"bookingPayment__modal modal" + (modalBuy ? ' show' : '')}>
                <div onClick={handleCloseModal} className="overlay"></div>
                <div className="subscribeServicesList__modal modal-content">
                    <img src={image ? image : IMAGES.modalImg} alt="check" className="subscribeServicesList__modal__img" />
                    <div className="subscribeServicesList__modal__info start">
                        <p className="subscribeServicesList__modal__info__title">
                            {myService.servicename[currentLanguage]}
                        </p>
                        <div className="subscribeServicesList__modal__info__list start">
                            {myService.servicedata.description[currentLanguage] && (<div className="subscribeServicesList__modal__info__list__el">
                                <svg className="subscribeServicesList__modal__info__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.667 3H6.33366C5.41699 3 4.66699 3.75 4.66699 4.66667V18L10.5003 15.5L16.3337 18V4.66667C16.3337 3.75 15.5837 3 14.667 3ZM14.667 15.5L10.5003 13.6833L6.33366 15.5V4.66667H14.667V15.5Z" fill="#BCB7B3" />
                                </svg>
                                <p className="subscribeServicesList__modal__info__list__el__text">{myService.servicedata.description[currentLanguage]}</p>
                            </div>)}
                            <div className="subscribeServicesList__modal__info__list__el">
                                <svg className="subscribeServicesList__modal__info__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.5003 2.1665C7.27533 2.1665 4.66699 4.77484 4.66699 7.99984C4.66699 12.3748 10.5003 18.8332 10.5003 18.8332C10.5003 18.8332 16.3337 12.3748 16.3337 7.99984C16.3337 4.77484 13.7253 2.1665 10.5003 2.1665ZM6.33366 7.99984C6.33366 5.69984 8.20033 3.83317 10.5003 3.83317C12.8003 3.83317 14.667 5.69984 14.667 7.99984C14.667 10.3998 12.267 13.9915 10.5003 16.2332C8.76699 14.0082 6.33366 10.3748 6.33366 7.99984Z" fill="#BCB7B3" />
                                    <path d="M10.5003 10.0832C11.6509 10.0832 12.5837 9.15043 12.5837 7.99984C12.5837 6.84924 11.6509 5.9165 10.5003 5.9165C9.34973 5.9165 8.41699 6.84924 8.41699 7.99984C8.41699 9.15043 9.34973 10.0832 10.5003 10.0832Z" fill="#BCB7B3" />
                                </svg>
                                <p className="subscribeServicesList__modal__info__list__el__text">31.10.2022 15:00 - 16:00</p>
                            </div>
                            <div className="subscribeServicesList__modal__info__list__el">
                                <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0003 1.66699C5.40033 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.40033 18.3337 10.0003 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 10.0003 1.66699ZM10.0003 16.667C6.32533 16.667 3.33366 13.6753 3.33366 10.0003C3.33366 6.32533 6.32533 3.33366 10.0003 3.33366C13.6753 3.33366 16.667 6.32533 16.667 10.0003C16.667 13.6753 13.6753 16.667 10.0003 16.667ZM10.2587 9.28366C8.78366 8.90866 8.30866 8.50033 8.30866 7.89199C8.30866 7.19199 8.96699 6.70033 10.0587 6.70033C11.2087 6.70033 11.642 7.25033 11.6753 8.06699H13.1003C13.0587 6.95033 12.3753 5.92533 11.0253 5.59199V4.16699H9.08366V5.57533C7.82533 5.84199 6.81699 6.65866 6.81699 7.91699C6.81699 9.40866 8.05866 10.1587 9.86699 10.592C11.492 10.9753 11.817 11.5503 11.817 12.1503C11.817 12.592 11.492 13.3087 10.067 13.3087C8.73366 13.3087 8.20866 12.7087 8.13366 11.942H6.70033C6.78366 13.3587 7.83366 14.1587 9.08366 14.417V15.8337H11.0337V14.442C12.3003 14.2003 13.3003 13.4753 13.3087 12.1337C13.3003 10.3003 11.7253 9.66699 10.2587 9.28366Z" fill="#BCB7B3" />
                                </svg>
                                <p className="subscribeServicesList__modal__info__list__el__text">₾{myService.servicedata.price}</p>
                            </div>
                            <div className="subscribeServicesList__modal__info__list__el">
                                <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0003 1.66699C6.77533 1.66699 4.16699 4.27533 4.16699 7.50033C4.16699 11.8753 10.0003 18.3337 10.0003 18.3337C10.0003 18.3337 15.8337 11.8753 15.8337 7.50033C15.8337 4.27533 13.2253 1.66699 10.0003 1.66699ZM5.83366 7.50033C5.83366 5.20033 7.70033 3.33366 10.0003 3.33366C12.3003 3.33366 14.167 5.20033 14.167 7.50033C14.167 9.90033 11.767 13.492 10.0003 15.7337C8.26699 13.5087 5.83366 9.87533 5.83366 7.50033Z" fill="#BCB7B3" />
                                    <path d="M10.0003 9.58366C11.1509 9.58366 12.0837 8.65092 12.0837 7.50033C12.0837 6.34973 11.1509 5.41699 10.0003 5.41699C8.84973 5.41699 7.91699 6.34973 7.91699 7.50033C7.91699 8.65092 8.84973 9.58366 10.0003 9.58366Z" fill="#BCB7B3" />
                                </svg>

                                <p className="subscribeServicesList__modal__info__list__el__text">{myService.servicedata.location[currentLanguage]}</p>
                            </div>
                            {myService.expertname && (myService.expertname[currentLanguage] && (<div className="subscribeServicesList__modal__info__list__el">
                                <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.7894 10.0336C9.71964 10.0336 8.84754 9.69644 8.17312 9.02202C7.49871 8.3476 7.1615 7.47551 7.1615 6.40574C7.1615 5.33597 7.49871 4.46388 8.17312 3.78946C8.84754 3.11504 9.71964 2.77783 10.7894 2.77783C11.8592 2.77783 12.7313 3.11504 13.4057 3.78946C14.0801 4.46388 14.4173 5.33597 14.4173 6.40574C14.4173 7.47551 14.0801 8.3476 13.4057 9.02202C12.7313 9.69644 11.8592 10.0336 10.7894 10.0336ZM3.20801 17.7778V15.4523C3.20801 14.8321 3.36305 14.3011 3.67312 13.8592C3.9832 13.4174 4.37855 13.0802 4.85917 12.8476C5.91343 12.3825 6.92119 12.0336 7.88243 11.8011C8.84367 11.5685 9.81266 11.4523 10.7894 11.4523C11.7661 11.4523 12.7313 11.5724 13.6848 11.8127C14.6382 12.053 15.6421 12.4057 16.6964 12.8709C17.1925 13.0879 17.5956 13.4174 17.9057 13.8592C18.2158 14.3011 18.3708 14.8321 18.3708 15.4523V17.7778H3.20801ZM4.7894 16.1964H16.7894V15.4755C16.7894 15.2274 16.7158 14.991 16.5685 14.7662C16.4212 14.5414 16.239 14.3747 16.022 14.2662C15.0452 13.8011 14.1499 13.4794 13.3359 13.3011C12.522 13.1228 11.6731 13.0336 10.7894 13.0336C9.90568 13.0336 9.04909 13.1228 8.21964 13.3011C7.39018 13.4794 6.49483 13.8011 5.53359 14.2662C5.31653 14.3747 5.13824 14.5414 4.99871 14.7662C4.85917 14.991 4.7894 15.2274 4.7894 15.4755V16.1964ZM10.7894 8.45225C11.3786 8.45225 11.8669 8.25845 12.2545 7.87086C12.6421 7.48326 12.8359 6.99489 12.8359 6.40574C12.8359 5.81659 12.6421 5.32822 12.2545 4.94062C11.8669 4.55303 11.3786 4.35923 10.7894 4.35923C10.2003 4.35923 9.71188 4.55303 9.32429 4.94062C8.93669 5.32822 8.74289 5.81659 8.74289 6.40574C8.74289 6.99489 8.93669 7.48326 9.32429 7.87086C9.71188 8.25845 10.2003 8.45225 10.7894 8.45225Z" fill="#BCB7B3" />
                                </svg>

                                <p className="subscribeServicesList__modal__info__list__el__text">{myService.expertname[currentLanguage]}</p>
                            </div>))}
                            <div className="subscribeServicesList__modal__info__list__el">
                                <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1185_29768)">
                                        <path d="M10.2727 4.82329V7.55056L13.9091 3.9142L10.2727 0.277832V3.0051C6.25455 3.0051 3 6.25965 3 10.2778C3 11.7051 3.41818 13.0324 4.12727 14.1506L5.45455 12.8233C5.04545 12.0687 4.81818 11.196 4.81818 10.2778C4.81818 7.26874 7.26364 4.82329 10.2727 4.82329ZM16.4182 6.4051L15.0909 7.73238C15.4909 8.49601 15.7273 9.35965 15.7273 10.2778C15.7273 13.2869 13.2818 15.7324 10.2727 15.7324V13.0051L6.63636 16.6415L10.2727 20.2778V17.5506C14.2909 17.5506 17.5455 14.296 17.5455 10.2778C17.5455 8.85056 17.1273 7.52329 16.4182 6.4051Z" fill="#BCB7B3" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1185_29768">
                                            <rect width="20" height="20" fill="white" transform="translate(0 0.277832)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <p className={"subscribeServicesList__modal__info__list__el__text" + ((myService.status == 5 || myService.status == 6) ? " paid" : "")}>{(myService.status == 5 || myService.status == 6) ? t("services.statuspaid") : t("services.statuspast")}</p>
                            </div>
                        </div>
                    </div>
                    <button onClick={handleCloseModal} className="subscribes__active__modal__endSub__content__cancelButton">
                        <svg className="subscribes__active__modal__endSub__content__cancelButton__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="20" fill="#FDFAFA" />
                            <path d="M12.929 12.9289L27.0711 27.0711M27.0711 12.9289L12.929 27.0711" stroke="#0B0A0A" stroke-width="2" />
                        </svg>

                        <p className="subscribes__active__modal__endSub__content__cancelButton__text">{t("services.modal.close")}</p>
                    </button>
                    <button onClick={handleChangeBooking} className="subscribes__active__modal__content__cancelButton">
                        <svg className="subscribes__active__modal__content__cancelButton__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="#BCB7B3" />
                            <g clip-path="url(#clip0_816_11992)">
                                <path d="M4 16.0002H6.49961L13.8718 8.62803L11.3722 6.12842L4 13.5006V16.0002ZM5.33313 14.0539L11.3722 8.01479L11.9854 8.62803L5.94637 14.6671H5.33313V14.0539Z" fill="#FDFAFA" />
                                <path d="M14.2456 4.19497C13.9856 3.93501 13.5657 3.93501 13.3057 4.19497L12.0859 5.41478L14.5856 7.9144L15.8054 6.69459C16.0653 6.43463 16.0653 6.01469 15.8054 5.75473L14.2456 4.19497Z" fill="#FDFAFA" />
                            </g>
                            <defs>
                                <clipPath id="clip0_816_11992">
                                    <rect width="12" height="12" fill="white" transform="translate(4 4)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <p className="subscribes__active__modal__content__cancelButton__text">{t("services.modal.change")}</p>
                    </button>
                </div>
            </div>
        )
    }
}
