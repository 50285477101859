const IMAGES = {
    titleFirst: require('./title-first.svg').default,
    titleSecond: require('./title-second.svg').default,
    titleFirstEn: require('./title-first-en.svg').default,
    titleSecondEn: require('./title-second-en.svg').default,
    // arrowDown: require('./arrow-down.svg').default,
    icons: require('./icons.png'),
    iconsMobile: require('./icons-mobile.png'),
    secondImg: require('./first-img.png'),
    firstImg: require('./second-img.png'),
    topImg: require('./top-img.png'),
    // arrowAbout: require('./arrow-button-about.svg').default,
    mobileFirst: require('./mobile-first-img.png'),
    mobileSecond: require('./mobile-second-img.png'),
    mobileThird: require('./mobile-third-img.png'),
}

export default IMAGES;