import {
    ADD_CATEGORY_CATION_ACTION,
    CHANGE_CATEGORY_CATION_ACTION,
    DELETE_CATEGORY_CATION_ACTION,
    SELECT_CATEGORY_CATION_ACTION,
    SET_CATEGORY_CATION_ACTION
} from './constants.js';

const initialState = [
    // {
    //     id: 0,
    //     ru: "Все",
    //     en: "All",
    //     isChosen: true
    // },
    // {
    //     id: 1,
    //     ru: "Юриспруденция",
    //     en: "Law",
    //     isChosen: false
    // },
    // {
    //     id: 2,
    //     ru: "Бухгалтерия",
    //     en: "Accounting",
    //     isChosen: false
    // },
    // {
    //     id: 3,
    //     ru: "Переводы",
    //     en: "Translation",
    //     isChosen: false
    // },
    // {
    //     id: 4,
    //     ru: "Регистрации",
    //     en: "Registarion",
    //     isChosen: false
    // }
];

export const categoriesInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORY_CATION_ACTION: {
            return (
                action.payload.map(item => {
                    return item.id == 1 ? {
                        id: item.id,
                        ...item.name,
                        isChosen: true
                    } : {
                        id: item.id,
                        ...item.name,
                        isChosen: false
                    }
                })
            )
        }
        case ADD_CATEGORY_CATION_ACTION: {
            return ([
                ...state,
                {
                    ...action.payload
                }
            ])
        }
        case CHANGE_CATEGORY_CATION_ACTION: {
            return state.map(item =>
                item.id == action.payload.id ? {
                    ...item,
                    ru: action.payload.ru,
                    en: action.payload.en,
                } : {
                    ...item,
                }
            );
        }
        case DELETE_CATEGORY_CATION_ACTION: {
            return state.filter(item => item.id !== action.payload.id);
        }
        case SELECT_CATEGORY_CATION_ACTION: {
            return state.map(item =>
                item.id == action.payload.id ? {
                    ...item,
                    isChosen: true
                } : {
                    ...item,
                    isChosen: false
                }
            );
        }
        default:
            return state;
    }
}