import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, compose } from "redux";
import { userInfoReducer } from "./User/reducer";
import { userQuestionReducer } from "./Questions/reducer";
import { servicesInfoReducer } from "./Services/reducer";
import { categoriesInfoReducer } from "./Categories/reducer";
import { languageReducer } from "./Language/reducer";
import { myServicesInfoReducer } from "./MyServices/reducer";
import { subscriptionsReducer } from "./Subscriptions/reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    user: userInfoReducer,
    questions: userQuestionReducer,
    services: servicesInfoReducer,
    categories: categoriesInfoReducer,
    language: languageReducer,
    myservices: myServicesInfoReducer,
    subscriptions: subscriptionsReducer,
});

export const store = configureStore({ reducer: rootReducer });
