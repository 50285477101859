import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import IMAGES from "./../../images/profile/images";
import { ROUTS } from "../../Constants/Routes";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionsSelector } from '../../Store/Subscriptions/selectors';
import { currentLanguageSelector } from '../../Store/Language/selectors';
import { userInfoSelector } from '../../Store/User/selectors';
import { changeSubCationAction } from '../../Store/User/actions';
import { addMyServiceCationAction } from '../../Store/MyServices/actions';
import { servicesInfoSelector } from '../../Store/Services/selectors';
import axios from 'axios';

export const Buy = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();

    const subscriptions = useSelector(subscriptionsSelector).filter(item => item.id == id)[0];
    const services = useSelector(servicesInfoSelector).filter(item => item.id == id)[0];
    const currentLanguage = useSelector(currentLanguageSelector);
    const user = useSelector(userInfoSelector);

    const dispatch = useDispatch();

    const [modal, setModal] = useState(false);
    const [firstButtonStyle, setFirstButtonStyle] = useState('buy__container__left__el__buttons__button actived');
    const [secondButtonStyle, setSecondButtonStyle] = useState('buy__container__left__el__buttons__button');
    const [paymentType, setPaymentType] = useState('Payze');

    const [title, setTitle] = useState('Оформление чека');

    const Navigate = useNavigate();
    const local = useLocation();

    useEffect(() => {
        (local.pathname.toLocaleLowerCase().includes('/profile') || local.pathname.toLocaleLowerCase().includes('/main')) ? setTitle('Приобрести подписку') : setTitle('Оформление чека');
    }, [local.pathname]);



    const handleGoBack = () => {
        if (local.pathname.includes('/profile')) {
            Navigate(ROUTS.HOME + ROUTS.PROFILE)
        } else if (local.pathname.includes('/main')) {
            Navigate(ROUTS.HOME + ROUTS.MAINPAGE);
        } else {
            Navigate(ROUTS.HOME + ROUTS.CONTACTS);
        }
    }

    const handleBuySubscription = () => {
        axios({
            method: 'post',
            url: 'https://api.lawchill.ge/purchaseSubscription',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            data: {
                sid: subscriptions.id,
            },
            withCredentials: true,
        }).then((res) => {
            window.location.href = res.data.payment.url;
        }).catch((err) => {
            console.log(err)
        });
    }

    const toggleModal = () => {
        if (modal) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal')
            if (title === 'Оформление чека') Navigate(ROUTS.HOME + ROUTS.CONTACTS);
        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal')
        }
        setModal(prev => !prev);
    };

    const handleGoProfile = () => {
        toggleModal();
        Navigate(ROUTS.HOME + ROUTS.PROFILE);
    }

    const handleGoContacts = () => {
        toggleModal();
        Navigate(ROUTS.HOME + ROUTS.CONTACTS);
    }

    const togglePay = () => {
        const timestamp = new Date().getTime();

        axios({
            method: 'post',
            url: 'https://api.lawchill.ge/createRequest',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            data: {
                "sid": services.id
            },
            withCredentials: true,
        }).then((res) => {
            window.location.href = res.data.payment.url;
        }).catch((err) => {
            console.log(err)
        });
    }

    const handleLogin = () => {
        const loginButton = document.querySelector('#loginButton');
        loginButton.click();
    }

    return (
        <div className={"buy" + (local.pathname.toLocaleLowerCase().includes('/profile') ? '' : ' wrap')}   >
            <div className="buy__wrapper">
                <div className="buy__header">
                    <button onClick={handleGoBack} className="buy__header__back-button">
                        <svg className="buy__header__back-button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="20" fill="#0B0A0A" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1569 26.5714L9.79289 20.2074C9.40237 19.8169 9.40237 19.1837 9.79289 18.7932L16.1569 12.4292C16.5474 12.0387 17.1805 12.0387 17.5711 12.4292C17.9616 12.8198 17.9616 13.4529 17.5711 13.8434L12.9142 18.5003H30.5V20.5003H12.9142L17.5711 25.1572C17.9616 25.5477 17.9616 26.1808 17.5711 26.5714C17.1805 26.9619 16.5474 26.9619 16.1569 26.5714Z" fill="#FDFAFA" />
                        </svg>
                    </button>
                    <h2 className="buy__header__title">{title === 'Оформление чека' ? (t("buy.title")) : (t("buy.subtitle"))}</h2>
                </div>
                <div className="buy__container">
                    <div className="buy__container__left">
                        <div className="buy__container__left__el">
                            <svg className="buy__container__left__el__number" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M47.5 23.7037C47.5 36.5129 36.9846 46.9074 24 46.9074C11.0154 46.9074 0.5 36.5129 0.5 23.7037C0.5 10.8945 11.0154 0.5 24 0.5C36.9846 0.5 47.5 10.8945 47.5 23.7037Z" stroke="#0B0A0A" />
                                <path d="M25.9883 33H23.1875V19.1484L20.9375 20.2852V17.3438L23.4219 15.9609H25.9883V33Z" fill="#0B0A0A" />
                            </svg>
                            <div className="buy__container__left__el__box">
                                <p className="buy__container__left__el__box__title">{t("buy.signin")}</p>
                                <p className="buy__container__left__el__box__text">
                                    {user.status != 'not logged' ? (<>{t("buy.signintext")}<font className="blue">{user.user.email ? user.user.email : user.user.phone}</font></>)
                                        : (<>{t("buy.notlogin")}</>)}
                                </p>
                                {user.status == 'not logged' && (
                                    <button onClick={handleLogin} className="buy__container__left__el__box__button">
                                        <svg className="buy__container__left__el__box__button__img" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="20.5" cy="20" r="20" fill="#FDFAFA" />
                                            <g clip-path="url(#clip0_1067_2072)">
                                                <path d="M14.5 14.4444L12.9444 16L15.8333 18.8889H4.5V21.1111H15.8333L12.9444 24L14.5 25.5556L20.0556 20L14.5 14.4444ZM24.5 27.7778H15.6111V30H24.5C25.7222 30 26.7222 29 26.7222 27.7778V12.2222C26.7222 11 25.7222 10 24.5 10H15.6111V12.2222H24.5V27.7778Z" fill="#E75549" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1067_2072">
                                                    <rect width="20" height="20" fill="white" transform="translate(10.5 10)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p className="buy__container__left__el__box__button__text">{t("buy.notloginbutton")}</p>
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="buy__container__left__el">
                            {user.status != 'not logged' ? (
                                <svg className="buy__container__left__el__number" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="24" cy="24" r="23.5" stroke="#0B0A0A" />
                                    <path d="M19.3828 29.5156L25.2773 21.7109C25.582 21.3125 25.8164 20.9023 25.9805 20.4805C26.1523 20.0508 26.2383 19.6523 26.2383 19.2852V19.2617C26.2383 18.6914 26.0625 18.25 25.7109 17.9375C25.3672 17.625 24.875 17.4688 24.2344 17.4688C23.6172 17.4688 23.1172 17.6602 22.7344 18.043C22.3594 18.418 22.1328 18.9531 22.0547 19.6484V19.6602H19.1719V19.6484C19.2812 18.625 19.5547 17.75 19.9922 17.0234C20.4297 16.2969 21.0078 15.7422 21.7266 15.3594C22.4453 14.9766 23.2734 14.7852 24.2109 14.7852C25.25 14.7852 26.1367 14.9609 26.8711 15.3125C27.6055 15.6562 28.1641 16.1602 28.5469 16.8242C28.9375 17.4883 29.1328 18.2891 29.1328 19.2266V19.2383C29.1328 19.9258 29 20.625 28.7344 21.3359C28.4766 22.0391 28.1172 22.6992 27.6562 23.3164L23.0859 29.3164H29.2266V32H19.3828V29.5156Z" fill="#0B0A0A" />
                                </svg>)
                                : (
                                    <svg className="buy__container__left__el__number" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="24" cy="24" r="23" stroke="#BCB7B3" stroke-width="2" />
                                        <path d="M19.3828 29.5156L25.2773 21.7109C25.582 21.3125 25.8164 20.9023 25.9805 20.4805C26.1523 20.0508 26.2383 19.6523 26.2383 19.2852V19.2617C26.2383 18.6914 26.0625 18.25 25.7109 17.9375C25.3672 17.625 24.875 17.4688 24.2344 17.4688C23.6172 17.4688 23.1172 17.6602 22.7344 18.043C22.3594 18.418 22.1328 18.9531 22.0547 19.6484V19.6602H19.1719V19.6484C19.2812 18.625 19.5547 17.75 19.9922 17.0234C20.4297 16.2969 21.0078 15.7422 21.7266 15.3594C22.4453 14.9766 23.2734 14.7852 24.2109 14.7852C25.25 14.7852 26.1367 14.9609 26.8711 15.3125C27.6055 15.6562 28.1641 16.1602 28.5469 16.8242C28.9375 17.4883 29.1328 18.2891 29.1328 19.2266V19.2383C29.1328 19.9258 29 20.625 28.7344 21.3359C28.4766 22.0391 28.1172 22.6992 27.6562 23.3164L23.0859 29.3164H29.2266V32H19.3828V29.5156Z" fill="#BCB7B3" />
                                    </svg>)}
                            <div className="buy__container__left__el__box">
                                <p className={`buy__container__left__el__box__title ${user.status == 'not logged' ? ' false' : ''}`}>{t("buy.payment")}</p>
                                {user.status != 'not logged' &&
                                    (<><p className="buy__container__left__el__box__text">{t("buy.paymenttext")}</p>
                                        <img src={IMAGES.interkassa} alt="interkassa" className="buy__container__left__el__interkassa" /> </>)}

                            </div>
                        </div>
                        {user.status != 'not logged' && (
                            <>
                                <button onClick={title != 'Оформление чека' ? handleBuySubscription : togglePay} className="buy__container__left__el__pay-button">
                                    <svg className="buy__container__left__el__pay-button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20" cy="20" r="20" fill="#FDFAFA" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8431 13.4286L30.2071 19.7926C30.5976 20.1831 30.5976 20.8163 30.2071 21.2068L23.8431 27.5708C23.4526 27.9613 22.8195 27.9613 22.4289 27.5708C22.0384 27.1802 22.0384 26.5471 22.4289 26.1566L27.0858 21.4997H9.5V19.4997H27.0858L22.4289 14.8428C22.0384 14.4523 22.0384 13.8192 22.4289 13.4286C22.8195 13.0381 23.4526 13.0381 23.8431 13.4286Z" fill="#E75549" />
                                    </svg>
                                    <p className="buy__container__left__el__pay-button__text">{t("buy.button")}</p>
                                </button>
                            </>)}
                    </div>
                    <div className="buy__container__right">
                        {user.status != "not logged" && (
                            <div className="buy__container__right__wrapper">
                                <p className="buy__container__right__title">{t("buy.summarytitle")}</p>
                                <div className="buy__container__right__list">
                                    <div className="buy__container__right__list__el">
                                        <svg className="buy__container__right__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.667 3H6.33366C5.41699 3 4.66699 3.75 4.66699 4.66667V18L10.5003 15.5L16.3337 18V4.66667C16.3337 3.75 15.5837 3 14.667 3ZM14.667 15.5L10.5003 13.6833L6.33366 15.5V4.66667H14.667V15.5Z" fill="#BCB7B3" />
                                        </svg>
                                        <p className="buy__container__right__list__el__text">{title === 'Оформление чека' ? t("contactBuy") : (subscriptions.name[currentLanguage])}</p>
                                    </div>
                                    <div className="buy__container__right__list__el">
                                        <svg className="buy__container__right__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5003 2.1665C7.27533 2.1665 4.66699 4.77484 4.66699 7.99984C4.66699 12.3748 10.5003 18.8332 10.5003 18.8332C10.5003 18.8332 16.3337 12.3748 16.3337 7.99984C16.3337 4.77484 13.7253 2.1665 10.5003 2.1665ZM6.33366 7.99984C6.33366 5.69984 8.20033 3.83317 10.5003 3.83317C12.8003 3.83317 14.667 5.69984 14.667 7.99984C14.667 10.3998 12.267 13.9915 10.5003 16.2332C8.76699 14.0082 6.33366 10.3748 6.33366 7.99984Z" fill="#BCB7B3" />
                                            <path d="M10.5003 10.0832C11.6509 10.0832 12.5837 9.15043 12.5837 7.99984C12.5837 6.84924 11.6509 5.9165 10.5003 5.9165C9.34973 5.9165 8.41699 6.84924 8.41699 7.99984C8.41699 9.15043 9.34973 10.0832 10.5003 10.0832Z" fill="#BCB7B3" />
                                        </svg>
                                        <p className="buy__container__right__list__el__text">{title === 'Оформление чека' ? t("services.locationonline") : (`${subscriptions.duration} ${(subscriptions.duration == 1 ? (t('text-month')) : (t('text-months')))}`)}</p>
                                    </div>
                                    <div className="buy__container__right__list__el">
                                        <svg className="buy__container__right__list__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0003 1.66699C5.40033 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.40033 18.3337 10.0003 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 10.0003 1.66699ZM10.0003 16.667C6.32533 16.667 3.33366 13.6753 3.33366 10.0003C3.33366 6.32533 6.32533 3.33366 10.0003 3.33366C13.6753 3.33366 16.667 6.32533 16.667 10.0003C16.667 13.6753 13.6753 16.667 10.0003 16.667ZM10.2587 9.28366C8.78366 8.90866 8.30866 8.50033 8.30866 7.89199C8.30866 7.19199 8.96699 6.70033 10.0587 6.70033C11.2087 6.70033 11.642 7.25033 11.6753 8.06699H13.1003C13.0587 6.95033 12.3753 5.92533 11.0253 5.59199V4.16699H9.08366V5.57533C7.82533 5.84199 6.81699 6.65866 6.81699 7.91699C6.81699 9.40866 8.05866 10.1587 9.86699 10.592C11.492 10.9753 11.817 11.5503 11.817 12.1503C11.817 12.592 11.492 13.3087 10.067 13.3087C8.73366 13.3087 8.20866 12.7087 8.13366 11.942H6.70033C6.78366 13.3587 7.83366 14.1587 9.08366 14.417V15.8337H11.0337V14.442C12.3003 14.2003 13.3003 13.4753 13.3087 12.1337C13.3003 10.3003 11.7253 9.66699 10.2587 9.28366Z" fill="#BCB7B3" />
                                        </svg>
                                        <p className="buy__container__right__list__el__text">{paymentType}</p>
                                    </div>
                                </div>
                                <div className="buy__container__right__price">
                                    <p className="buy__container__right__price__result">{t("buy.summarytotal")}</p>
                                    <p className="buy__container__right__price__money">₾{title === 'Оформление чека' ? ('100') : (subscriptions.price)}</p>
                                    {title != 'Оформление чека' ? (<p className="buy__container__right__price__monthly">{t("buy.summarymonth")}</p>) :
                                        (<p className="buy__container__right__price__monthly none">{t("buy.summarymonth")}</p>)}
                                </div>
                            </div>)}
                    </div>
                </div>

                <div className={"buy__modal modal" + (modal ? ' show' : '')}>
                    <div onClick={title === 'Приобрести подписку' ? handleGoProfile : handleGoContacts} className="overlay"></div>
                    <div className="buy__modal__content modal-content">
                        <div className="buy__modal__content__header">
                            <svg className="buy__modal__content__header__img" width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="60.5" cy="60" r="59" stroke="#14AE5C" stroke-width="2" />
                                <path d="M54.5004 71.1196L43.3804 59.9996L39.5938 63.7596L54.5004 78.6663L86.5004 46.6662L82.7404 42.9062L54.5004 71.1196Z" fill="#14AE5C" />
                            </svg>
                            <p className="buy__modal__content__header__text">{t("buy.modal.suctitle")}</p>
                        </div>
                        <div className="buy__modal__content__info">
                            <p className="buy__modal__content__info__title">
                                {t("buy.modal.suctext")}
                            </p>
                            <div className="buy__modal__content__info__list">
                                <div className="buy__modal__content__info__list__el">
                                    <svg className="buy__modal__content__info__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.667 3H6.33366C5.41699 3 4.66699 3.75 4.66699 4.66667V18L10.5003 15.5L16.3337 18V4.66667C16.3337 3.75 15.5837 3 14.667 3ZM14.667 15.5L10.5003 13.6833L6.33366 15.5V4.66667H14.667V15.5Z" fill="#BCB7B3" />
                                    </svg>

                                    <p className="buy__modal__content__info__list__el__text">{title === 'Оформление чека' ? t("contactBuy") : (subscriptions.name[currentLanguage])}</p>
                                </div>
                                <div className="buy__modal__content__info__list__el">
                                    <svg className="buy__modal__content__info__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5003 2.1665C7.27533 2.1665 4.66699 4.77484 4.66699 7.99984C4.66699 12.3748 10.5003 18.8332 10.5003 18.8332C10.5003 18.8332 16.3337 12.3748 16.3337 7.99984C16.3337 4.77484 13.7253 2.1665 10.5003 2.1665ZM6.33366 7.99984C6.33366 5.69984 8.20033 3.83317 10.5003 3.83317C12.8003 3.83317 14.667 5.69984 14.667 7.99984C14.667 10.3998 12.267 13.9915 10.5003 16.2332C8.76699 14.0082 6.33366 10.3748 6.33366 7.99984Z" fill="#BCB7B3" />
                                        <path d="M10.5003 10.0832C11.6509 10.0832 12.5837 9.15043 12.5837 7.99984C12.5837 6.84924 11.6509 5.9165 10.5003 5.9165C9.34973 5.9165 8.41699 6.84924 8.41699 7.99984C8.41699 9.15043 9.34973 10.0832 10.5003 10.0832Z" fill="#BCB7B3" />
                                    </svg>
                                    <p className="buy__modal__content__info__list__el__text">{title === 'Оформление чека' ? t("services.locationonline") : (`${subscriptions.duration} ${(subscriptions.duration == 1 ? (t('text-month')) : (t('text-months')))}`)}</p>
                                </div>
                                <div className="buy__modal__content__info__list__el">
                                    <svg className="buy__modal__content__info__list__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0003 1.66699C5.40033 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.40033 18.3337 10.0003 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 10.0003 1.66699ZM10.0003 16.667C6.32533 16.667 3.33366 13.6753 3.33366 10.0003C3.33366 6.32533 6.32533 3.33366 10.0003 3.33366C13.6753 3.33366 16.667 6.32533 16.667 10.0003C16.667 13.6753 13.6753 16.667 10.0003 16.667ZM10.2587 9.28366C8.78366 8.90866 8.30866 8.50033 8.30866 7.89199C8.30866 7.19199 8.96699 6.70033 10.0587 6.70033C11.2087 6.70033 11.642 7.25033 11.6753 8.06699H13.1003C13.0587 6.95033 12.3753 5.92533 11.0253 5.59199V4.16699H9.08366V5.57533C7.82533 5.84199 6.81699 6.65866 6.81699 7.91699C6.81699 9.40866 8.05866 10.1587 9.86699 10.592C11.492 10.9753 11.817 11.5503 11.817 12.1503C11.817 12.592 11.492 13.3087 10.067 13.3087C8.73366 13.3087 8.20866 12.7087 8.13366 11.942H6.70033C6.78366 13.3587 7.83366 14.1587 9.08366 14.417V15.8337H11.0337V14.442C12.3003 14.2003 13.3003 13.4753 13.3087 12.1337C13.3003 10.3003 11.7253 9.66699 10.2587 9.28366Z" fill="#BCB7B3" />
                                    </svg>
                                    <p className="buy__modal__content__info__list__el__text">{paymentType}</p>
                                </div>
                            </div>
                        </div>
                        {title === 'Приобрести подписку' ? (
                            <button onClick={handleGoProfile} className="buy__modal__content__button">
                                <svg className="buy__modal__content__button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20" cy="20" r="20" fill="#FDFAFA" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8431 13.4286L30.2071 19.7926C30.5976 20.1831 30.5976 20.8163 30.2071 21.2068L23.8431 27.5708C23.4526 27.9613 22.8195 27.9613 22.4289 27.5708C22.0384 27.1802 22.0384 26.5471 22.4289 26.1566L27.0858 21.4997H9.5V19.4997H27.0858L22.4289 14.8428C22.0384 14.4523 22.0384 13.8192 22.4289 13.4286C22.8195 13.0381 23.4526 13.0381 23.8431 13.4286Z" fill="#E75549" />
                                </svg>
                                <p className="buy__modal__content__button__text">{t("buy.modal.sucbutton")}</p>
                            </button>)
                            : (
                                <>
                                    <button style={{ alignSelf: 'center' }} className="contact__price__box__card__button telegram">{t("buy.modal.sucteleg")}</button>
                                    <button onClick={handleGoContacts} className="contact__modal__content__button-close buy-close">
                                        <svg className="contact__modal__content__button-close__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="10" cy="10" r="10" fill="#BCB7B3" />
                                            <path d="M5.75827 5.75736L14.2435 14.2426M14.2435 5.75736L5.75827 14.2426" stroke="#FDFAFA" stroke-width="2" />
                                        </svg>
                                        <p className="contact__modal__content__button-close__text">{t("buy.modal.succlose")}</p>
                                    </button>
                                </>
                            )}
                    </div>
                </div>
            </div>
        </div >
    )
}