import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTS } from "../Constants/Routes";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const Contact = () => {
    const { t, i18n } = useTranslation();
    const Navigate = useNavigate();

    const [modalSuc, setModalSuc] = useState(false);
    const [modalFail, setModalFail] = useState(false);

    const [contactPage, setContactPage] = useState([true, false]);

    const toggleModalSuc = () => {
        if (modalSuc) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal');

        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal');
        }
        setModalSuc(prev => !prev);
    };

    const handleGetConsultation = () => {
        Navigate(ROUTS.HOME + ROUTS.CONTACTS + "/questions" + ROUTS.BUY + "/1");
    }

    const toggleModalFail = () => {
        if (modalFail) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal')

        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal')
        }
        setModalFail(prev => !prev);
    };

    const handleChangePage = (e) => {
        e.target.id == 0 ? setContactPage([true, false]) : setContactPage([false, true]);
    }

    const location = useLocation();

    useEffect(() => {
        if (location.pathname.toLowerCase().includes('/questions')) {
            setContactPage([true, false])
        } else if (location.pathname.toLowerCase().includes('/info') || location.pathname.toLowerCase().includes('/about') || location.pathname.toLowerCase().includes('/main')) {
            setContactPage([false, true])
        }
    }, [location.pathname]);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [text, setText] = useState('');

    const handleSendMessage = () => {
        if (name.length > 0 && phone.length > 0 && text.length > 0) {
            axios({
                method: 'post',
                url: 'https://api.lawchill.ge/createQuestion',
                data: {
                    "name": name,
                    "phone": phone,
                    "message": text
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                },
                withCredentials: true,
            }).then((res) => {
                console.log(res);
                setName('');
                setText('');
                setPhone('');
                if(res.data.status == "failed") toggleModalFail();
                else toggleModalSuc();
            }).catch((err) => {
                console.log(err);
                toggleModalFail();
            });
        } else {
            toggleModalFail();
        }
    }


    return (
        <div className="contact">
            <nav className="contact__nav">
                <Link id="0" to="/contacts/questions" onClick={handleChangePage} className={contactPage[0] ? 'contact__nav__link page' : 'contact__nav__link'}>{t("contact.nav1")}</Link>
                <Link id="1" to="/contacts/info" onClick={handleChangePage} className={contactPage[1] ? 'contact__nav__link page' : 'contact__nav__link'}>{t("contact.nav2")}</Link>
            </nav>
            {contactPage[0] ? (
                <>
                    <div className="contact__questions">
                        <div className="contact__questions__wrapper">
                            <div className="contact__questions__left">
                                <p className="contact__questions__left__text">{t("contact.questions")}</p>
                            </div>
                            <div className="contact__questions__right">
                                <p className="contact__questions__right__title">{t("contact.right1")}</p>
                                <p className="contact__questions__right__text">{t("contact.right2")}</p>
                                <div className="contact__questions__right__form">
                                    <input value={name} onChange={(e) => setName(e.target.value)} className="contact__questions__right__form__el name" type="text" placeholder={t("contact.name")} />
                                    <input value={phone} onChange={(e) => setPhone(e.target.value)} className="contact__questions__right__form__el phone" type="text" placeholder={t("contact.phone")} />
                                    <textarea value={text} onChange={(e) => setText(e.target.value)} className="contact__questions__right__form__el question" placeholder={t("contact.yourquestion")} />
                                    <button onClick={handleSendMessage} className="contact__questions__right__form__button">{t("contact.sendmessage")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>)
                : (
                    <div className="contact__info">
                        <p className="contact__questions__left__text">{t("contact.info-questions")}</p>
                        <p className="contact__questions__right__title">{t("contact.contacts")}</p>
                        <div className="contact__info__el">
                            <span className="contact__info__el__title">{t("contact.info-phone")}</span>
                            <div className="contact__info__el__info phone">+995 568 00 88 99</div>
                        </div>
                        <div className="contact__info__el">
                            <span className="contact__info__el__title">Email</span>
                            <div className="contact__info__el__info email">lawchill100@gmail.com</div>
                        </div>
                        <div className="contact__info__el">
                            <span className="contact__info__el__title">{t("contact.address")}</span>
                            <div className="contact__info__el__info adress">Georgia, Berbuki street 7, Commercial area 6 </div>
                        </div>
                    </div>
                )}

            <div className="contact__price">
                <div className="contact__price__wrapper">
                    <h2 className="contact__price__title">{t("contact.secondTitle1")}<font className="black">{t("contact.secondTitle2")}</font>{t("contact.secondTitle3")}</h2>
                    <div className="contact__price__box">
                        <div className="contact__price__box__card">
                            <div className="contact__price__box__card__wrapper">
                                <p className="contact__price__box__card__title">{t("contact.firstBoxTitle")}</p>
                                <p className="contact__price__box__card__price">₾100</p>
                                <button onClick={handleGetConsultation} className="contact__price__box__card__button">{t("contact.firstBoxButton")}</button>
                            </div>
                        </div>
                        <p className="contact__price__box__text">{t("contact.firstBoxText1")}<font style={{ color: '#0B0A0A' }}>{t("contact.firstBoxText2")}
                        </font>{t("contact.firstBoxText3")}<font style={{ color: '#0B0A0A' }}>{t("contact.firstBoxText4")}</font>{t("contact.firstBoxText5")}</p>
                    </div>
                    <div className="contact__price__box">
                        <div className="contact__price__box__card">
                            <div className="contact__price__box__card__wrapper">
                                <p className="contact__price__box__card__title">{t("contact.secondBoxTitle")}</p>
                                <a href='https://t.me/lgowq_bot' className="contact__price__box__card__button telegram">{t("contact.secondBoxButton")}</a>
                            </div>
                        </div>
                        <p className="contact__price__box__text">{t("contact.secondBoxText1")}<font style={{ color: '#0B0A0A' }}>{t("contact.secondBoxText2")}</font>
                            {t("contact.secondBoxText3")}<font style={{ color: '#0B0A0A' }}>{t("contact.secondBoxText4")}</font>{t("contact.secondBoxText5")}</p>
                    </div>
                </div>
            </div>

            <div className={"contact__modal modal" + (modalSuc ? ' show' : '')}>
                <div onClick={toggleModalSuc} className="overlay"></div>
                <div className="contact__modal__content modal-content">
                    <div className="contact__modal__wrapper">
                        <div className="contact__modal__content__header">
                            <svg className="contact__modal__content__header__img" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="60" cy="60" r="59" stroke="#14AE5C" stroke-width="2" />
                                <path d="M54.0004 71.1196L42.8804 59.9996L39.0938 63.7596L54.0004 78.6663L86.0004 46.6662L82.2404 42.9062L54.0004 71.1196Z" fill="#14AE5C" />
                            </svg>

                            <p className="contact__modal__content__header__text">{t("contact.modal.suctitle")}</p>
                        </div>
                        <div className="contact__modal__content__info">
                            <p className="contact__modal__content__info__text">
                                {t("contact.modal.suctext")}
                            </p>
                        </div>
                        <button onClick={toggleModalSuc} className="contact__modal__content__button">
                            <svg className="contact__modal__content__button__img" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20.5" cy="20" r="20" fill="#FDFAFA" />
                                <path d="M13.4285 12.9289L27.5706 27.0711M27.5706 12.9289L13.4285 27.0711" stroke="#0B0A0A" stroke-width="2" />
                            </svg>
                            <p className="contact__modal__content__button__text">{t("contact.modal.succlose")}</p>
                        </button>
                    </div>
                </div>
            </div>
            <div className={"contact__modal modal" + (modalFail ? ' show' : '')}>
                <div onClick={toggleModalFail} className="overlay"></div>
                <div className="contact__modal__content modal-content">
                    <div className="contact__modal__wrapper">
                        <div className="contact__modal__content__header">
                            <svg className="contact__modal__content__header__img" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="60" cy="60" r="59" stroke="#E75549" stroke-width="2" />
                                <path d="M78.6673 45.094L74.9073 41.334L60.0007 56.2407L45.094 41.334L41.334 45.094L56.2407 60.0007L41.334 74.9073L45.094 78.6673L60.0007 63.7607L74.9073 78.6673L78.6673 74.9073L63.7607 60.0007L78.6673 45.094Z" fill="#E75549" />
                            </svg>

                            <p className="contact__modal__content__header__text fail">{t("contact.modal.failtitle")}</p>
                        </div>
                        <div className="contact__modal__content__info">
                            <p className="contact__modal__content__info__text">
                                {t("contact.modal.failtext")}
                            </p>
                        </div>
                        <button onClick={toggleModalFail} className="contact__modal__content__button">
                            <svg className="contact__modal__content__button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="#FDFAFA" />
                                <path d="M20.2727 14.5455V17.2727L23.9091 13.6364L20.2727 10V12.7273C16.2545 12.7273 13 15.9818 13 20C13 21.4273 13.4182 22.7545 14.1273 23.8727L15.4545 22.5455C15.0455 21.7909 14.8182 20.9182 14.8182 20C14.8182 16.9909 17.2636 14.5455 20.2727 14.5455ZM26.4182 16.1273L25.0909 17.4545C25.4909 18.2182 25.7273 19.0818 25.7273 20C25.7273 23.0091 23.2818 25.4545 20.2727 25.4545V22.7273L16.6364 26.3636L20.2727 30V27.2727C24.2909 27.2727 27.5455 24.0182 27.5455 20C27.5455 18.5727 27.1273 17.2455 26.4182 16.1273Z" fill="#0B0A0A" />
                            </svg>
                            <p className="contact__modal__content__button__text">{t("contact.modal.failtryagain")}</p>
                        </button>
                        <button onClick={toggleModalFail} className="contact__modal__content__button-close">
                            <svg className="contact__modal__content__button-close__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="#BCB7B3" />
                                <path d="M5.75827 5.75736L14.2435 14.2426M14.2435 5.75736L5.75827 14.2426" stroke="#FDFAFA" stroke-width="2" />
                            </svg>
                            <p className="contact__modal__content__button-close__text">{t("contact.modal.failclose")}</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}