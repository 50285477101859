import { LOGIN_CATION_ACTION, CHANGE_USER_PIC_CATION_ACTION, CHANGE_SUB_CATION_ACTION, LOGOUT_CATION_ACTION, CHANGE_DATA_CATION_ACTION } from './constants.js';

export const loginCationAction = (payload) => ({
    type: LOGIN_CATION_ACTION, 
    payload, 
});

export const changeUserPicCationAction = (payload) => ({
    type: CHANGE_USER_PIC_CATION_ACTION, 
    payload, 
});

export const changeSubCationAction = (payload) => ({
    type: CHANGE_SUB_CATION_ACTION, 
    payload, 
});

export const logoutCationAction = (payload) => ({
    type: LOGOUT_CATION_ACTION, 
    payload, 
});

export const changeDataCationAction = (payload) => ({
    type: CHANGE_DATA_CATION_ACTION, 
    payload, 
});





