import { useEffect, useRef, useState } from "react";
import { Calendar } from "react-calendar";
import IMAGESCALENDAR from './../../images/calendar/images';
import { currentLanguageSelector } from "../../Store/Language/selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { DropdownForDate } from "./DropdownForDate";
import { servicesInfoSelector } from "../../Store/Services/selectors";
import { Dropdown } from "./Dropdown";
import { DropdownBooking } from "./DropdownBooking";

export const Bookings = () => {
    const { t, i18n } = useTranslation();

    const language = useSelector(currentLanguageSelector);
    const services = useSelector(servicesInfoSelector);

    const [filtersStatus, setFiltersStatus] = useState([false, false, false, false, false, false, false]);

    const handleFilter = (e) => {
        let temp = [false, false, false, false, false, false, false];
        filtersStatus[e.target.name - 1] === false ? temp[e.target.name - 1] = true :
            temp[e.target.name - 1] = false;
        setFiltersStatus(temp);
    };

    const [bookings, setBookings] = useState([]);
    const [users, setUsers] = useState([]);
    const [registrations, setRegistrations] = useState([]);

    const [isActive, setIsActive] = useState([0, 0, 0]);
    const [filterQuery, setFilterQuery] = useState('createdate');
    const [filterType, setFilterType] = useState('DESC');

    const [trigger, setTrigger] = useState(false);

    const handleChangeFilter = (id) => {
        let temp = [0, 0, 0];
        temp[id] = (isActive[id] + 1) % 3;
        setIsActive(temp);
    }

    useEffect(() => {
        if (isActive[0]) {
            setFilterQuery('createdate');
            isActive[0] == 1 ? setFilterType('DESC') : setFilterType("ASC");
        }
        else if (isActive[1]) {
            setFilterQuery('user');
            isActive[1] == 1 ? setFilterType('DESC') : setFilterType("ASC");
        }
        else if (isActive[2]) {
            setFilterQuery('status');
            isActive[2] == 1 ? setFilterType('DESC') : setFilterType("ASC");
        } else {
            setFilterQuery('createdate');
            setFilterType('DESC');
        }
    }, [isActive]);

    const [count, setCount] = useState(100);

    const handleLoadMore = () => {
        setCount(prev => prev + 100);
    }

    useEffect(() => {
        console.log(bookings);
    }, [bookings])

    useEffect(() => {
        axios({
            method: 'get',
            url: `https://api.lawchill.ge/getRequests?filter=${filterQuery}&type=${filterType}&count=${count}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            withCredentials: true,
        }).then((res) => {
            setBookings(res.data.requests);
        }).catch((err) => {
            console.log(err);
        });
    }, [filterQuery, filterType, count, trigger]);


    useEffect(() => {
        axios({
            method: 'get',
            url: 'https://api.lawchill.ge/getRegistrations',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            withCredentials: true,
        }).then((res) => {
            setRegistrations(res.data.registrations);
        }).catch((err) => {
            console.log(err);
        });


        axios({
            method: 'get',
            url: 'https://api.lawchill.ge/getAccounts',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            withCredentials: true,
        }).then((res) => {
            setUsers(res.data.accounts)
        }).catch((err) => {
            console.log(err)
        });
    }, []);

    const [statusFilter, setStatusFilter] = useState([false, false, false]);

    const handleFilterStatus = (array) => {
        if (!statusFilter[0] && !statusFilter[1] && !statusFilter[2]) return array;
        if (statusFilter[0]) return array.filter(item => item.status == 0);
        if (statusFilter[2]) return array.filter(item => item.status == 4);
        if (statusFilter[1]) return array.filter((item) => { return item.status == 2 || item.status == 3 });
    }

    const [locationFilter, setLocationFilter] = useState([false, false]);

    const handleFilterLocation = (array) => {
        if (!locationFilter[0] && !locationFilter[1]) return array;
        if (locationFilter[0]) return array.filter((item) => { return item.servicedata.location && item.servicedata.location['en'] == 'Online' });
        if (locationFilter[1]) return array.filter(item => { return item.servicedata.location && item.servicedata.location['en'] != 'Online' });
    }

    const [searchFilter, setSearchFilter] = useState('');

    const [filterService, setFilterService] = useState(0);

    const handleFilterService = (array) => {
        if (filterService == 0) return array;
        return array.filter(item => item.service == filterService);
    }

    const [filterExpert, setFilterExpert] = useState(0);

    const handleFilterExpert = (array) => {
        if (filterExpert == 0) return array;
        return array.filter(item => item.expert == filterExpert);
    }

    const [value, onChange] = useState('');
    const [noValue, setNoValue] = useState(false);
    const [valueQuery, onChangeQuery] = useState('');

    const handleNoValue = () => {
        setNoValue(prev => !prev);
        onChange('');
    }

    const prevValue = useRef(value);

    useEffect(() => {
        if (noValue && value != '') setNoValue(prev => !prev);
        if (prevValue.current && value) {
            if (prevValue.current.toLocaleDateString("ru") == value.toLocaleDateString("ru")) {
                onChange('');
            }
        }
        prevValue.current = value;
    }, [value]);

    const prevValueQuery = useRef(valueQuery);

    useEffect(() => {
        if (prevValueQuery.current && valueQuery) {
            if (prevValueQuery.current.toLocaleDateString("ru") == valueQuery.toLocaleDateString("ru")) {
                onChangeQuery('');
            }
        }
        prevValueQuery.current = valueQuery;
    }, [valueQuery]);

    const dateFilterQueryArray = (array) => {
        if (valueQuery) return array.filter(item => new Date(item.createdate * 1).toLocaleString('ru', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }) == valueQuery.toLocaleDateString("ru"));
        else return array;
    }

    const registrationDate = (id) => {
        return registrations.filter(el => el.rid == id)[0] ? registrations.filter(el => el.rid == id)[0].date : 0;
    }

    const dateFilterArray = (array) => {
        if (noValue) return array.filter(item => registrationDate(item.id) == 0);
        if (value) return array.filter(item => new Date(registrationDate(item.id) * 1).toLocaleString('ru', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }) == value.toLocaleDateString("ru"));
        else return array;
    }

    const filter = (array) => {

        console.log(dateFilterArray(dateFilterQueryArray(handleFilterExpert(handleFilterService(handleFilterLocation(handleFilterStatus(array))))).filter((item) => {
            return (item.expertname && item.expertname.toLowerCase().includes(searchFilter.toLowerCase()))
                || (item.servicename && item.servicename[language] && item.servicename[language].toLowerCase().includes(searchFilter.toLowerCase()))
                || (item.servicedata.location && item.servicedata.location[language] && item.servicedata.location[language].toLowerCase().includes(searchFilter.toLowerCase()))
                || (users && users.filter(el => el.id == item.user)[0] && users.filter(el => el.id == item.user)[0].name && users.filter(el => el.id == item.user)[0].name.toLowerCase().includes(searchFilter.toLowerCase()));
        })))

        return dateFilterArray(dateFilterQueryArray(handleFilterExpert(handleFilterService(handleFilterLocation(handleFilterStatus(array))))).filter((item) => {
            return (item.expertname && item.expertname.toLowerCase().includes(searchFilter.toLowerCase()))
                || (item.servicename && item.servicename[language] && item.servicename[language].toLowerCase().includes(searchFilter.toLowerCase()))
                || (item.servicedata.location && item.servicedata.location[language] && item.servicedata.location[language].toLowerCase().includes(searchFilter.toLowerCase()))
                || (users && users.filter(el => el.id == item.user)[0] && users.filter(el => el.id == item.user)[0].name && users.filter(el => el.id == item.user)[0].name.toLowerCase().includes(searchFilter.toLowerCase()));
        }));
        
    }

    const changeBookingStatus = (id, statusId) => {
        axios({
            method: 'POST',
            url: 'https://api.lawchill.ge/setRequestStatus',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            data: {
                id: id,
                "data": {
                    "status": statusId
                }
            },
            withCredentials: true,
        }).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        });
    }


    const arrowRight = <img className='react-calendar__navigation__arrow-right' src={IMAGESCALENDAR.arrowRight} alt=">" />
    const arrowLeft = <img className='react-calendar__navigation__arrow-left' src={IMAGESCALENDAR.arrowLeft} alt="<" />

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const handleOpenFilter = () => {
        setIsFilterOpen(prev => !prev);
    }

    return (
        <div className="bookings">
            <div className="dashboard__filter__search-box small desktop-dashboard">
                <input value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} type="text" className="dashboard__filter__search-box__search" placeholder={t("dashboard.booking.search")} />
                <div className="dashboard__filter__search-box__img" />
            </div>
            <div className="dashboard__filter bookings__filter desktop-dashboard">
                <div className="dashboard__filter__search-box big">
                    <input value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} type="text" className="dashboard__filter__search-box__search" placeholder={t("dashboard.booking.search")} />
                    <div className="dashboard__filter__search-box__img" />
                </div>
                <div className="dashboard__filter__box">
                    <input onChange={handleFilter} checked={filtersStatus[1]} name="2" id="second" type="checkbox" className="dashboard__filter__box__checkbox" />
                    <label htmlFor="second" className="dashboard__filter__box__title">
                        <p className="dashboard__filter__box__title__text bookings__filter__el">{t("dashboard.booking.service")}</p>
                    </label>
                    <div className="dashboard__filter__box__content">
                        {services.map((item, index) => {
                            return (
                                <div className="dashboard__filter__box__content__el">
                                    <input checked={filterService == item.id} onChange={() => (filterService == item.id ? setFilterService(0) : setFilterService(item.id))} id={`1${index}`} type="checkbox" className="dashboard__filter__box__content__el__input" />
                                    <label htmlFor={`1${index}`} className="dashboard__filter__box__content__el__text">{item.name[language]}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="dashboard__filter__box">
                    <input onChange={handleFilter} checked={filtersStatus[2]} name="3" id="third" type="checkbox" className="dashboard__filter__box__checkbox" />
                    <label htmlFor="third" className="dashboard__filter__box__title">
                        <p className="dashboard__filter__box__title__text bookings__filter__el">{t("dashboard.booking.expert")}</p>
                    </label>
                    <div className="dashboard__filter__box__content">
                        {users.filter(item => item.status == 'expert').map((item, index) => {
                            return (
                                <div className="dashboard__filter__box__content__el">
                                    <input checked={filterExpert == item.id} onChange={() => (filterExpert == item.id ? setFilterExpert(0) : setFilterExpert(item.id))} id={`2${index}`} type="checkbox" className="dashboard__filter__box__content__el__input" />
                                    <label htmlFor={`2${index}`} className="dashboard__filter__box__content__el__text">{item.name}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="dashboard__filter__box">
                    <input onChange={handleFilter} checked={filtersStatus[3]} name="4" id="fourth" type="checkbox" className="dashboard__filter__box__checkbox" />
                    <label htmlFor="fourth" className="dashboard__filter__box__title">
                        <p className="dashboard__filter__box__title__text bookings__filter__el">{t("dashboard.booking.status")}</p>
                    </label>
                    <div className="dashboard__filter__box__content">
                        <div className="dashboard__filter__box__content__el">
                            <input checked={statusFilter[0]} onChange={() => setStatusFilter([!statusFilter[0], false, false])} id="31" type="checkbox" className="dashboard__filter__box__content__el__input" />
                            <label htmlFor="31" className="dashboard__filter__box__content__el__text">{t("dashboard.booking.paid")}</label>
                        </div>
                        <div className="dashboard__filter__box__content__el">
                            <input checked={statusFilter[1]} onChange={() => setStatusFilter([false, !statusFilter[1], false])} id="32" type="checkbox" className="dashboard__filter__box__content__el__input" />
                            <label htmlFor="32" className="dashboard__filter__box__content__el__text">{t("dashboard.booking.cancelled")}</label>
                        </div>
                        <div className="dashboard__filter__box__content__el">
                            <input checked={statusFilter[2]} onChange={() => setStatusFilter([false, false, !statusFilter[2]])} id="33" type="checkbox" className="dashboard__filter__box__content__el__input" />
                            <label htmlFor="33" className="dashboard__filter__box__content__el__text">{t("dashboard.booking.past")}</label>
                        </div>
                    </div>
                </div>
                <div className="dashboard__filter__box">
                    <input onChange={handleFilter} checked={filtersStatus[4]} name="5" id="fifth" type="checkbox" className="dashboard__filter__box__checkbox" />
                    <label htmlFor="fifth" className="dashboard__filter__box__title">
                        <p className="dashboard__filter__box__title__text bookings__filter__el">{t("dashboard.booking.location")}</p>
                    </label>
                    <div className="dashboard__filter__box__content">
                        <div className="dashboard__filter__box__content__el">
                            <input checked={locationFilter[0]} onChange={() => setLocationFilter([!locationFilter[0], false])} id="41" type="checkbox" className="dashboard__filter__box__content__el__input" />
                            <label htmlFor="41" className="dashboard__filter__box__content__el__text">{t("dashboard.service.online")}</label>
                        </div>
                        <div className="dashboard__filter__box__content__el">
                            <input checked={locationFilter[1]} onChange={() => setLocationFilter([false, !locationFilter[1]])} id="42" type="checkbox" className="dashboard__filter__box__content__el__input" />
                            <label htmlFor="42" className="dashboard__filter__box__content__el__text">{t("dashboard.service.offline")}</label>
                        </div>
                    </div>
                </div>
                <div className="dashboard__filter__box">
                    <input onChange={handleFilter} checked={filtersStatus[5]} name="6" id="sixth" type="checkbox" className="dashboard__filter__box__checkbox" />
                    <label htmlFor="sixth" className="dashboard__filter__box__title">
                        <p className="dashboard__filter__box__title__text bookings__filter__el">{t("dashboard.booking.queryTime")}</p>
                    </label>
                    <div className="dashboard__filter__box__content calendar-box">
                        <div className="dashboard__filter__box__content__el">
                            <input checked={noValue} onChange={handleNoValue} id="51" type="checkbox" className="dashboard__filter__box__content__el__input" />
                            <label htmlFor="51" className="dashboard__filter__box__content__el__text calendar">{t("dashboard.booking.notSet")}</label>
                        </div>
                        <Calendar minDetail="month" prevLabel={arrowLeft} nextLabel={arrowRight} locale="en" onChange={onChange} value={value} className="calendar" />
                    </div>
                </div>
                <div className="dashboard__filter__box">
                    <input onChange={handleFilter} checked={filtersStatus[6]} name="7" id="seventh" type="checkbox" className="dashboard__filter__box__checkbox" />
                    <label htmlFor="seventh" className="dashboard__filter__box__title">
                        <p className="dashboard__filter__box__title__text bookings__filter__el">{t("dashboard.booking.serviceTime")}</p>
                    </label>
                    <div className="dashboard__filter__box__content calendar">
                        <Calendar minDetail="month" prevLabel={arrowLeft} nextLabel={arrowRight} locale="en" onChange={onChangeQuery} value={valueQuery} className="calendar" />
                    </div>
                </div>
            </div>
            <div className={"dashboard__filter mobile" + (isFilterOpen ? ' close' : '')}>
                <div className="dashboard__filter__search-box">
                    <input value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} type="text" className="dashboard__filter__search-box__search" placeholder={t("dashboard.booking.search")} />
                    <div className="dashboard__filter__search-box__img" />
                </div>
                <button onClick={handleOpenFilter} className="dashboard__filter__mobile-button">
                    <svg className="dashboard__filter__mobile-button__img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_593_22721)">
                            <path d="M9.33333 20H14.6667V17.3333H9.33333V20ZM0 4V6.66667H24V4H0ZM4 13.3333H20V10.6667H4V13.3333Z" fill="#BCB7B3" />
                        </g>
                        <defs>
                            <clipPath id="clip0_593_22721">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </button>
            </div>
            <div className={"dashboard__filter-page mobile" + (isFilterOpen ? ' open' : '')}>
                <div className="dashboard__filter-page__header">
                    <span className="dashboard__filter-page__header__title">{t('services.filter')}</span>
                    <button onClick={handleOpenFilter} className="dashboard__filter-page__header__button">
                        <svg className="dashboard__filter-page__header__button__img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#E4E3E2" />
                            <path d="M7.75827 7.75736L16.2435 16.2426M16.2435 7.75736L7.75827 16.2426" stroke="#FDFAFA" stroke-width="2" />
                        </svg>
                    </button>
                </div>
                <div className="dashboard__filter-page__box">
                    <div className="dashboard__filter-page__box__el">
                        <div className="dashboard__filter-page__box__el__title__box">
                            <p className="dashboard__filter-page__box__el__title">{t("dashboard.booking.service")}</p>
                        </div>
                        <div className="dashboard__filter-page__box__el__content">
                            {services.map((item, index) => {
                                return (
                                    <div className="dashboard__filter-page__box__el__content__el">
                                        <input checked={filterService == item.id} onChange={() => (filterService == item.id ? setFilterService(0) : setFilterService(item.id))} id={`1${index}`} type="checkbox" className="dashboard__filter-page__box__el__content__el__input" />
                                        <label htmlFor={`1${index}`} className="dashboard__filter-page__box__el__content__el__text">{item.name[language]}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="dashboard__filter-page__box__el">
                        <div className="dashboard__filter-page__box__el__title__box">
                            <p className="dashboard__filter-page__box__el__title">{t("dashboard.booking.expert")}</p>
                        </div>
                        <div className="dashboard__filter-page__box__el__content">
                            {users.filter(item => item.status == 'expert').map((item, index) => {
                                return (
                                    <div className="dashboard__filter-page__box__el__content__el">
                                        <input checked={filterExpert == item.id} onChange={() => (filterExpert == item.id ? setFilterExpert(0) : setFilterExpert(item.id))} id={`2${index}`} type="checkbox" className="dashboard__filter-page__box__el__content__el__input" />
                                        <label htmlFor={`2${index}`} className="dashboard__filter-page__box__el__content__el__text">{item.name}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="dashboard__filter-page__box__el">
                        <div className="dashboard__filter-page__box__el__title__box">
                            <p className="dashboard__filter-page__box__el__title">{t("dashboard.booking.status")}</p>
                        </div>
                        <div className="dashboard__filter-page__box__el__content">
                            <div className="dashboard__filter-page__box__el__content__el">
                                <input checked={statusFilter[0]} onChange={() => setStatusFilter([!statusFilter[0], false, false])} id="31" type="checkbox" className="dashboard__filter-page__box__el__content__el__input" />
                                <label htmlFor="31" className="dashboard__filter-page__box__el__content__el__text">{t("dashboard.booking.paid")}</label>
                            </div>
                            <div className="dashboard__filter-page__box__el__content__el">
                                <input checked={statusFilter[1]} onChange={() => setStatusFilter([false, !statusFilter[1], false])} id="32" type="checkbox" className="dashboard__filter-page__box__el__content__el__input" />
                                <label htmlFor="32" className="dashboard__filter-page__box__el__content__el__text">{t("dashboard.booking.cancelled")}</label>
                            </div>
                            <div className="dashboard__filter-page__box__el__content__el">
                                <input checked={statusFilter[2]} onChange={() => setStatusFilter([false, false, !statusFilter[2]])} id="33" type="checkbox" className="dashboard__filter-page__box__el__content__el__input" />
                                <label htmlFor="33" className="dashboard__filter-page__box__el__content__el__text">{t("dashboard.booking.past")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard__filter-page__box__el">
                        <div className="dashboard__filter-page__box__el__title__box">
                            <p className="dashboard__filter-page__box__el__title">{t("dashboard.booking.location")}</p>
                        </div>
                        <div className="dashboard__filter-page__box__el__content">
                            <div className="dashboard__filter-page__box__el__content__el">
                                <input checked={locationFilter[0]} onChange={() => setLocationFilter([!locationFilter[0], false])} id="41" type="checkbox" className="dashboard__filter-page__box__el__content__el__input" />
                                <label htmlFor="41" className="dashboard__filter-page__box__el__content__el__text">{t("dashboard.service.online")}</label>
                            </div>
                            <div className="dashboard__filter-page__box__el__content__el">
                                <input checked={locationFilter[1]} onChange={() => setLocationFilter([false, !locationFilter[1]])} id="42" type="checkbox" className="dashboard__filter-page__box__el__content__el__input" />
                                <label htmlFor="42" className="dashboard__filter-page__box__el__content__el__text">{t("dashboard.service.offline")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard__filter-page__box__el">
                        <div className="dashboard__filter-page__box__el__title__box">
                            <p className="dashboard__filter-page__box__el__title">{t("dashboard.booking.queryTime")}</p>
                        </div>
                        <div className="dashboard__filter-page__box__el__content">
                            <Calendar minDetail="month" prevLabel={arrowLeft} nextLabel={arrowRight} locale="en" onChange={onChange} value={value} className="calendar" />
                        </div>
                    </div>
                    <div className="dashboard__filter-page__box__el">
                        <div className="dashboard__filter-page__box__el__title__box">
                            <p className="dashboard__filter-page__box__el__title">{t("dashboard.booking.serviceTime")}</p>
                        </div>
                        <div className="dashboard__filter-page__box__el__content">
                            <Calendar minDetail="month" prevLabel={arrowLeft} nextLabel={arrowRight} locale="en" onChange={onChangeQuery} value={valueQuery} className="calendar" />
                        </div>
                    </div>
                </div>
                <button onClick={handleOpenFilter} className="dashboard__filter-page__button">{t("services.usefilter")}</button>
            </div>
            <div className="bookings__table desktop-dashboard">
                <div className="bookings__table__title">
                    <button onClick={() => handleChangeFilter(0)} className={"bookings__table__title__el dashboard__title bg" + (isActive[0] == 1 ? ' actived' : (isActive[0] == 2 ? ' reverse' : ''))}>{t("dashboard.booking.query")}</button>
                    <div className="bookings__table__title__el dashboard__title">{t("dashboard.booking.booking")}</div>
                    <button onClick={() => handleChangeFilter(1)} className={"bookings__table__title__el dashboard__title bg" + (isActive[1] == 1 ? ' actived' : (isActive[1] == 2 ? ' reverse' : ''))}>{t("dashboard.booking.userName")}</button>
                    <div className="bookings__table__title__el dashboard__title">{t("dashboard.booking.service")}</div>
                    <div className="bookings__table__title__el dashboard__title">{t("dashboard.booking.location")}</div>
                    <button onClick={() => handleChangeFilter(2)} className={"bookings__table__title__el dashboard__title bg" + (isActive[2] == 1 ? ' actived' : (isActive[2] == 2 ? ' reverse' : ''))}>{t("dashboard.booking.status")}</button>
                </div>
                {bookings && filter(bookings).map((item) => {
                    if (item.status != 0) {
                        const createDate = new Date(item.createdate * 1).toLocaleString('ru', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric'
                        });
                        return (
                            <div className="bookings__table__row">
                                <div className="bookings__table__el dashboard__text">{item.createdate ? createDate : '-'}</div>
                                <DropdownForDate handleTrigger={setTrigger} id={item.id} expertName={item.expertname ? item.expertname : '-'} expertsId={item.expert ? item.expert : 0} date={item.regdate ? item.regdate * 1 : 0} />
                                {users.filter(el => el.id == item.user) && <div className="bookings__table__el dashboard__text">{users.filter(el => el.id == item.user)[0]?.name ? users.filter(el => el.id == item.user)[0].name : '-'}</div>}
                                <div className="bookings__table__el dashboard__text">{item.servicename ? item.servicename[language] : '-'}</div>
                                <div className="bookings__table__el dashboard__text">{item.servicedata.location ? item.servicedata.location[language] : '-'}</div>
                                <DropdownBooking id={item.id} handler={changeBookingStatus} className='delete'
                                    defaultValue={(item.status == 5 || item.status == 6) ? 'paid' : (item.status == 4 ? 'past' : ((item.status == 1 || item.status == 2) ? 'cancelled' : ''))}
                                    statusId={item.status}
                                    ids={[5, 4, 2]}
                                    content={['paid', 'past', 'cancelled']} />
                            </div>
                        )
                    }
                })}
            </div>
            <div className={"dashboard__table mobile" + (isFilterOpen ? ' close' : '')}>
                {filter(bookings).map((item) => {
                    if (item.status != 0) {
                        const createDate = new Date(item.createdate * 1).toLocaleString('ru', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric'
                        });
                        return (
                            <div className="dashboard__table__mobile-row">
                                <div className="dashboard__table__mobile-row__title">
                                    <div className="dashboard__table__mobile-row__title__left">
                                        <svg className="dashboard__table__mobile-row__title__left__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.16699 6.33366H10.8337V8.00033H9.16699V6.33366ZM9.16699 9.66699H10.8337V14.667H9.16699V9.66699ZM10.0003 2.16699C5.40033 2.16699 1.66699 5.90033 1.66699 10.5003C1.66699 15.1003 5.40033 18.8337 10.0003 18.8337C14.6003 18.8337 18.3337 15.1003 18.3337 10.5003C18.3337 5.90033 14.6003 2.16699 10.0003 2.16699ZM10.0003 17.167C6.32533 17.167 3.33366 14.1753 3.33366 10.5003C3.33366 6.82533 6.32533 3.83366 10.0003 3.83366C13.6753 3.83366 16.667 6.82533 16.667 10.5003C16.667 14.1753 13.6753 17.167 10.0003 17.167Z" fill="#BCB7B3" />
                                        </svg>
                                        <div className="dashboard__table__mobile-row__title__left__text">
                                            <span className="dashboard__table__mobile-row__title__left__text__title">{item.name ? item.name : '-'}</span>
                                            <span className="dashboard__table__mobile-row__title__left__text__descr">{item.servicename ? item.servicename[language] : '-'}</span>
                                            <span className="dashboard__table__mobile-row__title__left__text__value">{item.createdate ? createDate : '-'}</span>
                                        </div>
                                    </div>
                                    <div className="dashboard__table__mobile-row__title__right">
                                        <DropdownBooking id={item.id} handler={changeBookingStatus} className='delete'
                                            defaultValue={(item.status == 5 || item.status == 6) ? 'paid' : (item.status == 4 ? 'past' : ((item.status == 1 || item.status == 2) ? 'cancelled' : ''))}
                                            statusId={item.status}
                                            ids={[5, 4, 2]}
                                            content={['paid', 'past', 'cancelled']} />
                                    </div>
                                </div>
                                <div className="dashboard__table__mobile-row__content">
                                    <div className="dashboard__table__mobile-row__content__el">
                                        <svg className="dashboard__table__mobile-row__content__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5 1.33301H7.5V2.99967H12.5V1.33301ZM9.16667 12.1663H10.8333V7.16634H9.16667V12.1663ZM15.8583 6.65801L17.0417 5.47467C16.6833 5.04967 16.2917 4.64967 15.8667 4.29967L14.6833 5.48301C13.3917 4.44967 11.7667 3.83301 10 3.83301C5.85833 3.83301 2.5 7.19134 2.5 11.333C2.5 15.4747 5.85 18.833 10 18.833C14.15 18.833 17.5 15.4747 17.5 11.333C17.5 9.56634 16.8833 7.94134 15.8583 6.65801ZM10 17.1663C6.775 17.1663 4.16667 14.558 4.16667 11.333C4.16667 8.10801 6.775 5.49967 10 5.49967C13.225 5.49967 15.8333 8.10801 15.8333 11.333C15.8333 14.558 13.225 17.1663 10 17.1663Z" fill="#BCB7B3" />
                                        </svg>
                                        <div className="dashboard__table__mobile-row__content__el__text box">
                                            <DropdownForDate handleTrigger={setTrigger} id={item.id} expertName={item.expertname ? item.expertname : '-'} expertsId={item.expert ? item.expert : 0} date={item.regdate ? item.regdate * 1 : 0} />
                                        </div>
                                    </div>
                                    <div className="dashboard__table__mobile-row__content__el">
                                        <svg className="dashboard__table__mobile-row__content__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0003 1.66699C6.77533 1.66699 4.16699 4.27533 4.16699 7.50033C4.16699 11.8753 10.0003 18.3337 10.0003 18.3337C10.0003 18.3337 15.8337 11.8753 15.8337 7.50033C15.8337 4.27533 13.2253 1.66699 10.0003 1.66699ZM5.83366 7.50033C5.83366 5.20033 7.70033 3.33366 10.0003 3.33366C12.3003 3.33366 14.167 5.20033 14.167 7.50033C14.167 9.90033 11.767 13.492 10.0003 15.7337C8.26699 13.5087 5.83366 9.87533 5.83366 7.50033Z" fill="#BCB7B3" />
                                            <path d="M10.0003 9.58366C11.1509 9.58366 12.0837 8.65092 12.0837 7.50033C12.0837 6.34973 11.1509 5.41699 10.0003 5.41699C8.84973 5.41699 7.91699 6.34973 7.91699 7.50033C7.91699 8.65092 8.84973 9.58366 10.0003 9.58366Z" fill="#BCB7B3" />
                                        </svg>
                                        <p className="dashboard__table__mobile-row__content__el__text">{item.servicedata.location ? item.servicedata.location[language] : '-'}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
            <button onClick={handleLoadMore} className={"dashboard__load-more-button" + (bookings.length % 100 != 0 || bookings.length == 0 ? ' hidden' : '') + (isFilterOpen ? ' hidden' : '')}>
                <svg className="dashboard__load-more-button__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10.5" r="10" fill="#56514D" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2422 13.1059L10.4239 16.9243C10.1895 17.1586 9.80964 17.1586 9.57533 16.9243L5.75695 13.1059C5.52264 12.8716 5.52264 12.4917 5.75695 12.2574C5.99127 12.023 6.37117 12.023 6.60548 12.2574L9.39959 15.0515V4.5L10.5996 4.5V15.0515L13.3937 12.2574C13.628 12.023 14.0079 12.023 14.2422 12.2574C14.4765 12.4917 14.4765 12.8716 14.2422 13.1059Z" fill="#FDFAFA" />
                </svg>
                <span className="dashboard__load-more-button__text">{t('dashboard.showMore')}</span>
            </button>
        </div>
    )
}