import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTS } from "../../Constants/Routes";

export const Filters = () => {
    const { t, i18n } = useTranslation();

    const Navigate = useNavigate();
    const location = useLocation();


    const [filterData, setFilterData] = useState([
        {
            id: 0,
            name: "dashboard.site-queries",
            isChosen: false,
            link: "/siteQueries"
        },
        {
            id: 1,
            name: "dashboard.service-bookings",
            isChosen: false,
            link: "/serviceBookings"
        },
        {
            id: 2,
            name: "dashboard.users",
            isChosen: false,
            link: "/users"
        },
        {
            id: 3,
            name: "dashboard.plans",
            isChosen: false,
            link: "/plans"
        },
        {
            id: 4,
            name: "dashboard.services",
            isChosen: false,
            link: "/services"
        },
        {
            id: 5,
            name: "dashboard.service-groups",
            isChosen: false,
            link: "/serviceGroups"
        }
    ]);

    const handleChangeFilter = (e) => {
        let temp = [];
        filterData.map(el => {
            if (el.id == e.target.id) {
                Navigate(ROUTS.HOME + ROUTS.DASHBOARD + el.link);
                el.isChosen = true;
            } else {
                el.isChosen = false;
            }
            temp.push(el);
        });
        setFilterData(temp);
    }

    useEffect(() => {
        const path = location.pathname;
        
        let temp = [];
        filterData.map(el => {
            path.includes(el.link) ? el.isChosen = true : el.isChosen = false;
            temp.push(el);
        });
        setFilterData(temp);
    }, []);

    return (
        <div className="filters dashboard-filters">
            {filterData.map(el => {
                return (<button key={el.id * Math.random() * 1000}
                    id={el.id}
                    onClick={handleChangeFilter}
                    className={"filters__el dashboard__filter__el " + (el.isChosen ? "chosen" : '')}>
                    {t(el.name)}
                </button>)
            })}
        </div>
    );
}