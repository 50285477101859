import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import IMAGESLOGIN from "../../images/login/images";


export const LanguageDropdown = ({ defaultValue, content, handler, id = 0, type="desktop"}) => {
    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(defaultValue);

    const handleChangeValue = (value) => {
        setValue(value);
        handler(id, value);
    }

    const handleOpen = () => {
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                document.addEventListener("click", handleClose);
            }, 0);
        } else {
            document.removeEventListener("click", handleClose);
        }
        return () => {
            document.removeEventListener("click", handleClose);
        };
    }, [isOpen]);

    return (
        <div className={`querie__table__el delete ${type}`} style={{alignSelf: type === "mobile" ? "flex-start" : "none", marginBottom: type === "mobile" ? "50px" : "0px", boxShadow: "0px 4px 20px 0px rgba(79, 79, 79, 0.20)", borderRadius: "30px", border: "none"}}>
            <button className={"querie__table__el__status dashboard__text delete languageDropdown__button " + (isOpen ? 'open' : '')} onClick={handleOpen} style={{paddingRight: "1rem", border: "none"}}>
                <img src={IMAGESLOGIN[i18n.language.toUpperCase()]?.default || IMAGESLOGIN[i18n.language.toUpperCase()] } width={30} height={30} className="languageDropdown__image"/>
                {i18n.language.toUpperCase()}
                <svg width="16" height="16" style={{transform: isOpen ? "rotate(180deg)" : "rotate(0deg)"}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.8374 5.4807L8.11891 11.1992L2.40039 5.4807L3.08187 4.79922L8.11891 9.83626L13.1559 4.79922L13.8374 5.4807Z" fill="#0B0A0A"/>
                </svg>

            </button>
            <div className="querie__table__el__status__content languageDropdown__content delete">
                {content.map((item) => {
                    return (
                        <div data-value={item} onClick={() => handleChangeValue(item)} className="querie__table__el__status__content__el languageDropdown__el delete">
                            <img src={item.image?.default || item.image} width={30} height={30} className="languageDropdown__image"/>
                            {item.language}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}