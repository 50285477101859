import { useEffect, useState } from "react";
import IMAGES from "./../../images/dashboard/images";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { currentLanguageSelector } from "../../Store/Language/selectors";
import { servicesInfoSelector } from "../../Store/Services/selectors";
import { categoryInfoSelector } from "../../Store/Categories/selectors";
import { subscriptionsSelector } from "../../Store/Subscriptions/selectors";
import { deleteServiceCationAction } from "../../Store/Services/actions";
import { PopupDelete } from "./Popup/PopupDelete";
import { PopupCreate } from "./Popup/PopupCreate";
import { PopupDone } from "./Popup/PopupDone";

export const Service = () => {
    const { t, i18n } = useTranslation();

    const currentLanguage = useSelector(currentLanguageSelector);
    const services = useSelector(servicesInfoSelector);
    const categories = useSelector(categoryInfoSelector);
    const subscriptions = useSelector(subscriptionsSelector);

    console.log(categories);
    console.log(services);

    const [filtersStatus, setFiltersStatus] = useState([false, false, false, false]);
    const [selectedGroupId, setSelectedGroupId] = useState();

    const [deleteModal, setDeleteModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [doneModal, setDoneModal] = useState(false);

    const [actionCreate, setActionCreate] = useState('');

    const dispath = useDispatch();

    const handleDeleteServiceAction = (id) => {
        dispath(deleteServiceCationAction({ id: id }))
    }

    const toggleModal = (modal, setModal) => {
        if (modal) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal');
        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal')
        }
        setModal(prev => !prev);
    };

    const handleOpenDeleteModal = () => {
        toggleModal(deleteModal, setDeleteModal);
    }

    const openCreateModal = () => {
        toggleModal(createModal, setCreateModal);
    }

    const openDoneModal = () => {
        toggleModal(doneModal, setDoneModal);
    }

    const handleOpenCreateModal = (e, id, type) => {
        if (!e.target.closest('.delete')) {
            setActionCreate(type);
            setSelectedGroupId(id);
            openCreateModal();
        }
    }

    const handleDeleteService = (id) => {
        setSelectedGroupId(id);
        handleOpenDeleteModal();
    }

    const handleFilter = (e) => {
        let temp = [false, false, false, false];
        filtersStatus[e.target.name - 1] === false ? temp[e.target.name - 1] = true :
            temp[e.target.name - 1] = false;
        setFiltersStatus(temp);
    };

    const [locationFilter, setLocationFilter] = useState([false, false]);

    const handleFilterLocation = (array) => {
        if (!locationFilter[0] && !locationFilter[1]) return array;
        if (locationFilter[0]) return array.filter((item) => { return item.location && item.location['en'] == 'Online' });
        if (locationFilter[1]) return array.filter((item) => { return item.location && item.location['en'] != 'Online' });
    }

    const [searchFilter, setSearchFilter] = useState('');

    const [time, setTime] = useState([]);

    const [timeFilter, setTimeFilter] = useState(0);

    const [filterGroup, setFilterGroup] = useState(0);

    const handleFilterGroup = (array) => {
        if (filterGroup == 0) return array;
        return array.filter(item => item.category == filterGroup);
    }

    useEffect(() => {
        let temp = [];
        services.map(item => temp.indexOf(item.duration) >= 0 ? '' : temp.push(item.duration));
        setTime(temp.sort((a, b) => { return a - b }));
    }, [services]);

    const handleTimeFilter = (array) => {
        if (timeFilter == 0) return array;
        return array.filter(item => item.duration == time[timeFilter - 1]);
    }

    const [lowPrice, setLowPrice] = useState();
    const [highPrice, setHighPrice] = useState();

    const priceFilter = (array) => {
        if (!lowPrice && !highPrice) return array;
        if (!highPrice) return array.filter(item => item.price >= lowPrice);
        if (!lowPrice) return array.filter(item => item.price <= highPrice);
        return array.filter((item) => { return (item.price >= lowPrice && item.price <= highPrice) })
    }

    const filter = (array) => {
        return priceFilter(handleTimeFilter(handleFilterLocation(handleFilterGroup(array)))).filter((item) => {
            return ((item.name && item.name[currentLanguage].toLowerCase().includes(searchFilter.toLowerCase()))
                || (item.description && item.description[currentLanguage].toLowerCase().includes(searchFilter.toLowerCase()))
                || (item.location && item.location[currentLanguage].toLowerCase().includes(searchFilter.toLowerCase()))
                || ((item.category != null && item.category != 1) ? (categories.filter((el) => el.id == item.category)[0][currentLanguage].toLowerCase().includes(searchFilter.toLowerCase())) : (t("dashboard.nogroups").toLowerCase().includes(searchFilter.toLowerCase())))
                || (item.subscriptions && Object.keys(item.subscriptions).map((el) => subscriptions.filter((sub) => sub.id == el).map(elem => elem.name[currentLanguage].toLowerCase().includes(searchFilter.toLowerCase())).find(el => el == true)).find(el => el == true)));
        });
    }

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const handleOpenFilter = () => {
        setIsFilterOpen(prev => !prev);
    }

    return (
        <div className="service">
            <div className="dashboard__filter service__filter desktop-dashboard">
                <div className="dashboard__filter__search-box">
                    <input value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} type="text" className="dashboard__filter__search-box__search" placeholder={t('dashboard.service.search')} />
                    <div className="dashboard__filter__search-box__img" />
                </div>
                <div className="dashboard__filter__box">
                    <input onChange={handleFilter} checked={filtersStatus[0]} name="1" id="first" type="checkbox" className="dashboard__filter__box__checkbox" />
                    <label htmlFor="first" className="dashboard__filter__box__title">
                        <p className="dashboard__filter__box__title__text">{t('dashboard.service.cost')}</p>
                    </label>
                    <div className="dashboard__filter__box__content price">
                        <div className="services__filter__price-box__content__first">
                            {t("services.from")} <input value={lowPrice} onChange={(e) => setLowPrice(e.target.value)} type="number" placeholder="0" className="services__filter__price-box__content__first__input" />
                        </div>
                        <div className="services__filter__price-box__content__second">
                            {t("services.to")} <input value={highPrice} onChange={(e) => setHighPrice(e.target.value)} type="number" placeholder="1500" className="services__filter__price-box__content__second__input" />
                        </div>
                    </div>
                </div>
                <div className="dashboard__filter__box">
                    <input onChange={handleFilter} checked={filtersStatus[1]} name="2" id="second" type="checkbox" className="dashboard__filter__box__checkbox" />
                    <label htmlFor="second" className="dashboard__filter__box__title">
                        <p className="dashboard__filter__box__title__text">{t('dashboard.service.duration')}</p>
                    </label>
                    <div className="dashboard__filter__box__content">
                        {time.map((item, index) => {
                            return (
                                <div className="dashboard__filter__box__content__el">
                                    <input checked={timeFilter == index + 1} onChange={timeFilter == index + 1 ? () => setTimeFilter(0) : () => setTimeFilter(index + 1)} id={`2${index}`} type="checkbox" className="dashboard__filter__box__content__el__input" />
                                    <label htmlFor={`2${index}`} className="dashboard__filter__box__content__el__text">{item % 60 == 0 && item / 60 == 1 ? `1 ${t("hour")}` : (item % 60 == 0 ? `${item / 60} ${t("hours")}` : `${item} ${t("minutes")}`)}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="dashboard__filter__box">
                    <input onChange={handleFilter} checked={filtersStatus[2]} name="3" id="third" type="checkbox" className="dashboard__filter__box__checkbox" />
                    <label htmlFor="third" className="dashboard__filter__box__title">
                        <p className="dashboard__filter__box__title__text">{t('dashboard.service.location')}</p>
                    </label>
                    <div className="dashboard__filter__box__content">
                        <div className="dashboard__filter__box__content__el">
                            <input checked={locationFilter[0]} onChange={() => setLocationFilter([!locationFilter[0], false])} id="31" type="checkbox" className="dashboard__filter__box__content__el__input" />
                            <label htmlFor="31" className="dashboard__filter__box__content__el__text">{t('dashboard.service.online')}</label>
                        </div>
                        <div className="dashboard__filter__box__content__el">
                            <input checked={locationFilter[1]} onChange={() => setLocationFilter([false, !locationFilter[1]])} id="32" type="checkbox" className="dashboard__filter__box__content__el__input" />
                            <label htmlFor="32" className="dashboard__filter__box__content__el__text">{t('dashboard.service.offline')}</label>
                        </div>
                    </div>
                </div>
                <div className="dashboard__filter__box">
                    <input onChange={handleFilter} checked={filtersStatus[3]} name="4" id="fourth" type="checkbox" className="dashboard__filter__box__checkbox" />
                    <label htmlFor="fourth" className="dashboard__filter__box__title">
                        <p className="dashboard__filter__box__title__text">{t('dashboard.service.group')}</p>
                    </label>
                    <div className="dashboard__filter__box__content right">
                        {categories.map((item, index) => {
                            if (item.id != 1) {
                                return (
                                    <div className="dashboard__filter__box__content__el">
                                        <input checked={filterGroup == item.id} onChange={filterGroup == item.id ? () => setFilterGroup(0) : () => setFilterGroup(item.id)} id={`4${index}`} type="checkbox" className="dashboard__filter__box__content__el__input" />
                                        <label htmlFor={`4${index}`} className="dashboard__filter__box__content__el__text">{item[currentLanguage]}</label>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
            <div className={"dashboard__filter mobile" + (isFilterOpen ? ' close' : '')}>
                <div className="dashboard__filter__search-box">
                    <input value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} type="text" className="dashboard__filter__search-box__search" placeholder={t('dashboard.user.search')} />
                    <div className="dashboard__filter__search-box__img" />
                </div>
                <button onClick={handleOpenFilter} className="dashboard__filter__mobile-button">
                    <svg className="dashboard__filter__mobile-button__img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_593_22721)">
                            <path d="M9.33333 20H14.6667V17.3333H9.33333V20ZM0 4V6.66667H24V4H0ZM4 13.3333H20V10.6667H4V13.3333Z" fill="#BCB7B3" />
                        </g>
                        <defs>
                            <clipPath id="clip0_593_22721">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </button>
            </div>
            <div className={"dashboard__filter-page mobile" + (isFilterOpen ? ' open' : '')}>
                <div className="dashboard__filter-page__header">
                    <span className="dashboard__filter-page__header__title">{t('services.filter')}</span>
                    <button onClick={handleOpenFilter} className="dashboard__filter-page__header__button">
                        <svg className="dashboard__filter-page__header__button__img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#E4E3E2" />
                            <path d="M7.75827 7.75736L16.2435 16.2426M16.2435 7.75736L7.75827 16.2426" stroke="#FDFAFA" stroke-width="2" />
                        </svg>
                    </button>
                </div>
                <div className="dashboard__filter-page__box">
                    <div className="dashboard__filter-page__box__el">
                        <div className="dashboard__filter-page__box__el__title__box">
                            <p className="dashboard__filter-page__box__el__title">{t('dashboard.service.cost')}</p>
                        </div>
                        <div className="dashboard__filter-page__box__el__content numbers">
                            <div className="dashboard__filter-page__box__el__content__first">
                                {t("services.from")} <input value={lowPrice} onChange={(e) => setLowPrice(e.target.value)} type="number" placeholder="0" className="dashboard__filter-page__box__el__content__first__input" />
                            </div>
                            <div className="dashboard__filter-page__box__el__content__second">
                                {t("services.to")} <input value={highPrice} onChange={(e) => setHighPrice(e.target.value)} type="number" placeholder="1500" className="dashboard__filter-page__box__el__content__second__input" />
                            </div>
                        </div>
                    </div>
                    <div className="dashboard__filter-page__box__el">
                        <div className="dashboard__filter-page__box__el__title__box" htmlFor="first">
                            <p className="dashboard__filter-page__box__el__title">{t('dashboard.service.duration')}</p>
                        </div>
                        <div className="dashboard__filter-page__box__el__content">
                            {time.map((item, index) => {
                                return (
                                    <div className="dashboard__filter-page__box__el__content__el">
                                        <input checked={timeFilter == index + 1} onChange={timeFilter == index + 1 ? () => setTimeFilter(0) : () => setTimeFilter(index + 1)} id={`2${index}`} type="checkbox" className="dashboard__filter-page__box__el__content__el__input" />
                                        <label htmlFor={`2${index}`} className="dashboard__filter-page__box__el__content__el__text">{item % 60 == 0 && item / 60 == 1 ? `1 ${t("hour")}` : (item % 60 == 0 ? `${item / 60} ${t("hours")}` : `${item} ${t("minutes")}`)}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="dashboard__filter-page__box__el">
                        <div className="dashboard__filter-page__box__el__title__box" htmlFor="first">
                            <p className="dashboard__filter-page__box__el__title">{t('dashboard.service.location')}</p>
                        </div>
                        <div className="dashboard__filter-page__box__el__content">
                            <div className="dashboard__filter-page__box__el__content__el">
                                <input checked={locationFilter[0]} onChange={() => setLocationFilter([!locationFilter[0], false])} id="31" type="checkbox" className="dashboard__filter-page__box__el__content__el__input" />
                                <label htmlFor="31" className="dashboard__filter-page__box__el__content__el__text">{t('dashboard.service.online')}</label>
                            </div>
                            <div className="dashboard__filter-page__box__el__content__el">
                                <input checked={locationFilter[1]} onChange={() => setLocationFilter([false, !locationFilter[1]])} id="32" type="checkbox" className="dashboard__filter-page__box__el__content__el__input" />
                                <label htmlFor="32" className="dashboard__filter-page__box__el__content__el__text">{t('dashboard.service.offline')}</label>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard__filter-page__box__el">
                        <div className="dashboard__filter-page__box__el__title__box" htmlFor="first">
                            <p className="dashboard__filter-page__box__el__title">{t('dashboard.service.group')}</p>
                        </div>
                        <div className="dashboard__filter-page__box__el__content">
                            {categories.map((item, index) => {
                                if (item.id != 1) {
                                    return (
                                        <div className="dashboard__filter-page__box__el__content__el">
                                            <input checked={filterGroup == item.id} onChange={filterGroup == item.id ? () => setFilterGroup(0) : () => setFilterGroup(item.id)} id={`4${index}`} type="checkbox" className="dashboard__filter-page__box__el__content__el__input" />
                                            <label htmlFor={`4${index}`} className="dashboard__filter-page__box__el__content__el__text">{item[currentLanguage]}</label>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
                <button onClick={handleOpenFilter} className="dashboard__filter-page__button">{t("services.usefilter")}</button>
            </div>
            <div className="service__table desktop-dashboard">
                <div className="service__table__title">
                    <div className="service__table__title__el dashboard__title">{t('dashboard.service.title')}</div>
                    <div className="service__table__title__el dashboard__title">{t('dashboard.service.description')}</div>
                    <div className="service__table__title__el dashboard__title">{t('dashboard.service.cost')}</div>
                    <div className="service__table__title__el dashboard__title">{t('dashboard.service.duration')}</div>
                    <div className="service__table__title__el dashboard__title">{t('dashboard.service.location')}</div>
                    <div className="service__table__title__el dashboard__title">{t('dashboard.service.picture')}</div>
                    <div className="service__table__title__el dashboard__title">{t('dashboard.service.group')}</div>
                    <div className="service__table__title__el dashboard__title">{t('dashboard.service.plan')}</div>
                    <div className="service__table__title__el dashboard__title"></div>
                </div>
                {filter(services).map((item) => {
                    return (
                        <div onClick={(e) => handleOpenCreateModal(e, item.id, 'edit')} key={item.id} className="service__table__row">
                            <div className="service__table__el dashboard__text">{item.name[currentLanguage]}</div>
                            <div className="service__table__el dashboard__text big">{item.description[currentLanguage]}</div>
                            <div className="service__table__el dashboard__text">₾{item.price}</div>
                            <div className="service__table__el dashboard__text">{item.duration % 60 == 0 && item.duration / 60 == 1 ? `1 ${t("hour")}` : (item.duration % 60 == 0 ? `${item.duration / 60} ${t("hours")}` : `${item.duration} ${t("minutes")}`)}</div>
                            <div className="service__table__el dashboard__text">{item.location[currentLanguage]}</div>
                            <img src={item.image ? `https://lawchill.ge/imagesFromLawchill/${item.image}` : IMAGES.service} alt="service-img" className="service__table__img" />
                            <div className={"service__table__el dashboard__text" + ((item.category != null && item.category != 1) ? ('') : (' grey'))}>{(item.category != null && item.category != 1 && categories.length != 0) ? (categories.map(el => el.id).includes(item.category) ? categories.filter((el) => el.id == item.category)[0][currentLanguage] : (t("dashboard.nogroups"))) : (t("dashboard.nogroups"))}</div>
                            <div className={"service__table__el dashboard__text" + (item.subscriptions ? ('') : (' grey'))}>
                                {item.subscriptions ? (Object.entries(item.subscriptions).map((el, index) => {
                                    if (index == Object.keys(item.subscriptions).length - 1) {
                                        return (`${subscriptions.filter((elem) => elem.id == el[0])[0].name[currentLanguage]} (${el[1]})`)
                                    } else {
                                        return (`${subscriptions.filter((elem) => elem.id == el[0])[0].name[currentLanguage]} (${el[1]}), `)
                                    }
                                })) : (t('dashboard.noplan'))}
                            </div>
                            <svg onClick={() => handleDeleteService(item.id)} className="service__table__el__img delete" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10.5" r="10" fill="#BCB7B3" />
                                <path d="M5.75827 6.25736L14.2435 14.7426M14.2435 6.25736L5.75827 14.7426" stroke="#FDFAFA" stroke-width="2" />
                            </svg>
                        </div>
                    )
                })}

            </div>
            <div className={"dashboard__table mobile" + (isFilterOpen ? ' close' : '')}>
                {filter(services).map((item) => {
                    return (
                        <div className="dashboard__table__mobile-row">
                            <div className="dashboard__table__mobile-row__title">
                                <div className="dashboard__table__mobile-row__title__left">
                                    <svg className="dashboard__table__mobile-row__title__left__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.16699 6.33366H10.8337V8.00033H9.16699V6.33366ZM9.16699 9.66699H10.8337V14.667H9.16699V9.66699ZM10.0003 2.16699C5.40033 2.16699 1.66699 5.90033 1.66699 10.5003C1.66699 15.1003 5.40033 18.8337 10.0003 18.8337C14.6003 18.8337 18.3337 15.1003 18.3337 10.5003C18.3337 5.90033 14.6003 2.16699 10.0003 2.16699ZM10.0003 17.167C6.32533 17.167 3.33366 14.1753 3.33366 10.5003C3.33366 6.82533 6.32533 3.83366 10.0003 3.83366C13.6753 3.83366 16.667 6.82533 16.667 10.5003C16.667 14.1753 13.6753 17.167 10.0003 17.167Z" fill="#BCB7B3" />
                                    </svg>
                                    <div className="dashboard__table__mobile-row__title__left__text">
                                        <span className="dashboard__table__mobile-row__title__left__text__title">{item.name[currentLanguage]}</span>
                                        <span className="dashboard__table__mobile-row__title__left__text__value">{item.duration % 60 == 0 && item.duration / 60 == 1 ? `1 ${t("hour")}` : (item.duration % 60 == 0 ? `${item.duration / 60} ${t("hours")}` : `${item.duration} ${t("minutes")}`)}</span>
                                    </div>
                                </div>
                                <div className="dashboard__table__mobile-row__title__right">
                                    <svg onClick={() => handleDeleteService(item.id)} className="service__table__el__img delete" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10.5" r="10" fill="#BCB7B3" />
                                        <path d="M5.75827 6.25736L14.2435 14.7426M14.2435 6.25736L5.75827 14.7426" stroke="#FDFAFA" stroke-width="2" />
                                    </svg>
                                </div>
                            </div>
                            <div className="dashboard__table__mobile-row__content">
                                <div className="dashboard__table__mobile-row__content__el">
                                    <svg className="dashboard__table__mobile-row__content__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0003 1.66699C5.40033 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.40033 18.3337 10.0003 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 10.0003 1.66699ZM10.0003 16.667C6.32533 16.667 3.33366 13.6753 3.33366 10.0003C3.33366 6.32533 6.32533 3.33366 10.0003 3.33366C13.6753 3.33366 16.667 6.32533 16.667 10.0003C16.667 13.6753 13.6753 16.667 10.0003 16.667ZM10.2587 9.28366C8.78366 8.90866 8.30866 8.50033 8.30866 7.89199C8.30866 7.19199 8.96699 6.70033 10.0587 6.70033C11.2087 6.70033 11.642 7.25033 11.6753 8.06699H13.1003C13.0587 6.95033 12.3753 5.92533 11.0253 5.59199V4.16699H9.08366V5.57533C7.82533 5.84199 6.81699 6.65866 6.81699 7.91699C6.81699 9.40866 8.05866 10.1587 9.86699 10.592C11.492 10.9753 11.817 11.5503 11.817 12.1503C11.817 12.592 11.492 13.3087 10.067 13.3087C8.73366 13.3087 8.20866 12.7087 8.13366 11.942H6.70033C6.78366 13.3587 7.83366 14.1587 9.08366 14.417V15.8337H11.0337V14.442C12.3003 14.2003 13.3003 13.4753 13.3087 12.1337C13.3003 10.3003 11.7253 9.66699 10.2587 9.28366V9.28366Z" fill="#BCB7B3" />
                                    </svg>
                                    <p className="dashboard__table__mobile-row__content__el__text">₾{item.price}</p>
                                </div>
                                <div className="dashboard__table__mobile-row__content__el">
                                    <svg className="dashboard__table__mobile-row__content__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0003 1.66699C6.77533 1.66699 4.16699 4.27533 4.16699 7.50033C4.16699 11.8753 10.0003 18.3337 10.0003 18.3337C10.0003 18.3337 15.8337 11.8753 15.8337 7.50033C15.8337 4.27533 13.2253 1.66699 10.0003 1.66699ZM5.83366 7.50033C5.83366 5.20033 7.70033 3.33366 10.0003 3.33366C12.3003 3.33366 14.167 5.20033 14.167 7.50033C14.167 9.90033 11.767 13.492 10.0003 15.7337C8.26699 13.5087 5.83366 9.87533 5.83366 7.50033Z" fill="#BCB7B3" />
                                        <path d="M10.0003 9.58366C11.1509 9.58366 12.0837 8.65092 12.0837 7.50033C12.0837 6.34973 11.1509 5.41699 10.0003 5.41699C8.84973 5.41699 7.91699 6.34973 7.91699 7.50033C7.91699 8.65092 8.84973 9.58366 10.0003 9.58366Z" fill="#BCB7B3" />
                                    </svg>
                                    <p className="dashboard__table__mobile-row__content__el__text">{item.location[currentLanguage]}</p>
                                </div>
                                <div className="dashboard__table__mobile-row__content__el">
                                    <svg className="dashboard__table__mobile-row__content__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.7497 4.99967V14.583C13.7497 16.4247 12.258 17.9163 10.4163 17.9163C8.57467 17.9163 7.08301 16.4247 7.08301 14.583V4.16634C7.08301 3.01634 8.01634 2.08301 9.16634 2.08301C10.3163 2.08301 11.2497 3.01634 11.2497 4.16634V12.9163C11.2497 13.3747 10.8747 13.7497 10.4163 13.7497C9.95801 13.7497 9.58301 13.3747 9.58301 12.9163V4.99967H8.33301V12.9163C8.33301 14.0663 9.26634 14.9997 10.4163 14.9997C11.5663 14.9997 12.4997 14.0663 12.4997 12.9163V4.16634C12.4997 2.32467 11.008 0.833008 9.16634 0.833008C7.32467 0.833008 5.83301 2.32467 5.83301 4.16634V14.583C5.83301 17.1163 7.88301 19.1663 10.4163 19.1663C12.9497 19.1663 14.9997 17.1163 14.9997 14.583V4.99967H13.7497Z" fill="#BCB7B3" />
                                    </svg>
                                    <p className="dashboard__table__mobile-row__content__el__text">{item.image ? item.image : '-'}</p>
                                </div>
                                <div className="dashboard__table__mobile-row__content__el">
                                    <svg className="dashboard__table__mobile-row__content__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0003 1.66699C5.40033 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.40033 18.3337 10.0003 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 10.0003 1.66699ZM10.0003 16.667C6.32533 16.667 3.33366 13.6753 3.33366 10.0003C3.33366 6.32533 6.32533 3.33366 10.0003 3.33366C13.6753 3.33366 16.667 6.32533 16.667 10.0003C16.667 13.6753 13.6753 16.667 10.0003 16.667Z" fill="#BCB7B3" />
                                        <path d="M6.66667 13.3333C7.58714 13.3333 8.33333 12.5871 8.33333 11.6667C8.33333 10.7462 7.58714 10 6.66667 10C5.74619 10 5 10.7462 5 11.6667C5 12.5871 5.74619 13.3333 6.66667 13.3333Z" fill="#BCB7B3" />
                                        <path d="M9.99967 8.33333C10.9201 8.33333 11.6663 7.58714 11.6663 6.66667C11.6663 5.74619 10.9201 5 9.99967 5C9.0792 5 8.33301 5.74619 8.33301 6.66667C8.33301 7.58714 9.0792 8.33333 9.99967 8.33333Z" fill="#BCB7B3" />
                                        <path d="M13.3337 13.3333C14.2541 13.3333 15.0003 12.5871 15.0003 11.6667C15.0003 10.7462 14.2541 10 13.3337 10C12.4132 10 11.667 10.7462 11.667 11.6667C11.667 12.5871 12.4132 13.3333 13.3337 13.3333Z" fill="#BCB7B3" />
                                    </svg>
                                    <p className="dashboard__table__mobile-row__content__el__text">{(item.category != null && item.category != 1 && categories.length != 0) ? ( categories.map(el => el.id).includes(item.category) ? categories.filter((el) => el.id == item.category)[0][currentLanguage] : (t("dashboard.nogroups"))) : (t("dashboard.nogroups"))}</p>
                                    
                                </div>
                                <div className="dashboard__table__mobile-row__content__el">
                                    <svg className="dashboard__table__mobile-row__content__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.6917 4.86699C14.3917 4.44199 13.8917 4.16699 13.3333 4.16699L4.16667 4.17533C3.25 4.17533 2.5 4.91699 2.5 5.83366V14.167C2.5 15.0837 3.25 15.8253 4.16667 15.8253L13.3333 15.8337C13.8917 15.8337 14.3917 15.5587 14.6917 15.1337L18.3333 10.0003L14.6917 4.86699ZM13.3333 14.167H4.16667V5.83366H13.3333L16.2917 10.0003L13.3333 14.167Z" fill="#BCB7B3" />
                                    </svg>
                                    <p className="dashboard__table__mobile-row__content__el__text">{item.description[currentLanguage]}</p>
                                </div>
                                <div className="dashboard__table__mobile-row__content__el">
                                    <svg className="dashboard__table__mobile-row__content__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.49967 3.33301C3.81634 3.33301 0.833008 6.31634 0.833008 9.99967C0.833008 13.683 3.81634 16.6663 7.49967 16.6663C11.183 16.6663 14.1663 13.683 14.1663 9.99967C14.1663 6.31634 11.183 3.33301 7.49967 3.33301ZM7.49967 14.9997C4.74134 14.9997 2.49967 12.758 2.49967 9.99967C2.49967 7.24134 4.74134 4.99967 7.49967 4.99967C10.258 4.99967 12.4997 7.24134 12.4997 9.99967C12.4997 12.758 10.258 14.9997 7.49967 14.9997ZM14.1663 3.54967V5.29134C16.108 5.97467 17.4997 7.82467 17.4997 9.99967C17.4997 12.1747 16.108 14.0247 14.1663 14.708V16.4497C17.0413 15.708 19.1663 13.108 19.1663 9.99967C19.1663 6.89134 17.0413 4.29134 14.1663 3.54967Z" fill="#BCB7B3" />
                                    </svg>
                                    <p className="dashboard__table__mobile-row__content__el__text">
                                        {item.subscriptions ? (Object.entries(item.subscriptions).map((el, index) => {
                                            if (index == Object.keys(item.subscriptions).length - 1) {
                                                return (`${subscriptions.filter((elem) => elem.id == el[0])[0].name[currentLanguage]} (${el[1]})`)
                                            } else {
                                                return (`${subscriptions.filter((elem) => elem.id == el[0])[0].name[currentLanguage]} (${el[1]}), `)
                                            }
                                        })) : (t('dashboard.noplan'))}
                                    </p>
                                </div>
                                <button onClick={(e) => handleOpenCreateModal(e, item.id, 'edit')} className="dashboard__table__mobile-row__content__el button">
                                    <svg className="dashboard__table__mobile-row__content__el__img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="12" fill="#BCB7B3" />
                                        <g clip-path="url(#clip0_2663_5499)">
                                            <mask id="path-2-inside-1_2663_5499" fill="white">
                                                <path d="M4.7998 19.1997H7.79934L16.646 10.3531L13.6464 7.35352L4.7998 16.2002V19.1997ZM6.39956 16.864L13.6464 9.61717L14.3823 10.3531L7.13544 17.5999H6.39956V16.864Z" />
                                            </mask>
                                            <path d="M4.7998 19.1997H7.79934L16.646 10.3531L13.6464 7.35352L4.7998 16.2002V19.1997ZM6.39956 16.864L13.6464 9.61717L14.3823 10.3531L7.13544 17.5999H6.39956V16.864Z" fill="#FDFAFA" />
                                            <path d="M4.7998 19.1997H2.13314V21.8664H4.7998V19.1997ZM7.79934 19.1997V21.8664H8.90391L9.68496 21.0853L7.79934 19.1997ZM16.646 10.3531L18.5316 12.2387L20.4172 10.3531L18.5316 8.46744L16.646 10.3531ZM13.6464 7.35352L15.5321 5.4679L13.6464 3.58228L11.7608 5.4679L13.6464 7.35352ZM4.7998 16.2002L2.91419 14.3145L2.13314 15.0956V16.2002H4.7998ZM6.39956 16.864L4.51394 14.9784L3.73289 15.7595V16.864H6.39956ZM13.6464 9.61717L15.5321 7.73155L13.6464 5.84593L11.7608 7.73155L13.6464 9.61717ZM14.3823 10.3531L16.2679 12.2387L18.1536 10.3531L16.2679 8.46743L14.3823 10.3531ZM7.13544 17.5999V20.2666H8.24001L9.02106 19.4856L7.13544 17.5999ZM6.39956 17.5999H3.73289V20.2666H6.39956V17.5999ZM4.7998 21.8664H7.79934V16.533H4.7998V21.8664ZM9.68496 21.0853L18.5316 12.2387L14.7604 8.46743L5.91372 17.3141L9.68496 21.0853ZM18.5316 8.46744L15.5321 5.4679L11.7608 9.23913L14.7604 12.2387L18.5316 8.46744ZM11.7608 5.4679L2.91419 14.3145L6.68542 18.0858L15.5321 9.23913L11.7608 5.4679ZM2.13314 16.2002V19.1997H7.46647V16.2002H2.13314ZM8.28518 18.7497L15.5321 11.5028L11.7608 7.73155L4.51394 14.9784L8.28518 18.7497ZM11.7608 11.5028L12.4967 12.2387L16.2679 8.46743L15.5321 7.73155L11.7608 11.5028ZM12.4967 8.46743L5.24983 15.7143L9.02106 19.4856L16.2679 12.2387L12.4967 8.46743ZM7.13544 14.9333H6.39956V20.2666H7.13544V14.9333ZM9.06622 17.5999V16.864H3.73289V17.5999H9.06622Z" fill="#FDFAFA" mask="url(#path-2-inside-1_2663_5499)" />
                                            <path d="M17.0936 5.03377C16.7816 4.72182 16.2777 4.72182 15.9657 5.03377L14.502 6.49754L17.5015 9.49708L18.9653 8.03331C19.2772 7.72135 19.2772 7.21743 18.9653 6.90548L17.0936 5.03377Z" fill="#FDFAFA" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2663_5499">
                                                <rect width="14.4" height="14.4" fill="white" transform="translate(4.7998 4.7998)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p className="dashboard__table__mobile-row__content__el__text button">{t('edit')}</p>
                                </button>
                            </div>
                        </div>
                    )
                })}
            </div>
            <button onClick={(e) => handleOpenCreateModal(e, Math.random() * 1000, 'add')} className={"dashboard__add-button" + (isFilterOpen ? ' close' : '')}>{t('dashboard.service.button')}</button>
            <PopupDelete page={'service'} isOpen={deleteModal} handler={handleOpenDeleteModal} id={selectedGroupId} action={handleDeleteServiceAction} />
            <PopupCreate page={'service'} setSelectedGroupId={setSelectedGroupId} isOpen={createModal} openDoneModal={openDoneModal} handler={openCreateModal} id={selectedGroupId} action={actionCreate} handlerOpenDelete={handleOpenDeleteModal} />
            <PopupDone page={'service'} isOpen={doneModal} openEditModal={openCreateModal} handler={openDoneModal} id={selectedGroupId} action={actionCreate} />
        </div>
    )
}