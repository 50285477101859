import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

export const PopupDropdown = ({ add = '', id, setId, content, ids }) => {
    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleChangeValue = (e) => {
        setId(e.target.id);
    }

    const handleOpen = () => {
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                document.addEventListener("click", handleClose);
            }, 0);
        } else {
            document.removeEventListener("click", handleClose);
        }
        return () => {
            document.removeEventListener("click", handleClose);
        };
    }, [isOpen]);

    return (
        <div className={"querie__table__el delete dropdown" + add}>
            <button className={"querie__table__el__status dashboard__text delete " + (isOpen ? 'open' : '')} onClick={handleOpen}>
                {content[ids.indexOf(parseInt(id))]}
            </button>
            <div className="querie__table__el__status__content delete">
                {content.map((item, index) => {
                    return (
                        <div id={ids[index]} data-value={item} onClick={handleChangeValue} className="querie__table__el__status__content__el delete">
                            {item}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}