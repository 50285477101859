import { ADD_QUESTION_CATION_ACTION, SET_QUESTIONS_CATION_ACTION } from './constants.js';

export const addQuestionCationAction = (payload) => ({
    type: ADD_QUESTION_CATION_ACTION,
    payload,
});

export const setQuestionsCationAction = (payload) => ({
    type: SET_QUESTIONS_CATION_ACTION,
    payload,
});




