import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTS } from "../Constants/Routes";
import IMAGES from "./../images/FAQ/images";
import { useState } from "react";
import { useEffect } from "react";

export const FAQ = () => {
    const { t, i18n } = useTranslation();

    const [actived, setActived] = useState('');

    useEffect(() => {
        setActived('actived');
    }, []);

    const Navigate = useNavigate();

    const navigateToContact = () => {
        Navigate(ROUTS.HOME + ROUTS.CONTACTS);
    }
    
    const slideToggle = (e, duration = 500) => {
        const parent = e.target.closest('.faq__second-block__box__button');
        if (!parent.classList.contains('animating')) {
            const target = parent.querySelector('.faq__second-block__box__button__open');
            const arrow = parent.querySelector('.faq__second-block__box__button__img');

            arrow.classList.toggle('open');

            if (window.getComputedStyle(target).display === 'none') {
                slideDown(target, parent, duration);
            } else {
                slideUp(target, parent, duration);
            }


            parent.classList.add('animating');

            setTimeout(() => {
                parent.classList.remove('animating');
            }, duration);
        }
    }

    const slideUp = (target, parent, duration) => {
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.boxSizing = 'border-box';
        target.style.height = target.offsetHeight + 'px';
        parent.style.overflow = 'hidden';
        parent.style.transitionProperty = 'height, margin, padding';
        parent.style.transitionDuration = duration + 'ms';
        parent.style.boxSizing = 'border-box';
        parent.style.height = parent.offsetHeight + 'px';
        parent.style.height = parent.offsetHeight - target.offsetHeight + 'px';
        target.style.overflow = 'hidden';
        target.style.height = '0';
        target.style.paddingTop = '0';
        target.style.paddingBottom = '0';
        target.style.marginTop = '0';
        target.style.marginBottom = '0';

        window.setTimeout(() => {
            target.style.display = 'none';
            parent.style.removeProperty('height');
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            parent.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
            parent.style.removeProperty('transition-duration');
            parent.style.removeProperty('transition-property');
        }, duration);
    }

    const slideDown = (target, parent, duration) => {
        target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;
        let parentHeight = parent.offsetHeight;
        parent.style.height = parentHeight + 'px';
        if (display === 'none') display = 'block';
        target.style.display = display;
        let height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = '0';
        target.style.paddingTop = '0';
        target.style.paddingBottom = '0';
        target.style.marginTop = '0';
        target.style.marginBottom = '0';
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = "height, margin, padding";
        target.style.transitionDuration = duration + 'ms';
        parent.style.overflow = 'hidden';
        parent.style.transitionProperty = 'height, margin, padding';
        parent.style.transitionDuration = duration + 'ms';
        parent.style.boxSizing = 'border-box';
        parent.style.height = parentHeight + height + 'px';
        target.style.height = height + 'px';
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');

        window.setTimeout(() => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
            parent.style.removeProperty('height');
            parent.style.removeProperty('overflow');
            parent.style.removeProperty('transition-duration');
            parent.style.removeProperty('transition-property');
        }, duration);
    }

    return (
        <div className="faq">
            <div className="faq__first-block">
                <div className="faq__first-block__wrapper">
                    <div className="faq__first-block__box">
                        <p className="faq__first-block__box__text desktop">{t("faq.title1")}<br />
                            {t("faq.title2")}</p>
                        <p className="faq__first-block__box__text mobile first">{t("faq.title1")}</p>
                        <p className="faq__first-block__box__text mobile">{t("faq.title2")}</p>
                        <svg className="faq__first-block__box__img" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="60" cy="60" r="59" stroke="#FDFAFA" stroke-width="2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M74.1418 68.6863L61.4138 81.4142C60.6328 82.1953 59.3665 82.1953 58.5854 81.4142L45.8575 68.6863C45.0764 67.9052 45.0764 66.6389 45.8575 65.8579C46.6385 65.0768 47.9049 65.0768 48.6859 65.8579L57.9996 75.1716V40H61.9996V75.1716L71.3133 65.8579C72.0944 65.0768 73.3607 65.0768 74.1418 65.8579C74.9228 66.6389 74.9228 67.9052 74.1418 68.6863Z" fill="#FDFAFA" />
                        </svg>

                    </div>
                    <img src={IMAGES.bgFirstPage} alt="box" className={`faq__first-block__bg ${actived}`} />
                    <div className={`about__promo__shield faq__promo__bg ${actived}`}></div>
                </div>
            </div>
            <div className="faq__second-block">
                <div className="faq__second-block__box">
                    <h1 className="faq__second-block__box__title">FAQ</h1>
                    <button className="faq__second-block__box__button" onClick={(e) => slideToggle(e)}>
                        <div className="faq__second-block__box__button__content">
                            <p className="faq__second-block__box__button__text">{t("faq.question1")}</p>
                            <svg className={"faq__second-block__box__button__img"} width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="45" cy="45" r="45" fill="#0B0A0A" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M50.7647 35.1425L60.3107 44.6884C60.8964 45.2742 60.8964 46.2239 60.3107 46.8097L50.7647 56.3557C50.1789 56.9415 49.2292 56.9415 48.6434 56.3557C48.0576 55.7699 48.0576 54.8201 48.6434 54.2343L55.6287 47.2491H29.25V44.2491H55.6287L48.6434 37.2638C48.0576 36.678 48.0576 35.7283 48.6434 35.1425C49.2292 34.5567 50.1789 34.5567 50.7647 35.1425Z" fill="#FDFAFA" />
                            </svg>
                        </div>
                        <div className={"faq__second-block__box__button__open"}>
                            <p className="faq__second-block__box__button__open__el">1. {t("faq.answer11")}</p>
                            <p className="faq__second-block__box__button__open__el">2. {t("faq.answer12")}</p>
                            <p className="faq__second-block__box__button__open__el">3. {t("faq.answer13")}</p>
                        </div>
                    </button>
                    <button className="faq__second-block__box__button" onClick={(e) => slideToggle(e)}>
                        <div className="faq__second-block__box__button__content">
                            <p className="faq__second-block__box__button__text">{t("faq.question2")}</p>
                            <svg className={"faq__second-block__box__button__img "} width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="45" cy="45" r="45" fill="#0B0A0A" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M50.7647 35.1425L60.3107 44.6884C60.8964 45.2742 60.8964 46.2239 60.3107 46.8097L50.7647 56.3557C50.1789 56.9415 49.2292 56.9415 48.6434 56.3557C48.0576 55.7699 48.0576 54.8201 48.6434 54.2343L55.6287 47.2491H29.25V44.2491H55.6287L48.6434 37.2638C48.0576 36.678 48.0576 35.7283 48.6434 35.1425C49.2292 34.5567 50.1789 34.5567 50.7647 35.1425Z" fill="#FDFAFA" />
                            </svg>
                        </div>
                        <div className={"faq__second-block__box__button__open"}>
                            <p className="faq__second-block__box__button__open__el">1. {t("faq.answer21")}</p>
                            <p className="faq__second-block__box__button__open__el">2. {t("faq.answer22")}</p>
                        </div>
                    </button>
                </div>
            </div>
            <div className="faq__third-block">
                <div className="faq__third-block__wrapper">
                    <div className="faq__third-block__box">
                        <div className="faq__third-block__box__left">
                            <p className="faq__third-block__box__left__text">{t("faq.footertitle1")}<br />
                                {t("faq.footertitle2")}</p>
                            <button onClick={navigateToContact} className="faq__third-block__box__left__button">
                                <svg className="faq__third-block__box__left__button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20" cy="20" r="20" fill="#0B0A0A" />
                                    <g clip-path="url(#clip0_844_663)">
                                        <path d="M10 30V11.93C10 11.4154 10.189 10.965 10.5669 10.579C10.9449 10.193 11.3912 10 11.9059 10H28.07C28.5846 10 29.035 10.193 29.421 10.579C29.807 10.965 30 11.4154 30 11.93V24.234C30 24.7326 29.807 25.1749 29.421 25.5609C29.035 25.9469 28.5846 26.1399 28.07 26.1399H13.8601L10 30ZM11.9059 25.6815L13.3534 24.234H28.07V11.93H11.9059V25.6815ZM11.9059 11.93V25.6815V11.93Z" fill="#FDFAFA" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_844_663">
                                            <rect width="20" height="20" fill="white" transform="translate(10 10)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <p className="faq__third-block__box__left__button__text">{t("faq.footerbutton")}</p>
                            </button>
                            <svg className="mobile-line top" width="182" height="1" viewBox="0 0 182 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="0.988281" y1="0.5" x2="190.012" y2="0.5" stroke="#FDFAFA" />
                            </svg>
                        </div>
                        <img src={IMAGES.QR} alt="QR" className="faq__third-block__box__right" />
                        <svg className="mobile-line bottom" width="182" height="1" viewBox="0 0 182 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="0.988281" y1="0.5" x2="190.012" y2="0.5" stroke="#FDFAFA" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}