import { useState } from "react";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../../images/dashboard/images";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { servicesInfoSelector } from "../../../../Store/Services/selectors";
import { addServiceCationAction, addServicesImgCationAction, editServiceCationAction } from "../../../../Store/Services/actions";
import { PopupDropdown } from "../PopupDropdown";
import { categoryInfoSelector } from "../../../../Store/Categories/selectors";
import { DropdownForServices } from "../../DropdownForServices";
import axios from "axios";

export const ServicesInput = ({ setSelectedGroupId, trigger, language, action, id }) => {
    const { t, i18n } = useTranslation();

    const service = useSelector(servicesInfoSelector).filter((item) => item.id == id)[0];
    const categories = useSelector(categoryInfoSelector);

    const [titleKa, setTitleKa] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [titleUk, setTitleUk] = useState('');

    const [time, setTime] = useState('');

    const [price, setPrice] = useState('');

    const [locationKa, setLocationKa] = useState('');
    const [locationEn, setLocationEn] = useState('');
    const [locationUk, setLocationUk] = useState('');

    const [descriptionKa, setDescriptionKa] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionUk, setDescriptionUk] = useState('');

    const [groupId, setGroupId] = useState(1);

    const [subscriptionsData, setSubscriptionsData] = useState({});

    const [tempSubInfo, setTempSubInfo] = useState([]);

    const [photo, setPhoto] = useState(null);
    const [newPhoto, setNewPhoto] = useState(null);

    const [photoBg, setPhotoBg] = useState(null);


    useEffect(() => {
        if (service) {
            setTitleKa(service.name.ka);
            setTitleEn(service.name.en);
            setTitleUk(service.name.uk);

            setLocationKa(service.location.ka);
            setLocationEn(service.location.en);
            setLocationUk(service.location.uk);
            
            setDescriptionKa(service.description.ka);
            setDescriptionEn(service.description.en);
            setDescriptionUk(service.description.uk);

            setPrice(service.price);
            setTime(service.duration);
            setGroupId(service.category ? service.category : 1);
            setSubscriptionsData({ ...service.subscriptions });
            setPhoto(service.image);
            setPhotoBg(null);
        } else {
            setTitleKa("");
            setTitleEn("");
            setTitleUk("");

            setLocationKa("");
            setLocationEn("");
            setLocationUk("");

            setDescriptionKa("");
            setDescriptionEn("");
            setDescriptionUk("");

            setPrice("");
            setTime("");
            setGroupId(1);
            setSubscriptionsData({});
            setTempSubInfo([]);
            setPhoto(null);
            setPhotoBg(null);
        }
    }, [service]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (trigger) {
            let subInfo = {};
            if(tempSubInfo[0] && tempSubInfo[0] > 0) subInfo['1'] = `${tempSubInfo[0]}`;
            if(tempSubInfo[1] && tempSubInfo[1] > 0) subInfo['2'] = `${tempSubInfo[1]}`;
            if(tempSubInfo[2] && tempSubInfo[2] > 0) subInfo['3'] = `${tempSubInfo[2]}`;
            let data;
            if (Object.keys(subInfo).length != 0) {
                data = {
                    "category": `${groupId}`,
                    "name": {
                        'ka': `${titleKa}`,
                        'en': `${titleEn}`,
                        "uk": `${titleUk}`
                    },
                    "description": {
                        "ka": `${descriptionKa}`,
                        "en": `${descriptionEn}`,
                        "uk": `${descriptionUk}`
                    },
                    "price": `${price}`,
                    "duration": `${time}`,
                    "location": {
                        "ka": `${locationKa}`,
                        "en": `${locationEn}`,
                        "uk": `${locationUk}`
                    },
                    "subscriptions": {
                        ...subInfo
                    }
                }
            } else {
                data = {
                    "category": `${groupId}`,
                    "name": {
                        'ka': `${titleKa}`,
                        'en': `${titleEn}`,
                        'uk': `${titleUk}`
                    },
                    "description": {
                        "ka": `${descriptionKa}`,
                        "en": `${descriptionEn}`,
                        "uk": `${descriptionUk}`
                    },
                    "price": `${price}`,
                    "duration": `${time}`,
                    "location": {
                        "ka": `${locationKa}`,
                        "en": `${locationEn}`,
                        "uk": `${locationUk}`
                    }
                }
            }

            console.log(data)

            if (action == 'edit') {
                axios({
                    method: 'post',
                    url: 'https://api.lawchill.ge/editService',
                    headers: {
                        'Content-Type': 'application/json',
                        // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                    },
                    data: {
                        id: `${id}`,
                        data: data
                    },
                    withCredentials: true,
                }).then((res) => {
                    console.log(res)
                    dispatch(editServiceCationAction({ service: { ...data, id: id } }));
                }).catch((err) => {
                    console.log(err)
                });
                if (newPhoto != null) {
                    const formData = new FormData();
                    formData.append('preview', newPhoto);
                    formData.append('id', id);

                    axios({
                        method: 'post',
                        url: "https://api.lawchill.ge/editService",
                        headers: {
                            'Content-Type': "multipart/form-data",
                            // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                        },
                        data: formData,
                        withCredentials: true,
                    }).then((res) => {
                        console.log(res)
                        dispatch(addServicesImgCationAction({ service: { img: res.data.image, id: id } }));
                    }).catch((err) => {
                        console.log(err)
                    });
                }
            } else if (action == 'add') {
                let newId;
                axios({
                    method: 'post',
                    url: 'https://api.lawchill.ge/createService',
                    headers: {
                        'Content-Type': 'application/json',
                        // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                    },
                    data: {
                        data: data
                    },
                    withCredentials: true,
                }).then((res) => {
                    console.log(res)
                    setSelectedGroupId(res.data.insertId);
                    dispatch(addServiceCationAction({ service: { ...data, id: res.data.insertId } }));
                    newId = res.data.insertId;
                }).then(() => {

                    if (newPhoto != null) {
                        const formData = new FormData();
                        formData.append('preview', newPhoto);
                        formData.append('id', newId);

                        axios({
                            method: 'post',
                            url: "https://api.lawchill.ge/editService",
                            headers: {
                                'Content-Type': "multipart/form-data",
                                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                            },
                            data: formData,
                            withCredentials: true,
                        }).then((res) => {
                            console.log({ img: res.data.image, id: newId })
                            dispatch(addServicesImgCationAction({ service: { img: res.data.image, id: newId } }));
                        }).catch((err) => {
                            console.log(err)
                        });
                    }
                }).catch((err) => {
                    console.log(err)
                });

            }
        }
    }, [trigger]);

    const handleChangePhoto = (e) => {
        setNewPhoto(e.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
            setPhotoBg(reader.result);
        }
    }

    const handleDragOverFiles = (e) => {
        e.preventDefault();
    }

    const handleDropFiles = (e) => {
        e.preventDefault();
        setNewPhoto(e.dataTransfer.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(e.dataTransfer.files[0]);
        reader.onloadend = () => {
            setPhotoBg(reader.result);
        }
    }

    return (
        <div className="dashboard__popup__inputs">
            {language == 'en' ?
                (<>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                        </svg>
                        <input value={titleEn} onChange={(e) => setTitleEn(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.service.title')} />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 1.33301H7.5V2.99967H12.5V1.33301ZM9.16667 12.1663H10.8333V7.16634H9.16667V12.1663ZM15.8583 6.65801L17.0417 5.47467C16.6833 5.04967 16.2917 4.64967 15.8667 4.29967L14.6833 5.48301C13.3917 4.44967 11.7667 3.83301 10 3.83301C5.85833 3.83301 2.5 7.19134 2.5 11.333C2.5 15.4747 5.85 18.833 10 18.833C14.15 18.833 17.5 15.4747 17.5 11.333C17.5 9.56634 16.8833 7.94134 15.8583 6.65801ZM10 17.1663C6.775 17.1663 4.16667 14.558 4.16667 11.333C4.16667 8.10801 6.775 5.49967 10 5.49967C13.225 5.49967 15.8333 8.10801 15.8333 11.333C15.8333 14.558 13.225 17.1663 10 17.1663Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container">
                            <input style={{ width: `${time.toString().length > 0 ? time.toString().length * 1 : 1}rem`, marginRight: '0.35rem' }} value={time} onChange={(e) => setTime(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder="0" />
                            <span className="dashboard__popup__inputs__el__input">{t('dashboard.popup.service.time')}</span>
                        </div>
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 2.16699C5.39935 2.16699 1.66602 5.90033 1.66602 10.5003C1.66602 15.1003 5.39935 18.8337 9.99935 18.8337C14.5993 18.8337 18.3327 15.1003 18.3327 10.5003C18.3327 5.90033 14.5993 2.16699 9.99935 2.16699ZM9.99935 17.167C6.32435 17.167 3.33268 14.1753 3.33268 10.5003C3.33268 6.82533 6.32435 3.83366 9.99935 3.83366C13.6743 3.83366 16.666 6.82533 16.666 10.5003C16.666 14.1753 13.6743 17.167 9.99935 17.167ZM10.2577 9.78366C8.78268 9.40866 8.30768 9.00033 8.30768 8.39199C8.30768 7.69199 8.96602 7.20033 10.0577 7.20033C11.2077 7.20033 11.641 7.75033 11.6743 8.56699H13.0993C13.0577 7.45033 12.3743 6.42533 11.0243 6.09199V4.66699H9.08268V6.07533C7.82435 6.34199 6.81602 7.15866 6.81602 8.41699C6.81602 9.90866 8.05768 10.6587 9.86602 11.092C11.491 11.4753 11.816 12.0503 11.816 12.6503C11.816 13.092 11.491 13.8087 10.066 13.8087C8.73268 13.8087 8.20768 13.2087 8.13268 12.442H6.69935C6.78268 13.8587 7.83268 14.6587 9.08268 14.917V16.3337H11.0327V14.942C12.2993 14.7003 13.2993 13.9753 13.3077 12.6337C13.2993 10.8003 11.7243 10.167 10.2577 9.78366Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container price">
                            ₾
                            <input value={price} onChange={(e) => setPrice(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder="0" />
                        </div>
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 1.66699C6.77435 1.66699 4.16602 4.27533 4.16602 7.50033C4.16602 11.8753 9.99935 18.3337 9.99935 18.3337C9.99935 18.3337 15.8327 11.8753 15.8327 7.50033C15.8327 4.27533 13.2243 1.66699 9.99935 1.66699ZM5.83268 7.50033C5.83268 5.20033 7.69935 3.33366 9.99935 3.33366C12.2993 3.33366 14.166 5.20033 14.166 7.50033C14.166 9.90033 11.766 13.492 9.99935 15.7337C8.26602 13.5087 5.83268 9.87533 5.83268 7.50033Z" fill="#56514D" />
                            <path d="M9.99935 9.58366C11.1499 9.58366 12.0827 8.65092 12.0827 7.50033C12.0827 6.34973 11.1499 5.41699 9.99935 5.41699C8.84876 5.41699 7.91602 6.34973 7.91602 7.50033C7.91602 8.65092 8.84876 9.58366 9.99935 9.58366Z" fill="#56514D" />
                        </svg>
                        <input value={locationEn} onChange={(e) => setLocationEn(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.service.location')} />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6917 5.3665C14.3917 4.9415 13.8917 4.6665 13.3333 4.6665L4.16667 4.67484C3.25 4.67484 2.5 5.4165 2.5 6.33317V14.6665C2.5 15.5832 3.25 16.3248 4.16667 16.3248L13.3333 16.3332C13.8917 16.3332 14.3917 16.0582 14.6917 15.6332L18.3333 10.4998L14.6917 5.3665ZM13.3333 14.6665H4.16667V6.33317H13.3333L16.2917 10.4998L13.3333 14.6665Z" fill="#56514D" />
                        </svg>
                        <input value={descriptionEn} onChange={(e) => setDescriptionEn(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.service.description')} />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 2.16699C5.39935 2.16699 1.66602 5.90033 1.66602 10.5003C1.66602 15.1003 5.39935 18.8337 9.99935 18.8337C14.5993 18.8337 18.3327 15.1003 18.3327 10.5003C18.3327 5.90033 14.5993 2.16699 9.99935 2.16699ZM9.99935 17.167C6.32435 17.167 3.33268 14.1753 3.33268 10.5003C3.33268 6.82533 6.32435 3.83366 9.99935 3.83366C13.6743 3.83366 16.666 6.82533 16.666 10.5003C16.666 14.1753 13.6743 17.167 9.99935 17.167Z" fill="#56514D" />
                            <path d="M6.66667 13.8333C7.58714 13.8333 8.33333 13.0871 8.33333 12.1667C8.33333 11.2462 7.58714 10.5 6.66667 10.5C5.74619 10.5 5 11.2462 5 12.1667C5 13.0871 5.74619 13.8333 6.66667 13.8333Z" fill="#56514D" />
                            <path d="M10.0007 8.83333C10.9211 8.83333 11.6673 8.08714 11.6673 7.16667C11.6673 6.24619 10.9211 5.5 10.0007 5.5C9.08018 5.5 8.33398 6.24619 8.33398 7.16667C8.33398 8.08714 9.08018 8.83333 10.0007 8.83333Z" fill="#56514D" />
                            <path d="M13.3327 13.8333C14.2532 13.8333 14.9993 13.0871 14.9993 12.1667C14.9993 11.2462 14.2532 10.5 13.3327 10.5C12.4122 10.5 11.666 11.2462 11.666 12.1667C11.666 13.0871 12.4122 13.8333 13.3327 13.8333Z" fill="#56514D" />
                        </svg>

                        <PopupDropdown id={groupId} setId={setGroupId} content={[
                            ...(categories.map((item) => {
                                if (item.ru != 'Все') {
                                    return (
                                        item.en
                                    )
                                } else {
                                    return ('Не назначена');
                                }
                            }))
                        ]}
                            ids={[
                                ...(categories.map((item) => {
                                    return item.id
                                }))
                            ]}
                        />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.50065 3.83301C3.81732 3.83301 0.833984 6.81634 0.833984 10.4997C0.833984 14.183 3.81732 17.1663 7.50065 17.1663C11.184 17.1663 14.1673 14.183 14.1673 10.4997C14.1673 6.81634 11.184 3.83301 7.50065 3.83301ZM7.50065 15.4997C4.74232 15.4997 2.50065 13.258 2.50065 10.4997C2.50065 7.74134 4.74232 5.49967 7.50065 5.49967C10.259 5.49967 12.5007 7.74134 12.5007 10.4997C12.5007 13.258 10.259 15.4997 7.50065 15.4997ZM14.1673 4.04967V5.79134C16.109 6.47467 17.5007 8.32467 17.5007 10.4997C17.5007 12.6747 16.109 14.5247 14.1673 15.208V16.9497C17.0423 16.208 19.1673 13.608 19.1673 10.4997C19.1673 7.39134 17.0423 4.79134 14.1673 4.04967Z" fill="#56514D" />
                        </svg>
                        <DropdownForServices handler={setTempSubInfo} data={subscriptionsData} />
                    </div>
                    <div
                        onDragOver={handleDragOverFiles}
                        onDrop={handleDropFiles}
                        className="dashboard__popup__inputs__el__img-box">
                        <span className="dashboard__popup__inputs__el__img-box__text">{t('dashboard.popup.service.drag')}</span>
                        <input onChange={(e) => handleChangePhoto(e)} id="pic" type="file" className="dashboard__popup__inputs__el__img-box__input" />
                        <label htmlFor="pic" className="dashboard__popup__inputs__el__img-box__button">
                            <svg className="dashboard__popup__inputs__el__img-box__button__img" width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="14.5" cy="14.6108" r="14.5" fill="#56514D" />
                                <path d="M14.5 7.11084V22.1108M22 14.6108H7" stroke="#FDFAFA" stroke-width="2" />
                            </svg>
                            <span className="dashboard__popup__inputs__el__img-box__button__text">{t('dashboard.popup.service.choose')}</span>
                        </label>
                        {photoBg ? <img src={photoBg} className="dashboard__popup__inputs__el__img-box__img" alt="bg" /> : <img src={photo ? `https://lawchill.ge/imagesFromLawchill/${photo}` : IMAGES.service} className="dashboard__popup__inputs__el__img-box__img" />}
                    </div>
                </>) :
                language == "ka" ?
                (<>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                        </svg>
                        <input value={titleKa} onChange={(e) => setTitleKa(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.service.title')} />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 1.33301H7.5V2.99967H12.5V1.33301ZM9.16667 12.1663H10.8333V7.16634H9.16667V12.1663ZM15.8583 6.65801L17.0417 5.47467C16.6833 5.04967 16.2917 4.64967 15.8667 4.29967L14.6833 5.48301C13.3917 4.44967 11.7667 3.83301 10 3.83301C5.85833 3.83301 2.5 7.19134 2.5 11.333C2.5 15.4747 5.85 18.833 10 18.833C14.15 18.833 17.5 15.4747 17.5 11.333C17.5 9.56634 16.8833 7.94134 15.8583 6.65801ZM10 17.1663C6.775 17.1663 4.16667 14.558 4.16667 11.333C4.16667 8.10801 6.775 5.49967 10 5.49967C13.225 5.49967 15.8333 8.10801 15.8333 11.333C15.8333 14.558 13.225 17.1663 10 17.1663Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container">
                            <input style={{ width: `${time.toString().length > 0 ? time.toString().length * 1 : 1}ren`, marginRight: '0.35rem' }} value={time} onChange={(e) => setTime(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder="0" />
                            <span className="dashboard__popup__inputs__el__input">{t('dashboard.popup.service.time')}</span>
                        </div>
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 2.16699C5.39935 2.16699 1.66602 5.90033 1.66602 10.5003C1.66602 15.1003 5.39935 18.8337 9.99935 18.8337C14.5993 18.8337 18.3327 15.1003 18.3327 10.5003C18.3327 5.90033 14.5993 2.16699 9.99935 2.16699ZM9.99935 17.167C6.32435 17.167 3.33268 14.1753 3.33268 10.5003C3.33268 6.82533 6.32435 3.83366 9.99935 3.83366C13.6743 3.83366 16.666 6.82533 16.666 10.5003C16.666 14.1753 13.6743 17.167 9.99935 17.167ZM10.2577 9.78366C8.78268 9.40866 8.30768 9.00033 8.30768 8.39199C8.30768 7.69199 8.96602 7.20033 10.0577 7.20033C11.2077 7.20033 11.641 7.75033 11.6743 8.56699H13.0993C13.0577 7.45033 12.3743 6.42533 11.0243 6.09199V4.66699H9.08268V6.07533C7.82435 6.34199 6.81602 7.15866 6.81602 8.41699C6.81602 9.90866 8.05768 10.6587 9.86602 11.092C11.491 11.4753 11.816 12.0503 11.816 12.6503C11.816 13.092 11.491 13.8087 10.066 13.8087C8.73268 13.8087 8.20768 13.2087 8.13268 12.442H6.69935C6.78268 13.8587 7.83268 14.6587 9.08268 14.917V16.3337H11.0327V14.942C12.2993 14.7003 13.2993 13.9753 13.3077 12.6337C13.2993 10.8003 11.7243 10.167 10.2577 9.78366Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container price">
                            ₾
                            <input value={price} onChange={(e) => setPrice(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder="0" />
                        </div>
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 1.66699C6.77435 1.66699 4.16602 4.27533 4.16602 7.50033C4.16602 11.8753 9.99935 18.3337 9.99935 18.3337C9.99935 18.3337 15.8327 11.8753 15.8327 7.50033C15.8327 4.27533 13.2243 1.66699 9.99935 1.66699ZM5.83268 7.50033C5.83268 5.20033 7.69935 3.33366 9.99935 3.33366C12.2993 3.33366 14.166 5.20033 14.166 7.50033C14.166 9.90033 11.766 13.492 9.99935 15.7337C8.26602 13.5087 5.83268 9.87533 5.83268 7.50033Z" fill="#56514D" />
                            <path d="M9.99935 9.58366C11.1499 9.58366 12.0827 8.65092 12.0827 7.50033C12.0827 6.34973 11.1499 5.41699 9.99935 5.41699C8.84876 5.41699 7.91602 6.34973 7.91602 7.50033C7.91602 8.65092 8.84876 9.58366 9.99935 9.58366Z" fill="#56514D" />
                        </svg>
                        <input value={locationKa} onChange={(e) => setLocationKa(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.service.location')} />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6917 5.3665C14.3917 4.9415 13.8917 4.6665 13.3333 4.6665L4.16667 4.67484C3.25 4.67484 2.5 5.4165 2.5 6.33317V14.6665C2.5 15.5832 3.25 16.3248 4.16667 16.3248L13.3333 16.3332C13.8917 16.3332 14.3917 16.0582 14.6917 15.6332L18.3333 10.4998L14.6917 5.3665ZM13.3333 14.6665H4.16667V6.33317H13.3333L16.2917 10.4998L13.3333 14.6665Z" fill="#56514D" />
                        </svg>
                        <input value={descriptionKa} onChange={(e) => setDescriptionKa(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.service.description')} />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 2.16699C5.39935 2.16699 1.66602 5.90033 1.66602 10.5003C1.66602 15.1003 5.39935 18.8337 9.99935 18.8337C14.5993 18.8337 18.3327 15.1003 18.3327 10.5003C18.3327 5.90033 14.5993 2.16699 9.99935 2.16699ZM9.99935 17.167C6.32435 17.167 3.33268 14.1753 3.33268 10.5003C3.33268 6.82533 6.32435 3.83366 9.99935 3.83366C13.6743 3.83366 16.666 6.82533 16.666 10.5003C16.666 14.1753 13.6743 17.167 9.99935 17.167Z" fill="#56514D" />
                            <path d="M6.66667 13.8333C7.58714 13.8333 8.33333 13.0871 8.33333 12.1667C8.33333 11.2462 7.58714 10.5 6.66667 10.5C5.74619 10.5 5 11.2462 5 12.1667C5 13.0871 5.74619 13.8333 6.66667 13.8333Z" fill="#56514D" />
                            <path d="M10.0007 8.83333C10.9211 8.83333 11.6673 8.08714 11.6673 7.16667C11.6673 6.24619 10.9211 5.5 10.0007 5.5C9.08018 5.5 8.33398 6.24619 8.33398 7.16667C8.33398 8.08714 9.08018 8.83333 10.0007 8.83333Z" fill="#56514D" />
                            <path d="M13.3327 13.8333C14.2532 13.8333 14.9993 13.0871 14.9993 12.1667C14.9993 11.2462 14.2532 10.5 13.3327 10.5C12.4122 10.5 11.666 11.2462 11.666 12.1667C11.666 13.0871 12.4122 13.8333 13.3327 13.8333Z" fill="#56514D" />
                        </svg>
                        <PopupDropdown id={groupId} setId={setGroupId} content={[
                            ...(categories.map((item) => {
                                if (item.en != 'All') {
                                    return (
                                        item.ka
                                    )
                                } else {
                                    return ('Not assigned');
                                }

                            }))
                        ]}
                            ids={[
                                ...(categories.map((item) => {
                                    return item.id
                                }))
                            ]} />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.50065 3.83301C3.81732 3.83301 0.833984 6.81634 0.833984 10.4997C0.833984 14.183 3.81732 17.1663 7.50065 17.1663C11.184 17.1663 14.1673 14.183 14.1673 10.4997C14.1673 6.81634 11.184 3.83301 7.50065 3.83301ZM7.50065 15.4997C4.74232 15.4997 2.50065 13.258 2.50065 10.4997C2.50065 7.74134 4.74232 5.49967 7.50065 5.49967C10.259 5.49967 12.5007 7.74134 12.5007 10.4997C12.5007 13.258 10.259 15.4997 7.50065 15.4997ZM14.1673 4.04967V5.79134C16.109 6.47467 17.5007 8.32467 17.5007 10.4997C17.5007 12.6747 16.109 14.5247 14.1673 15.208V16.9497C17.0423 16.208 19.1673 13.608 19.1673 10.4997C19.1673 7.39134 17.0423 4.79134 14.1673 4.04967Z" fill="#56514D" />
                        </svg>
                        <DropdownForServices handler={setTempSubInfo} data={subscriptionsData} />
                    </div>
                    <div
                        onDragOver={handleDragOverFiles}
                        onDrop={handleDropFiles}
                        className="dashboard__popup__inputs__el__img-box">
                        <span className="dashboard__popup__inputs__el__img-box__text">{t('dashboard.popup.service.drag')}</span>
                        <input onChange={(e) => handleChangePhoto(e)} id="pic" type="file" className="dashboard__popup__inputs__el__img-box__input" />
                        <label htmlFor="pic" className="dashboard__popup__inputs__el__img-box__button">
                            <svg className="dashboard__popup__inputs__el__img-box__button__img" width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="14.5" cy="14.6108" r="14.5" fill="#56514D" />
                                <path d="M14.5 7.11084V22.1108M22 14.6108H7" stroke="#FDFAFA" stroke-width="2" />
                            </svg>
                            <span className="dashboard__popup__inputs__el__img-box__button__text">{t('dashboard.popup.service.choose')}</span>
                        </label>
                        {photoBg ? <img src={photoBg} className="dashboard__popup__inputs__el__img-box__img" alt="bg" /> : <img src={photo ? `https://lawchill.ge/imagesFromLawchill/${photo}` : IMAGES.service} className="dashboard__popup__inputs__el__img-box__img" />}
                    </div>
                </>) : 
                (<>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                        </svg>
                        <input value={titleUk} onChange={(e) => setTitleUk(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.service.title')} />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 1.33301H7.5V2.99967H12.5V1.33301ZM9.16667 12.1663H10.8333V7.16634H9.16667V12.1663ZM15.8583 6.65801L17.0417 5.47467C16.6833 5.04967 16.2917 4.64967 15.8667 4.29967L14.6833 5.48301C13.3917 4.44967 11.7667 3.83301 10 3.83301C5.85833 3.83301 2.5 7.19134 2.5 11.333C2.5 15.4747 5.85 18.833 10 18.833C14.15 18.833 17.5 15.4747 17.5 11.333C17.5 9.56634 16.8833 7.94134 15.8583 6.65801ZM10 17.1663C6.775 17.1663 4.16667 14.558 4.16667 11.333C4.16667 8.10801 6.775 5.49967 10 5.49967C13.225 5.49967 15.8333 8.10801 15.8333 11.333C15.8333 14.558 13.225 17.1663 10 17.1663Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container">
                            <input style={{ width: `${time.toString().length > 0 ? time.toString().length * 1 : 1}ren`, marginRight: '0.35rem' }} value={time} onChange={(e) => setTime(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder="0" />
                            <span className="dashboard__popup__inputs__el__input">{t('dashboard.popup.service.time')}</span>
                        </div>
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 2.16699C5.39935 2.16699 1.66602 5.90033 1.66602 10.5003C1.66602 15.1003 5.39935 18.8337 9.99935 18.8337C14.5993 18.8337 18.3327 15.1003 18.3327 10.5003C18.3327 5.90033 14.5993 2.16699 9.99935 2.16699ZM9.99935 17.167C6.32435 17.167 3.33268 14.1753 3.33268 10.5003C3.33268 6.82533 6.32435 3.83366 9.99935 3.83366C13.6743 3.83366 16.666 6.82533 16.666 10.5003C16.666 14.1753 13.6743 17.167 9.99935 17.167ZM10.2577 9.78366C8.78268 9.40866 8.30768 9.00033 8.30768 8.39199C8.30768 7.69199 8.96602 7.20033 10.0577 7.20033C11.2077 7.20033 11.641 7.75033 11.6743 8.56699H13.0993C13.0577 7.45033 12.3743 6.42533 11.0243 6.09199V4.66699H9.08268V6.07533C7.82435 6.34199 6.81602 7.15866 6.81602 8.41699C6.81602 9.90866 8.05768 10.6587 9.86602 11.092C11.491 11.4753 11.816 12.0503 11.816 12.6503C11.816 13.092 11.491 13.8087 10.066 13.8087C8.73268 13.8087 8.20768 13.2087 8.13268 12.442H6.69935C6.78268 13.8587 7.83268 14.6587 9.08268 14.917V16.3337H11.0327V14.942C12.2993 14.7003 13.2993 13.9753 13.3077 12.6337C13.2993 10.8003 11.7243 10.167 10.2577 9.78366Z" fill="#56514D" />
                        </svg>
                        <div className="dashboard__popup__inputs__el__input__container price">
                            ₾
                            <input value={price} onChange={(e) => setPrice(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder="0" />
                        </div>
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 1.66699C6.77435 1.66699 4.16602 4.27533 4.16602 7.50033C4.16602 11.8753 9.99935 18.3337 9.99935 18.3337C9.99935 18.3337 15.8327 11.8753 15.8327 7.50033C15.8327 4.27533 13.2243 1.66699 9.99935 1.66699ZM5.83268 7.50033C5.83268 5.20033 7.69935 3.33366 9.99935 3.33366C12.2993 3.33366 14.166 5.20033 14.166 7.50033C14.166 9.90033 11.766 13.492 9.99935 15.7337C8.26602 13.5087 5.83268 9.87533 5.83268 7.50033Z" fill="#56514D" />
                            <path d="M9.99935 9.58366C11.1499 9.58366 12.0827 8.65092 12.0827 7.50033C12.0827 6.34973 11.1499 5.41699 9.99935 5.41699C8.84876 5.41699 7.91602 6.34973 7.91602 7.50033C7.91602 8.65092 8.84876 9.58366 9.99935 9.58366Z" fill="#56514D" />
                        </svg>
                        <input value={locationUk} onChange={(e) => setLocationUk(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.service.location')} />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6917 5.3665C14.3917 4.9415 13.8917 4.6665 13.3333 4.6665L4.16667 4.67484C3.25 4.67484 2.5 5.4165 2.5 6.33317V14.6665C2.5 15.5832 3.25 16.3248 4.16667 16.3248L13.3333 16.3332C13.8917 16.3332 14.3917 16.0582 14.6917 15.6332L18.3333 10.4998L14.6917 5.3665ZM13.3333 14.6665H4.16667V6.33317H13.3333L16.2917 10.4998L13.3333 14.6665Z" fill="#56514D" />
                        </svg>
                        <input value={descriptionUk} onChange={(e) => setDescriptionUk(e.target.value)} type="text" className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.service.description')} />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 2.16699C5.39935 2.16699 1.66602 5.90033 1.66602 10.5003C1.66602 15.1003 5.39935 18.8337 9.99935 18.8337C14.5993 18.8337 18.3327 15.1003 18.3327 10.5003C18.3327 5.90033 14.5993 2.16699 9.99935 2.16699ZM9.99935 17.167C6.32435 17.167 3.33268 14.1753 3.33268 10.5003C3.33268 6.82533 6.32435 3.83366 9.99935 3.83366C13.6743 3.83366 16.666 6.82533 16.666 10.5003C16.666 14.1753 13.6743 17.167 9.99935 17.167Z" fill="#56514D" />
                            <path d="M6.66667 13.8333C7.58714 13.8333 8.33333 13.0871 8.33333 12.1667C8.33333 11.2462 7.58714 10.5 6.66667 10.5C5.74619 10.5 5 11.2462 5 12.1667C5 13.0871 5.74619 13.8333 6.66667 13.8333Z" fill="#56514D" />
                            <path d="M10.0007 8.83333C10.9211 8.83333 11.6673 8.08714 11.6673 7.16667C11.6673 6.24619 10.9211 5.5 10.0007 5.5C9.08018 5.5 8.33398 6.24619 8.33398 7.16667C8.33398 8.08714 9.08018 8.83333 10.0007 8.83333Z" fill="#56514D" />
                            <path d="M13.3327 13.8333C14.2532 13.8333 14.9993 13.0871 14.9993 12.1667C14.9993 11.2462 14.2532 10.5 13.3327 10.5C12.4122 10.5 11.666 11.2462 11.666 12.1667C11.666 13.0871 12.4122 13.8333 13.3327 13.8333Z" fill="#56514D" />
                        </svg>
                        <PopupDropdown id={groupId} setId={setGroupId} content={[
                            ...(categories.map((item) => {
                                if (item.en != 'All') {
                                    return (
                                        item.uk
                                    )
                                } else {
                                    return ('Not assigned');
                                }

                            }))
                        ]}
                            ids={[
                                ...(categories.map((item) => {
                                    return item.id
                                }))
                            ]} />
                    </div>
                    <div className="dashboard__popup__inputs__el">
                        <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.50065 3.83301C3.81732 3.83301 0.833984 6.81634 0.833984 10.4997C0.833984 14.183 3.81732 17.1663 7.50065 17.1663C11.184 17.1663 14.1673 14.183 14.1673 10.4997C14.1673 6.81634 11.184 3.83301 7.50065 3.83301ZM7.50065 15.4997C4.74232 15.4997 2.50065 13.258 2.50065 10.4997C2.50065 7.74134 4.74232 5.49967 7.50065 5.49967C10.259 5.49967 12.5007 7.74134 12.5007 10.4997C12.5007 13.258 10.259 15.4997 7.50065 15.4997ZM14.1673 4.04967V5.79134C16.109 6.47467 17.5007 8.32467 17.5007 10.4997C17.5007 12.6747 16.109 14.5247 14.1673 15.208V16.9497C17.0423 16.208 19.1673 13.608 19.1673 10.4997C19.1673 7.39134 17.0423 4.79134 14.1673 4.04967Z" fill="#56514D" />
                        </svg>
                        <DropdownForServices handler={setTempSubInfo} data={subscriptionsData} />
                    </div>
                    <div
                        onDragOver={handleDragOverFiles}
                        onDrop={handleDropFiles}
                        className="dashboard__popup__inputs__el__img-box">
                        <span className="dashboard__popup__inputs__el__img-box__text">{t('dashboard.popup.service.drag')}</span>
                        <input onChange={(e) => handleChangePhoto(e)} id="pic" type="file" className="dashboard__popup__inputs__el__img-box__input" />
                        <label htmlFor="pic" className="dashboard__popup__inputs__el__img-box__button">
                            <svg className="dashboard__popup__inputs__el__img-box__button__img" width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="14.5" cy="14.6108" r="14.5" fill="#56514D" />
                                <path d="M14.5 7.11084V22.1108M22 14.6108H7" stroke="#FDFAFA" stroke-width="2" />
                            </svg>
                            <span className="dashboard__popup__inputs__el__img-box__button__text">{t('dashboard.popup.service.choose')}</span>
                        </label>
                        {photoBg ? <img src={photoBg} className="dashboard__popup__inputs__el__img-box__img" alt="bg" /> : <img src={photo ? `https://lawchill.ge/imagesFromLawchill/${photo}` : IMAGES.service} className="dashboard__popup__inputs__el__img-box__img" />}
                    </div>
                </>)
            }
        </div >
    )
}