//import { useParams } from "react-router-dom";
import { useState, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import IMAGES from "./../images/profile/images";
import { ROUTS } from "./../Constants/Routes";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { servicesInfoSelector } from '../Store/Services/selectors';
import { currentLanguageSelector } from '../Store/Language/selectors';
import { userInfoSelector } from '../Store/User/selectors';
import { addMyServiceCationAction } from '../Store/MyServices/actions';
import axios from 'axios';


export const BookingPayment = () => {
    const { t, i18n } = useTranslation();

    const [modalSuc, setModalSuc] = useState(false);
    const [modalFail, setModalFail] = useState(false);

    const { id } = useParams();
    const services = useSelector(servicesInfoSelector).filter(item => item.id == id)[0];
    const currentLanguage = useSelector(currentLanguageSelector);
    const user = useSelector(userInfoSelector);
    const dispatch = useDispatch();

    const Navigate = useNavigate();
    const location = useLocation();

    const handleGoBack = () => {
        let path = '';
        location.pathname.includes('subscribe') ? path += ROUTS.SUBSCRIBE : path += ROUTS.SERVICES;
        Navigate(ROUTS.HOME + ROUTS.PROFILE + path);
    }


    const toggleModalSuc = () => {
        if (modalSuc) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal')

        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal')
        }
        setModalSuc(prev => !prev);
    };

    const toggleModalFail = () => {
        if (modalFail) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal')

        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal')
        }
        setModalFail(prev => !prev);
    };

    const handleGoProfile = () => {
        toggleModalSuc();
        Navigate(ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES);
    }

    const [ik_co_id, setIk_co_id] = useState();
    const [ik_pm_no, setIk_pm_no] = useState();
    const [ik_sign, setIk_sign] = useState();
    const [ik_am, setIk_am] = useState();
    const [ik_cur, setIk_cur] = useState();
    const [ik_payment_method, setIk_payment_method] = useState();
    const [ik_payment_currency, setIk_payment_currency] = useState();
    const [ik_pw_via, setIk_pw_via] = useState();
    const [ik_desc, setIk_desc] = useState();
    const payButton = useRef(null);

    const handleTriggerPay = () => {
        // toggleModalSuc();
        const timestamp = new Date().getTime();

        const service = {
            "id": Math.random() * 1000,
            "status": 0,
            "user": user.user.id,
            "expert": null,
            "expertname": null,
            "service": services.id,
            "servicename": {
                ...services.name
            },
            "servicedata": {
                "price": services.price,
                "duration": services.duration,
                "location": {
                    ...services.location
                },
                "subscriptions": {
                    ...services.subscriptions
                },
                "description": {
                    ...services.description
                }
            },
            "createdate": timestamp,
            "completedate": null
        };

        axios({
            method: 'post',
            url: 'https://api.lawchill.ge/createRequest',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            data: {
                "sid": services.id
            },
            withCredentials: true,
        }).then((res) => {
            window.location.href = res.data.payment.url;
        }).catch((err) => {
            console.log(err)
        });
    }

    if (services) {
        return (
            <div className="bookingPayment">
                <div className="bookingPayment__wrap wrap">
                    <div className="bookingPayment__header">
                        <button onClick={handleGoBack} className="bookingPayment__header__back-button">
                            <svg className="bookingPayment__header__back-button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="#0B0A0A" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1569 26.5714L9.79289 20.2074C9.40237 19.8169 9.40237 19.1837 9.79289 18.7932L16.1569 12.4292C16.5474 12.0387 17.1805 12.0387 17.5711 12.4292C17.9616 12.8198 17.9616 13.4529 17.5711 13.8434L12.9142 18.5003H30.5V20.5003H12.9142L17.5711 25.1572C17.9616 25.5477 17.9616 26.1808 17.5711 26.5714C17.1805 26.9619 16.5474 26.9619 16.1569 26.5714Z" fill="#FDFAFA" />
                            </svg>
                        </button>
                        <h2 className="bookingPayment__header__title">{t("bookingDate.title")}</h2>
                    </div>
                    <div className="bookingPayment__container">
                        <div className="bookingPayment__container__left">
                            <div className="bookingPayment__container__right">
                                <div className="bookingPayment__container__right__wrapper">
                                    <p className="bookingDate__container__right__title">{t("bookingDate.summary")}</p>
                                    <div className="bookingDate__container__right__list">
                                        <div className="bookingDate__container__right__list__el">
                                            <svg className="bookingDate__container__right__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.667 3H6.33366C5.41699 3 4.66699 3.75 4.66699 4.66667V18L10.5003 15.5L16.3337 18V4.66667C16.3337 3.75 15.5837 3 14.667 3ZM14.667 15.5L10.5003 13.6833L6.33366 15.5V4.66667H14.667V15.5Z" fill="#BCB7B3" />
                                            </svg>
                                            <p className="bookingDate__container__right__list__el__text">{services.name[currentLanguage]}</p>
                                        </div>
                                        <div className="bookingDate__container__right__list__el">
                                            <svg className="bookingDate__container__right__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5003 2.1665C7.27533 2.1665 4.66699 4.77484 4.66699 7.99984C4.66699 12.3748 10.5003 18.8332 10.5003 18.8332C10.5003 18.8332 16.3337 12.3748 16.3337 7.99984C16.3337 4.77484 13.7253 2.1665 10.5003 2.1665ZM6.33366 7.99984C6.33366 5.69984 8.20033 3.83317 10.5003 3.83317C12.8003 3.83317 14.667 5.69984 14.667 7.99984C14.667 10.3998 12.267 13.9915 10.5003 16.2332C8.76699 14.0082 6.33366 10.3748 6.33366 7.99984Z" fill="#BCB7B3" />
                                                <path d="M10.5003 10.0832C11.6509 10.0832 12.5837 9.15043 12.5837 7.99984C12.5837 6.84924 11.6509 5.9165 10.5003 5.9165C9.34973 5.9165 8.41699 6.84924 8.41699 7.99984C8.41699 9.15043 9.34973 10.0832 10.5003 10.0832Z" fill="#BCB7B3" />
                                            </svg>
                                            <p className="bookingDate__container__right__list__el__text">{services.location[currentLanguage]}</p>
                                        </div>
                                    </div>
                                    <div className="bookingDate__container__right__price">
                                        <p className="bookingDate__container__right__price__result">{t("booking.price")}</p>
                                        <p className="bookingDate__container__right__price__money">₾{services.price}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <p className="bookingPayment__container__left__el__buttons__title__mobile">{t("booking.method")}</p> */}
                            <div className="bookingPayment__container__left__el__buttons">
                                <p className="bookingPayment__container__left__el__buttons__title interkassa">{t("buy.paymenttext")}</p>
                                <img src={IMAGES.interkassa} alt="interkassa" className="buy__container__left__el__interkassa" />
                                
                            </div>
                            <button onClick={handleTriggerPay} className="bookingPayment__container__left__el__pay-button">
                                <svg className="bookingPayment__container__left__el__pay-button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20" cy="20" r="20" fill="#FDFAFA" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8431 13.4286L30.2071 19.7926C30.5976 20.1831 30.5976 20.8163 30.2071 21.2068L23.8431 27.5708C23.4526 27.9613 22.8195 27.9613 22.4289 27.5708C22.0384 27.1802 22.0384 26.5471 22.4289 26.1566L27.0858 21.4997H9.5V19.4997H27.0858L22.4289 14.8428C22.0384 14.4523 22.0384 13.8192 22.4289 13.4286C22.8195 13.0381 23.4526 13.0381 23.8431 13.4286Z" fill="#E75549" />
                                </svg>

                                <p className="bookingPayment__container__left__el__pay-button__text">{t("booking.pay")}</p>
                            </button>
                        </div>
                    </div>

                    <div className={"bookingPayment__modal modal" + (modalSuc ? ' show' : '')}>
                        <div onClick={handleGoProfile} className="overlay"></div>
                        <div className="bookingPayment__modal__content modal-content">
                            <div className="bookingPayment__modal__content__header">
                                <svg className="bookingPayment__modal__content__header__img" width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="60.5" cy="60" r="59" stroke="#14AE5C" stroke-width="2" />
                                    <path d="M54.5004 71.1196L43.3804 59.9996L39.5938 63.7596L54.5004 78.6663L86.5004 46.6662L82.7404 42.9062L54.5004 71.1196Z" fill="#14AE5C" />
                                </svg>
                                <p className="bookingPayment__modal__content__header__text">{t("buy.modal.suctitle")}</p>
                            </div>
                            <div className="bookingPayment__modal__content__info">
                                <p className="bookingPayment__modal__content__info__title">
                                    {t("bookingEdit.modal.details")}
                                </p>
                                <div className="bookingPayment__modal__content__info__list">
                                    <div className="bookingPayment__modal__content__info__list__el">
                                        <svg className="bookingPayment__modal__content__info__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.667 3H6.33366C5.41699 3 4.66699 3.75 4.66699 4.66667V18L10.5003 15.5L16.3337 18V4.66667C16.3337 3.75 15.5837 3 14.667 3ZM14.667 15.5L10.5003 13.6833L6.33366 15.5V4.66667H14.667V15.5Z" fill="#BCB7B3" />
                                        </svg>
                                        <p className="bookingPayment__modal__content__info__list__el__text">{services.name[currentLanguage]}</p>
                                    </div>
                                    <div className="bookingPayment__modal__content__info__list__el">
                                        <svg className="bookingPayment__modal__content__info__list__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0003 1.66699C6.77533 1.66699 4.16699 4.27533 4.16699 7.50033C4.16699 11.8753 10.0003 18.3337 10.0003 18.3337C10.0003 18.3337 15.8337 11.8753 15.8337 7.50033C15.8337 4.27533 13.2253 1.66699 10.0003 1.66699ZM5.83366 7.50033C5.83366 5.20033 7.70033 3.33366 10.0003 3.33366C12.3003 3.33366 14.167 5.20033 14.167 7.50033C14.167 9.90033 11.767 13.492 10.0003 15.7337C8.26699 13.5087 5.83366 9.87533 5.83366 7.50033Z" fill="#BCB7B3" />
                                            <path d="M10.0003 9.58366C11.1509 9.58366 12.0837 8.65092 12.0837 7.50033C12.0837 6.34973 11.1509 5.41699 10.0003 5.41699C8.84973 5.41699 7.91699 6.34973 7.91699 7.50033C7.91699 8.65092 8.84973 9.58366 10.0003 9.58366Z" fill="#BCB7B3" />
                                        </svg>
                                        <p className="bookingPayment__modal__content__info__list__el__text">{services.location[currentLanguage]}</p>
                                    </div>
                                    <div className="bookingPayment__modal__content__info__list__el">
                                        <svg className="bookingPayment__modal__content__info__list__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0003 1.66699C5.40033 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.40033 18.3337 10.0003 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 10.0003 1.66699ZM10.0003 16.667C6.32533 16.667 3.33366 13.6753 3.33366 10.0003C3.33366 6.32533 6.32533 3.33366 10.0003 3.33366C13.6753 3.33366 16.667 6.32533 16.667 10.0003C16.667 13.6753 13.6753 16.667 10.0003 16.667ZM10.2587 9.28366C8.78366 8.90866 8.30866 8.50033 8.30866 7.89199C8.30866 7.19199 8.96699 6.70033 10.0587 6.70033C11.2087 6.70033 11.642 7.25033 11.6753 8.06699H13.1003C13.0587 6.95033 12.3753 5.92533 11.0253 5.59199V4.16699H9.08366V5.57533C7.82533 5.84199 6.81699 6.65866 6.81699 7.91699C6.81699 9.40866 8.05866 10.1587 9.86699 10.592C11.492 10.9753 11.817 11.5503 11.817 12.1503C11.817 12.592 11.492 13.3087 10.067 13.3087C8.73366 13.3087 8.20866 12.7087 8.13366 11.942H6.70033C6.78366 13.3587 7.83366 14.1587 9.08366 14.417V15.8337H11.0337V14.442C12.3003 14.2003 13.3003 13.4753 13.3087 12.1337C13.3003 10.3003 11.7253 9.66699 10.2587 9.28366Z" fill="#BCB7B3" />
                                        </svg>
                                        <p className="bookingPayment__modal__content__info__list__el__text">₾{services.price}</p>
                                    </div>
                                    <div className="bookingPayment__modal__content__info__list__el">
                                        <svg className="bookingPayment__modal__content__info__list__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_1185_29768)">
                                                <path d="M10.2727 4.82329V7.55056L13.9091 3.9142L10.2727 0.277832V3.0051C6.25455 3.0051 3 6.25965 3 10.2778C3 11.7051 3.41818 13.0324 4.12727 14.1506L5.45455 12.8233C5.04545 12.0687 4.81818 11.196 4.81818 10.2778C4.81818 7.26874 7.26364 4.82329 10.2727 4.82329ZM16.4182 6.4051L15.0909 7.73238C15.4909 8.49601 15.7273 9.35965 15.7273 10.2778C15.7273 13.2869 13.2818 15.7324 10.2727 15.7324V13.0051L6.63636 16.6415L10.2727 20.2778V17.5506C14.2909 17.5506 17.5455 14.296 17.5455 10.2778C17.5455 8.85056 17.1273 7.52329 16.4182 6.4051Z" fill="#BCB7B3" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1185_29768">
                                                    <rect width="20" height="20" fill="white" transform="translate(0 0.277832)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p className="bookingPayment__modal__content__info__list__el__text green">{t("booking.status")}</p>
                                    </div>
                                </div>
                            </div>
                            <p className="subscribes__active__modal__endSub__content__text">{t("booking.info")}</p>
                            <button onClick={handleGoProfile} className="subscribes__active__modal__endSub__content__cancelButton">
                                <svg className="subscribes__active__modal__endSub__content__cancelButton__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20" cy="20" r="20" fill="#FDFAFA" />
                                    <path d="M12.929 12.9289L27.0711 27.0711M27.0711 12.9289L12.929 27.0711" stroke="#0B0A0A" stroke-width="2" />
                                </svg>
                                <p className="subscribes__active__modal__endSub__content__cancelButton__text">{t("bookingEdit.modal.close")}</p>
                            </button>
                        </div>
                    </div>

                    <div className={"bookingPayment__modal modal" + (modalFail ? ' show' : '')}>
                        <div onClick={toggleModalFail} className="overlay"></div>
                        <div className="bookingPayment__modal__content modal-content">
                            <div className="bookingPayment__modal__content__header">
                                <svg className="bookingPayment__modal__content__header__img" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="60" cy="60" r="59" stroke="#E75549" stroke-width="2" />
                                    <path d="M78.6663 45.0935L74.9063 41.3335L59.9997 56.2402L45.093 41.3335L41.333 45.0935L56.2397 60.0002L41.333 74.9068L45.093 78.6668L59.9997 63.7602L74.9063 78.6668L78.6663 74.9068L63.7597 60.0002L78.6663 45.0935Z" fill="#E75549" />
                                </svg>
                                <p className="bookingPayment__modal__content__header__text bookingPayment__modal__title">{t("bookingEdit.modal.fail")}</p>
                            </div>
                            <div className="subscribes__active__modal__endSub__content__info">
                                <p className="subscribes__active__modal__endSub__content__info__text">
                                    {t("bookingEdit.modal.tryAgainText")}
                                </p>
                            </div>
                            <button onClick={toggleModalFail} className="subscribes__active__modal__endSub__content__cancelButton bookingPayment__modal__button">
                                <svg className="subscribes__active__modal__endSub__content__cancelButton__img" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20.5" cy="20" r="20" fill="#FDFAFA" />
                                    <path d="M20.7727 14.5455V17.2727L24.4091 13.6364L20.7727 10V12.7273C16.7545 12.7273 13.5 15.9818 13.5 20C13.5 21.4273 13.9182 22.7545 14.6273 23.8727L15.9545 22.5455C15.5455 21.7909 15.3182 20.9182 15.3182 20C15.3182 16.9909 17.7636 14.5455 20.7727 14.5455ZM26.9182 16.1273L25.5909 17.4545C25.9909 18.2182 26.2273 19.0818 26.2273 20C26.2273 23.0091 23.7818 25.4545 20.7727 25.4545V22.7273L17.1364 26.3636L20.7727 30V27.2727C24.7909 27.2727 28.0455 24.0182 28.0455 20C28.0455 18.5727 27.6273 17.2455 26.9182 16.1273Z" fill="#0B0A0A" />
                                </svg>
                                <p className="subscribes__active__modal__endSub__content__cancelButton__text">{t("bookingEdit.modal.trueAgain")}</p>
                            </button>
                            <button onClick={toggleModalFail} className="subscribes__active__modal__content__cancelButton">
                                <svg className="subscribes__active__modal__content__cancelButton__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="#BCB7B3" />
                                    <path d="M5.75729 5.75736L14.2426 14.2426M14.2426 5.75736L5.75729 14.2426" stroke="#FDFAFA" stroke-width="2" />
                                </svg>
                                <p className="subscribes__active__modal__content__cancelButton__text">{t("bookingEdit.modal.close")}</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}