import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTS } from '../Constants/Routes';

export const NotFound = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        document.body.classList.add('active-modal');
        return () => {
            document.body.classList.remove('active-modal');
        }
    }, [])

    const Navigate = useNavigate();

    const handleRedirectHome = () => {
        Navigate(ROUTS.HOME + ROUTS.MAINPAGE);
    }

    return (
        <div className="notFound">
            <div className="notFound__wrapper">
                <h1 className="notFound__title">404</h1>
                <p className="notFound__text">{t("404.text")}</p>
                <button onClick={handleRedirectHome} className="notFound__button">
                    <svg className="notFound__button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="20" fill="#0B0A0A" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8431 13.4296L30.2071 19.7936C30.5976 20.1841 30.5976 20.8173 30.2071 21.2078L23.8431 27.5717C23.4526 27.9623 22.8195 27.9623 22.4289 27.5717C22.0384 27.1812 22.0384 26.5481 22.4289 26.1575L27.0858 21.5007H9.5V19.5007H27.0858L22.4289 14.8438C22.0384 14.4533 22.0384 13.8201 22.4289 13.4296C22.8195 13.0391 23.4526 13.0391 23.8431 13.4296Z" fill="#FDFAFA" />
                    </svg>
                    <p className="notFound__button__text">{t("404.button")}</p>
                </button>
            </div>
        </div>
    )
}