import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTS } from "../../Constants/Routes";
import { Calendar } from "react-calendar";
import { SubscribeServicesList } from "./ServicesList";
import IMAGESCALENDAR from './../../images/calendar/images';
import { ServicesModal } from "./ServicesModal";
import { useSelector } from "react-redux";
import { myServicesSelector } from "../../Store/MyServices/selectors";
import { currentLanguageSelector } from "../../Store/Language/selectors";
import { userInfoSelector } from "../../Store/User/selectors";
import axios from "axios";
import { ExpertModal } from "./ExpertModal";

export const Services = () => {
    const { t, i18n } = useTranslation();

    const [modalBuy, setModalBuy] = useState(false);
    const [modalId, setModalId] = useState();
    const [usersForExp, setUsersForExp] = useState([]);
    const [clients, setClients] = useState([]);

    const booking = useSelector(myServicesSelector);
    const currentLanguage = useSelector(currentLanguageSelector);

    useEffect(() => {
        if (user.user.status == 'expert') {
            setUsersForExp([]);
            booking.map((item) => {
                setUsersForExp(prev => [...prev, item.user]);
            })
        }
    }, [booking]);

    useEffect(() => {
        const fetchData = async () => {
            const clientData = await Promise.all(usersForExp.map(getClient));
            setClients(clientData);
        };
        fetchData();
    }, [usersForExp]);

    const [modalProps, setModalProps] = useState({});

    const [isMobileFilterFirstOpen, setIsMobileFilterFirstOpen] = useState(false);
    const [isMobileFilterSecondOpen, setIsMobileFilterSecondOpen] = useState(false);

    const [locationFilter, setLocationFilter] = useState([false, false]);

    const user = useSelector(userInfoSelector);

    const [mylistStatus, setMylistStatus] = useState([]);

    useEffect(() => {
        for (let i = 0; i < booking.length; i++) {
            setMylistStatus(prev => [...prev, false]);
        }
    }, []);


    const toggleModalBuy = () => {
        if (modalBuy) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal');
        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal')
        }
        setModalBuy(prev => !prev);
    };

    function handleOpenMylist(e, id) {
        if (!(e.target.className.includes('link') || e.target.className.includes('edit'))) {

            let temp = [];
            for (let i = 0; i < mylistStatus.length; i++) {
                temp.push(false);
            }

            temp[id] = !mylistStatus[id];
            setMylistStatus(temp);
        }
    }

    const handleOpenPaidServiceInfo = (e, id) => {
        if (!(e.target.className.includes('edit') || e.target.className.includes('link'))) {
            setModalProps({ handleClickFunc: setModalBuy, type: 'paid' });
            setModalId(id);
            toggleModalBuy();
        }
    }

    const [value, onChange] = useState();
    const [language, setLanguage] = useState('ru-RU');

    const arrowRight = <img className='react-calendar__navigation__arrow-right' src={IMAGESCALENDAR.arrowRight} alt=">" />
    const arrowLeft = <img className='react-calendar__navigation__arrow-left' src={IMAGESCALENDAR.arrowLeft} alt="<" />

    const [filtersStatus, setFiltersStatus] = useState([false, false, false, false, false, false]);

    const handleClickOutsideFilter = () => {
        setFiltersStatus([false, false, false, false, false, false]);
        document.removeEventListener("click", handleClickOutsideFilter);
    }

    const handleFilter = (e) => {
        let temp = [false, false, false, false, false, false];

        if (!filtersStatus[e.target.name - 1]) {
            temp[e.target.name - 1] = true;

            setTimeout(() => {
                document.addEventListener("click", handleClickOutsideFilter);
            }, 0);
        } else {
            temp[e.target.name - 1] = false;
        }
        setFiltersStatus(temp);
    }

    const Navigate = useNavigate();
    const editPage = ROUTS.HOME + ROUTS.PROFILE + ROUTS.SERVICES + ROUTS.BOOKEDIT;

    useEffect(() => {
        i18n.language.includes('ru') ? setLanguage('ru-RU') : setLanguage('en-EN');
    }, [i18n.language])

    const toggleFilterFirstOpen = () => {
        if (isMobileFilterFirstOpen) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal')

        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal')
        }
        setIsMobileFilterFirstOpen(prev => !prev);
    };

    const toggleFilterSecondOpen = () => {
        if (isMobileFilterSecondOpen) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal')

        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal')
        }
        setIsMobileFilterSecondOpen(prev => !prev);
    };

    const [modalExpert, setModalExpert] = useState();
    const [modalUserId, setModalUserId] = useState();

    const toggleExpertModal = (id) => {
        setModalUserId(id);
        if (modalExpert) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal')

        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal')
        }
        setModalExpert(prev => !prev);
    };

    let servicesCountCurrent = 0;
    let servicesCountCurrentMobile = 0;
    const [servicesTotalCount, setServicesTotalCount] = useState();
    const [serviceTotalCountShow, setServiceTotalCountShow] = useState(3);

    const [searchServices, setSearchServices] = useState('');

    useEffect(() => {
        setServicesTotalCount(booking.filter(item => item.status != 0).length);
    }, [booking]);

    const handleShowAllServices = () => {
        setServiceTotalCountShow(999);

        for (let i = 0; i < booking.length; i++) {
            setMylistStatus(prev => [...prev, false]);
        }
    }

    useEffect(() => {
        const apiUrl = 'https://api.lawchill.ge/getAccount';

        axios({
            method: 'get',
            url: apiUrl,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            withCredentials: true,
        }).then((res) => {
            if (res.data.status != "success") {
                Navigate(ROUTS.HOME);

                return (<></>);
            }
        })
    }, []);

    const [bookingSearch, setBookingSearch] = useState('');
    const [bookingLocation, setBookingLocation] = useState([false, false]);

    const locationFilterArray = (array) => {
        if (!bookingLocation[0] && !bookingLocation[1]) return array;
        if (bookingLocation[0]) return array.filter(item => item.servicedata.location['en'].toLowerCase() == 'online');
        if (bookingLocation[1]) return array.filter(item => item.servicedata.location['en'].toLowerCase() != 'online');
    }

    const dateFilterArray = (array) => {
        if (value) return array.filter(item => new Date(item.regdate * 1).toLocaleString('ru', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }) == value.toLocaleDateString("ru"));
        else return array;
    }

    const prevValue = useRef(value);

    useEffect(() => {
        if (prevValue.current && value) {
            if (prevValue.current.toLocaleDateString("ru") == value.toLocaleDateString("ru")) {
                onChange('');
            }
        }
        prevValue.current = value;
    }, [value]);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `https://api.lawchill.ge/getAccounts`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            withCredentials: true,
        }).then((res) => {
            console.log(res)
            setUsers(res.data.accounts)
        }).catch((err) => {
            console.log(err)
        });
    }, [user]);


    const filter = (array) => {
        return dateFilterArray(locationFilterArray(array)).filter((item) => {
            return (item.servicename[currentLanguage].toLowerCase().includes(bookingSearch.toLowerCase())) ||
                (item.servicedata.location && item.servicedata.location[currentLanguage].toLowerCase().includes(bookingSearch.toLowerCase())) ||
                (item.expertname && item.expertname.toLowerCase().includes(bookingSearch.toLowerCase()))
        })
    }

    const getClient = async (id) => {
        try {
            const response = await axios({
                method: 'get',
                url: `https://api.lawchill.ge/getAccount?id=${id}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true,
            });
            console.log(response);
            return response.data.user.name;
        } catch (err) {
            console.log(err);
            return null;
        }
    };


    if (user.user.status == "expert") {
        return (
            <div className="services">
                {booking.filter(item => item.status != 0).length == 0 && (
                    <p className="services__text">{t("services.nobooking")}</p>
                )}
                {booking.filter(item => item.status != 0).length > 0 && (
                    <>
                        <h3 className="services__filter__title__mobile">{t("services.mybooking")}</h3>
                        <div className="services__filter">
                            <div className="services__filter__search-box first">
                                <input value={bookingSearch} onChange={(e) => setBookingSearch(e.target.value)} type="text" className="services__filter__search-box__search" placeholder={t("services.searchboxplaceholder")} />
                                <div className="services__filter__search-box__img" />
                            </div>

                            <svg onClick={toggleFilterFirstOpen} className="services__filter__mobile__img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_593_20546)">
                                    <path d="M9.33333 20H14.6667V17.3333H9.33333V20ZM0 4V6.66667H24V4H0ZM4 13.3333H20V10.6667H4V13.3333Z" fill="#BCB7B3" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_593_20546">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            {isMobileFilterFirstOpen && (
                                <div>
                                    <div className="overlay"></div>
                                    <div className="services__filter__mobile__list__modal modal mobile">
                                        <div className="services__filter__mobile__list modal-content">
                                            <div className="services__filter__mobile__list__header">
                                                <h2 className="services__filter__mobile__list__header__title">{t("services.filter")}</h2>
                                                <svg onClick={toggleFilterFirstOpen} className="services__filter__mobile__list__header__close-button" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="10" cy="10" r="10" fill="#BCB7B3" />
                                                    <path d="M5.75729 5.75736L14.2426 14.2426M14.2426 5.75736L5.75729 14.2426" stroke="#FDFAFA" stroke-width="2" />
                                                </svg>

                                            </div>
                                            <div className="services__filter__mobile__list__filters">
                                                <div className="services__filter__mobile__list__filters__el">
                                                    <div className="services__filter__mobile__list__filters__el__header">
                                                        <h3 className="services__filter__mobile__list__filters__el__header__title">{t("services.period")}</h3>
                                                        <svg className="services__filter__mobile__list__filters__el__header__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.2543 8.85185L9.10616 16L1.95801 8.85185L2.80986 8L9.10616 14.2963L15.4025 8L16.2543 8.85185Z" fill="#BCB7B3" />
                                                        </svg>
                                                    </div>
                                                    <div className="services__filter__mobile__list__filters__el__body">
                                                        <Calendar minDetail="month" prevLabel={arrowLeft} nextLabel={arrowRight} locale="en" onChange={onChange} value={value} className="calendar services__filter__mobile__list__filters__el__body__calendar" />
                                                    </div>
                                                </div>
                                                <div className="services__filter__mobile__list__filters__el">
                                                    <div className="services__filter__mobile__list__filters__el__header">
                                                        <h3 className="services__filter__mobile__list__filters__el__header__title">{t("services.location")}</h3>
                                                        <svg className="services__filter__mobile__list__filters__el__header__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.2543 8.85185L9.10616 16L1.95801 8.85185L2.80986 8L9.10616 14.2963L15.4025 8L16.2543 8.85185Z" fill="#BCB7B3" />
                                                        </svg>
                                                    </div>
                                                    <div className="services__filter__mobile__list__filters__el__body">
                                                        <div className="services__filter__location-box__content__el">
                                                            <input checked={bookingLocation[0]} onChange={() => setBookingLocation([!bookingLocation[0], false])} id="top-online" type="checkbox" className="services__filter__location-box__content__el__input" />
                                                            <label htmlFor="top-online" className="services__filter__location-box__content__el__text">{t("services.locationonline")}</label>
                                                        </div>
                                                        <div className="services__filter__location-box__content__el">
                                                            <input checked={bookingLocation[1]} onChange={() => setBookingLocation([false, !bookingLocation[1]])} id="top-offline" type="checkbox" className="services__filter__location-box__content__el__input" />
                                                            <label htmlFor="top-offline" className="services__filter__location-box__content__el__text">{t("services.locationoffline")}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={toggleFilterFirstOpen} className="services__filter__mobile__list__button">{t("services.usefilter")}</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="services__filter__price-box">
                                <input onChange={handleFilter} checked={filtersStatus[0]} name="1" id="date" type="checkbox" className="services__filter__price-box__checkbox" />
                                <label htmlFor="date" className="services__filter__price-box__title">
                                    <p className="services__filter__price-box__title__text">{t("services.date")}</p>
                                </label>
                                <div className="services__filter__price-box__content calendar">
                                    <Calendar minDetail="month" prevLabel={arrowLeft} nextLabel={arrowRight} locale="en" onChange={onChange} value={value} className="calendar" />
                                </div>
                            </div>
                            <div className="services__filter__location-box">
                                <input onChange={handleFilter} checked={filtersStatus[2]} name="3" id="locationService" type="checkbox" className="services__filter__location-box__checkbox" />
                                <label htmlFor="locationService" className="services__filter__location-box__title">
                                    <p className="services__filter__location-box__title__text">{t("services.location")}</p>
                                </label>
                                <div className="services__filter__location-box__content">
                                    <div className="services__filter__location-box__content__el">
                                        <input checked={bookingLocation[0]} onChange={() => setBookingLocation([!bookingLocation[0], false])} id="top-online" type="checkbox" className="services__filter__location-box__content__el__input" />
                                        <label htmlFor="top-online" className="services__filter__location-box__content__el__text">{t("services.locationonline")}</label>
                                    </div>
                                    <div className="services__filter__location-box__content__el">
                                        <input checked={bookingLocation[1]} onChange={() => setBookingLocation([false, !bookingLocation[1]])} id="top-offline" type="checkbox" className="services__filter__location-box__content__el__input" />
                                        <label htmlFor="top-offline" className="services__filter__location-box__content__el__text">{t("services.locationoffline")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="services__mobile__mylist">
                            <div className="services__mobile__mylist">
                                {booking && filter(booking).map((item) => {
                                    if ((serviceTotalCountShow > servicesCountCurrentMobile)) {
                                        servicesCountCurrentMobile++;

                                        const index = servicesCountCurrentMobile;
                                        let date;
                                        let style;
                                        if (item.regdate) {
                                            date = new Date(item.regdate * 1).toLocaleString('ru', {
                                                day: 'numeric',
                                                month: 'numeric',
                                                year: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric'
                                            });
                                            style = false;
                                        } else {
                                            date = t("notstated");
                                            style = true;
                                        }

                                        return (
                                            <div key={item.id} onClick={(e) => handleOpenMylist(e, index)} className={`services__mobile__mylist__el ${mylistStatus[index]}`}>
                                                <div className="services__mobile__mylist__el__header">
                                                    <div className="services__mobile__mylist__el__header__info">
                                                        <p className="services__mobile__mylist__el__header__name">{item.servicename[currentLanguage]}</p>
                                                        <div className={`services__mobile__mylist__el__header__time` + (style ? ' grey' : '')}>{date}</div>
                                                    </div>

                                                </div>
                                                <div className={"services__mobile__mylist__el__body done"}>
                                                    <div className="subscribeServicesList__modal__info__list__el">
                                                        <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.0003 1.66699C6.77533 1.66699 4.16699 4.27533 4.16699 7.50033C4.16699 11.8753 10.0003 18.3337 10.0003 18.3337C10.0003 18.3337 15.8337 11.8753 15.8337 7.50033C15.8337 4.27533 13.2253 1.66699 10.0003 1.66699ZM5.83366 7.50033C5.83366 5.20033 7.70033 3.33366 10.0003 3.33366C12.3003 3.33366 14.167 5.20033 14.167 7.50033C14.167 9.90033 11.767 13.492 10.0003 15.7337C8.26699 13.5087 5.83366 9.87533 5.83366 7.50033Z" fill="#BCB7B3" />
                                                            <path d="M10.0003 9.58366C11.1509 9.58366 12.0837 8.65092 12.0837 7.50033C12.0837 6.34973 11.1509 5.41699 10.0003 5.41699C8.84973 5.41699 7.91699 6.34973 7.91699 7.50033C7.91699 8.65092 8.84973 9.58366 10.0003 9.58366Z" fill="#BCB7B3" />
                                                        </svg>

                                                        <p className="subscribeServicesList__modal__info__list__el__text">{item.servicedata.location ? item.servicedata.location[currentLanguage] : t('services.locationonline')}</p>
                                                    </div>
                                                    <div className="subscribeServicesList__modal__info__list__el">
                                                        <svg className="subscribeServicesList__modal__info__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13 1.33301H8V2.99967H13V1.33301ZM9.66667 12.1663H11.3333V7.16634H9.66667V12.1663ZM16.3583 6.65801L17.5417 5.47467C17.1833 5.04967 16.7917 4.64967 16.3667 4.29967L15.1833 5.48301C13.8917 4.44967 12.2667 3.83301 10.5 3.83301C6.35833 3.83301 3 7.19134 3 11.333C3 15.4747 6.35 18.833 10.5 18.833C14.65 18.833 18 15.4747 18 11.333C18 9.56634 17.3833 7.94134 16.3583 6.65801ZM10.5 17.1663C7.275 17.1663 4.66667 14.558 4.66667 11.333C4.66667 8.10801 7.275 5.49967 10.5 5.49967C13.725 5.49967 16.3333 8.10801 16.3333 11.333C16.3333 14.558 13.725 17.1663 10.5 17.1663Z" fill="#BCB7B3" />
                                                        </svg>
                                                        <p className="subscribeServicesList__modal__info__list__el__text">{item.servicedata.duration % 60 == 0 && item.servicedata.duration / 60 == 1 ? `1 ${t("hour")}` : (item.servicedata.duration % 60 == 0 ? `${item.servicedata.duration / 60} ${t("hours")}` : `${item.servicedata.duration} ${t("minutes")}`)}</p>
                                                    </div>
                                                    <div className="subscribeServicesList__modal__info__list__el">
                                                        <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.7894 10.0336C9.71964 10.0336 8.84754 9.69644 8.17312 9.02202C7.49871 8.3476 7.1615 7.47551 7.1615 6.40574C7.1615 5.33597 7.49871 4.46388 8.17312 3.78946C8.84754 3.11504 9.71964 2.77783 10.7894 2.77783C11.8592 2.77783 12.7313 3.11504 13.4057 3.78946C14.0801 4.46388 14.4173 5.33597 14.4173 6.40574C14.4173 7.47551 14.0801 8.3476 13.4057 9.02202C12.7313 9.69644 11.8592 10.0336 10.7894 10.0336ZM3.20801 17.7778V15.4523C3.20801 14.8321 3.36305 14.3011 3.67312 13.8592C3.9832 13.4174 4.37855 13.0802 4.85917 12.8476C5.91343 12.3825 6.92119 12.0336 7.88243 11.8011C8.84367 11.5685 9.81266 11.4523 10.7894 11.4523C11.7661 11.4523 12.7313 11.5724 13.6848 11.8127C14.6382 12.053 15.6421 12.4057 16.6964 12.8709C17.1925 13.0879 17.5956 13.4174 17.9057 13.8592C18.2158 14.3011 18.3708 14.8321 18.3708 15.4523V17.7778H3.20801ZM4.7894 16.1964H16.7894V15.4755C16.7894 15.2274 16.7158 14.991 16.5685 14.7662C16.4212 14.5414 16.239 14.3747 16.022 14.2662C15.0452 13.8011 14.1499 13.4794 13.3359 13.3011C12.522 13.1228 11.6731 13.0336 10.7894 13.0336C9.90568 13.0336 9.04909 13.1228 8.21964 13.3011C7.39018 13.4794 6.49483 13.8011 5.53359 14.2662C5.31653 14.3747 5.13824 14.5414 4.99871 14.7662C4.85917 14.991 4.7894 15.2274 4.7894 15.4755V16.1964ZM10.7894 8.45225C11.3786 8.45225 11.8669 8.25845 12.2545 7.87086C12.6421 7.48326 12.8359 6.99489 12.8359 6.40574C12.8359 5.81659 12.6421 5.32822 12.2545 4.94062C11.8669 4.55303 11.3786 4.35923 10.7894 4.35923C10.2003 4.35923 9.71188 4.55303 9.32429 4.94062C8.93669 5.32822 8.74289 5.81659 8.74289 6.40574C8.74289 6.99489 8.93669 7.48326 9.32429 7.87086C9.71188 8.25845 10.2003 8.45225 10.7894 8.45225Z" fill="#BCB7B3" />
                                                        </svg>
                                                        <p className="subscribeServicesList__modal__info__list__el__text">{users && users.filter(el => el.id == item.user)[0] ? (users.filter(el => el.id == item.user)[0].name) : ("-")}</p>
                                                    </div>
                                                    <div className="subscribeServicesList__modal__info__list__el">
                                                        <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_1185_29768)">
                                                                <path d="M10.2727 4.82329V7.55056L13.9091 3.9142L10.2727 0.277832V3.0051C6.25455 3.0051 3 6.25965 3 10.2778C3 11.7051 3.41818 13.0324 4.12727 14.1506L5.45455 12.8233C5.04545 12.0687 4.81818 11.196 4.81818 10.2778C4.81818 7.26874 7.26364 4.82329 10.2727 4.82329ZM16.4182 6.4051L15.0909 7.73238C15.4909 8.49601 15.7273 9.35965 15.7273 10.2778C15.7273 13.2869 13.2818 15.7324 10.2727 15.7324V13.0051L6.63636 16.6415L10.2727 20.2778V17.5506C14.2909 17.5506 17.5455 14.296 17.5455 10.2778C17.5455 8.85056 17.1273 7.52329 16.4182 6.4051Z" fill="#BCB7B3" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1185_29768">
                                                                    <rect width="20" height="20" fill="white" transform="translate(0 0.277832)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        <p className={"subscribeServicesList__modal__info__list__el__text" + ((item.status == 5 || item.status == 6) ? " paid" : "")}>{(item.status == 5 || item.status == 6) ? t("services.statuspaid") : t("services.statuspast")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <button onClick={handleShowAllServices} className={"services__list__more" + (serviceTotalCountShow == 999 ? ' hide' : '') + (servicesTotalCount < 4 ? ' hide' : '')}>
                                <svg className="services__list__more__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="#56514D" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2422 12.6059L10.4239 16.4243C10.1895 16.6586 9.80964 16.6586 9.57533 16.4243L5.75695 12.6059C5.52264 12.3716 5.52264 11.9917 5.75695 11.7574C5.99127 11.523 6.37117 11.523 6.60548 11.7574L9.39959 14.5515V4L10.5996 4V14.5515L13.3937 11.7574C13.628 11.523 14.0079 11.523 14.2422 11.7574C14.4765 11.9917 14.4765 12.3716 14.2422 12.6059Z" fill="#FDFAFA" />
                                </svg>

                                <p className="services__list__more__text">{t("services.seeall")}</p>
                            </button>
                        </div>
                        <div className="services__list">
                            <div className="services__list__title">
                                <p className="services__list__title__el__date">{t("services.date")}</p>
                                <p className="services__list__title__el__serv">{t("services.service")}</p>
                                <p className="services__list__title__el__time">{t("services.duration")}</p>
                                <p className="services__list__title__el__name">{t("services.client")}</p>
                                <p className="services__list__title__el__location">{t("services.location")}</p>
                                <p className="services__list__title__el__status">{t("services.status")}</p>
                            </div>
                            {booking && filter(booking).map((item, index) => {
                                console.log(item)

                                if (item.status != 0 && (serviceTotalCountShow > servicesCountCurrent)) {
                                    servicesCountCurrent++;

                                    let date;
                                    let style;
                                    if (item.regdate) {
                                        date = new Date(item.regdate * 1).toLocaleString('ru', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric'
                                        });
                                        style = false;
                                    } else {
                                        date = t("notstated");
                                        style = true;
                                    }
                                    return (
                                        <div onClick={() => toggleExpertModal(item.user)} key={item.id} className={'services__list__item expert' + ((item.status != 5 && item.status != 6) ? ' done' : '')}>
                                            <p className={`services__list__item__date` + (style ? ' grey' : '')}>{date}</p>
                                            <p className="services__list__item__title">{item.servicename[currentLanguage]}</p>
                                            <p className="services__list__item__time">{item.servicedata.duration % 60 == 0 && item.servicedata.duration / 60 == 1 ? `1 ${t("hour")}` : (item.servicedata.duration % 60 == 0 ? `${item.servicedata.duration / 60} ${t("hours")}` : `${item.servicedata.duration} ${t("minutes")}`)}</p>
                                            <p className="services__list__item__name">{clients[index] ? clients[index] : '-'}</p>
                                            <p className="services__list__item__location">{item.servicedata.location ? item.servicedata.location[currentLanguage] : t('services.locationonline')}</p>
                                            <p className={"services__list__item__status" + ((item.status == 5 || item.status == 6) ? " paid" : "")}>{(item.status == 5 || item.status == 6) ? t("services.statuspaid") : (item.status == 2 ? t("services.canceled") : t("services.statuspast"))}</p>
                                        </div>
                                    )
                                }
                            })}

                            <button onClick={handleShowAllServices} className={"services__list__more myservices" + (serviceTotalCountShow == 999 ? ' hide' : '') + (servicesTotalCount < 4 ? ' hide' : '')}>
                                <svg className="services__list__more__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="#56514D" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2422 12.6059L10.4239 16.4243C10.1895 16.6586 9.80964 16.6586 9.57533 16.4243L5.75695 12.6059C5.52264 12.3716 5.52264 11.9917 5.75695 11.7574C5.99127 11.523 6.37117 11.523 6.60548 11.7574L9.39959 14.5515V4L10.5996 4V14.5515L13.3937 11.7574C13.628 11.523 14.0079 11.523 14.2422 11.7574C14.4765 11.9917 14.4765 12.3716 14.2422 12.6059Z" fill="#FDFAFA" />
                                </svg>

                                <p className="services__list__more__text">{t("services.seeall")}</p>
                            </button>
                            <ExpertModal handler={setModalExpert} modalExpert={modalExpert} userId={modalUserId} />
                            {/* <ServicesModal props={modalProps} modalBuy={modalBuy} serviceId={modalId} /> */}
                        </div>
                    </>
                )}
                <div className="services__expert-margin" />
            </div>

        )
    }
    else if (user.user.status == "user") {
        return (
            <div className="services">
                {booking.filter(item => item.status != 0).length == 0 && (
                    <p className="services__text">{t("services.nobooking")}</p>
                )}
                {booking.filter(item => item.status != 0).length > 0 && (
                    <>
                        <h3 className="services__filter__title__mobile">{t("services.mybooking")}</h3>
                        <div className="services__filter">
                            <div className="services__filter__search-box first">
                                <input value={bookingSearch} onChange={(e) => setBookingSearch(e.target.value)} type="text" className="services__filter__search-box__search" placeholder={t("services.searchboxplaceholder")} />
                                <div className="services__filter__search-box__img" />
                            </div>
                            <svg onClick={toggleFilterFirstOpen} className="services__filter__mobile__img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_593_20546)">
                                    <path d="M9.33333 20H14.6667V17.3333H9.33333V20ZM0 4V6.66667H24V4H0ZM4 13.3333H20V10.6667H4V13.3333Z" fill="#BCB7B3" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_593_20546">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            {isMobileFilterFirstOpen && (
                                <div>
                                    <div className="overlay"></div>
                                    <div className="services__filter__mobile__list__modal modal mobile">
                                        <div className="services__filter__mobile__list modal-content">
                                            <div className="services__filter__mobile__list__header">
                                                <h2 className="services__filter__mobile__list__header__title">{t("services.filter")}</h2>
                                                <svg onClick={toggleFilterFirstOpen} className="services__filter__mobile__list__header__close-button" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="10" cy="10" r="10" fill="#BCB7B3" />
                                                    <path d="M5.75729 5.75736L14.2426 14.2426M14.2426 5.75736L5.75729 14.2426" stroke="#FDFAFA" stroke-width="2" />
                                                </svg>

                                            </div>
                                            <div className="services__filter__mobile__list__filters">
                                                <div className="services__filter__mobile__list__filters__el">
                                                    <div className="services__filter__mobile__list__filters__el__header">
                                                        <h3 className="services__filter__mobile__list__filters__el__header__title">{t("services.period")}</h3>
                                                        <svg className="services__filter__mobile__list__filters__el__header__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.2543 8.85185L9.10616 16L1.95801 8.85185L2.80986 8L9.10616 14.2963L15.4025 8L16.2543 8.85185Z" fill="#BCB7B3" />
                                                        </svg>
                                                    </div>
                                                    <div className="services__filter__mobile__list__filters__el__body">
                                                        <Calendar minDetail="month" prevLabel={arrowLeft} nextLabel={arrowRight} locale="en" onChange={onChange} value={value} className="calendar services__filter__mobile__list__filters__el__body__calendar" />
                                                    </div>
                                                </div>
                                                <div className="services__filter__mobile__list__filters__el">
                                                    <div className="services__filter__mobile__list__filters__el__header">
                                                        <h3 className="services__filter__mobile__list__filters__el__header__title">{t("services.location")}</h3>
                                                        <svg className="services__filter__mobile__list__filters__el__header__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.2543 8.85185L9.10616 16L1.95801 8.85185L2.80986 8L9.10616 14.2963L15.4025 8L16.2543 8.85185Z" fill="#BCB7B3" />
                                                        </svg>
                                                    </div>
                                                    <div className="services__filter__mobile__list__filters__el__body">
                                                        <div className="services__filter__location-box__content__el">
                                                            <input checked={bookingLocation[0]} onChange={() => setBookingLocation([!bookingLocation[0], false])} id="top-online" type="checkbox" className="services__filter__location-box__content__el__input" />
                                                            <label htmlFor="top-online" className="services__filter__location-box__content__el__text">{t("services.locationonline")}</label>
                                                        </div>
                                                        <div className="services__filter__location-box__content__el">
                                                            <input checked={bookingLocation[1]} onChange={() => setBookingLocation([false, !bookingLocation[1]])} id="top-offline" type="checkbox" className="services__filter__location-box__content__el__input" />
                                                            <label htmlFor="top-offline" className="services__filter__location-box__content__el__text">{t("services.locationoffline")}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={toggleFilterFirstOpen} className="services__filter__mobile__list__button">{t("services.usefilter")}</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="services__filter__price-box">
                                <input onChange={handleFilter} checked={filtersStatus[0]} name="1" id="date" type="checkbox" className="services__filter__price-box__checkbox" />
                                <label htmlFor="date" className="services__filter__price-box__title">
                                    <p className="services__filter__price-box__title__text">{t("services.date")}</p>
                                </label>
                                <div className="services__filter__price-box__content calendar">
                                    <Calendar minDetail="month" prevLabel={arrowLeft} nextLabel={arrowRight} locale="en" onChange={onChange} value={value} className="calendar" />
                                </div>
                            </div>
                            <div className="services__filter__location-box">
                                <input onChange={handleFilter} checked={filtersStatus[2]} name="3" id="locationService" type="checkbox" className="services__filter__location-box__checkbox" />
                                <label htmlFor="locationService" className="services__filter__location-box__title">
                                    <p className="services__filter__location-box__title__text">{t("services.location")}</p>
                                </label>
                                <div className="services__filter__location-box__content">
                                    <div className="services__filter__location-box__content__el">
                                        <input checked={bookingLocation[0]} onChange={() => setBookingLocation([!bookingLocation[0], false])} id="top-online" type="checkbox" className="services__filter__location-box__content__el__input" />
                                        <label htmlFor="top-online" className="services__filter__location-box__content__el__text">{t("services.locationonline")}</label>
                                    </div>
                                    <div className="services__filter__location-box__content__el">
                                        <input checked={bookingLocation[1]} onChange={() => setBookingLocation([false, !bookingLocation[1]])} id="top-offline" type="checkbox" className="services__filter__location-box__content__el__input" />
                                        <label htmlFor="top-offline" className="services__filter__location-box__content__el__text">{t("services.locationoffline")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="services__mobile__mylist">
                            {booking && filter(booking).map((item) => {
                                if (item.status != 0 && (serviceTotalCountShow > servicesCountCurrentMobile)) {
                                    servicesCountCurrentMobile++;

                                    const index = servicesCountCurrentMobile;
                                    let date;
                                    let style;
                                    if (item.regdate) {
                                        date = new Date(item.regdate * 1).toLocaleString('ru', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric'
                                        });
                                        style = false;
                                    } else {
                                        date = t("notstated");
                                        style = true;
                                    }

                                    return (
                                        <div key={item.id} onClick={(e) => handleOpenMylist(e, index)} className={`services__mobile__mylist__el ${mylistStatus[index]}`}>
                                            <div className="services__mobile__mylist__el__header">
                                                {(item.status == 5 || item.status == 6) ? (
                                                    <svg onClick={() => Navigate(editPage + `/${item.id}`)} className="services__mobile__mylist__el__header__edit" width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="20.5" cy="20.5" r="20.5" fill="#E75549" />
                                                        <g clip-path="url(#clip0_947_14216)">
                                                            <path d="M10 30.9999H14.166L26.453 18.7129L22.287 14.5469L10 26.8339V30.9999ZM12.2219 27.756L22.287 17.6908L23.3091 18.7129L13.2439 28.778H12.2219V27.756Z" fill="#FDFAFA" />
                                                            <path d="M27.075 11.3249C26.6418 10.8917 25.9419 10.8917 25.5086 11.3249L23.4756 13.358L27.6416 17.524L29.6746 15.491C30.1079 15.0577 30.1079 14.3578 29.6746 13.9246L27.075 11.3249Z" fill="#FDFAFA" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_947_14216">
                                                                <rect width="20" height="20" fill="white" transform="translate(10 11)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                ) : (<></>)}
                                                <div className="services__mobile__mylist__el__header__info">
                                                    <p className="services__mobile__mylist__el__header__name">{item.servicename[currentLanguage]}</p>
                                                    <div className={`services__mobile__mylist__el__header__time` + (style ? ' grey' : '')}>{date}</div>
                                                </div>

                                            </div>
                                            <div className={"services__mobile__mylist__el__body" + ((item.status != 5 && item.status != 6) ? ' done' : '')}>
                                                <div className="subscribeServicesList__modal__info__list__el">
                                                    <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.0003 1.66699C6.77533 1.66699 4.16699 4.27533 4.16699 7.50033C4.16699 11.8753 10.0003 18.3337 10.0003 18.3337C10.0003 18.3337 15.8337 11.8753 15.8337 7.50033C15.8337 4.27533 13.2253 1.66699 10.0003 1.66699ZM5.83366 7.50033C5.83366 5.20033 7.70033 3.33366 10.0003 3.33366C12.3003 3.33366 14.167 5.20033 14.167 7.50033C14.167 9.90033 11.767 13.492 10.0003 15.7337C8.26699 13.5087 5.83366 9.87533 5.83366 7.50033Z" fill="#BCB7B3" />
                                                        <path d="M10.0003 9.58366C11.1509 9.58366 12.0837 8.65092 12.0837 7.50033C12.0837 6.34973 11.1509 5.41699 10.0003 5.41699C8.84973 5.41699 7.91699 6.34973 7.91699 7.50033C7.91699 8.65092 8.84973 9.58366 10.0003 9.58366Z" fill="#BCB7B3" />
                                                    </svg>
                                                    <p className="subscribeServicesList__modal__info__list__el__text">{item.servicedata.location[currentLanguage]}</p>
                                                </div>
                                                <div className="subscribeServicesList__modal__info__list__el">
                                                    <svg className="subscribeServicesList__modal__info__list__el__img" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13 1.33301H8V2.99967H13V1.33301ZM9.66667 12.1663H11.3333V7.16634H9.66667V12.1663ZM16.3583 6.65801L17.5417 5.47467C17.1833 5.04967 16.7917 4.64967 16.3667 4.29967L15.1833 5.48301C13.8917 4.44967 12.2667 3.83301 10.5 3.83301C6.35833 3.83301 3 7.19134 3 11.333C3 15.4747 6.35 18.833 10.5 18.833C14.65 18.833 18 15.4747 18 11.333C18 9.56634 17.3833 7.94134 16.3583 6.65801ZM10.5 17.1663C7.275 17.1663 4.66667 14.558 4.66667 11.333C4.66667 8.10801 7.275 5.49967 10.5 5.49967C13.725 5.49967 16.3333 8.10801 16.3333 11.333C16.3333 14.558 13.725 17.1663 10.5 17.1663Z" fill="#BCB7B3" />
                                                    </svg>

                                                    <p className="subscribeServicesList__modal__info__list__el__text">{item.servicedata.duration % 60 == 0 && item.servicedata.duration / 60 == 1 ? `1 ${t("hour")}` : (item.servicedata.duration % 60 == 0 ? `${item.servicedata.duration / 60} ${t("hours")}` : `${item.servicedata.duration} ${t("minutes")}`)}</p>
                                                </div>
                                                <div className="subscribeServicesList__modal__info__list__el">
                                                    <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.0003 1.66699C5.40033 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.40033 18.3337 10.0003 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 10.0003 1.66699ZM10.0003 16.667C6.32533 16.667 3.33366 13.6753 3.33366 10.0003C3.33366 6.32533 6.32533 3.33366 10.0003 3.33366C13.6753 3.33366 16.667 6.32533 16.667 10.0003C16.667 13.6753 13.6753 16.667 10.0003 16.667ZM10.2587 9.28366C8.78366 8.90866 8.30866 8.50033 8.30866 7.89199C8.30866 7.19199 8.96699 6.70033 10.0587 6.70033C11.2087 6.70033 11.642 7.25033 11.6753 8.06699H13.1003C13.0587 6.95033 12.3753 5.92533 11.0253 5.59199V4.16699H9.08366V5.57533C7.82533 5.84199 6.81699 6.65866 6.81699 7.91699C6.81699 9.40866 8.05866 10.1587 9.86699 10.592C11.492 10.9753 11.817 11.5503 11.817 12.1503C11.817 12.592 11.492 13.3087 10.067 13.3087C8.73366 13.3087 8.20866 12.7087 8.13366 11.942H6.70033C6.78366 13.3587 7.83366 14.1587 9.08366 14.417V15.8337H11.0337V14.442C12.3003 14.2003 13.3003 13.4753 13.3087 12.1337C13.3003 10.3003 11.7253 9.66699 10.2587 9.28366Z" fill="#BCB7B3" />
                                                    </svg>
                                                    <p className="subscribeServicesList__modal__info__list__el__text">₾{item.servicedata.price}</p>
                                                </div>
                                                <div className="subscribeServicesList__modal__info__list__el">
                                                    <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.7894 10.0336C9.71964 10.0336 8.84754 9.69644 8.17312 9.02202C7.49871 8.3476 7.1615 7.47551 7.1615 6.40574C7.1615 5.33597 7.49871 4.46388 8.17312 3.78946C8.84754 3.11504 9.71964 2.77783 10.7894 2.77783C11.8592 2.77783 12.7313 3.11504 13.4057 3.78946C14.0801 4.46388 14.4173 5.33597 14.4173 6.40574C14.4173 7.47551 14.0801 8.3476 13.4057 9.02202C12.7313 9.69644 11.8592 10.0336 10.7894 10.0336ZM3.20801 17.7778V15.4523C3.20801 14.8321 3.36305 14.3011 3.67312 13.8592C3.9832 13.4174 4.37855 13.0802 4.85917 12.8476C5.91343 12.3825 6.92119 12.0336 7.88243 11.8011C8.84367 11.5685 9.81266 11.4523 10.7894 11.4523C11.7661 11.4523 12.7313 11.5724 13.6848 11.8127C14.6382 12.053 15.6421 12.4057 16.6964 12.8709C17.1925 13.0879 17.5956 13.4174 17.9057 13.8592C18.2158 14.3011 18.3708 14.8321 18.3708 15.4523V17.7778H3.20801ZM4.7894 16.1964H16.7894V15.4755C16.7894 15.2274 16.7158 14.991 16.5685 14.7662C16.4212 14.5414 16.239 14.3747 16.022 14.2662C15.0452 13.8011 14.1499 13.4794 13.3359 13.3011C12.522 13.1228 11.6731 13.0336 10.7894 13.0336C9.90568 13.0336 9.04909 13.1228 8.21964 13.3011C7.39018 13.4794 6.49483 13.8011 5.53359 14.2662C5.31653 14.3747 5.13824 14.5414 4.99871 14.7662C4.85917 14.991 4.7894 15.2274 4.7894 15.4755V16.1964ZM10.7894 8.45225C11.3786 8.45225 11.8669 8.25845 12.2545 7.87086C12.6421 7.48326 12.8359 6.99489 12.8359 6.40574C12.8359 5.81659 12.6421 5.32822 12.2545 4.94062C11.8669 4.55303 11.3786 4.35923 10.7894 4.35923C10.2003 4.35923 9.71188 4.55303 9.32429 4.94062C8.93669 5.32822 8.74289 5.81659 8.74289 6.40574C8.74289 6.99489 8.93669 7.48326 9.32429 7.87086C9.71188 8.25845 10.2003 8.45225 10.7894 8.45225Z" fill="#BCB7B3" />
                                                    </svg>

                                                    <p className="subscribeServicesList__modal__info__list__el__text">{item.expertname ? (item.expertname[currentLanguage] ? item.expertname[currentLanguage] : '-') : '-'}</p>
                                                </div>
                                                <div className="subscribeServicesList__modal__info__list__el">
                                                    <svg className="subscribeServicesList__modal__info__list__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_1185_29768)">
                                                            <path d="M10.2727 4.82329V7.55056L13.9091 3.9142L10.2727 0.277832V3.0051C6.25455 3.0051 3 6.25965 3 10.2778C3 11.7051 3.41818 13.0324 4.12727 14.1506L5.45455 12.8233C5.04545 12.0687 4.81818 11.196 4.81818 10.2778C4.81818 7.26874 7.26364 4.82329 10.2727 4.82329ZM16.4182 6.4051L15.0909 7.73238C15.4909 8.49601 15.7273 9.35965 15.7273 10.2778C15.7273 13.2869 13.2818 15.7324 10.2727 15.7324V13.0051L6.63636 16.6415L10.2727 20.2778V17.5506C14.2909 17.5506 17.5455 14.296 17.5455 10.2778C17.5455 8.85056 17.1273 7.52329 16.4182 6.4051Z" fill="#BCB7B3" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1185_29768">
                                                                <rect width="20" height="20" fill="white" transform="translate(0 0.277832)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <p className={"subscribeServicesList__modal__info__list__el__text" + ((item.status == 5 || item.status == 6) ? " paid" : "")}>{(item.status == 5 || item.status == 6) ? t("services.statuspaid") : t("services.statuspast")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}

                            <button onClick={handleShowAllServices} className={"services__list__more" + (serviceTotalCountShow == 999 ? ' hide' : '') + (servicesTotalCount < 4 ? ' hide' : '')}>
                                <svg className="services__list__more__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="#56514D" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2422 12.6059L10.4239 16.4243C10.1895 16.6586 9.80964 16.6586 9.57533 16.4243L5.75695 12.6059C5.52264 12.3716 5.52264 11.9917 5.75695 11.7574C5.99127 11.523 6.37117 11.523 6.60548 11.7574L9.39959 14.5515V4L10.5996 4V14.5515L13.3937 11.7574C13.628 11.523 14.0079 11.523 14.2422 11.7574C14.4765 11.9917 14.4765 12.3716 14.2422 12.6059Z" fill="#FDFAFA" />
                                </svg>
                                <p className="services__list__more__text">{t("services.seeall")}</p>
                            </button>
                        </div>
                        <div className="services__list">
                            <div className="services__list__title">
                                <p className="services__list__title__el__date">{t("services.date")}</p>
                                <p className="services__list__title__el__serv">{t("services.service")}</p>
                                <p className="services__list__title__el__time">{t("services.duration")}</p>
                                <p className="services__list__title__el__name">{t("services.expert")}</p>
                                <p className="services__list__title__el__location">{t("services.location")}</p>
                                <p className="services__list__title__el__status">{t("services.status")}</p>
                            </div>
                            {booking && filter(booking).map((item) => {
                                if (item.status != 0 && (serviceTotalCountShow > servicesCountCurrent)) {
                                    servicesCountCurrent++;
                                    let date;
                                    let style;

                                    if (item.regdate) {
                                        date = new Date(item.regdate * 1).toLocaleString('ru', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric'
                                        });
                                        style = false;
                                    } else {
                                        date = t("notstated");
                                        style = true;
                                    }

                                    return (
                                        <div key={item.id} onClick={((item.status != 5 && item.status != 6) ? (e) => handleOpenPaidServiceInfo(e, item.id) : null)} className={'services__list__item' + ((item.status != 5 && item.status != 6) ? ' done' : '')}>
                                            <svg className="services__list__item__edit" onClick={() => Navigate(editPage + `/${item.id}`)} width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="20.5" cy="20.5" r="20.5" fill="#E75549" />
                                                <g clip-path="url(#clip0_947_14216)">
                                                    <path d="M10 30.9999H14.166L26.453 18.7129L22.287 14.5469L10 26.8339V30.9999ZM12.2219 27.756L22.287 17.6908L23.3091 18.7129L13.2439 28.778H12.2219V27.756Z" fill="#FDFAFA" />
                                                    <path d="M27.075 11.3249C26.6418 10.8917 25.9419 10.8917 25.5086 11.3249L23.4756 13.358L27.6416 17.524L29.6746 15.491C30.1079 15.0577 30.1079 14.3578 29.6746 13.9246L27.075 11.3249Z" fill="#FDFAFA" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_947_14216">
                                                        <rect width="20" height="20" fill="white" transform="translate(10 11)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <p className={`services__list__item__date` + (style ? ' grey' : '')}>{date}</p>
                                            <p className="services__list__item__title">{item.servicename[currentLanguage]}</p>
                                            <p className="services__list__item__time">{item.servicedata.duration % 60 == 0 && item.servicedata.duration / 60 == 1 ? `1 ${t("hour")}` : (item.servicedata.duration % 60 == 0 ? `${item.servicedata.duration / 60} ${t("hours")}` : `${item.servicedata.duration} ${t("minutes")}`)}</p>
                                            <p className="services__list__item__name">{item.expertname ? item.expertname : '-'}</p>
                                            <p className="services__list__item__location">{item.servicedata.location ? item.servicedata.location[currentLanguage] : t('services.locationonline')}</p>
                                            <p className={"services__list__item__status" + ((item.status == 5 || item.status == 6) ? " paid" : "")}>{(item.status == 5 || item.status == 6) ? t("services.statuspaid") : ((item.status == 1 || item.status == 2) ? t("services.canceled") : t("services.statuspast"))}</p>
                                        </div>
                                    )
                                }
                            })}

                            <button onClick={handleShowAllServices} className={"services__list__more myservices" + (serviceTotalCountShow == 999 ? ' hide' : '') + (servicesTotalCount < 4 ? ' hide' : '')}>
                                <svg className="services__list__more__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="#56514D" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2422 12.6059L10.4239 16.4243C10.1895 16.6586 9.80964 16.6586 9.57533 16.4243L5.75695 12.6059C5.52264 12.3716 5.52264 11.9917 5.75695 11.7574C5.99127 11.523 6.37117 11.523 6.60548 11.7574L9.39959 14.5515V4L10.5996 4V14.5515L13.3937 11.7574C13.628 11.523 14.0079 11.523 14.2422 11.7574C14.4765 11.9917 14.4765 12.3716 14.2422 12.6059Z" fill="#FDFAFA" />
                                </svg>

                                <p className="services__list__more__text">{t("services.seeall")}</p>
                            </button>

                            <ServicesModal props={modalProps} modalBuy={modalBuy} serviceId={modalId} />
                        </div>
                    </>
                )}
                <h2 className="services__title">{t("services.services")}</h2>
                <div className="services__filter">
                    <div className="services__filter__search-box">
                        <input value={searchServices} onChange={(e) => setSearchServices(e.target.value)} type="text" className="services__filter__search-box__search" placeholder={t("services.servicessearchplaceholder")} />
                        <div className="services__filter__search-box__img" />
                    </div>
                    <svg className="services__filter__mobile__img" onClick={toggleFilterSecondOpen} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_593_20546)">
                            <path d="M9.33333 20H14.6667V17.3333H9.33333V20ZM0 4V6.66667H24V4H0ZM4 13.3333H20V10.6667H4V13.3333Z" fill="#BCB7B3" />
                        </g>
                        <defs>
                            <clipPath id="clip0_593_20546">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    {isMobileFilterSecondOpen && (
                        <div>
                            <div className="overlay"></div>
                            <div className="services__filter__mobile__list__modal modal mobile">
                                <div className="services__filter__mobile__list modal-content">
                                    <div className="services__filter__mobile__list__header">
                                        <h2 className="services__filter__mobile__list__header__title">{t("services.filter")}</h2>
                                        <svg onClick={toggleFilterSecondOpen} className="services__filter__mobile__list__header__close-button" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="10" cy="10" r="10" fill="#BCB7B3" />
                                            <path d="M5.75729 5.75736L14.2426 14.2426M14.2426 5.75736L5.75729 14.2426" stroke="#FDFAFA" stroke-width="2" />
                                        </svg>
                                    </div>
                                    <div className="services__filter__mobile__list__filters">
                                        <div className="services__filter__mobile__list__filters__el">
                                            <div className="services__filter__mobile__list__filters__el__header">
                                                <h3 className="services__filter__mobile__list__filters__el__header__title">{t("services.location")}</h3>
                                                <svg className="services__filter__mobile__list__filters__el__header__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.2543 8.85185L9.10616 16L1.95801 8.85185L2.80986 8L9.10616 14.2963L15.4025 8L16.2543 8.85185Z" fill="#BCB7B3" />
                                                </svg>

                                            </div>
                                            <div className="services__filter__mobile__list__filters__el__body">
                                                <div className="services__filter__location-box__content__el">
                                                    <input checked={locationFilter[0]} onChange={() => setLocationFilter([!locationFilter[0], false])} id="online" type="checkbox" className="services__filter__location-box__content__el__input" />
                                                    <label htmlFor="online" className="services__filter__location-box__content__el__text">{t("services.locationonline")}</label>
                                                </div>
                                                <div className="services__filter__location-box__content__el">
                                                    <input checked={locationFilter[1]} onChange={() => setLocationFilter([false, !locationFilter[1]])} id="offline" type="checkbox" className="services__filter__location-box__content__el__input" />
                                                    <label htmlFor="offline" className="services__filter__location-box__content__el__text">{t("services.locationoffline")}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={toggleFilterSecondOpen} className="services__filter__mobile__list__button">{t("services.usefilter")}</button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="services__filter__location-box">
                        <input onChange={handleFilter} checked={filtersStatus[5]} name="6" id="location" type="checkbox" className="services__filter__location-box__checkbox" />
                        <label htmlFor="location" className="services__filter__location-box__title">
                            <p className="services__filter__location-box__title__text">{t("services.location")}</p>
                        </label>
                        <div className="services__filter__location-box__content">
                            <div className="services__filter__location-box__content__el">
                                <input checked={locationFilter[0]} onChange={() => setLocationFilter([!locationFilter[0], false])} id="online" type="checkbox" className="services__filter__location-box__content__el__input" />
                                <label htmlFor="online" className="services__filter__location-box__content__el__text">{t("services.locationonline")}</label>
                            </div>
                            <div className="services__filter__location-box__content__el">
                                <input checked={locationFilter[1]} onChange={() => setLocationFilter([false, !locationFilter[1]])} id="offline" type="checkbox" className="services__filter__location-box__content__el__input" />
                                <label htmlFor="offline" className="services__filter__location-box__content__el__text">{t("services.locationoffline")}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <SubscribeServicesList locationFilter={locationFilter} search={searchServices.toUpperCase()} />
            </div>
        )
    }
}