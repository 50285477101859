import { ADD_CATEGORY_CATION_ACTION, CHANGE_CATEGORY_CATION_ACTION, DELETE_CATEGORY_CATION_ACTION, SELECT_CATEGORY_CATION_ACTION, SET_CATEGORY_CATION_ACTION } from './constants.js';

export const addCategoryCationAction = (payload) => ({
    type: ADD_CATEGORY_CATION_ACTION,
    payload,
});

export const deleteCategoryCationAction = (payload) => ({
    type: DELETE_CATEGORY_CATION_ACTION, 
    payload, 
});

export const selectCategoryCationAction = (payload) => ({
    type: SELECT_CATEGORY_CATION_ACTION, 
    payload, 
});

export const changeCategoryCationAction = (payload) => ({
    type: CHANGE_CATEGORY_CATION_ACTION, 
    payload, 
});

export const setCategoryCationAction = (payload) => ({
    type: SET_CATEGORY_CATION_ACTION, 
    payload, 
});



