import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTS } from "../Constants/Routes";
import IMAGES from "./../images/FAQ/images";

export const Questions = () => {
    const { t, i18n } = useTranslation();

    const Navigate = useNavigate();

    const navigateToContact = () => {
        Navigate(ROUTS.HOME + ROUTS.CONTACTS);
    }
    return (
        <div className="questions">
            <div className="questions__wrapper">
                <div className="questions__box">
                    <h2 className="questions__box__title">{t("question.title")}</h2>
                    <button onClick={navigateToContact} className="questions__box__button">
                        <svg className="questions__box__button__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="20" fill="#0B0A0A" />
                            <g clip-path="url(#clip0_844_663)">
                                <path d="M10 30V11.93C10 11.4154 10.189 10.965 10.5669 10.579C10.9449 10.193 11.3912 10 11.9059 10H28.07C28.5846 10 29.035 10.193 29.421 10.579C29.807 10.965 30 11.4154 30 11.93V24.234C30 24.7326 29.807 25.1749 29.421 25.5609C29.035 25.9469 28.5846 26.1399 28.07 26.1399H13.8601L10 30ZM11.9059 25.6815L13.3534 24.234H28.07V11.93H11.9059V25.6815ZM11.9059 11.93V25.6815V11.93Z" fill="#FDFAFA" />
                            </g>
                            <defs>
                                <clipPath id="clip0_844_663">
                                    <rect width="20" height="20" fill="white" transform="translate(10 10)" />
                                </clipPath>
                            </defs>
                        </svg>

                        <p className="questions__box__button__text">{t("question.button")}</p>
                    </button>
                    <img src={IMAGES.QR} alt="QR" className="questions_box_img" />
                </div>
            </div>
        </div>
    )
}