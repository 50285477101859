import { useEffect } from "react";
import TelegramIcon from "../../../images/login/telegram.png";
import GoogleIcon from "../../../images/login/google.png";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loginCationAction, logoutCationAction } from "../../../Store/User/actions";
import { useSearchParams } from "react-router-dom";

const titles = {
    "login": "LOG IN",
    "signup": "SIGN UP",
    "restore1": "SEND",
    "restore2": "CONFIRM",
    "emailsent": "CLOSE",
    "personal info": "PROFILE",

}

const links = {
    "login": "Sign Up",
    "signup": "Log In", 
}

const suggestions = {
    "login": "New User?",
    "signup": "Already have an account?",
    "restore1": "Enter your email below so we can send you a letter with further instructions",
    "restore2": "Verification passed successfully. Set new password.",
    "emailsent": "We’ve sent you a link to restore your password. Please check your email.",
    "personal info": "Welcome to LAWCHILL!",
}

const apiURL = "https://api.lawchill.ge";

export default function Login({isOpen, setIsOpen, initialType}) {

    const [type, setType] = useState(initialType);

    const [searchParams, setSearchParams] = useSearchParams();

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [repeatedPassword, setRepeatedPassword] = useState();
    const [fullName, setFullName] = useState();

    // holds data which we get after "login" to use "loginCationAction" function
    const [userDataFromServer, setUserDataFromServer] = useState();

    const [errors, setErrors] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        setType(initialType);
    }, [initialType])

    const clearFields = () => {
        setType("login");
        setEmail("");
        setPassword("");
        setRepeatedPassword("");
        setErrors([]);
    }

    const onSuggestionLinkClick = () => {
        if (type === "login") {
            setType("signup");
        } else {
            setType("login");
        }
    }

    const onMainButtonClick = () => {

        setErrors([]);

        if (type === "restore2") {
            if (!password) {
                setErrors(prevState => [...prevState, "password"]);
            }

            if (!repeatedPassword) {
                setErrors(prevState => [...prevState, "password2"]);
            }

            if (password !== repeatedPassword) {
                setErrors(prevState => [...prevState, "password", "password2"]);
            }

            if (!!repeatedPassword && !!password && password === repeatedPassword) {
                axios({
                    method: "post",
                    url: `https://api.lawchill.ge/reset/confirm`,
                    data: {
                        "token": searchParams.get("token"),
                        "password": password,
                    },
                    withCredentials: true,
                }).then(response => {
                    const data = response;

                    if (data.status) {
                        setType("login");
                    } else {
                        setErrors(prevState => [...prevState, "password"]);
                    }
                })
            }

        }

        if (type === "restore1") {
            if (!email) {
                setErrors(prevState => [...prevState, "email"]);
            } 

            if (!!email) {
                axios({
                    method: "post",
                    url: `${apiURL}/reset/password`,
                    data: {
                        email: email,
                    },
                    withCredentials: true,
                }).then(response => {
                    const data = response.data;

                    if (!data.status) {
                        setErrors(prevState => [...prevState, "email"]);
                    } else {
                        setType("emailsent");
                    }
                })
            }
            return
        }

        if (type === "emailsent") {
            setType("restore2");
            return;
        }

        if (type === "login") {
            axios({
                method: "post",
                url: `${apiURL}/email/login`,
                // headers: {
                //     // "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTQ0LCJpYXQiOjE2OTc4MTkxNjAsImV4cCI6MTY5NzkwNTU2MH0.aoR6Z5gaRh98sDr_MuRd-IZrm0L3D6pcVPvrbWVWOSc`,
                // },
                withCredentials: true,
                // headers: {
                //     // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                // },
                data: {
                    password: password,
                    email: email
                }
            }).then(response => {
                const data = response.data;

                if (data.status === "failed") {
                    setErrors(prevState => [...prevState, "email", "password"]);
                } else {
                    dispatch(loginCationAction(data));
                    setIsOpen(false);
                    clearFields();

                }
            })

            return;
        }

        if (type === "signup") {

            if (!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                setErrors(prevState => [...prevState, "email"]);
            }

            if (!password) {
                setErrors(prevState => [...prevState, "password"]);
            }

            if (password.length < 4) {
                setErrors(prevState => [...prevState, "password"]);
            }

            if (!repeatedPassword) {
                setErrors(prevState => [...prevState, "password2"]);   
            }

            if (password !== repeatedPassword) {
                setErrors(prevState => [...prevState, "password", "password2"]);
            }

            if (!!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) && !!password && !!repeatedPassword && (password === repeatedPassword) && password.length >= 4) {
                axios({
                    method: "post",
                    url: `${apiURL}/email/register`,
                    // withCredentials: true,
                    data: {
                        email: email,
                        password: password,
                    }
                }).then(response => {
                    const data = response.data;

                    if (data.status === "ok") {

                        axios({
                            method: "post",
                            url: `${apiURL}/email/login`,
                            withCredentials: true,
                            data: {
                                password: password,
                                email: email
                            }
                        }).then(response2 => {
                            const data2 = response2.data;
                            console.log(data2);
                            setType("personal info");
                            setUserDataFromServer(data2);
                        })
                    } else {
                        setErrors(prevState => [...prevState, "email"]);
                    }
                })
            }
        }

        if (type === "personal info") {
            if (!fullName) {
                setErrors(prevState => [...prevState, "personal info"]);
            }

            if (!!fullName) {
                //setting name to the user
                const apiUrl = 'https://api.lawchill.ge/editAccount';
                axios({
                    method: 'post',
                    url: apiUrl,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                    },
                    data: {
                        "data": {
                            "name": fullName,
                        },
                    },
                    withCredentials: true,
                }).then((res) => {
                    dispatch(loginCationAction(userDataFromServer));
                    setIsOpen(false);
                    clearFields();
                });
            }
        }
    }

    const googleAuthorization = () => {

        const apiUrl = "https://api.lawchill.ge/google/auth"

        window.open(apiUrl, "_blank", "noreferrer");
    }

    const telegramAuthorization = () => {
        window.Telegram.Login.auth({
                bot_id: '6834160877:AAFQKS8noMUH5olsDwWHDjL5K5hvv7fcwmw',
                request_access: 'write',
                auth_url: 'https://api.lawchill.ge/login',
            },
                function onSuccess(user) {
                    if (user) {
                        axios({
                            method: 'post',
                            url: 'https://api.lawchill.ge/login',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            data: {
                                ...user
                            },
                            withCredentials: true,
                        }).then((res) => {
                            console.log(res)
                            if (res.data.status === "error") return;
                            dispatch(loginCationAction(res.data));
                            setIsOpen(false);
                            clearFields();
                        }).catch((err) => {
                            console.log(err)
                        });
                    }
                },
            );
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-widget.js?14';
        script.async = true;
        document.body.appendChild(script);
        console.log(window.Telegram)
    }, []);
    

    const handleClosePopup = () => {
        setIsOpen(false);
        clearFields();
    }

    useEffect(() => {
        const lockPaddingValue = window.innerWidth - document.body.offsetWidth + 'px';

        if (isOpen) {
            document.documentElement.style.overflow = 'hidden'
            // document.body.style.paddingRight = lockPaddingValue;
        } else {
            document.documentElement.style.overflow = 'auto';
            document.body.style.paddingRight = 0;
        }
    }, [isOpen]);


    return (
        <div className={"dashboard__popup modal scroll " + (isOpen ? "show" : "")} style={{display: isOpen ? "flex" : "none"}}>
            <div onClick={handleClosePopup} className="overlay"></div>
            <div className={"dashboard__popup__content modal-content login-popup"}>
                {["login", "signup", "personal info"].includes(type) && (
                    <div className="login-popup__topPart">
                        <span className="title">{titles[type]}</span>
                        <div className="login-popup__topPart__suggestion">
                            {suggestions[type]} <button onClick={onSuggestionLinkClick} className="login-popup__topPart__suggestion__link">{links[type]}</button>
                        </div>
                        {type === "personal info" && (
                            <span className="login-popup__topPart__suggestion" style={{color: "#BCB7B3"}}>You can add some personal details now or later</span>
                        )}
                    </div>
                )}
                {["restore1", "restore2"].includes(type) && (
                    <div className="login-popup__topPart">
                        <div className="login-popup__topPart__title">
                            {type === "restore1" && (
                                <button onClick={() => setType("login")}  className="login-popup__topPart__title__button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.69392 10.4427L0.875541 6.6243C0.641227 6.38998 0.641227 6.01008 0.875541 5.77577L4.69392 1.95739C4.92823 1.72308 5.30813 1.72308 5.54245 1.95739C5.77676 2.19171 5.77676 2.5716 5.54245 2.80592L2.74833 5.60003L13.2998 5.60003V6.80003L2.74833 6.80003L5.54245 9.59414C5.77676 9.82846 5.77676 10.2084 5.54245 10.4427C5.30813 10.677 4.92823 10.677 4.69392 10.4427Z" fill="#FDFAFA"/>
                                    </svg>
                                </button>
                            )}
                            {type !== "personal info" && (
                                <span className="smalltitle">
                                    Restore Password
                                </span>
                            )}
                        </div>
                        <div className="login-popup__topPart__suggestion">
                            {suggestions[type]}
                        </div>
                    </div>
                )}
                {type === "emailsent" && (
                    <div className="login-popup__topPart">
                        <svg className="login-popup__topPart__svg" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="60.5" cy="60" r="59" stroke="#14AE5C" stroke-width="2"/>
                            <path d="M54.5004 71.1201L43.3804 60.0001L39.5938 63.7601L54.5004 78.6667L86.5004 46.6667L82.7404 42.9067L54.5004 71.1201Z" fill="#14AE5C"/>
                        </svg>
                        <span className="login-popup__topPart__emailsent">
                            Email Sent
                        </span>
                        <div className="login-popup__topPart__suggestion">
                            {suggestions[type]}
                        </div>
                    </div>
                )}
                <div className="login-popup__body">
                    {["login", "signup", "restore1"].includes(type) && (
                        <div className="login-popup__body__input">
                            <span className="login-popup__body__input__title">Email</span>
                            <input type="text" placeholder="User@user.com" value={email} onChange={(e) => setEmail(e.target.value)} className="login-popup__body__input__input" style={{borderColor: errors.includes("email") ? "red" : "initial"}}/>
                        </div>
                    )}
                    {["personal info"].includes(type) && (
                        <div className="login-popup__body__input">
                        <span className="login-popup__body__input__title">Full Name</span>
                        <input type="text" placeholder="Giorgi Beridze" value={fullName} onChange={(e) => setFullName(e.target.value)} className="login-popup__body__input__input" style={{borderColor: errors.includes("personal info") ? "red" : "initial"}}/>
                    </div>
                    )}
                    {["login", "signup", "restore2"].includes(type) && (
                        <div className="login-popup__body__input">
                            <span className="login-popup__body__input__title">{type === "restore2" ? "New Password" : "Password"}</span>
                            <input placeholder="***************************" type="password" value={password} onChange={(e) => setPassword(e.target.value)}  className="login-popup__body__input__input" style={{borderColor: errors.includes("password") ? "red" : "initial"}}/>
                        </div>
                    )}
                    {["signup", "restore2"].includes(type) && (
                        <div className="login-popup__body__input">
                            <span className="login-popup__body__input__title">Repeat password</span>
                            <input placeholder="***************************" type="password" value={repeatedPassword} onChange={(e) => setRepeatedPassword(e.target.value)}  className="login-popup__body__input__input" style={{borderColor: errors.includes("password2") ? "red" : "initial"}}/>
                        </div>
                    )}
                    {type === "login" && (
                        <button onClick={() => setType("restore1")} className="login-popup__body__suggestion">
                            Forgot your password ?
                        </button>
                    )}
                    <button onClick={onMainButtonClick} className="login-popup__body__button" style={{backgroundColor: type === "emailsent" ? "#0B0A0A" : "#E75549"}}>
                        <div className="login-popup__body__button__svg">
                            {type === "emailsent" ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <path d="M3.42849 2.92893L17.5706 17.0711M17.5706 2.92893L3.42849 17.0711" stroke="#0B0A0A" stroke-width="2"/>
                              </svg>
                            ) : (
                                <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 4.44444L2.44445 6L5.33333 8.88889H-6V11.1111H5.33333L2.44445 14L4 15.5556L9.55556 10L4 4.44444ZM14 17.7778H5.11111V20H14C15.2222 20 16.2222 19 16.2222 17.7778V2.22222C16.2222 1 15.2222 0 14 0H5.11111V2.22222H14V17.7778Z" fill="#E75549"/>
                                </svg>
                            )}
                        </div>
                        <span className="login-popup__body__button__text">
                            {type === "personal info" ? (
                                "NEXT"
                            ) : (
                                titles[type]
                            )}
                        </span>
                    </button>
                    {type === "personal info" && (
                        <button className="login-popup__body__skipButton">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10.5" r="10" fill="#BCB7B3"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3061 6.55733L16.1245 10.3757C16.3588 10.61 16.3588 10.9899 16.1245 11.2242L12.3061 15.0426C12.0718 15.2769 11.6919 15.2769 11.4576 15.0426C11.2232 14.8083 11.2232 14.4284 11.4576 14.1941L14.2517 11.4H3.7002V10.2H14.2517L11.4576 7.40586C11.2232 7.17154 11.2232 6.79164 11.4576 6.55733C11.6919 6.32301 12.0718 6.32301 12.3061 6.55733Z" fill="#FDFAFA"/>
                            </svg>
                            Skip
                        </button>
                    )}
                    {["login", "signup"].includes(type) && (
                        <div className="login-popup__body__divider">
                            <div className="login-popup__body__divider__line"></div>
                            <span className="login-popup__body__divider__text">OR</span>
                            <div className="login-popup__body__divider__line"></div>
                        </div>
                    )}
                    {["login", "signup"].includes(type) && (
                        <div className="login-popup__body__buttons">
                            <button onClick={telegramAuthorization} className="login-popup__body__buttons__button">
                                <img src={TelegramIcon} className="login-popup__body__buttons__button__svg"/>
                            </button>
                            <button onClick={googleAuthorization} className="login-popup__body__buttons__button">
                                <img src={GoogleIcon} className="login-popup__body__buttons__button__svg"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}