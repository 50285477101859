import { useTranslation } from "react-i18next";

export const Terms = () => {
    const { t, i18n } = useTranslation();

    const handleLinkClick = (e) => {
        e.preventDefault();
        const element = document.getElementById(e.target.href.slice(-1));
        window.scrollTo({
            left: 0,
            top: element.getBoundingClientRect().y + window.pageYOffset - 80,
            behavior: 'smooth'
        });
    };

    const handleScrollTop = () => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className="terms">
            <div></div>
            <nav className="terms__nav">
                <div className="terms__nav__container">
                    <a onClick={handleLinkClick} href="1" className="terms__nav__el chosen">{t("terms.nav1")}</a>
                    <a onClick={handleLinkClick} href="2" className="terms__nav__el">{t("terms.nav2")}</a>
                    <a onClick={handleLinkClick} href="3" className="terms__nav__el">{t("terms.nav3")}</a>
                    <a onClick={handleLinkClick} href="4" className="terms__nav__el">{t("terms.nav4")}</a>
                    <a onClick={handleLinkClick} href="5" className="terms__nav__el">{t("terms.nav5")}</a>
                    <a onClick={handleLinkClick} href="6" className="terms__nav__el">{t("terms.nav6")}</a>
                    <a onClick={handleLinkClick} href="7" className="terms__nav__el">{t("terms.nav7")}</a>
                    <a onClick={handleLinkClick} href="8" className="terms__nav__el">{t("terms.nav8")}</a>
                </div>
            </nav>
            <div className="terms__content">
                <span id="1" className="terms__content__title">{t("terms.main-title")}</span>
                <p className="terms__content__text">{t("terms.main-text")}</p>
                <span id="2" className="terms__content__subtitle">01. {t("terms.title1")}</span>
                <p className="terms__content__text">
                    {t("terms.text1")}</p>
                <span id="3" className="terms__content__subtitle">02. {t("terms.title2")}</span>
                <p className="terms__content__text">
                    {t("terms.text2")}</p>
                <span id="4" className="terms__content__subtitle">03. {t("terms.title3")}</span>
                <p className="terms__content__text">
                    {t("terms.text3")}</p>
                <span id="5" className="terms__content__subtitle">04. {t("terms.title4")}</span>
                <p className="terms__content__text">
                    {t("terms.text4")}</p>
                <span id="6" className="terms__content__subtitle">05. {t("terms.title5")}</span>
                <p className="terms__content__text">
                    {t("terms.text5")}</p>
                <span id="7" className="terms__content__subtitle">06. {t("terms.title6")}</span>
                <p className="terms__content__text">
                    {t("terms.text6")}
                </p>
                <span id="8" className="terms__content__subtitle">07. {t("terms.title7")}
                </span>
                <p className="terms__content__text">
                    {t("terms.text7")}</p>
            </div>
            <svg onClick={handleScrollTop} className="terms__mobile-button" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.92961 6.65685L9.29357 0.292892C9.6841 -0.0976315 10.3173 -0.0976315 10.7078 0.292892L17.0717 6.65685C17.4623 7.04738 17.4623 7.68054 17.0717 8.07107C16.6812 8.46159 16.0481 8.46159 15.6575 8.07107L11.0007 3.41421V21H9.00068V3.41421L4.34383 8.07107C3.9533 8.46159 3.32014 8.46159 2.92961 8.07107C2.53909 7.68054 2.53909 7.04738 2.92961 6.65685Z" fill="#E75549" />
            </svg>
        </div>
    )
}