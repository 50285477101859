import {
    CHANGE_SUBSCRIPTION_CATION_ACTION,
    SET_SUBSCRIPTIONS_CATION_ACTION
} from './constants.js';

const initialState = [{
        "id": 1,
        "name": {
            "ka": "Телеграм поддержка",
            "en": "Telegram support",
            "uk": "",
        },
        "duration": 1,
        "price": 100,
        "description": {
            "ka": [
                "Юридические и бухгалтерские консультации",
                "Доступ к базе часто задаваемых вопросов",
                "Доступ к базе договоров",
                "Помощь в подготовке текстов доверенностей",
                "Чек листы пакетов документов",
            ],
            "en": [
                "Legal and accounting consultations",
                "Access to the database of frequent questions",
                "Access to the database of contracts",
                "Assistance in the preparation of power of attorney texts",
                "Check lists of document packages",
            ],
            "uk": [
                "Legal and accounting consultations",
                "Access to the database of frequent questions",
                "Access to the database of contracts",
                "Assistance in the preparation of power of attorney texts",
                "Check lists of document packages",
            ]
        }
    },
    {
        "id": 2,
        "name": {
            "ka": "Предприниматель",
            "en": "Entrepreneur",
            "uk": "Предприниматель"
        },
        "duration": 6,
        "price": 500,
        "description": {
            "ka": [
                "3 часа юридической консультации",
                "Доступ к платному Телеграм каналу",
                "Подготовка документов для регистрации",
                "Сопровождение при регистрации",
                "Регистрация в налоговой Грузии",
                "Открытие банковского счета",
                "Бухгалтерское сопровождение (сдача отчетов)",
                "Облачное хранение документов",
                "Составление договоров",
            ],
            "en": [
                "3 hours of legal consultation",
                "Access to the paid Telegram chat",
                "Preparation of documents for registration",
                "Support during registration",
                "Registration in the Tax Service of Georgia",
                "Opening a bank account",
                "Accounting support (submission of reports)",
                "Cloud storage of documents",
                "Drafting contracts",
            ], 
            "uk": [
                "3 hours of legal consultation",
                "Access to the paid Telegram chat",
                "Preparation of documents for registration",
                "Support during registration",
                "Registration in the Tax Service of Georgia",
                "Opening a bank account",
                "Accounting support (submission of reports)",
                "Cloud storage of documents",
                "Drafting contracts",
            ], 

        }
    },
    {
        "id": 3,
        "name": {
            "ka": "ООО",
            "en": "LLC",
            "uk": "OOO",
        },
        "duration": 12,
        "price": 1500,
        "description": {
            "ka": [
                "6 часов юридической консультации",
                "Доступ к платному Телеграм каналу для двух сотрудников",
                "Подготовка документов для регистрации",
                "Сопровождение при регистрации",
                "Регистрация в налоговой Грузии",
                "Открытие банковского счета",
                "Бухгалтерское сопровождение (сдача отчетов)",
                "Облачное хранение документов",
                "Составление договоров",
            ],
            "en": [
                "6 hours of legal consultation",
                "Access to the paid Telegram chat for 2 employees",
                "Preparation of documents for registration",
                "Support during registration",
                "Registration in the Tax Service of Georgia",
                "Opening a bank account",
                "Accounting support (submission of reports)",
                "Cloud storage of documents",
                "Drafting contracts",
            ], 
            "uk": [
                "6 hours of legal consultation",
                "Access to the paid Telegram chat for 2 employees",
                "Preparation of documents for registration",
                "Support during registration",
                "Registration in the Tax Service of Georgia",
                "Opening a bank account",
                "Accounting support (submission of reports)",
                "Cloud storage of documents",
                "Drafting contracts",
            ]
        }
    }
];

export const subscriptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBSCRIPTIONS_CATION_ACTION: {
            return (
                action.payload
            )
        }
        case CHANGE_SUBSCRIPTION_CATION_ACTION: {
            return state.map((item) => {
                if (item.id != action.payload.id) {
                    return item;
                }
                return {
                    ...item,
                    "name": {
                        'ka': action.payload.name.ka,
                        'en': action.payload.name.en,
                        "uk": action.payload.name.uk,
                    },
                    "duration": action.payload.duration,
                    "price": action.payload.price,
                    "description": {
                        "ka": [
                            ...action.payload.description.ka
                        ],
                        "en": [
                            ...action.payload.description.en
                        ],
                        "uk": [
                            ...action.payload.description.uk
                        ]
                    }
                };
            });
        }
        default:
            return state;
    }
}