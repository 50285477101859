import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTS } from "../Constants/Routes";
import IMAGESLOGIN from "../images/login/images";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { userInfoSelector } from "../Store/User/selectors";
import { loginCationAction, logoutCationAction } from "../Store/User/actions";
import { languageSelector, currentLanguageSelector } from "../Store/Language/selectors";
import { changeLanguageCationAction } from "../Store/Language/actions";
import { changeDataCationAction } from "../Store/User/actions";
import { setCategoryCationAction } from "../Store/Categories/actions";
import { setQuestionsCationAction } from "../Store/Questions/actions";
import { setSubscriptionCationAction } from "../Store/Subscriptions/actions";
import { setServicesCationAction } from "../Store/Services/actions";
import { setMyServiceCationAction } from "../Store/MyServices/actions";
import Login from "./Dashboard/Popup/Login";
import { LanguageDropdown } from "./Dashboard/LanguangeDropdown";
import IMAGES from "../images/login/images";
import { useSearchParams } from "react-router-dom";

export const Header = () => {
    const { t, i18n } = useTranslation();

    const [searchParams, serSearchParams] = useSearchParams();

    const currentLanguage = useSelector(currentLanguageSelector);

    const user = useSelector(userInfoSelector);

    const dispatch = useDispatch();

    const Navigate = useNavigate();

    const [loginModal, setLoginModal] = useState(false);
    const [loginModalData, setLoginModalData] = useState(false);
    const [initialType, setInitialType] = useState("login");

    const [profileName, setProfileName] = useState('');
    const [profileEmail, setProfileEmail] = useState('');
    const [profileTelegram, setProfileTelegram] = useState('');

    const [loginTelephone, setLoginTelephone] = useState('');

    const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);
    const [isPhoneInputDisable, setIsPhoneInputDisable] = useState(false);

    const handleLoginTelephoneChange = (e) => {
        if (e.target.value.length < 12) {
            setLoginTelephone(e.target.value);
        }
    }

    const toggleLoginModal = () => {
        if (loginModal) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal');
        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal');
        }
        setLoginModal(prev => !prev);
    };

    const toggleLoginModalData = () => {
        if (loginModalData) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal');
        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal');
        }
        setLoginModalData(prev => !prev);
    }

    useEffect(() => {
        const apiUrl = 'https://api.lawchill.ge/getAccount';

        axios({
            method: 'get',
            url: apiUrl,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            withCredentials: true,

        }).then((res) => {
            if (res.data.status == "success") dispatch(loginCationAction(res.data));
        }).catch((err) => {
            console.log(err)
        });

        axios({
            method: 'get',
            url: 'https://api.lawchill.ge/getSubscriptions',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            withCredentials: true,
        }).then((res) => {
            console.log("SUUUBSCRIBTION",res.data);
            dispatch(setSubscriptionCationAction(res.data));
        }).catch((err) => {
            console.log(err)
        });

        if (!!searchParams.get("token")) {
            setIsLoginPopupOpen(true);
            setInitialType("restore2");
        }
    }, []);

    useEffect(() => {
        axios({
            method: 'get',
            url: 'https://api.lawchill.ge/getCategories',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            withCredentials: true,
        }).then((res) => {
            console.log("categories", res.data);
            dispatch(setCategoryCationAction(res.data.categories))
        }).catch((err) => {
            console.log(err)
        });

        axios({
            method: 'get',
            url: 'https://api.lawchill.ge/getQuestions',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
                
            },
            withCredentials: true,
        }).then((res) => {
            console.log("questions", res.data);
            dispatch(setQuestionsCationAction(res.data.questions));
        }).catch((err) => {
            console.log(err)
        });

        // axios({
        //     method: 'get',
        //     url: 'https://api.lawchill.ge/getQuestions',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //         // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
        //     },
        //     withCredentials: true,
        // }).then((res) => {
        //     dispatch(setQuestionsCationAction(res.data.questions));
        // }).catch((err) => {
        //     console.log(err)
        // });

        axios({
            method: 'get',
            url: 'https://api.lawchill.ge/getServices?categories=false',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            withCredentials: true,
        }).then((res) => {
            console.log("services", res.data);
            dispatch(setServicesCationAction(res.data.services));
        }).catch((err) => {
            console.log(err)
        });

        axios({
            method: 'get',
            url: 'https://api.lawchill.ge/getRequests',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            withCredentials: true,
        }).then((res) => {
            console.log("requests", res.data);
            dispatch(setMyServiceCationAction(res.data.requests));
        }).catch((err) => {
            console.log(err)
        });
    }, [user]);

    useEffect(() => {
        if (loginTelephone.length === 11) {
            setIsPhoneCorrect(true);
            setIsPhoneInputDisable(true);
            const apiUrl = 'https://api.lawchill.ge/login';

            axios({
                method: 'post',
                url: apiUrl,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    phone: loginTelephone
                },
                withCredentials: true,
            }).then((res) => {
                if (res.data.status === "error") return;
                if (res.data.status === "registered") continueReg();
                if (res.data.status === "logged") toggleLoginModal();
                dispatch(loginCationAction(res.data));
                setLoginTelephone('');
            }).catch((err) => {
                setIsPhoneCorrect(false);
            });
            setIsPhoneInputDisable(false);
        }
    }, [loginTelephone]);

    const continueReg = () => {
        toggleLoginModal();
        toggleLoginModalData();
    }

    const handleAddProfileData = () => {
        const apiUrl = 'https://api.lawchill.ge/editAccount';
        // const nameChecker = /^[а-яА-ЯёЁa-zA-Z]+$/;
        axios({
            method: 'post',
            url: apiUrl,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: {
                "data": {
                    "name": profileName,
                    "email": profileEmail,
                },
            },
            withCredentials: true,
        }).then((res) => {
            dispatch(changeDataCationAction({
                "name": profileName,
                "email": profileEmail,
            }))
            toggleLoginModalData();
        });
    }


    const logout = () => {
        if (location.pathname.includes('profile') || location.pathname.includes('dashboard')) {
            Navigate(ROUTS.HOME + ROUTS.ABOUT);
        }
        const apiUrl = 'https://api.lawchill.ge/logout';
        axios({
            method: 'post',
            url: apiUrl,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            withCredentials: true,
        }).then((res) => {
            dispatch(logoutCationAction());
        });
    }


    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (!isMobileMenuOpen) {
            document.body.style.paddingRight = '0px';
            document.body.classList.remove('active-modal');
        } else {
            document.body.style.paddingRight = window.innerWidth - document.getElementsByTagName('body')[0].offsetWidth + 'px';
            document.body.classList.add('active-modal');
        }
    }, [isMobileMenuOpen]);

    useEffect(() => {
        window.addEventListener('popstate', mobileMenuClose)
        return () => {
            window.removeEventListener('popstate', mobileMenuClose)
        }
    }, []);

    const mobileMenuClose = () => {
        setIsMobileMenuOpen(false)
    }
    useEffect(() => {
        if (i18n.language === 'en') {
            dispatch(changeLanguageCationAction("en"));
        }
    }, []);

    const handleLanguageChange = () => {
        dispatch(changeLanguageCationAction("en"));
    }

    const handleNumberCheck = () => {
        if (loginTelephone.length !== 11) {
            setIsPhoneCorrect(false);
        } else {
            setIsPhoneCorrect(true);
        }
    }

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleClickOutside = () => {
        setIsMenuOpen(false);
        setTimeout(() => {
            document.removeEventListener("click", handleClickOutside);
        }, 0);
    }

    const handleOpenMenu = () => {
        setIsMenuOpen(prev => !prev);
        if (!isMenuOpen) {
            setTimeout(() => {
                document.addEventListener("click", handleClickOutside);
            }, 0);
        }
    }

    const handleGoProfile = () => {
        Navigate(ROUTS.HOME + ROUTS.PROFILE)
    }

    // const handleLogin = () => {
    //     toggleLoginModal();
    // }

    // useEffect(() => {
    //     document.querySelector("#loginButton")?.addEventListener("handleLogin", toggleLoginModal);
    //     return () => {
    //         document.querySelector("#loginButton")?.removeEventListener("handleLogin", toggleLoginModal);
    //     }
    // }, [user.status]);

    useEffect(() => {

        dispatch(changeLanguageCationAction(i18n.language));
    }, []);

    const handleLogin = () => {
        // window.Telegram.Login.auth({
        //     bot_id: '5671215145',
        //     request_access: 'write',
        //     auth_url: 'https://api.lawchill.ge/login',
        // },
        //     function onSuccess(user) {
        //         if (user) {
        //             axios({
        //                 method: 'post',
        //                 url: 'https://api.lawchill.ge/login',
        //                 headers: {
        //                     'Content-Type': 'application/x-www-form-urlencoded'
        //                 },
        //                 data: {
        //                     ...user
        //                 },
        //                 withCredentials: true,
        //             }).then((res) => {
        //                 console.log(res)
        //                 if (res.data.status === "error") return;
        //                 dispatch(loginCationAction(res.data));
        //             }).catch((err) => {
        //                 console.log(err)
        //             });
        //         }
        //     },
        // );

        setIsLoginPopupOpen(true);
    }

    const changeLanguage = (id, value) => {
        dispatch(changeLanguageCationAction(value.language.toLowerCase()));
        i18n.changeLanguage(value.language.toLowerCase());
    } 

    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);

    return (
        <header className="header">
            <Login isOpen={isLoginPopupOpen} setIsOpen={setIsLoginPopupOpen} initialType={initialType}/>
            <div className="header__left">
                <input onChange={() => setIsMobileMenuOpen(prev => !prev)} checked={isMobileMenuOpen} type="checkbox" id="mobile-menu-button" className="header__left__mobile-button" />
                <label htmlFor="mobile-menu-button" className="header__left__mobile-button__label" />
                <div className="header__left__mobile-menu">
                    <div className="header__left__mobile-menu__header">
                        {/* <input type="checkbox" id="toggle-button" className="header__left__mobile-menu__header__toggle-button" onChange={handleLanguageChange} checked={currentLanguage === "en"} />
                        <label htmlFor="toggle-button" className="header__left__mobile-menu__header__text-ru">KA</label>
                        <label htmlFor="toggle-button" className="header__left__mobile-menu__header__text-en">EN</label> */}
                        <Link className="header__left__mobile-menu__header__logo__box" onClick={() => setIsMobileMenuOpen(prev => !prev)} to={ROUTS.MAINPAGE}>
                            <svg className="header__left__mobile-menu__header__logo" width="196" height="30" viewBox="0 0 196 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.4648 24.3562V29.694H3.78711V16.2769H9.32394V24.3562H23.4648Z" fill="#FDFAFA" />
                                <path d="M51.06 29.694H45.102L43.4518 24.9825H32.6911L31.0409 29.694H25.0801L30.2072 16.2769H35.7412L34.5604 19.6504H41.5825L40.4017 16.2769H45.9328L51.06 29.694Z" fill="#FDFAFA" />
                                <path d="M65.392 16.2769L61.8297 29.694H56.6173L53.5273 16.2769H58.626L59.3259 19.9123L60.3673 16.2769H65.392Z" fill="#FDFAFA" />
                                <path d="M80.798 16.2769L77.7081 29.694H72.4956L69.0898 16.2769H73.9609L75.0022 19.9123L75.7022 16.2769H80.798Z" fill="#FDFAFA" />
                                <path d="M108.054 19.8525C107.751 21.7937 107.075 23.6577 106.062 25.3412C105.183 26.7881 103.944 27.9819 102.466 28.8058C100.953 29.6162 99.259 30.0267 97.5435 29.9986C95.3072 29.9986 93.3942 29.5706 91.8047 28.7147C90.2284 27.8796 88.9522 26.5728 88.1542 24.9768C87.3215 23.3408 86.9042 21.3632 86.9023 19.044V16.2797H92.6412V19.044C92.611 20.0506 92.8054 21.0513 93.2102 21.9734C93.5623 22.7473 94.1446 23.3935 94.8775 23.8238C95.6867 24.2694 96.6005 24.4897 97.5236 24.4615C98.2848 24.4727 99.0362 24.2884 99.7059 23.9263C100.374 23.5483 100.936 23.009 101.342 22.3577C101.818 21.5901 102.147 20.7406 102.312 19.8525H108.054Z" fill="#FDFAFA" />
                                <path d="M134.317 16.2769V29.694H128.781V17.7914H118.236V29.694H112.699V16.2769H134.317Z" fill="#FDFAFA" />
                                <path d="M146.116 16.2797H140.377V29.6969H146.116V16.2797Z" fill="#FDFAFA" />
                                <path d="M171.855 24.3562V29.694H152.178V16.2769H157.712V24.3562H171.855Z" fill="#FDFAFA" />
                                <path d="M195.572 24.3562V29.694H175.895V16.2769H181.431V24.3562H195.572Z" fill="#FDFAFA" />
                                <path d="M6.53488 0.30603H0.998047V16.2797H6.53488V0.30603Z" fill="#FDFAFA" />
                                <path d="M37.0406 0.30603H33.5239L27.418 16.2797H32.9548L35.2851 9.62376L37.6153 16.2797H43.1465L37.0406 0.30603Z" fill="#FDFAFA" />
                                <path d="M52.7596 0.303101L55.8381 16.2768H50.7394L47.0605 0.303101H52.7596Z" fill="#FDFAFA" />
                                <path d="M66.5975 0.303101L71.1727 16.2768H66.3016L64.4949 9.15964L62.6028 16.2768H57.5781L62.1533 0.303101H66.5975Z" fill="#FDFAFA" />
                                <path d="M81.6888 0.303101L78.0099 16.2768H72.9141L75.9926 0.303101H81.6888Z" fill="#FDFAFA" />
                                <path d="M105.265 10.1476H99.5345C99.3536 9.26533 99.0143 8.42326 98.533 7.66226C98.1218 7.01299 97.5607 6.47209 96.8969 6.08511C96.2384 5.71732 95.4943 5.53068 94.7403 5.54421C93.8174 5.51519 92.9036 5.73442 92.0942 6.17905C91.361 6.61084 90.7795 7.25928 90.4297 8.0352C90.0256 8.96246 89.8312 9.96766 89.8607 10.9788V16.2797H84.1133V10.9788C84.1133 8.65771 84.5306 6.6801 85.3652 5.04601C86.162 3.44841 87.4344 2.13746 89.0071 1.29386C90.5966 0.434116 92.5096 0.00329331 94.7459 0.00139542C96.4705 -0.0271712 98.1741 0.383286 99.6967 1.19422C101.177 2.01722 102.418 3.21237 103.296 4.66169C104.306 6.3432 104.975 8.20731 105.265 10.1476V10.1476Z" fill="#FDFAFA" />
                                <path d="M131.528 0.303101V16.2768H109.91V0.303101H115.447V12.4534H125.991V0.303101H131.528Z" fill="#FDFAFA" />
                                <path d="M143.329 0.30603H137.59V16.2797H143.329V0.30603Z" fill="#FDFAFA" />
                                <path d="M154.923 0.30603H149.389V16.2797H154.923V0.30603Z" fill="#FDFAFA" />
                                <path d="M178.644 0.30603H173.107V16.2797H178.644V0.30603Z" fill="#FDFAFA" />
                                <path d="M6.53488 0.30603H0.998047V16.2797H6.53488V0.30603Z" fill="url(#paint0_linear_844_3557)" />
                                <path d="M37.0406 0.30603H33.5239L27.418 16.2797H32.9548L35.2851 9.62376L37.6153 16.2797H43.1465L37.0406 0.30603Z" fill="url(#paint1_linear_844_3557)" />
                                <path d="M52.7596 0.303101L55.8381 16.2768H50.7394L47.0605 0.303101H52.7596Z" fill="url(#paint2_linear_844_3557)" />
                                <path d="M66.5975 0.303101L71.1727 16.2768H66.3016L64.4949 9.15964L62.6028 16.2768H57.5781L62.1533 0.303101H66.5975Z" fill="url(#paint3_linear_844_3557)" />
                                <path d="M81.6888 0.303101L78.0099 16.2768H72.9141L75.9926 0.303101H81.6888Z" fill="url(#paint4_linear_844_3557)" />
                                <path d="M105.265 10.1476H99.5345C99.3536 9.26533 99.0143 8.42326 98.533 7.66226C98.1218 7.01299 97.5607 6.47209 96.8969 6.08511C96.2384 5.71732 95.4943 5.53068 94.7403 5.54421C93.8174 5.51519 92.9036 5.73442 92.0942 6.17905C91.361 6.61084 90.7795 7.25928 90.4297 8.0352C90.0256 8.96246 89.8312 9.96766 89.8607 10.9788V16.2797H84.1133V10.9788C84.1133 8.65771 84.5306 6.6801 85.3652 5.04601C86.162 3.44841 87.4344 2.13746 89.0071 1.29386C90.5966 0.434116 92.5096 0.00329331 94.7459 0.00139542C96.4705 -0.0271712 98.1741 0.383286 99.6967 1.19422C101.177 2.01722 102.418 3.21237 103.296 4.66169C104.306 6.3432 104.975 8.20731 105.265 10.1476V10.1476Z" fill="url(#paint5_linear_844_3557)" />
                                <path d="M131.528 0.303101V16.2768H109.91V0.303101H115.447V12.4534H125.991V0.303101H131.528Z" fill="url(#paint6_linear_844_3557)" />
                                <path d="M143.329 0.30603H137.59V16.2797H143.329V0.30603Z" fill="url(#paint7_linear_844_3557)" />
                                <path d="M154.923 0.30603H149.389V16.2797H154.923V0.30603Z" fill="url(#paint8_linear_844_3557)" />
                                <path d="M178.644 0.30603H173.107V16.2797H178.644V0.30603Z" fill="url(#paint9_linear_844_3557)" />
                                <defs>
                                    <linearGradient id="paint0_linear_844_3557" x1="3.76646" y1="0.163688" x2="3.76646" y2="20.9542" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                        <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                        <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_844_3557" x1="35.2822" y1="0.163688" x2="35.2822" y2="20.9542" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                        <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                        <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_844_3557" x1="51.4507" y1="0.163605" x2="51.4507" y2="20.9541" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                        <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                        <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                                    </linearGradient>
                                    <linearGradient id="paint3_linear_844_3557" x1="64.3754" y1="0.163605" x2="64.3754" y2="20.9541" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                        <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                        <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                                    </linearGradient>
                                    <linearGradient id="paint4_linear_844_3557" x1="77.3014" y1="0.163605" x2="77.3014" y2="20.9541" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                        <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                        <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                                    </linearGradient>
                                    <linearGradient id="paint5_linear_844_3557" x1="94.689" y1="0.163666" x2="94.689" y2="20.9542" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                        <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                        <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                                    </linearGradient>
                                    <linearGradient id="paint6_linear_844_3557" x1="120.719" y1="0.163605" x2="120.719" y2="20.9541" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                        <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                        <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                                    </linearGradient>
                                    <linearGradient id="paint7_linear_844_3557" x1="140.461" y1="0.163688" x2="140.461" y2="20.9542" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                        <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                        <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                                    </linearGradient>
                                    <linearGradient id="paint8_linear_844_3557" x1="152.157" y1="0.163688" x2="152.157" y2="20.9542" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                        <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                        <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                                    </linearGradient>
                                    <linearGradient id="paint9_linear_844_3557" x1="175.876" y1="0.163688" x2="175.876" y2="20.9542" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                        <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                        <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </Link>
                        <label htmlFor="mobile-menu-button" className="header__left__mobile-menu__header__cross" />
                    </div>
                    <nav className="header__left__mobile-menu__nav">
                        <LanguageDropdown id={1} handler={changeLanguage} defaultValue={{language: currentLanguage.toUpperCase(), image: IMAGESLOGIN[currentLanguage.toUpperCase()]}} content={[{language: "EN", image: IMAGESLOGIN.EN}, {language: "KA", image: IMAGESLOGIN.KA}, {language: "UK", image: IMAGESLOGIN.UK}]} type={"mobile"}/>
                        <Link onClick={() => setIsMobileMenuOpen(prev => !prev)} to={ROUTS.MAINPAGE} className="header__left__mobile-menu__nav__el">{t("header.main")}</Link>
                        <Link onClick={() => setIsMobileMenuOpen(prev => !prev)} to={ROUTS.ABOUT} className="header__left__mobile-menu__nav__el">{t("header.about")}</Link>
                        <Link onClick={() => setIsMobileMenuOpen(prev => !prev)} to={ROUTS.FAQ} className="header__left__mobile-menu__nav__el">FAQ</Link>
                        <Link onClick={() => setIsMobileMenuOpen(prev => !prev)} to={ROUTS.CONTACTS} className="header__left__mobile-menu__nav__el">{t("header.contacts")}</Link>
                        {user.user.status == "admin" && (<Link onClick={() => setIsMobileMenuOpen(prev => !prev)} to={ROUTS.DASHBOARD} className="header__left__mobile-menu__nav__el">{t("header.dashboard")}</Link>)}
                    </nav>
                    <div className="header__left__mobile-menu__login">
                        {user.status !== "not logged" ?
                            (<>
                                <Link onClick={() => setIsMobileMenuOpen(prev => !prev)} to={ROUTS.HOME + ROUTS.PROFILE} className="header__left__mobile-menu__login__profile">{t("header.profile")}</Link>
                                <button onClick={logout} className="header__left__mobile-menu__login__logout">{t("header.logout")}</button>
                            </>) :
                            (<>
                                <button onClick={handleLogin} className="header__left__mobile-menu__login__signin">
                                    <svg className="header__left__mobile-menu__login__signin__img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="12" fill="#0B0A0A" />
                                        <g clip-path="url(#clip0_1315_26638)">
                                            <path d="M8.40039 8.66667L7.46706 9.6L9.20039 11.3333H2.40039V12.6667H9.20039L7.46706 14.4L8.40039 15.3333L11.7337 12L8.40039 8.66667ZM14.4004 16.6667H9.06706V18H14.4004C15.1337 18 15.7337 17.4 15.7337 16.6667V7.33333C15.7337 6.6 15.1337 6 14.4004 6H9.06706V7.33333H14.4004V16.6667Z" fill="#FDFAFA" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1315_26638">
                                                <rect width="12" height="12" fill="white" transform="translate(6 6)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p className="header__left__mobile-menu__login__signin__text">{t("header.login")}</p>
                                </button>
                            </>)}
                        <div className="hidden">
                            <script async src="https://telegram.org/js/telegram-widget.js?21" data-telegram-login="lgowq_bot" data-size="medium" data-userpic="false" data-auth-url="https://api.lawchill.ge/login"></script>
                        </div>
                    </div>
                </div>
                {/* <input type="checkbox" id="toggle-button" className="header__left__toggle-button" onChange={handleLanguageChange} checked={language.english} />
                <label htmlFor="toggle-button" className="header__left__toggle-button__text-ru">KA</label>
                <label htmlFor="toggle-button" className="header__left__toggle-button__text-en">EN</label> */}
                <LanguageDropdown id={1} handler={changeLanguage} defaultValue={{language: currentLanguage.toUpperCase(), image: IMAGESLOGIN[currentLanguage.toUpperCase()]}} content={[{language: "EN", image: IMAGESLOGIN.EN}, {language: "KA", image: IMAGESLOGIN.KA}, {language: "UK", image: IMAGESLOGIN.UK}]} />
                <Link to={ROUTS.MAINPAGE}>
                    <svg className="header__left__logo" width="196" height="30" viewBox="0 0 196 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.9788 24.3557V29.6936H3.29004V16.2764H8.83V24.3557H22.9788Z" fill="#0B0A0A" />
                        <path d="M50.5903 29.6936H44.629L42.9778 24.982H32.211L30.5598 29.6936H24.5957L29.7257 16.2764H35.2628L34.0814 19.6499H41.1074L39.926 16.2764H45.4602L50.5903 29.6936Z" fill="#0B0A0A" />
                        <path d="M64.9299 16.2764L61.3657 29.6936H56.1503L53.0586 16.2764H58.1601L58.8605 19.9118L59.9024 16.2764H64.9299Z" fill="#0B0A0A" />
                        <path d="M80.3456 16.2764L77.254 29.6936H72.0385L68.6309 16.2764H73.5047L74.5466 19.9118L75.2469 16.2764H80.3456Z" fill="#0B0A0A" />
                        <path d="M107.616 19.8521C107.313 21.7933 106.636 23.6573 105.623 25.3408C104.744 26.7877 103.504 27.9815 102.024 28.8054C100.511 29.6158 98.8158 30.0264 97.0994 29.9983C94.8617 29.9983 92.9477 29.5703 91.3573 28.7143C89.7801 27.8792 88.5032 26.5724 87.7048 24.9764C86.8716 23.3404 86.454 21.3628 86.4521 19.0436V16.2793H92.1942V19.0436C92.164 20.0502 92.3586 21.0509 92.7636 21.973C93.1158 22.7469 93.6985 23.3932 94.4319 23.8234C95.2414 24.2691 96.1557 24.4893 97.0794 24.4611C97.8411 24.4723 98.5928 24.2881 99.263 23.9259C99.931 23.5479 100.494 23.0086 100.9 22.3573C101.376 21.5897 101.705 20.7402 101.871 19.8521H107.616Z" fill="#0B0A0A" />
                        <path d="M133.895 16.2764V29.6936H128.355V17.7909H117.805V29.6936H112.265V16.2764H133.895Z" fill="#0B0A0A" />
                        <path d="M145.701 16.2793H139.959V29.6965H145.701V16.2793Z" fill="#0B0A0A" />
                        <path d="M171.453 24.3557V29.6936H151.765V16.2764H157.302V24.3557H171.453Z" fill="#0B0A0A" />
                        <path d="M195.185 24.3557V29.6936H175.496V16.2764H181.036V24.3557H195.185Z" fill="#0B0A0A" />
                        <path d="M6.03997 0.305664H0.5V16.2793H6.03997V0.305664Z" fill="#0B0A0A" />
                        <path d="M36.5636 0.305664H33.0449L26.9355 16.2793H32.4755L34.8071 9.6234L37.1386 16.2793H42.6729L36.5636 0.305664Z" fill="#0B0A0A" />
                        <path d="M52.2901 0.302734L55.3704 16.2764H50.2689L46.5879 0.302734H52.2901Z" fill="#0B0A0A" />
                        <path d="M66.1368 0.302734L70.7145 16.2764H65.8407L64.033 9.15928L62.1398 16.2764H57.1123L61.69 0.302734H66.1368Z" fill="#0B0A0A" />
                        <path d="M81.2367 0.302734L77.5557 16.2764H72.457L75.5373 0.302734H81.2367Z" fill="#0B0A0A" />
                        <path d="M104.826 10.1476H99.092C98.9111 9.26533 98.5715 8.42326 98.0899 7.66226C97.6785 7.01299 97.1171 6.47209 96.453 6.08511C95.7941 5.71732 95.0495 5.53068 94.2951 5.54421C93.3717 5.51519 92.4574 5.73442 91.6475 6.17905C90.9139 6.61084 90.3321 7.25928 89.9821 8.0352C89.5777 8.96247 89.3833 9.96766 89.4127 10.9788V16.2797H83.6621V10.9788C83.6621 8.65771 84.0797 6.6801 84.9147 5.04601C85.7119 3.44841 86.9851 2.13746 88.5587 1.29386C90.1491 0.434116 92.0632 0.00329331 94.3008 0.00139542C96.0264 -0.0271712 97.7309 0.383286 99.2543 1.19422C100.736 2.01722 101.977 3.21237 102.856 4.66168C103.866 6.3432 104.536 8.20731 104.826 10.1476Z" fill="#0B0A0A" />
                        <path d="M131.105 0.302734V16.2764H109.475V0.302734H115.015V12.4531H125.565V0.302734H131.105Z" fill="#0B0A0A" />
                        <path d="M142.911 0.305664H137.169V16.2793H142.911V0.305664Z" fill="#0B0A0A" />
                        <path d="M154.512 0.305664H148.975V16.2793H154.512V0.305664Z" fill="#0B0A0A" />
                        <path d="M178.246 0.305664H172.706V16.2793H178.246V0.305664Z" fill="#0B0A0A" />
                        <path d="M6.03997 0.305664H0.5V16.2793H6.03997V0.305664Z" fill="url(#paint0_linear_715_11582)" />
                        <path d="M36.5636 0.305664H33.0449L26.9355 16.2793H32.4755L34.8071 9.6234L37.1386 16.2793H42.6729L36.5636 0.305664Z" fill="url(#paint1_linear_715_11582)" />
                        <path d="M52.2901 0.302734L55.3704 16.2764H50.2689L46.5879 0.302734H52.2901Z" fill="url(#paint2_linear_715_11582)" />
                        <path d="M66.1368 0.302734L70.7145 16.2764H65.8407L64.033 9.15928L62.1398 16.2764H57.1123L61.69 0.302734H66.1368Z" fill="url(#paint3_linear_715_11582)" />
                        <path d="M81.2367 0.302734L77.5557 16.2764H72.457L75.5373 0.302734H81.2367Z" fill="url(#paint4_linear_715_11582)" />
                        <path d="M104.826 10.1476H99.092C98.9111 9.26533 98.5715 8.42326 98.0899 7.66226C97.6785 7.01299 97.1171 6.47209 96.453 6.08511C95.7941 5.71732 95.0495 5.53068 94.2951 5.54421C93.3717 5.51519 92.4574 5.73442 91.6475 6.17905C90.9139 6.61084 90.3321 7.25928 89.9821 8.0352C89.5777 8.96247 89.3833 9.96766 89.4127 10.9788V16.2797H83.6621V10.9788C83.6621 8.65771 84.0797 6.6801 84.9147 5.04601C85.7119 3.44841 86.9851 2.13746 88.5587 1.29386C90.1491 0.434116 92.0632 0.00329331 94.3008 0.00139542C96.0264 -0.0271712 97.7309 0.383286 99.2543 1.19422C100.736 2.01722 101.977 3.21237 102.856 4.66168C103.866 6.3432 104.536 8.20731 104.826 10.1476Z" fill="url(#paint5_linear_715_11582)" />
                        <path d="M131.105 0.302734V16.2764H109.475V0.302734H115.015V12.4531H125.565V0.302734H131.105Z" fill="url(#paint6_linear_715_11582)" />
                        <path d="M142.911 0.305664H137.169V16.2793H142.911V0.305664Z" fill="url(#paint7_linear_715_11582)" />
                        <path d="M154.512 0.305664H148.975V16.2793H154.512V0.305664Z" fill="url(#paint8_linear_715_11582)" />
                        <path d="M178.246 0.305664H172.706V16.2793H178.246V0.305664Z" fill="url(#paint9_linear_715_11582)" />
                        <defs>
                            <linearGradient id="paint0_linear_715_11582" x1="3.26998" y1="0.163322" x2="3.26998" y2="20.9538" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0" />
                                <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_715_11582" x1="34.8042" y1="0.163322" x2="34.8042" y2="20.9538" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0" />
                                <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_715_11582" x1="50.9806" y1="0.163239" x2="50.9806" y2="20.9538" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0" />
                                <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_715_11582" x1="63.9134" y1="0.163239" x2="63.9134" y2="20.9538" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0" />
                                <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                            </linearGradient>
                            <linearGradient id="paint4_linear_715_11582" x1="76.8469" y1="0.163239" x2="76.8469" y2="20.9538" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0" />
                                <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                            </linearGradient>
                            <linearGradient id="paint5_linear_715_11582" x1="94.2438" y1="0.163666" x2="94.2438" y2="20.9542" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0" />
                                <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                            </linearGradient>
                            <linearGradient id="paint6_linear_715_11582" x1="120.29" y1="0.163239" x2="120.29" y2="20.9538" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0" />
                                <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                            </linearGradient>
                            <linearGradient id="paint7_linear_715_11582" x1="140.041" y1="0.163322" x2="140.041" y2="20.9538" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0" />
                                <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                            </linearGradient>
                            <linearGradient id="paint8_linear_715_11582" x1="151.745" y1="0.163322" x2="151.745" y2="20.9538" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0" />
                                <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                            </linearGradient>
                            <linearGradient id="paint9_linear_715_11582" x1="175.476" y1="0.163322" x2="175.476" y2="20.9538" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" stop-opacity="0" />
                                <stop offset="0.18" stop-color="#BFBFBF" stop-opacity="0.21" />
                                <stop offset="0.63" stop-color="#4F4F4F" stop-opacity="0.57" />
                                <stop offset="1" stop-color="#282828" stop-opacity="0.7" />
                            </linearGradient>
                        </defs>
                    </svg>
                </Link>
            </div>
            <div className="header__right">
                <nav className="header__right__nav">
                    <Link to={ROUTS.ABOUT} className={location.pathname.includes('/about') ? "header__right__nav__link actived" : "header__right__nav__link"}>{t("header.about")}</Link>
                    <Link to={ROUTS.FAQ} className={location.pathname.includes('/FAQ') ? "header__right__nav__link actived" : "header__right__nav__link"}>FAQ</Link>
                    <Link to={ROUTS.CONTACTS} className={location.pathname.includes('/contacts') ? "header__right__nav__link actived" : "header__right__nav__link"}>{t("header.contacts")}</Link>
                    {user.user.status == "admin" && (<Link to={ROUTS.HOME + ROUTS.DASHBOARD} className={location.pathname.includes('/dashboard') ? "header__right__nav__link actived" : "header__right__nav__link"}>{t("header.dashboard")}</Link>)}
                </nav>
                <div className="hidden">
                    <script async src="https://telegram.org/js/telegram-widget.js?21" data-telegram-login="lgowq_bot" data-size="medium" data-userpic="false" data-auth-url="https://api.lawchill.ge/login"></script>
                </div>
                {user.status == "not logged" ? (
                    <>
                        <button id="loginButton" onClick={handleLogin} className="header__right__login">
                            <svg className="header__right__login__img" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="#FDFAFA" />
                                <g clip-path="url(#clip0_766_5841)">
                                    <path d="M14 14.4444L12.4444 16L15.3333 18.8889H4V21.1111H15.3333L12.4444 24L14 25.5556L19.5556 20L14 14.4444ZM24 27.7778H15.1111V30H24C25.2222 30 26.2222 29 26.2222 27.7778V12.2222C26.2222 11 25.2222 10 24 10H15.1111V12.2222H24V27.7778Z" fill="#0B0A0A" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_766_5841">
                                        <rect width="20" height="20" fill="white" transform="translate(10 10)" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <p className="header__right__login__text">{t("header.login")}</p>
                        </button>
                    </>
                ) : (user.user.status == "admin" ? (
                    <button onClick={logout} className="header__right__profile-logout">{t("header.logout")}</button>
                ) : (
                    <div className="header__right__profile">
                        <svg onClick={window.innerWidth > 850 ? handleOpenMenu : handleGoProfile} className="header__right__profile-img" width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="24.943" cy="24" rx="24.0572" ry="24" fill="#0B0A0A" />
                            <path d="M20.181 23.6045C19.0859 23.6045 18.1972 24.4912 18.1972 25.5837C18.1972 26.6762 19.0859 27.5628 20.181 27.5628C21.2761 27.5628 22.1649 26.6762 22.1649 25.5837C22.1649 24.4912 21.2761 23.6045 20.181 23.6045ZM29.7037 23.6045C28.6086 23.6045 27.7198 24.4912 27.7198 25.5837C27.7198 26.6762 28.6086 27.5628 29.7037 27.5628C30.7988 27.5628 31.6876 26.6762 31.6876 25.5837C31.6876 24.4912 30.7988 23.6045 29.7037 23.6045ZM24.9424 8.16699C16.1815 8.16699 9.07129 15.2603 9.07129 24.0003C9.07129 32.7403 16.1815 39.8337 24.9424 39.8337C33.7032 39.8337 40.8134 32.7403 40.8134 24.0003C40.8134 15.2603 33.7032 8.16699 24.9424 8.16699ZM24.9424 36.667C17.9432 36.667 12.2455 30.9828 12.2455 24.0003C12.2455 23.5412 12.2772 23.082 12.3249 22.6387C16.0704 20.9762 19.0383 17.9203 20.5937 14.1362C23.4664 18.1895 28.1959 20.8337 33.5445 20.8337C34.7824 20.8337 35.9728 20.6912 37.1155 20.422C37.4488 21.5462 37.6392 22.7495 37.6392 24.0003C37.6392 30.9828 31.9415 36.667 24.9424 36.667Z" fill="#FAF6F6" />
                        </svg>
                        <div className={"header__right__profile__content" + (isMenuOpen ? ' open' : '')}>
                            <div className="header__right__profile__content__wrapper">
                                <Link to={ROUTS.HOME + ROUTS.PROFILE} className="header__right__profile__content__el">{t("header.account")}</Link>
                                <button onClick={logout} className="header__right__profile__content__el button">
                                    <svg className="header__right__profile__content__el__img" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2190_4489)">
                                            <path d="M16 15.5556L17.5556 14L14.6667 11.1111L26 11.1111V8.88889L14.6667 8.88889L17.5556 6L16 4.44444L10.4444 10L16 15.5556ZM6 2.22222L14.8889 2.22222V0L6 0C4.77778 0 3.77778 1 3.77778 2.22222L3.77778 17.7778C3.77778 19 4.77778 20 6 20L14.8889 20V17.7778L6 17.7778L6 2.22222Z" fill="#56514D" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2190_4489">
                                                <rect width="20" height="20" fill="white" transform="translate(20 20) rotate(-180)" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <span className="header__right__profile__content__el__text">
                                        {t("header.logout")}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                ))}
            </div>
            {loginModalData && (
                <div className={"login__modal modal show"}>
                    <div onClick={toggleLoginModalData} className="overlay"></div>
                    <div className="login__modal__content modal-content">
                        <div className="login__modal__content__wrapper">
                            <h2 className="login__modal__content__title">{t('header.profileReg')}</h2>
                            <div className="login__modal__content__content-text">
                                <span className="login__modal__content__content-text__welcome">{t('header.welcome')}</span>
                                <p className="login__modal__content__content-text__descr">{t('header.text')}</p>
                            </div>
                            <input value={profileName} onChange={e => setProfileName(e.target.value)} placeholder={t('header.name')} type="text" className="login__modal__content__input" />
                            <input value={profileEmail} onChange={e => setProfileEmail(e.target.value)} placeholder="Email" type="email" className="login__modal__content__input" />
                            {/* <input value={profileTelegram} onChange={e => setProfileTelegram(e.target.value)} placeholder="Telegram" type="text" className="login__modal__content__input" /> */}
                            <button onClick={handleAddProfileData} className="login__modal__content__button-next">
                                <svg className="login__modal__content__button-next__img" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20.5" cy="20" r="20" fill="#FDFAFA" />
                                    <g clip-path="url(#clip0_1900_562)">
                                        <path d="M14.5 14.4444L12.9444 16L15.8333 18.8889H4.5V21.1111H15.8333L12.9444 24L14.5 25.5556L20.0556 20L14.5 14.4444ZM24.5 27.7778H15.6111V30H24.5C25.7222 30 26.7222 29 26.7222 27.7778V12.2222C26.7222 11 25.7222 10 24.5 10H15.6111V12.2222H24.5V27.7778Z" fill="#E75549" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1900_562">
                                            <rect width="20" height="20" fill="white" transform="translate(10.5 10)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span className="login__modal__content__button-next__text">{t('header.next')}</span>
                            </button>
                            <button onClick={toggleLoginModalData} className="login__modal__content__skip">
                                <svg className="login__modal__content__skip__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10.5" r="10" fill="#BCB7B3" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3061 6.5566L16.1245 10.375C16.3588 10.6093 16.3588 10.9892 16.1245 11.2235L12.3061 15.0419C12.0718 15.2762 11.6919 15.2762 11.4576 15.0419C11.2232 14.8076 11.2232 14.4277 11.4576 14.1933L14.2517 11.3992H3.7002V10.1992H14.2517L11.4576 7.40512C11.2232 7.17081 11.2232 6.79091 11.4576 6.5566C11.6919 6.32228 12.0718 6.32228 12.3061 6.5566Z" fill="#FDFAFA" />
                                </svg>
                                {t('header.skip')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {loginModal && (
                <div className="login__modal modal show">
                    <div onClick={toggleLoginModal} className="overlay"></div>
                    <div className="login__modal__content modal-content">
                        <div className="login__modal__content__wrapper">
                            <h2 className="login__modal__content__title">{t('header.login')}</h2>
                            <div className="login__modal__content__connect-box">
                                {/* <div className="login__modal__content__connect-box__el">
                                    <img src={IMAGESLOGIN.google} alt="" className="login__modal__content__connect-box__el__img" />
                                </div>
                                <div className="login__modal__content__connect-box__el">
                                    <img src={IMAGESLOGIN.facebook} alt="" className="login__modal__content__connect-box__el__img" />
                                </div> */}
                                <div className="login__modal__content__connect-box__el">
                                    <img src={IMAGESLOGIN.telegram} alt="" className="login__modal__content__connect-box__el__img" />
                                </div>
                            </div>
                            {/* <div className="login__modal__content__or">
                                <span className="login__modal__content__or__text">{t('header.or')}</span>
                            </div>
                            <span className="login__modal__content__choose-number">{t('header.enterNumber')}</span> */}
                            <input disabled={isPhoneInputDisable} onBlur={handleNumberCheck} value={loginTelephone} onChange={handleLoginTelephoneChange} placeholder="+995-хх-ххх-ххх" type="number" className={`login__modal__content__input` + (!isPhoneCorrect ? ' error' : '')} />
                            {/* <p className={"login__modal__content__error-text" + (!isPhoneCorrect ? ' error' : '')}>{t('header.valid')}</p>
                            <p className="login__modal__content__text">{t('header.code')}</p> */}
                        </div>
                    </div>
                </div>
            )}
        </header>
    )
}