import { CHANGE_SUBSCRIPTION_CATION_ACTION, SET_SUBSCRIPTIONS_CATION_ACTION } from './constants.js';

export const changeSubscriptionCationAction = (payload) => ({
    type: CHANGE_SUBSCRIPTION_CATION_ACTION,
    payload,
});

export const setSubscriptionCationAction = (payload) => ({
    type: SET_SUBSCRIPTIONS_CATION_ACTION,
    payload,
});


