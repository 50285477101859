import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Slider from "react-slick";
import IMAGES from "./../images/about/images";
import { Questions } from "./../Component/Questions";
import { useTranslation } from "react-i18next";

export const About = () => {
    const { t, i18n } = useTranslation();

    const [actived, setActived] = useState('');

    const rotator = useRef(null);

    const why1 = useRef(null);
    const why2 = useRef(null);
    const why3 = useRef(null);
    const why4 = useRef(null);
    const why5 = useRef(null);

    const rotator_img1 = useRef(null);
    const rotator_img2 = useRef(null);
    const rotator_img3 = useRef(null);
    const rotator_img4 = useRef(null);

    gsap.registerPlugin(ScrollTrigger);

    let elWidth;

    const [settings, setSettings] = useState({
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        speed: 4000,
        autoplaySpeed: 1000,
        cssEase: "linear",
        arrows: false,
    });

    function useWindowDimensions() {

        const hasWindow = typeof window !== 'undefined';

        function getWindowDimensions() {
            const width = hasWindow ? window.innerWidth : null;
            return {
                width,
            };
        }

        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        useEffect(() => {
            if (hasWindow) {
                function handleResize() {
                    setWindowDimensions(getWindowDimensions());
                }

                window.addEventListener('resize', handleResize);
                return () => window.removeEventListener('resize', handleResize);
            }
        }, [hasWindow]);

        return windowDimensions;
    }

    const width = useWindowDimensions();

    const tl = useRef();

    useEffect(() => {
        elWidth = document.querySelector('.slick-list').offsetWidth;
        if (width.width < 801) {
            setSettings({
                dots: false,
                infinite: true,
                slidesToShow: elWidth / 270,
                slidesToScroll: 1,
                autoplay: true,
                speed: 4000,
                autoplaySpeed: 1000,
                cssEase: "linear",
                arrows: false,
            })
        } else if (width.width > 800 && width.width < 1601) {
            setSettings({
                dots: false,
                infinite: true,
                slidesToShow: elWidth / 320,
                slidesToScroll: 1,
                autoplay: true,
                speed: 4000,
                autoplaySpeed: 1000,
                cssEase: "linear",
                arrows: false,
            })
        } else {
            setSettings({
                dots: false,
                infinite: true,
                slidesToShow: elWidth / 470,
                slidesToScroll: 1,
                autoplay: true,
                speed: 4000,
                autoplaySpeed: 1000,
                cssEase: "linear",
                arrows: false,
            })
        }
    }, []);


    useEffect(() => {
        setActived('actived');
        if (width.width < 600) {
            tl.current && tl.current.progress(0).kill();
            tl.current = gsap.timeline({
                scrollTrigger: {
                    trigger: rotator.current,
                    start: `450 center`,
                    end: `+=1350`,
                    toggleActions: "restart none reverse none",
                    scrub: 1,
                }
            }).to(rotator_img1.current, { opacity: 0, y: 450 * 1, ease: "none" })
                .to(rotator_img2.current, { opacity: 1, y: 450 * 1, ease: "none" }, '<')
                .to(rotator_img3.current, { y: 450 * 1, ease: "none" }, '<')
                .to(rotator_img4.current, { y: 450 * 1, ease: "none" }, '<')
                .to(rotator_img2.current, { opacity: 0, y: 450 * 2, ease: "none" })
                .to(rotator_img3.current, { opacity: 1, y: 450 * 2, ease: "none" }, '<')
                .to(rotator_img4.current, { y: 450 * 2, ease: "none" }, '<')
                .to(rotator_img3.current, { opacity: 0, y: 450 * 3, ease: "none" })
                .to(rotator_img4.current, { opacity: 1, y: 450 * 3, ease: "none" }, '<')

            gsap.to(rotator.current, {
                rotate: -270,
                y: 450 * 3,
                x: 0,
                ease: "none",
                scrollTrigger: {
                    trigger: rotator.current,
                    start: `450 center`,
                    end: `+=1350`,
                    toggleActions: "restart none reverse none",
                    scrub: 1,
                },
            });

        } else {
            gsap.to(rotator.current, {
                rotate: -270,
                y: 300 * 3,
                x: 0,
                ease: "none",
                scrollTrigger: {
                    trigger: why2.current,
                    start: `90 center`,
                    end: `+=900`,
                    toggleActions: "restart none reverse none",
                    scrub: true,
                },
            });

            gsap.to(rotator_img1.current, {
                opacity: 1,
                ease: "none",
                scrollTrigger: {
                    trigger: why1.current,
                    start: `90 center`,
                    end: `+=300`,
                    markers: false,
                    toggleActions: "restart none reverse none",
                    scrub: true,
                },
            });

            gsap.to(rotator_img1.current, {
                opacity: 0,
                y: 300 * 1,
                ease: "none",
                scrollTrigger: {
                    trigger: why2.current,
                    start: "90 center",
                    end: `+=300`,
                    markers: false,
                    toggleActions: "restart none reverse none",
                    scrub: true,
                    immediateRender: false
                },
            });

            gsap.to(rotator_img2.current, {
                opacity: 1,
                y: 300 * 1,
                ease: "none",
                scrollTrigger: {
                    trigger: why2.current,
                    start: "90 center",
                    end: `+=300`,
                    markers: false,
                    toggleActions: "restart none reverse none",
                    scrub: true,
                },
            });


            gsap.to(rotator_img2.current, {
                opacity: 0,
                y: 300 * 2,
                ease: "none",
                scrollTrigger: {
                    trigger: why3.current,
                    start: "90 center",
                    end: `+=300`,
                    markers: false,
                    toggleActions: "restart none reverse none",
                    scrub: true,
                    immediateRender: false
                },
            }
            );


            gsap.to(rotator_img3.current, {
                opacity: 1,
                y: 300 * 1,
                ease: "none",
                scrollTrigger: {
                    trigger: why3.current,
                    start: "90 center",
                    end: `+=300`,
                    markers: false,
                    toggleActions: "restart none reverse none",
                    scrub: true,
                },
            });

            gsap.to(rotator_img3.current, {
                opacity: 0,
                y: 300 * 2,
                ease: "none",
                scrollTrigger: {
                    trigger: why4.current,
                    start: "90 center",
                    end: `+=300`,
                    markers: false,
                    toggleActions: "restart none reverse none",
                    scrub: true,
                    immediateRender: false
                },
            }
            );

            gsap.to(rotator_img4.current, {
                opacity: 1,
                y: 300 * 1,
                ease: "none",
                scrollTrigger: {
                    trigger: why4.current,
                    start: "90 center",
                    end: `+=300`,
                    markers: false,
                    toggleActions: "restart none reverse none",
                    scrub: true,
                },
            });
        }

        return () => {
            window.scrollTo(0, 0);
        }

    }, []);

    return (

        <div className="about">
            <div className="about__promo">
                <div className="about__promo__wrapper">
                    <div className="about__promo__box">
                        <h1 className="about__promo__box__title">{t("about.headtitle")}</h1>
                        <p className="about__promo__box__text">{t("about.headtext")}</p>
                    </div>
                    <img src={IMAGES.bgFirstPage} alt="box" className={`about__promo__bg ${actived}`} />
                    <div className={`about__promo__shield ${actived}`}></div>
                </div>
            </div>
            <div className="about__life">
                <p className="about__life__text"><font style={{ color: '#56514D' }}>
                    {t("about.lifetext")}</font>{t("about.lifetextblack")}
                </p>
            </div>
            <div className="about__why">
                <img ref={rotator} src={IMAGES.rotate} alt="" className="about__why__el__rotate" />
                <img ref={rotator_img1} src={IMAGES.why_img1} alt="" className="about__why__el__rotate__img first" />
                <img ref={rotator_img2} src={IMAGES.why_img2} alt="" className="about__why__el__rotate__img second" />
                <img ref={rotator_img3} src={IMAGES.why_img3} alt="" className="about__why__el__rotate__img third" />
                <img ref={rotator_img4} src={IMAGES.why_img4} alt="" className="about__why__el__rotate__img fouth" />
                <div className="about__why__el left main" ref={why1}>
                    <h2 className="about__why__el__title main">{t("about.whytitle")}</h2>
                    <p className="about__why__el__text">{t("about.whytext1")}
                        <font>{t("about.whytext2")}</font>
                        {t("about.whytext3")}</p>
                </div>
                <div className="about__why__el right" ref={why2}>
                    <h2 className="about__why__el__title">{t("about.whytitle2")}</h2>
                    <p className="about__why__el__text">{t("about.whytext21")}<font style={{ color: '#0B0A0A' }}>{t("about.whytext22")}</font>
                        {t("about.whytext23")}</p>
                </div>
                <div className="about__why__el left" ref={why3}>
                    <h2 className="about__why__el__title">{t("about.whytitle3")}</h2>
                    <p className="about__why__el__text">{t("about.whytext31")}<font style={{ color: '#0B0A0A' }}>{t("about.whytext32")}</font>
                        {t("about.whytext33")}</p>
                </div>
                <div className="about__why__el right" ref={why4}>
                    <h2 className="about__why__el__title">{t("about.whytitle4")}</h2>
                    <p className="about__why__el__text">
                        {t("about.whytext41")}<font style={{ color: '#0B0A0A' }}>{t("about.whytext42")}</font>{t("about.whytext43")}<font style={{ color: '#0B0A0A' }}>{t("about.whytext44")}</font>{t("about.whytext45")}
                    </p>
                </div>
                <div className="about__why__el left" ref={why5}>
                    <h2 className="about__why__el__title">{t("about.whytitle5")}</h2>
                    <p className="about__why__el__text">{t("about.whytext51")}<font style={{ color: '#0B0A0A' }}>{t("about.whytext52")}</font>{t("about.whytext53")}</p>
                </div>
            </div>
            <div className="about__team">
                <div className="about__team__wrapper">
                    <div className="about__team__header">
                        <h2 className="about__team__header__title">{t("about.team")}</h2>
                        <div className="about__team__header__buttons">
                        </div>
                    </div>
                    <Slider className="about__team__slider" {...settings}>
                        <div className="about__team__slider__card">
                            <img src={IMAGES.Nadejda1} alt="" className="about__team__slider__card__img" />
                            <p className="about__team__slider__card__position">{t('about.experts.1.profession')}</p>
                            <p className="about__team__slider__card__name">{t('about.experts.1.name')}</p>
                            <div className="about__team__slider__card__info">{t('about.experts.1.1')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.1.2')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.1.3')}</div>
                        </div>
                        <div className="about__team__slider__card">
                            <img src={IMAGES.Elene1} alt="" className="about__team__slider__card__img" />
                            <p className="about__team__slider__card__position">{t('about.experts.2.profession')}</p>
                            <p className="about__team__slider__card__name">{t('about.experts.2.name')}</p>
                            <div className="about__team__slider__card__info">{t('about.experts.2.1')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.2.2')}</div>
                            {/* <div className="about__team__slider__card__info">{t('about.experts.2.3')}</div> */}
                        </div>
                        <div className="about__team__slider__card">
                            <img src={IMAGES.Valeri1} alt="" className="about__team__slider__card__img" />
                            <p className="about__team__slider__card__position">{t('about.experts.3.profession')}</p>
                            <p className="about__team__slider__card__name">{t('about.experts.3.name')}</p>
                            <div className="about__team__slider__card__info">{t('about.experts.3.1')}</div>
                            {/* <div className="about__team__slider__card__info">{t('about.experts.3.2')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.3.3')}</div> */}
                        </div>
                        <div className="about__team__slider__card">
                            <img src={IMAGES.Iulia1} alt="" className="about__team__slider__card__img" />
                            <p className="about__team__slider__card__position">{t('about.experts.4.profession')}</p>
                            <p className="about__team__slider__card__name">{t('about.experts.4.name')}</p>
                            <div className="about__team__slider__card__info">{t('about.experts.4.1')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.4.2')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.4.3')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.4.4')}</div>
                        </div>
                        {/* <div className="about__team__slider__card">
                            <img src={IMAGES.photo} alt="" className="about__team__slider__card__img" />
                            <p className="about__team__slider__card__position">{t('about.experts.5.profession')}</p>
                            <p className="about__team__slider__card__name">{t('about.experts.5.name')}</p>
                            <div className="about__team__slider__card__info">{t('about.experts.5.1')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.5.2')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.5.3')}</div>
                        </div> */}
                        <div className="about__team__slider__card">
                            <img src={IMAGES.NikaTs1} alt="" className="about__team__slider__card__img" />
                            <p className="about__team__slider__card__position">{t('about.experts.6.profession')}</p>
                            <p className="about__team__slider__card__name">{t('about.experts.6.name')}</p>
                            <div className="about__team__slider__card__info">{t('about.experts.6.1')}</div>
                        </div>
                        {/* <div className="about__team__slider__card">
                            <img src={IMAGES.photo} alt="" className="about__team__slider__card__img" />
                            <p className="about__team__slider__card__position">{t('about.experts.7.profession')}</p>
                            <p className="about__team__slider__card__name">{t('about.experts.7.name')}</p>
                            <div className="about__team__slider__card__info">{t('about.experts.7.1')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.7.2')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.7.3')}</div>
                        </div>
                        <div className="about__team__slider__card">
                            <img src={IMAGES.photo} alt="" className="about__team__slider__card__img" />
                            <p className="about__team__slider__card__position">{t('about.experts.8.profession')}</p>
                            <p className="about__team__slider__card__name">{t('about.experts.8.name')}</p>
                            <div className="about__team__slider__card__info">{t('about.experts.8.1')}</div>
                            <div className="about__team__slider__card__info">{t('about.experts.8.2')}</div>
                        </div> */}
                        <div className="about__team__slider__card">
                            <img src={IMAGES.Ekaterina} alt="" className="about__team__slider__card__img" />
                            <p className="about__team__slider__card__position">{t('about.experts.9.profession')}</p>
                            <p className="about__team__slider__card__name">{t('about.experts.9.name')}</p>
                            <div className="about__team__slider__card__info">{t('about.experts.9.1')}</div>
                        </div>
                    </Slider>
                </div>
            </div>
            <Questions />
        </div>
    )
}