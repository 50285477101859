import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { categoryInfoSelector } from "../../../../Store/Categories/selectors";

export const GroupStatic = ({ language, id }) => {
    const { t, i18n } = useTranslation();

    const category = useSelector(categoryInfoSelector).filter((item) => item.id == id)[0];

    return (
        <div className="dashboard__popup__inputs">
            {category && (
                <>
                    {language == 'en' ?
                        (<div key={1} className="dashboard__popup__inputs__el">
                            <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                            </svg>
                            <div className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.groups.title')}>{category.en}</div>
                        </div>) : language == "ka" ?
                        (<div key={2} className="dashboard__popup__inputs__el">
                            <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                            </svg>
                            <div className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.groups.title')}>{category.ka}</div>
                        </div>) :
                         (<div key={3} className="dashboard__popup__inputs__el">
                         <svg className="dashboard__popup__inputs__el__img" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M14.166 3H5.83268C4.91602 3 4.16602 3.75 4.16602 4.66667V18L9.99935 15.5L15.8327 18V4.66667C15.8327 3.75 15.0827 3 14.166 3ZM14.166 15.5L9.99935 13.6833L5.83268 15.5V4.66667H14.166V15.5Z" fill="#56514D" />
                         </svg>
                         <div className="dashboard__popup__inputs__el__input" placeholder={t('dashboard.popup.groups.title')}>{category.uk}</div>
                     </div>)
                    }
                </>
            )}
        </div >
    )
}